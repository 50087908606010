import * as React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useTranslate, useListContext, useResourceContext, useGetResourceLabel, } from 'ra-core';

import {
  CreateButton     
} from 'react-admin';

export var Empty = function ({ title, subtitle, icon, ...props }) {
  var _a = useListContext(props), basePath = _a.basePath, hasCreate = _a.hasCreate;
  var resource = useResourceContext(props);
  var classes = useStyles(props);
  var translate = useTranslate();
  var getResourceLabel = useGetResourceLabel();
  var resourceName = translate('resources.' + resource + '.forcedCaseName', {
    smart_count: 0,
    _: getResourceLabel(resource, 0),
  });
  var emptyMessage = translate('ra.page.empty', { name: title });
  var inviteMessage = translate('ra.page.invite');
  return (React.createElement(React.Fragment, null,
    React.createElement('div', { className: classes.message },
      React.createElement(icon, { className: classes.icon }),
      React.createElement(Typography, { variant: 'h4', paragraph: true }, translate('resources.' + resource + '.empty', {
        _: emptyMessage,
      })),
      hasCreate && (React.createElement(Typography, { variant: 'body1' }, translate('resources.' + resource + '.invite', {
        _: inviteMessage,
      })))),
    hasCreate && (React.createElement('div', { className: classes.toolbar },
      React.createElement(CreateButton, { variant: 'contained', basePath: basePath })))));
};
var useStyles = makeStyles(function (theme) { return ({
  message: {
    textAlign: 'center',
    opacity: theme.palette.type === 'light' ? 0.5 : 0.8,
    margin: '2em 1em',
    color: theme.palette.type === 'light'
      ? 'inherit'
      : theme.palette.text.primary,
  },
  icon: {
    width: '9em',
    height: '9em',
  },
  toolbar: {
    textAlign: 'center',
    marginTop: '2em',
  },
}); }, { name: 'RaEmpty' });
