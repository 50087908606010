// in src/posts.js
import React, { useState } from 'react';

import {
  List, 
  Datagrid,
  TextField,
  DeleteButton,
  TextInput,
  Button ,
  TopToolbar, required,NumberInput,  ResourceContextProvider, useCreate, useRefresh, FormWithRedirect, SaveButton,
  useUpdate,
  useNotify,
  BooleanField,
  BooleanInput,
  SelectInput,
  FormDataConsumer,
  FunctionField
} from 'react-admin';


import { useTranslate  } from '../common/language';

import Typography from '@mui/material/Typography';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Avatar from '@mui/material/Avatar';


import EditIcon from '@material-ui/icons/Edit';
import Grid from '@mui/material/Grid';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';


import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';




import Box from '@material-ui/core/Box';


import { validateMaxLength, validateInteger  } from './../components/Validator';


export const SettingsPaymentSystems = (props) => {
  const translate = useTranslate();    
    
  let basePath='/payment_systems'; 
  let resource = 'payment_systems';
  let strRedirect =  '/settings-payment-systems';  
    
  return (
    <>
      <br/>
      <Typography variant="h5" className="grey8" align="center">
        {translate('settings.payment_systems_desc')}              
      </Typography>
      <Box sx={{ bgcolor: 'background.paper', padding: '20px' }} className="settings_box" >
        <ResourceContextProvider value={resource}>
      <List 
        basePath={basePath}   
        filter={{ associations_id : localStorage.getItem('associations_id') }}  
        actions={<ListActions />}    
        empty={<ListActions />}        
        bulkActionButtons={false}          
        perPage={1000}   
        pagination={false}
        sort={{ field: 'id', order: 'ASC' }} 
        label=""  
        title=""  
        resource={resource}        
      >        
        <Datagrid> 
          <Avatar><CreditCardIcon /></Avatar>     
          <FunctionField render={record => translate('payment_systems.plattform_' + record.plattform ) } label={translate('payment_systems.plattform')} sortable={false} />
          <TextField source="name" label={translate('payment_systems.name')} sortable={false} />    
          {localStorage.getItem('permissions_groups') === 'SIFAC' ?
          <TextField source="public_id" label={translate('payment_systems.public_id')} sortable={false} />    
          : null }
          <TextField source="url" label={translate('payment_systems.url')} sortable={false} />   
          <BooleanField source="real" label={translate('payment_systems.real')} sortable={false} />  
          <MyEditButton label="" />
          <DeleteButton undoable={false} redirect={strRedirect} label=""/>
        </Datagrid>        
      </List>
    </ResourceContextProvider>
      </Box>
    </>
  );
};

const ListActions = (props) => {
  const translate = useTranslate(); 
  return (
    <TopToolbar>     
      <MyCreateButton />      
    </TopToolbar>
  );
};


const MyCreateButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
    
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  

  const [create, { loading }] = useCreate('payment_systems');

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify( error.message , 'error');
        }
      }
    );
  };    

  return (
    <>            
      <Button onClick={handleClick} label={translate('payment_systems.create')}>
        <IconContentAdd />                
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('payment_systems.create')}
        className="dialog-md"
        maxWidth="md"
      >
        <DialogTitle>{translate('payment_systems.create')}</DialogTitle>

        <FormWithRedirect
          resource="payment_systems"
          save={handleSubmit}
          initialValues={ { associations_id : localStorage.getItem('associations_id'), currency: 978, transaction_type: 0, plattform: 0 } }
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <FormContent />
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

const FormContent = (props) => {

  const translate = useTranslate(); 
  return (
    <DialogContent>
      <FormDataConsumer>
      {({ formData, ...rest }) => 
      <Grid container spacing={2}>     
        <Grid item xs={3}>   
            <SelectInput fullWidth source="plattform" label={translate('payment_systems.plattform')} validate={[required()]} choices={[{id:0,name:translate('payment_systems.plattform_0')},{id:1,name:translate('payment_systems.plattform_1')}]} />
        </Grid> 
        <Grid item xs={localStorage.getItem('permissions_groups') === 'SIFAC' ? 6 : 9}>   
            <TextInput fullWidth source="name" label={translate('payment_systems.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
        </Grid> 
        {localStorage.getItem('permissions_groups') === 'SIFAC' ?
        <Grid item xs={3}>   
            <TextInput fullWidth source="public_id" label={translate('payment_systems.public_id')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />
        </Grid> 
        : null }
        <Grid item xs={3}>   
            <TextInput fullWidth source="merchant" label={translate('payment_systems.merchant')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
        </Grid>                 
        <Grid item xs={3}>   
            <TextInput fullWidth source="terminal" label={translate('payment_systems.terminal')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />            
        </Grid> 
        <Grid item xs={2}>   
            <TextInput fullWidth source="currency" label={translate('payment_systems.currency')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
        </Grid> 
        <Grid item xs={4}>   
            <TextInput fullWidth source="encrypt" label={translate('payment_systems.encrypt')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
        </Grid>   
        { false ?
        <Grid item xs={2}>               
            <TextInput fullWidth source="transaction_type" label={translate('payment_systems.transaction_type')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />            
        </Grid> : null }
        <Grid item xs={6}>   
            <TextInput fullWidth source="url" label={translate('payment_systems.url')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
        </Grid>
        <Grid item xs={6}>   
            <TextInput fullWidth source="url_test" label={translate('payment_systems.url_test')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
        </Grid>
        <Grid item xs={12}>   
            <TextInput fullWidth source="url_async" label={translate('payment_systems.url_async')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
        </Grid> 
        <Grid item xs={6}>   
            <TextInput fullWidth source="url_ok" label={translate('payment_systems.url_ok')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
        </Grid> 
        <Grid item xs={6}>   
            <TextInput fullWidth source="url_ko" label={translate('payment_systems.url_ko')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
        </Grid> 
        <Grid item xs={12}>   
            <BooleanInput fullWidth source="real" label={translate('payment_systems.real')}  />
        </Grid>                
      </Grid> 
      }
      </FormDataConsumer>
    </DialogContent>
  );
};

const MyEditButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
    
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  

  const [update, { loading, error }] = useUpdate();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    update(
      { 
        type: 'update', 
        resource: 'payment_systems', 
        payload : { id: values.id, data : values }
      },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };    

  return (
    <>
      <Button onClick={handleClick} label="" startIcon={<EditIcon />} />

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('payment_systems.edit')}
        className="dialog-md"
        maxWidth="md"
      >
        <DialogTitle>{translate('payment_systems.edit')}</DialogTitle>

        <FormWithRedirect
          resource="payment_systems"
          save={handleSubmit}
          initialValues={ props.record }
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <FormContent />
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

