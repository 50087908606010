import React, { useState } from 'react';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  useCreate,
  useUpdate,
  useNotify,
  FormWithRedirect,
  useRefresh, TopToolbar, useTranslate, SelectInput, NumberInput, BooleanInput, FormDataConsumer, downloadCSV
} from 'react-admin';

import jsonExport from 'jsonexport/dist';
import {emailsStatus } from './../common/constants';

import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';


import Grid from '@mui/material/Grid';


import { choicesRemittancesStatus} from './../common/constants';

import { UserInput } from '../components/User';
import { ExportButton } from './../components/ExportButton';

import { validateMaxLength, validateInteger  } from './../components/Validator';

export const RemittancesUsersEditButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  

  const [update, { loading, error }] = useUpdate();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    update(
      { 
        type: 'update', 
        resource: 'remittances_users', 
        payload : { id: values.remittances_users_id, data : { status : values.status, comments: values.comments, reemit: values.reemit, payer_payment_cash: values.payer_payment_cash, return_notification: values.return_notification } }
      },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };    

  return (
    <>
      <Button onClick={handleClick} label="" startIcon={<EditIcon />} />

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('remittances.status_form')}
      >
        <DialogTitle>{translate('remittances.status_form')}</DialogTitle>

        <FormWithRedirect
          resource="remittances_users_items"
          save={handleSubmit}
          initialValues={{ remittances_users_id : props.record.id, status : props.record.status, comments: props.record.comments, reemit: props.record.reemit, payer_payment_cash: props.record.payer_payment_cash, return_notification : false }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={5}>     
                    <SelectInput fullWidth label={translate('remittances.status_new')} source="status" choices={choicesRemittancesStatus(translate)} />
                  </Grid> 
                  <Grid item xs={1}> 
                  </Grid> 
                  <Grid item xs={6}>  <br/>   
                    <BooleanInput label={translate('remittances.payer_payment_cash')} source="payer_payment_cash" />
                  </Grid> 
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12}>  
                    <TextInput label={translate('remittances.comments_form')} fullWidth source="comments" validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />                                        
                  </Grid> 
                </Grid>                                
                                
                <FormDataConsumer>
                  {({ formData, ...rest }) => 
                    formData.status === 1 && 
                                <>                                
                                  <BooleanInput label={translate('remittances.check_reemit')} source="reemit" />
                                  <BooleanInput label={translate('remittances.check_sent_return_notification')} source="return_notification" />
                                </>                                
                  }
                </FormDataConsumer>
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};


export const RemittancesUsersRefreshButton = ({ record, ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  

  const [update, { loading, error }] = useUpdate();

  const handleClickRefresh = id => {
        
    update(
      { 
        type: 'update', 
        resource: 'remittances_users/refresh', 
        payload : { id: id, data : { refresh : true } }
      },
      {
        onSuccess: ({ data }) => {
          notify(translate('remittances.refresh_payer_confirm'));
          refresh();
        },
        onFailure: ( error ) => {                                        
          notify(error.message, 'error');
        }
      }
    );

  };

  return (
    <Button onClick={ (e) => { e.stopPropagation(); handleClickRefresh(record.id); } } label={translate('remittances.refresh_payer')} startIcon={<RefreshIcon />} />        
  );
};


export const RemittancesUsersToolbar = ({ remittances_id , date_creation, ...props }) => {

  let filterExport = {
    remittances_id : remittances_id,  
    associations_id: localStorage.getItem('associations_id'),
    export: 1,
  };
    
  return (        
    <TopToolbar>
      <RemittancesUsersCreateButton remittances_id={remittances_id} date_creation={date_creation} />
      <ExportButton maxResults="100000" filterValues={filterExport} alwaysEnabled={false} exporter={exporter} sort={{ field: 'id', order: 'DESC' }} />        
    </TopToolbar>        
  );
};


const exporter = (items, translate, notify) => {
    
  const eventsForExport = items.map(remittance_user => {
    var eventForExport = {
      id : remittance_user.id,
      first_name : remittance_user.first_name, 
      last_name_1 : remittance_user.last_name_1, 
      last_name_2 : remittance_user.last_name_2, 
      email: remittance_user.email,
      payer_first_name : remittance_user.payer_first_name, 
      payer_last_name_1 : remittance_user.payer_last_name_1, 
      payer_last_name_2 : remittance_user.payer_last_name_2, 
      payer_iban: remittance_user.payer_iban, 
      payer_nif: remittance_user.payer_nif,
      payer_payment_cash: remittance_user.payer_payment_cash ? translate('remittances.cash') : translate('remittances.bank'), 
      quantity: remittance_user.quantity != null ? remittance_user.quantity.toFixed(2).replace('.', ',') : '',   
      status: translate('remittances.status' + remittance_user.status ) ,
      returned_status: remittance_user.status === 1 ? remittance_user.returned_status === emailsStatus.SENT_TO_SMTP_OK ? translate('common.yes') : translate('common.no') : '',
      concept: remittance_user.items ? remittance_user.items
              .map(
                  (item) =>
                      `${item.concept} (${item.quantity.toFixed(2).replace('.', ',')})`
              )
              .join(', ') : '',
      comments: remittance_user.comments
    };
    return eventForExport;
  });
  jsonExport(eventsForExport, {
    headers: ['id', 'first_name', 'last_name_1', 'last_name_2','email','quantity', 'payer_first_name', 'payer_last_name_1', 'payer_last_name_2', 
      'payer_iban', 'payer_nif','payer_payment_cash','status','returned_status','concept', 'comments'],
    rename: [translate('remittances.ref'), translate('users.first_name'), translate('users.last_name_1'), translate('users.last_name_2'), 
      translate('users.email'), translate('remittances.quantity'), translate('users.first_name') + ' (' + translate('remittances.payer') + ')', 
      translate('users.last_name_1')+ ' (' + translate('remittances.payer') + ')', translate('users.last_name_2')+ ' (' + translate('remittances.payer') + ')',
      translate('users.bank_iban')+ ' (' + translate('remittances.payer') + ')', translate('users.nif')+ ' (' + translate('remittances.payer') + ')',
      translate('remittances.payment_system'),translate('remittances.status'), translate('remittances.exporter_header_returned_status') , translate('remittances.concept'), translate('remittances.comments')  
    ],        
        
  }, (err, csv) => {
    const csv_new = '\uFEFF' + csv;
    downloadCSV(csv_new, translate('remittances.exporter_file_name') ); 
    notify(translate('common.download_ok'),"success");
  });
};


export const RemittancesUsersCreateButton = ({ remittances_id , date_creation, ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('remittances_users');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  


  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify( error.message , 'error');
        }
      }
    );
  };
    
  return (
    <>            
      <Button onClick={handleClick} label={translate('remittances.create')}>
        <IconContentAdd />                
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('remittances.create')}
      >
        <DialogTitle>{translate('remittances.create')}</DialogTitle>

        <FormWithRedirect
          save={handleSubmit}
          initialValues={{ remittances_id : remittances_id }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}> 
                    <UserInput source="users_id" user_type={-1} disabled={false} label={translate('common.name')} allowEmpty={false} required={true} />
                  </Grid> 
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={9}>     
                    <TextInput label={translate('remittances.concept')} fullWidth source="concept" validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />                                        
                  </Grid> 
                  <Grid item xs={3}>     
                    <NumberInput label={translate('remittances.quantity')} fullWidth source="quantity" validate={[required(),validateInteger(translate('common.validation.number'))]} />                                        
                  </Grid> 
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};
