import React, { useState } from 'react';

import { SaveButton ,
  List,
  Datagrid,
  TextField,
  DeleteButton,
  useTranslate,
  required,    
  useCreate,
  useUpdate,
  useNotify,
  FormWithRedirect,useRefresh,
  Button,
  FunctionField, TopToolbar, NumberInput, ResourceContextProvider
} from 'react-admin';


import EditIcon from '@material-ui/icons/Edit';

import { useStyles  } from '../common/styles';

import Typography from '@material-ui/core/Typography';



import Divider from '@mui/material/Divider';

import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';


import { TextFieldShow } from '../components/TextFieldShow';

import Grid from '@mui/material/Grid';


import { validateInteger, validateDouble  } from './../components/Validator';

export const UsersWorkersList = (props) => {
  const translate = useTranslate(); 
    
  let strRedirect =  `/users/${props.users_id}/more_federation`; 
        
  return (
           
    <ResourceContextProvider value="users_workers" >
      <List 
        syncWithLocation 
        basePath="/users_workers" 
        filter={{ users_id : props.users_id, type: props.type }}  
        title=""
        bulkActionButtons={false}                
        actions={<UsersWorkersToolbar users_id={props.users_id} type={props.type} /> }  
        perPage={1000}
        sort={{ field: 'year', order: 'ASC' }} 
        empty={<UsersWorkersToolbar users_id={props.users_id} type={props.type} /> } 
        pagination={false}
      >

        <Datagrid   
          expand={<UsersWorkersDetails type={props.type} />} 
          className="header_grey6"
          label=""
          fullWidth
        >
          <TextField source="year" label={translate('common.year')} sortable={false} /> 
          <FunctionField label={translate('users_workers.total_' + props.type)} render={record => <RenderWorkersTotal record={record} /> } sortable={false} />
          <UsersWorkersEditButton label="" type={props.type} />
          <DeleteButton undoable={false} redirect={strRedirect} label=""/>
        </Datagrid>

      </List> 
    </ResourceContextProvider>

  );
};


export const UsersWorkersEditButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  

  const [update, { loading, error }] = useUpdate();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };
                        
  const handleSubmit = async values => {
    update(
      { 
        type: 'update', 
        resource: 'users_workers', 
        payload : { id: values.id, data : 
                {       users_id: values.users_id,
                  year : values.year, 
                  administration: values.administration,
                  activities: values.activities,
                  concierge: values.concierge,
                  clean_up: values.clean_up,
                  comunication : values.comunication,
                  accounting : values.accounting,
                  maintenance : values.maintenance,
                  management : values.management,
                  others : values.others
                } }
      },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };    

  return (
    <>
      <Button onClick={handleClick} label="" startIcon={<EditIcon />} />

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
      >
        <DialogTitle>{translate('users_workers.edit_title_' + props.type)}</DialogTitle>

        <FormWithRedirect
          resource=""
          save={handleSubmit}
          initialValues={{ id : props.record.id, 
            users_id: props.record.users_id,
            year : props.record.year, 
            administration: props.record.administration,
            activities: props.record.activities,
            concierge: props.record.concierge,
            clean_up: props.record.clean_up,
            comunication : props.record.comunication,
            accounting : props.record.accounting,
            maintenance : props.record.maintenance,
            management : props.record.management,
            others : props.record.others
          }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <UsersWorkersForm edit={true} />
                            
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

const RenderWorkersTotal = ({ record, ...props }) => {
                           
  let total_workers = record.administration + record.activities + record.concierge + record.clean_up + record.comunication + record.accounting + record.maintenance + record.management + record.others;
  return (        
    total_workers
  );
};

export const UsersWorkersShow = ({ ...props }) => {
  const translate = useTranslate();   

  return (
        
    <ResourceContextProvider value="users_workers" >
      <List 
        syncWithLocation 
        basePath="/users_workers" 
        filter={{ users_id : props.users_id, type: props.type }}  
        title=""
        bulkActionButtons={false}                                
        perPage={1000}
        sort={{ field: 'year', order: 'ASC' }} 
        pagination={false}
        actions={false}
        empty={<></>}
        className="users_remittances_list"
      >                            
        <Datagrid
          expand={<UsersWorkersDetails type={props.type} />} 
          className="header_grey6"
          fullWidth
        >   
          <TextField source="year" label={translate('common.year')} sortable={false}  />                        
          <FunctionField label={translate('users_workers.total_' + props.type)} render={record => <RenderWorkersTotal record={record} /> } sortable={false} />
        </Datagrid>

      </List> 

    </ResourceContextProvider>            

  );
};

export const UsersWorkersToolbar = ({ ...props }) => {

  return (
        
    <TopToolbar>
      <UsersWorkersCreateButton users_id={props.users_id} type={props.type} />
    </TopToolbar>
        
  );
};


export const UsersWorkersCreateButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('users_workers');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  
    

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };


  return (
    <>            
      <Button onClick={handleClick} label={translate('users_workers.create_button')}>
        <IconContentAdd />                
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('users_workers.create_' + props.type)}
      >
        <DialogTitle>{translate('users_workers.create_' + props.type)}</DialogTitle>

        <FormWithRedirect
          resource="users_workers"
          save={handleSubmit}
          initialValues={{ users_id : props.users_id, type: props.type }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <UsersWorkersForm edit={false} />
                            
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

const UsersWorkersForm = ({ edit, ...props }) => {
  const translate = useTranslate(); 
  const classes = useStyles();
    
  return (
    <DialogContent className="users_workers_form">
      <br />
      <Grid container spacing={2} >
        <Grid item xs={3}>     
          <Typography className="grey8">{translate('common.year')}                                 
          </Typography>
        </Grid> 
        <Grid item xs={3}>  
          { edit ? (
            <NumberInput label="" fullWidth source="year" step={1} disabled />                                        
          ) : (
            <NumberInput label="" fullWidth source="year" step={1} validate={[required(),validateInteger(translate('common.validation.number'))]} />                                        
          )}
        </Grid> 
      </Grid>
                
      <br />                    
      <Grid container spacing={2}>
        <Grid item xs={9}>     
          <Typography className="grey8">{translate('users_workers.administration')}                                   
          </Typography>
        </Grid> 
        <Grid item xs={3}>     
          <NumberInput label="" fullWidth source="administration" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
        </Grid> 
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={9}>     
          <Typography className="grey8">{translate('users_workers.activities')}                                   
          </Typography>
        </Grid> 
        <Grid item xs={3}>     
          <NumberInput label="" fullWidth source="activities" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
        </Grid> 
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={9}>     
          <Typography className="grey8">{translate('users_workers.concierge')}                                   
          </Typography>
        </Grid> 
        <Grid item xs={3}>     
          <NumberInput label="" fullWidth source="concierge" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
        </Grid> 
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={9}>     
          <Typography className="grey8">{translate('users_workers.clean_up')}                                   
          </Typography>
        </Grid> 
        <Grid item xs={3}>     
          <NumberInput label="" fullWidth source="clean_up" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
        </Grid> 
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={9}>     
          <Typography className="grey8">{translate('users_workers.comunication')}                                   
          </Typography>
        </Grid> 
        <Grid item xs={3}>     
          <NumberInput label="" fullWidth source="comunication" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
        </Grid> 
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={9}>     
          <Typography className="grey8">{translate('users_workers.accounting')}                                   
          </Typography>
        </Grid> 
        <Grid item xs={3}>     
          <NumberInput label="" fullWidth source="accounting" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
        </Grid> 
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={9}>     
          <Typography className="grey8">{translate('users_workers.maintenance')}                                   
          </Typography>
        </Grid> 
        <Grid item xs={3}>     
          <NumberInput label="" fullWidth source="maintenance" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
        </Grid> 
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={9}>     
          <Typography className="grey8">{translate('users_workers.management')}                                   
          </Typography>
        </Grid> 
        <Grid item xs={3}>     
          <NumberInput label="" fullWidth source="management" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
        </Grid> 
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={9}>     
          <Typography className="grey8">{translate('users_workers.others')}                                   
          </Typography>
        </Grid> 
        <Grid item xs={3}>     
          <NumberInput label="" fullWidth source="others" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
        </Grid> 
      </Grid>
                        
    </DialogContent>
  );
    
    
};
    
export const UsersWorkersDetails = ({ id, record, resource, ...props }) => {
  const translate = useTranslate(); 

  let total_workers = record.administration + record.activities + record.concierge + record.clean_up + record.comunication + record.accounting + record.maintenance + record.management + record.others;
        
  return (
    <div className="usersEconomicDataExpand">
      <Grid container spacing={2}>
        <Grid item xs={2} >
          <FunctionField render={record => <TextFieldShow source={record.year} label={translate('common.year')} /> } />
        </Grid>
        <Grid item xs={3} >
          <FunctionField render={record => <TextFieldShow source={total_workers} label={translate('users_workers.total_' + props.type)} /> } />
        </Grid> 
      </Grid>
        
      <br />
      <Divider className="" />
                
      <Grid container spacing={2}>
        <Grid item xs={2} >
        </Grid>
        <Grid item xs={3} >
          <FunctionField render={record => <TextFieldShow source={record.administration} label={translate('users_workers.administration')} /> } /><br />
          <FunctionField render={record => <TextFieldShow source={record.activities} label={translate('users_workers.activities')} /> } /><br />
          <FunctionField render={record => <TextFieldShow source={record.concierge} label={translate('users_workers.concierge')} /> } /><br />
          <FunctionField render={record => <TextFieldShow source={record.clean_up} label={translate('users_workers.clean_up')} /> } /><br />
          <FunctionField render={record => <TextFieldShow source={record.comunication} label={translate('users_workers.comunication')} /> } /><br />
          <FunctionField render={record => <TextFieldShow source={record.accounting} label={translate('users_workers.accounting')} /> } /><br />
          <FunctionField render={record => <TextFieldShow source={record.maintenance} label={translate('users_workers.maintenance')} /> } /><br />
          <FunctionField render={record => <TextFieldShow source={record.management} label={translate('users_workers.management')} /> } /><br />
          <FunctionField render={record => <TextFieldShow source={record.others} label={translate('users_workers.others')} /> } />
        </Grid>
        <Grid item xs={7} >
        </Grid>
      </Grid>
        
      <br /> 
    </div>
  );
};