import React, { useState } from 'react';

import {
  List, 
  Datagrid,
  TextField,
  DeleteButton,
  Edit,
  SelectInput,
  TextInput,
  Button ,
  Create,
  SimpleForm,
  CreateButton ,
  TopToolbar,
  FormTab,
  TabbedForm,
  ReferenceManyField,
  FormDataConsumer,
  useListContext, required,NumberInput,BooleanInput,SelectField,  FunctionField, ResourceContextProvider, useCreate, useRefresh, FormWithRedirect, Toolbar, SaveButton,
  useNotify,
  useRedirect,
  DateField  , 
  Filter, 
  downloadCSV,
  DateInput,
  SimpleFormIterator,
  ArrayInput,BooleanField, useUpdate
} from 'react-admin';

import { useEffect } from 'react';
import {useHistory} from 'react-router-dom';

import jsonExport from 'jsonexport/dist';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { AccordionSection  } from '@react-admin/ra-form-layout';


import { makeStyles } from '@material-ui/core/styles';

import Divider from '@mui/material/Divider';


import { useTranslate  } from '../common/language';

import Typography from '@mui/material/Typography';


import RichTextInput from 'ra-input-rich-text';


import { TextFieldShow } from '../components/TextFieldShow';

import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import GavelIcon from '@material-ui/icons/Gavel';
import EditIcon from '@material-ui/icons/Edit';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Chip from '@material-ui/core/Chip';

import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';

import { ColorInput } from 'react-admin-color-input';

import { DocsList, DocsListShow } from './docs';
import { targets, modules, choicesClassroomsType  } from './../common/constants';
import { dateOnlyToCatalan, subscriptionModule, setActivePermissionData, structArrayToIdList } from './../common/utils';  

import { CityInput } from './../components/Inputs';
import { ExportButton } from './../components/ExportButton';

import { RenderBoolean } from '../components/User';

import Box from '@material-ui/core/Box';

import { Link } from 'react-router-dom';

import { validateMaxLength, validateInteger  } from './../components/Validator';

export const CustomFieldsOptionsList = (props) => {
  const translate = useTranslate();  
    
  let basePath='/custom_fields_options'; 
  let resource = 'custom_fields_options';
  let strRedirect = '/custom_fields/' + props.custom_fields_id + '/options';
    
  return (
    <ResourceContextProvider value={resource}>
      <List 
        basePath={basePath}   
        filter={{ associations_id : localStorage.getItem('associations_id'), custom_fields_id: props.custom_fields_id }}  
        actions={props.show ?  <span/> : <ListActions custom_fields_id={props.custom_fields_id} />}    
        empty={props.show ?  <span/> : <ListActions custom_fields_id={props.custom_fields_id} />}        
        bulkActionButtons={false}          
        perPage={1000}   
        pagination={false}
        sort={{ field: 'priority', order: 'ASC' }} 
        label=""  
        title=""  
        resource={resource}        
      >        
        <Datagrid className="header-marked">                                  
          <TextField source="name" label={translate('custom_fields_options.name')} sortable={false} /> 
          { !props.show ? <TextField source="priority" label={translate('custom_fields_options.priority')} sortable={false} /> : null }
          { !props.show ? <CustomFieldsOptionsEditButton label=""/> : null }
          { !props.show ? <DeleteButton undoable={false} redirect={strRedirect} label="" confirmTitle={translate('custom_fields_options.delete_title')}
        confirmContent={translate('custom_fields_options.delete_ask')} /> : null }
        </Datagrid>        
      </List>
    </ResourceContextProvider>
  );
};

const ListActions = (props) => {            
    return (
    <TopToolbar>     
        <MyCreateButton {...props} />            
    </TopToolbar>
    );
};

const CustomFieldsOptionsEditButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
    
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  

  const [update, { loading, error }] = useUpdate();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    update(
      { 
        type: 'update', 
        resource: 'custom_fields_options', 
        payload : { id: values.id, data : values }
      },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };    

  return (
    <>
      <Button onClick={handleClick} label="" startIcon={<EditIcon />} />

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('custom_fields_options.edit')}
      >
        <DialogTitle>{translate('custom_fields_options.edit')}</DialogTitle>

        <FormWithRedirect
          resource="custom_fields_options"
          save={handleSubmit}
          initialValues={ props.record }
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <FormContent />
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

export const CustomFieldsOptionsShow = (props) => {
  const translate = useTranslate();  
    
  let basePath='/custom_fields_options'; 
  let resource = 'custom_fields_options';
  let strRedirect = 'custom_fields_options';
    
  return (
    <ResourceContextProvider value={resource}>
      <List 
        basePath={basePath}   
        filter={{ associations_id : localStorage.getItem('associations_id'), custom_fields_id: props.custom_fields_id  }}  
        actions={props.show ? <span/> : <ListActions custom_fields_id={props.custom_fields_id} />}    
        empty={props.show ? <span/> : <ListActions custom_fields_id={props.custom_fields_id} />}        
        bulkActionButtons={false}          
        perPage={1000}   
        pagination={false}
        sort={{ field: 'priority', order: 'ASC' }} 
        label=""  
        title=""  
        resource={resource}        
      >        
        <Datagrid>                                  
          <TextField source="name" label={translate('custom_fields_options.name')} sortable={false} /> 
          <TextField source="priority" label={translate('custom_fields_options.priority')} sortable={false} /> 
        </Datagrid>        
      </List>
    </ResourceContextProvider>
  );
};

const MyFilter = (props) => {
    const translate = useTranslate(); 
    return (
    <Filter {...props}>         
        <TextInput label={translate('common.search')} source="q" alwaysOn validate={[validateMaxLength(50,translate('common.validation.maxLength'))]} resettable />    
    </Filter>
);
};


const ListActionsOptions = (props) => {
    const translate = useTranslate(); 
        
    return (
    <TopToolbar>     
        <MyCreateButton {...props} />            
    </TopToolbar>
    );
};

export const MyCreateButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('custom_fields_options');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  
  const redirect = useRedirect();
  const history = useHistory();
        
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {                      
          refresh();        
        },
        onFailure: ( error ) => {
          notify( error.message , 'error');
        }
      }
    );
  };
    
  return (
    <>            
      <Button onClick={handleClick} label={translate('custom_fields_options.create')}>
        <IconContentAdd />                
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('custom_fields_options.create')}
      >
        <DialogTitle>{translate('custom_fields_options.create')}</DialogTitle>

        <FormWithRedirect
          save={handleSubmit}
          initialValues={{ associations_id: localStorage.getItem('associations_id'), custom_fields_id: props.custom_fields_id, priority: 0 }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <FormContent />              
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};


const FormContent = (props) => {

  const translate = useTranslate(); 
  return (
      <DialogContent>
        <Grid container spacing={1}>                                     
          <Grid item xs={8}>                                               
            <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
          </Grid>
          <Grid item xs={4}>                                               
            <NumberInput fullWidth source="priority" label={translate('custom_fields_options.priority')} validate={[]} />
          </Grid>
        </Grid> 
      </DialogContent>
  );
};


const MyActions = ({ basePath, data, resource }) => {

  const translate = useTranslate();   
    
  let linkBack = '/custom_fields_options';
        
  return (
    <>
      <TopToolbar>
        <Grid container spacing={2}>
          <Grid item xs={12}>   
            <div style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
              <Link
                to={linkBack}
                className=""
              >
                <ArrowBackIosNewIcon className="mr-2" />                        
              </Link>
              <Typography variant="h5" className="grey8">
                {data && data.name ? data.name : null}                    
              </Typography>
            </div>  
                
          </Grid>
        </Grid> 
      </TopToolbar>
    </>
  );
};


export const CustomFieldsOptionsEdit  = ({ id, ...props }) => {

  const translate = useTranslate();        
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  let strRedirect =  `/custom_fields/${id}/options`;    
    
  const onSuccess = ({ data }) => {
    notify(translate('common.notification.updated'));        
    redirect(strRedirect);        
  };
        
  return (

    <Edit 
      title={<ElementTitle {...props}  />} 
      {...props} 
      id={id}
      mutationMode="pessimistic"
      actions={<MyActions {...props} />}
      onSuccess={onSuccess}   
    >
        
      <TabbedForm        
        toolbar={<FormToolbar />}
      >    
        <FormTab
          label={translate('classrooms.data')}
          contentClassName={classes.tab}
          path="" 
                
        > 
               
          <FormDataConsumer>
            {({ formData, ...rest }) => 
              <>                              
                <br/>
                <Grid container spacing={2}>
                  <Grid item xs={3}>   
            <TextInput fullWidth source="custom_fields_id" label={translate('custom_fields_options.custom_fields_id')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />
                  </Grid> 
                  <Grid item xs={3}>   
            <TextInput fullWidth source="name" label={translate('custom_fields_options.name')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />
                  </Grid> 
                  <Grid item xs={3}>   
            <TextInput fullWidth source="priority" label={translate('custom_fields_options.priority')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />
                  </Grid> 
                  <Grid item xs={12}>   
                  </Grid> 
                </Grid>                                

              </>
            }
          </FormDataConsumer>

        </FormTab> 
        
    
      </TabbedForm>
    </Edit>
  );
};

const ElementTitle = ({ record, ...props }) => {    
  const translate = useTranslate(); 
  return (<span>{translate('common.edit')} {record ? `"${record.name}"` : ''}</span>);
};

const FormToolbar = ({ id , ...props }) => {
    
  let redirect = '/custom_fields_options';
  const translate = useTranslate();   
    
  return (
    <Toolbar {...props} className="user_form_toolbar" >
      <SaveButton />
      <DeleteButton 
        redirect={redirect} 
        mutationMode="pessimistic" 
        confirmTitle={translate('custom_fields_options.delete_title')}
        confirmContent={translate('custom_fields_options.delete_ask')} 
      />
    </Toolbar>
  );
};

const useStyles = makeStyles({
  comment: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    maxWidth: '100%',
    display: 'block',
  },
});






export const CustomFieldsOptionsCreate  = ({ id, ...props }) => {

  const translate = useTranslate();   
  const [info, setInfo] = useState();
    
  return (

    <Create {...props} >
      <SimpleForm 
        initialValues={{ associations_id: localStorage.getItem('associations_id') }}
        redirect="list"
      >                
        <Grid container spacing={2}>
          <Grid item xs={12}>                    
            <Typography variant="h5" className="grey">{translate('custom_fields_options.create')}</Typography>
          </Grid>  
          <Grid item xs={11}>                    
            <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
          </Grid>          
        </Grid>   

      </SimpleForm> 
    </Create>
  );
};
