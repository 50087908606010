import * as React from 'react';

import {
  Datagrid,
  TextField,
  TextInput, 
  ReferenceManyField,
  useTranslate
} from 'react-admin';

import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';



import { useFormState } from 'react-final-form';


import { validateMaxLength, validateEmail  } from './../components/Validator';

export const UsersEmailsList= ({ ...props }) => {
  const translate = useTranslate();   

  return (
           
    <ReferenceManyField                    
      reference="users_emails"
      target="users_id"
      label=""
      perPage={1000}
    >

      <EditableDatagrid                       
        createForm={<UserEmailForm initialValues={{ users_id: props.users_id , comment: '', priority: 0 }} edit={false} />}
        editForm={<UserEmailForm edit={true} />}
        label=""
        hasCreate
        fullWidth
      >                        
        <TextField source="email" label={translate('common.email')} sortable={false} />   
        <TextField source="comment" label={translate('common.comment')} sortable={false} />   
      </EditableDatagrid>

    </ReferenceManyField> 

  );
};

const UserEmailForm= ({edit, ...props}) => {
  const translate = useTranslate();   
  const { values } = useFormState();

  return (
    <RowForm {...props}  >
      <TextInput source="email" label={translate('common.email')} validate={[validateMaxLength(100,translate('common.validation.maxLength')),validateEmail()]} />   
      <TextInput source="comment" label={translate('common.comment')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />  
    </RowForm>
  );
};


export const UsersEmailsShow= ({ reference, ...props }) => {
  const translate = useTranslate();   
    
  return (
           
    <ReferenceManyField                    
      reference={reference}
      target="users_id"
      label=""
      perPage={1000}
    >

      <Datagrid               
        label=""
        hasCreate
        fullWidth
        className="users_remittances_list header_grey6"
      >
        <TextField source="email" label={translate('common.email')} sortable={false} />   
        <TextField source="comment" label={translate('common.comment')} sortable={false} />   
      </Datagrid>

    </ReferenceManyField> 

  );
};
