import React, { useState } from 'react';
import { useEffect } from 'react';

import {
  AutocompleteInput,
  required, FunctionField, useRecordContext, useDataProvider
} from 'react-admin';

import { Link } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';

import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import BusinessIcon from '@mui/icons-material/Business';
import TodayIcon from '@mui/icons-material/Today';
import GiteIcon from '@mui/icons-material/Gite';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import GavelIcon from '@material-ui/icons/Gavel';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

import { useTranslate  } from '../common/language';
import { choicesGenderUser  } from '../common/constants';

export const UserAvatarIcon = ({record, ...props}) => {

  return (record.user_type === 0 ? (<Avatar><PersonIcon/></Avatar>) : (<Avatar><BusinessIcon/></Avatar>) );

};

export const GroupsAvatarIcon = ({record, ...props}) => {

  return (<Avatar><GroupsIcon/></Avatar>);

};

export const EventsAvatarIcon = ({record, ...props}) => {

  return (<Avatar><TodayIcon/></Avatar>);

};

export const RealStateAvatarIcon = ({record, ...props}) => {

  return (<Avatar><GiteIcon/></Avatar>);

};

export const AgreementsAvatarIcon = ({record, ...props}) => {

  return (<Avatar><ThumbUpIcon/></Avatar>);

};

export const InsurancesAvatarIcon = ({record, ...props}) => {

  return (<Avatar><GavelIcon/></Avatar>);

};

export const AlertsAvatarIcon = ({record, ...props}) => {

  return (<Avatar><NotificationsActiveIcon/></Avatar>);

};


export const UserAvatarField = ({record, ...props}) => {

  return (
    <FunctionField 
      label={props.label}  
      render={record => <UserAvatarIcon record={record} /> } 
    /> );
};

export const UserFullNameTxt = ({record, ...props}) => {
  let first_name = record.first_name;
  let last_name_1 = record.last_name_1 ? record.last_name_1 : '';
  let last_name_2 = record.last_name_2 ? record.last_name_2 : '';
  return (`${first_name} ${last_name_1} ${last_name_2}`); 
};

export const RenderAge = function (props) {
    
  const translate = useTranslate();      
  let age = '';
  if (props.source)
  {
    let date_str = props.source.substring(8,10) + '-' + props.source.substring(5,7) + '-' + props.source.substring(0,4);
    var birthday_arr = date_str.split('-');
    var birthday_date = new Date(birthday_arr[2], birthday_arr[1] - 1, birthday_arr[0]);
    var ageDifMs = Date.now() - birthday_date.getTime();
    var ageDate = new Date(ageDifMs);
    age = Math.abs(ageDate.getUTCFullYear() - 1970) + ' ' + translate('users.age_sufix');
  }
  return age;
};


export const RenderDate = function (props) {
    
  let date_str = '';
  if (props.source)
    date_str = props.source.substring(8,10) + '-' + props.source.substring(5,7) + '-' + props.source.substring(0,4);

  return (<>{date_str}</>);
};

export const RenderGender = function (props) {
  const translate = useTranslate();  
  if (props.source && props.source >= 0)
    return (choicesGenderUser(translate)[props.source].name);
  else
    return '';
};

export const RenderBoolean = function (props) {
  const translate = useTranslate();  
  if (props.source && props.source === true)
    return (translate('common.yes'));
  else
    return (translate('common.no'));
};


export var ChipFieldUserName = function (props) {
  var record = useRecordContext(props);
  let link = '/users/' + record.id + '/show';
  return (<Link
    to={link}
    className="users_show_families_chip"
  >
    <Chip className="users_show_families_chip" label={<UserFullNameTxt record={record}/> } />
  </Link> );
};

export const UserInput = ({ ...props }) => {

  const translate = useTranslate(); 
    
  const dataProvider = useDataProvider();
  const [info, setInfo] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
    
  const disabled = props.disabled && props.disabled==true ? true : false;
  const resettable = props.resettable != null ? props.resettable : true;
  const label = props.label ? props.label : translate('common.name');
  const validate = props.required ? [required()] : [];
    
  useEffect(() => {
    dataProvider
      .getList('users_searcher', {
        pagination: { page: 0, perPage: 10000 },
        sort: { field: 'last_name_1', order: 'ASC' },
        filter: { associations_id: localStorage.getItem('associations_id'), user_type: props.user_type },
      })
      .then(({ data }) => {
        setInfo(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) return (<span>{translate('common.loading')}</span>);
  if (error) return null;
  if (!info) return null;
  
  return (
    <AutocompleteInput 
      optionText={ (record)=> record.complete_name } 
      suggestionLimit={50}
      resettable={resettable}
      clearAlwaysVisible={true}
      validate={validate}
      fullWidth
      disabled={disabled}
      choices={info}
      source={props.source}
      label={label}
    />
                
  );
};

export const chipsUsers = (users) => {
  if (!users) {
    return null; 
  }
  const chips = users.map((user) => (
    <Link key={user.id} to={`/users/${user.id}/show`} className="chip" >
      <Chip label={user.name} className="chip" clickable />
    </Link>
  ));

  return <div>{chips}</div>;
};
