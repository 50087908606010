
import {
  useTranslate ,
  Button ,
  useNotify
} from 'react-admin';

import SaveIcon from '@material-ui/icons/GetApp';

import { apiUrl, apiOrigin, apiDefaultLanguage,  } from './../common/constants';

export function DownloadPDFButton ({ record, remittances_id, date_creation, ...props}) {
    
  const translate = useTranslate(); 
  const notify = useNotify();

  const handleAction = async () => {

    let headers = new Headers({ Accept: 'application/json' });
    headers.set('Access-Control-Allow-Origin', apiOrigin );
    headers.set('Authorization', localStorage.getItem('auth'));
    headers.set('Accept-Language', apiDefaultLanguage);
    headers.set('Role', localStorage.getItem('active_permission'));

    const result = await fetch(apiUrl + '/remittances/pdf/' + remittances_id , { 
      headers: headers
    });
    
    const blob = await result.blob();
    const href = window.URL.createObjectURL(blob);
    
    var ms = new Date().getTime();
    var strName = date_creation.substring(0,10) + '_' + remittances_id+ '_' + ms;
    
    var tempLink = document.createElement('a');
    tempLink.href = href;
    tempLink.setAttribute('download', translate('remittances.pdf_filename') + '_' + strName);
    tempLink.click();
    
    notify(translate('remittances.pdf_ok'),"success");
    
  };

  return (
    <Button label={translate('remittances.pdf')} startIcon={<SaveIcon />} onClick={handleAction} />
  );

}