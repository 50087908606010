// in src/posts.js
import * as React from 'react';
import { useState } from 'react';

import {
  List,
  Datagrid,
  TextField,
  DeleteButton,
  SelectInput, 
  ReferenceManyField,
  useTranslate,
  TopToolbar,
  Button,
  FormWithRedirect,
  SaveButton,
  DateField ,DateInput, required, FunctionField, useCreate, useNotify, useRefresh, NumberInput,
  ResourceContextProvider,useUpdate, RadioButtonGroupInput, FormDataConsumer
} from 'react-admin';



import EditIcon from '@material-ui/icons/Edit';

import IconCancel from '@material-ui/icons/Cancel';
import EmailIcon from '@mui/icons-material/Email';

import Grid from '@mui/material/Grid';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { SendEmailIconStatus } from './../components/Emails';
import { targetsEmails } from './../common/constants';
import { useStyles  } from '../common/styles';




export const UsersAnnuaryShow= (props) => {
    
  const translate = useTranslate();  
            
  return (
    <>
      <ReferenceManyField                    
        reference="users_annuary"
        target="users_id"
        label=""
        perPage={1000}
        sort={{ field: 'year', order: 'DESC' }} 
      >

        <Datagrid        
          className="users_remittances_list header_grey6"
        >
          <TextField source="year" label={translate('common.year')} sortable={false} />
          <FunctionField label={translate('users_annuary.answered_status')} render={record =>
            translate('users_annuary.answered_status_' + record.answered_status)
          } sortable={false}  /> 
          <DateField showTime source="answered_date_time" label={translate('users_annuary.answered_date_time')} sortable={false} />
          <DateField source="expiration_date" label={translate('users_annuary.expiration_date')} sortable={false} />
          <FunctionField label={translate('users_annuary.sent_status')} render={record => 
            <SendEmailIconStatus record={record} target={targetsEmails.ANNUARY_SEND}  />
          } sortable={false}  />       
          <FunctionField label={translate('users_annuary.reminder_status')} render={record => 
            <SendEmailIconStatus record={record} target={targetsEmails.ANNUARY_REMINDER}  />
          } sortable={false}  /> 
          <FunctionField label={translate('users_annuary.confirm_status')} render={record => 
            <SendEmailIconStatus record={record} target={targetsEmails.ANNUARY_CONFIRM}  />
          } sortable={false}  /> 
        </Datagrid>

      </ReferenceManyField> 
                
    </>
  );
};
                  
export const UsersAnnuaryList= (props) => {
  const translate = useTranslate();
    
  let strRedirect =  `/users/${props.users_id}/more_federation`; 
    
  return (
    <ResourceContextProvider value="users_annuary" >
      <List 
        syncWithLocation 
        basePath="/users_annuary" 
        filter={{ users_id : props.users_id }}  
        title=""
        bulkActionButtons={false}                
        actions={<CustomToolbar users_id={props.users_id} /> }  
        perPage={1000}
        sort={{ field: 'year', order: 'ASC' }} 
        empty={<CustomToolbar users_id={props.users_id} /> } 
        pagination={false}
      >
        <Datagrid                     
          label=""
          fullWidth
        >
          <TextField source="year" label={translate('common.year')} sortable={false} />
          <FunctionField label={translate('users_annuary.answered_status')} render={record =>
            translate('users_annuary.answered_status_' + record.answered_status)
          } sortable={false}  /> 
          <DateField showTime source="answered_date_time" label={translate('users_annuary.answered_date_time')} sortable={false} />
          <DateField source="expiration_date" label={translate('users_annuary.expiration_date')} sortable={false} />
          <FunctionField label={translate('users_annuary.sent_status')} render={record => 
            <SendEmailIconStatus record={record} target={targetsEmails.ANNUARY_SEND}  />
          } sortable={false}  />       
          <FunctionField label={translate('users_annuary.reminder_status')} render={record => 
            <SendEmailIconStatus record={record} target={targetsEmails.ANNUARY_REMINDER}  />
          } sortable={false}  />  
          <FunctionField label={translate('users_annuary.confirm_status')} render={record => 
            <SendEmailIconStatus record={record} target={targetsEmails.ANNUARY_CONFIRM}  />
          } sortable={false}  /> 
          <CustomEditButton label=""/>
          <DeleteButton undoable={false} redirect={strRedirect} label=""/>
        </Datagrid>

      </List> 
    </ResourceContextProvider>
  );
};


export const CustomEditButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  

  const [update, { loading, error }] = useUpdate();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };
                        
  const handleSubmit = async values => {
    update(
      { 
        type: 'update', 
        resource: 'users_annuary', 
        payload : { id: values.id, data : 
                {       users_id: values.users_id,
                  year : values.year, 
                  expiration_date: values.expiration_date,
                  answered_status: values.answered_status
                } }
      },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };    

  return (
    <>
      <Button onClick={handleClick} label="" startIcon={<EditIcon />} />

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
      >
        <DialogTitle>{translate('users_annuary.edit')}</DialogTitle>

        <FormWithRedirect
          resource=""
          save={handleSubmit}
          initialValues={{ id : props.record.id, 
            users_id: props.record.users_id,
            year : props.record.year, 
            expiration_date: props.record.expiration_date,
            answered_status: props.record.answered_status
          }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <CustomForm />
                            
              <DialogContent className="users_sections_form">
                <Grid container spacing={2} >
                  <Grid item xs={4}> 
                    <SelectInput label={translate('users_annuary.answered_status')} source="answered_status" choices={[{id:1,name:translate('common.yes')},{id:0,name:translate('common.no')}]} validate={[required()]} />
                  </Grid> 
                </Grid>
              </DialogContent>
                            
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};


export const CustomToolbar = ({ ...props }) => {

  return (
        
    <TopToolbar>
      <CustomCreateButton users_id={props.users_id} />
    </TopToolbar>
        
  );
};


export const CustomCreateButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('users_annuary');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  
    
  let lastYear = new Date().getFullYear()-1;
    
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };


  return (
    <>            
      <Button onClick={handleClick} label={translate('users_annuary.create_button')}>
        <EmailIcon />                
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('users_annuary.create_button')}
      >
        <DialogTitle>{translate('users_annuary.create_button')}</DialogTitle>

        <FormWithRedirect
          resource="users_sections"
          save={handleSubmit}
          initialValues={{ users_id : props.users_id, year: lastYear, action: 0 }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <CustomForm />
                            
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                  label={translate('users_annuary.send')}
                  icon={<EmailIcon/>}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

const CustomForm = ({ ...props }) => {
  const translate = useTranslate(); 
  const classes = useStyles();
    
  return (
    <DialogContent className="users_sections_form">
      <br />
      <Grid container spacing={2} >
        <Grid item xs={4}> 
          <NumberInput label={translate('common.year')} fullWidth source="year" step={1} disabled validate={[required()]} />  
        </Grid> 
        <Grid item xs={5}> 
          <DateInput label={translate('users_annuary.expiration_date')} fullWidth source="expiration_date" validate={[required()]} /> 
        </Grid>
      </Grid>                
                        
    </DialogContent>
  );
    
    
};

export const UsersAnnuarySendButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('users_annuary/all');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  
    
  let lastYear = new Date().getFullYear()-1;
    

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };


  return (
    <>            
      <Button onClick={handleClick} label={translate('users_annuary.send_dialog_button')}>
        <EmailIcon />                
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('users_annuary.title')}
      >
        <DialogTitle>{translate('users_annuary.title')}</DialogTitle>

        <FormWithRedirect
          resource="users_annuary"
          save={handleSubmit}
          initialValues={{ action : 0, year: lastYear, groups_id: props.group }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
                                                        
              <DialogContent className="users_sections_form">
                <FormDataConsumer>
                  {({ formData, ...rest }) => <>
                    <Grid container spacing={2} > 
                      <Grid item xs={12}> 
                        <RadioButtonGroupInput source="action" choices={[
                          { id: 0, name: translate('users_annuary.send_dialog_action_0') },
                          { id: 1, name: translate('users_annuary.send_dialog_action_1') }
                        ]} 
                        optionText="name" optionValue="id" label={translate('users_annuary.send_dialog_action')} validate={required()}  />
                      </Grid>
                      <Grid item xs={12}> 
                        <span>{translate('users_annuary.send_dialog_text1_' + formData.action )}</span>
                      </Grid>
                      <Grid item xs={12}> 
                        <span>{translate('users_annuary.send_dialog_text2')}</span>
                      </Grid>
                      <Grid item xs={12}> 
                        <span>{translate('users_annuary.send_dialog_text3')}</span>
                      </Grid>
                    </Grid> 
                  </> }
                </FormDataConsumer>
              </DialogContent>
                            
              <CustomForm />
                            
              <br />
                            
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                  label={translate('users_annuary.send')}
                  icon={<EmailIcon/>}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );  
    
};
