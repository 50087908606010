import React, { useState } from 'react';

import {
  List, 
  Datagrid,
  TextField,
  DeleteButton,
  Edit,
  SelectInput,
  TextInput,
  Button ,
  Create,
  SimpleForm,
  CreateButton ,
  TopToolbar,
  FormTab,
  TabbedForm,
  ReferenceManyField,
  FormDataConsumer,
  useListContext, required,NumberInput,BooleanInput,SelectField,  FunctionField, ResourceContextProvider, useCreate, useRefresh, FormWithRedirect, Toolbar, SaveButton,
  useNotify,
  useRedirect,
  DateField  , 
  Filter, 
  downloadCSV,
  DateInput,
  SimpleFormIterator,
  ArrayInput,BooleanField
} from 'react-admin';

import { useEffect } from 'react';

import jsonExport from 'jsonexport/dist';

import {useHistory} from 'react-router-dom';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Card, CardContent, CardHeader,useMediaQuery } from '@material-ui/core';
import { green, orange, deepOrange } from '@mui/material/colors';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

import { AccordionSection  } from '@react-admin/ra-form-layout';


import { makeStyles } from '@material-ui/core/styles';

import Divider from '@mui/material/Divider';


import { useTranslate  } from '../common/language';

import Typography from '@mui/material/Typography';


import RichTextInput from 'ra-input-rich-text';


import { TextFieldShow, TextHtmlShow } from '../components/TextFieldShow';

import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import GavelIcon from '@material-ui/icons/Gavel';
import PersonIcon from '@mui/icons-material/Person';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Chip from '@material-ui/core/Chip';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

import { ColorInput } from 'react-admin-color-input';

import { DocsList, DocsListShow } from './docs';
import { targets, modules, choicesClassroomsType  } from './../common/constants';
import { dateOnlyToCatalan, subscriptionModule, setActivePermissionData, structArrayToIdList, DateTimeField } from './../common/utils';  

import { CityInput } from './../components/Inputs';
import { ExportButton } from './../components/ExportButton';

import { RenderBoolean } from '../components/User';

import Box from '@material-ui/core/Box';

import { Link } from 'react-router-dom';

import { validateMaxLength, validateInteger  } from './../components/Validator';


export const EmailsHistoryList = (props) => {
  const translate = useTranslate();  
    
  let basePath='/emails'; 
  let resource = 'emails';
  let strRedirect = 'emails';
    
  return (
          <>
      <br/>
      { !props.show ?
      <Typography variant="h5" className="grey8" align="center">
        {translate('settings.emails_logs')}              
      </Typography> : null }
    <ResourceContextProvider value={resource}>
      <List 
        basePath={basePath}   
        filter={{ associations_id : localStorage.getItem('associations_id'), users_id : props.users_id ? props.users_id : -1 }}  
        actions={null}    
        empty={null}        
        bulkActionButtons={false}          
        perPage={props.show ? 10 : 50}   
        sort={{ field: 'id', order: 'DESC' }} 
        label=""  
        title=""  
        resource={resource}
        filters={ !props.show? <MyFilter /> : null } 
        filterDefaultValues={ { q: "", only_not_sent: false }}
      >        
        <Datagrid 
          rowClick={null}
          expand={<EmailsDetails />} >                                                 
          <DateTimeField source="date_ordered" label={translate('emails.date_ordered')} sortable={false} />  
          <FunctionField label={translate('emails.from')} render={record => record.from_name + ' <' + record.from_email + '>'} sortable={false}  />
          { !props.show ? <UserLinkButton label={translate('emails.contact')} /> : null }
          <FunctionField label={translate('emails.to')} render={record => <>{record.to}</>} sortable={false}  />          
          <TextField source="subject" label={translate('emails.subject')} sortable={false} />            
          <FunctionField label={translate('remittances.status')} render={RenderStatus} sortable={false}  />
        </Datagrid>        
      </List>
    </ResourceContextProvider>
    </>
  );
};

const UserLinkButton = ({ record, ...props }) => {
    const translate = useTranslate();
    const history = useHistory();

    const handleClick = () => {
            history.push('/users/' + record.users_id + '/show');  
    };
    if (record.users_id)
        return <Button {...props} label="" onClick={handleClick} startIcon={<PersonIcon />} />;
    else
        return null;
    
};

export function RenderStatus( record )
{
    const translate = useTranslate(); 
    
    let title = translate('emails.status_' + record.status);

    if (record.status === 0) 
        return (<CardHeader
            avatar={<Avatar sx={{ bgcolor: orange[500] , width: 30, height: 30 }} ><CloseIcon/></Avatar> }
            title={title}
            className="remittanceStatusIcon"
        />);

    else if (record.status === 1) 
        return (<CardHeader
            avatar={<Avatar sx={{ bgcolor: orange[500] , width: 30, height: 30 }} ><ForwardToInboxIcon/></Avatar> }
            title={title}
            className="remittanceStatusIcon"
        />);

    else if (record.status === 2)
        return (<CardHeader
            avatar={<Avatar sx={{ bgcolor: deepOrange[500] , width: 30, height: 30 }} ><CloseIcon/></Avatar> }
            title={title}
            className="remittanceStatusIcon"
        />);

    else if (record.status === 3)
        return (<CardHeader
            avatar={<Avatar sx={{ bgcolor: green[500] , width: 30, height: 30 }} ><DoneIcon/></Avatar> }
            title={title}
            className="remittanceStatusIcon"
        />);
    
    else if (record.status === 4)
        return (<CardHeader
            avatar={<Avatar sx={{ bgcolor: deepOrange[500] , width: 30, height: 30 }} ><CloseIcon/></Avatar> }
            title={title}
            className="remittanceStatusIcon"
        />);
}

const MyFilter = (props) => {
    const translate = useTranslate(); 
    return (
    <Filter {...props}>         
        <TextInput label={translate('common.search')} source="q" alwaysOn validate={[validateMaxLength(50,translate('common.validation.maxLength'))]} resettable /> 
        <BooleanInput label={translate('emails.only_not_sent')} source="only_not_sent" alwaysOn />
    </Filter>
);
};

export const EmailsDetails = ({ id, record, resource, ...props }) => {
  const translate = useTranslate(); 
        
  return (
    <div className="emails-details">        
      <Grid container spacing={2}>   
        { record.error_message ?
        <Grid item xs={12} >   
          <span className="bg-red p-5">{record.error_message}</span><br/><br/>
        </Grid> : null }
        <Grid item xs={12}>   
          <TextHtmlShow source={record.content} label={translate('emails.content')} html={true} />
        </Grid> 
        { record.docs ?
        <Grid item xs={12}>   
          <TextFieldShow source={record.docs} label={translate('emails.docs')} />
        </Grid> : null }        
      </Grid>
      
    </div>
  );
};

/*
const exporter = (items, translate, notify) => {
    
  const itemsForExport = items.map(item => {
    var itemForExport = {
        id: item.id,
        target: item.target,
        users_id: item.users_id,
        groups_id: item.groups_id,
        events_id: item.events_id,
        bookings_id: item.bookings_id,
        date_ordered: dateOnlyToCatalan(item.date_ordered),
        from_name: item.from_name,
        from_email: item.from_email,
        to: item.to,
        cc: item.cc,
        bcc: item.bcc,
        subject: item.subject,
        content: item.content,
        docs: item.docs,
        date_sent: dateOnlyToCatalan(item.date_sent),
        status: item.status,
        reply_to: item.reply_to,
        remittances_users_id: item.remittances_users_id,
        users_groups_id: item.users_groups_id,
        users_permissions_id: item.users_permissions_id,
        users_annuary_id: item.users_annuary_id,
        error_message: item.error_message,
        recercat_centers_id: item.recercat_centers_id,
        users_login_id: item.users_login_id,
        donations_users_id: item.donations_users_id,
        donations_projects_id: item.donations_projects_id,
    };
    return itemForExport;
  });
  jsonExport(itemsForExport, {
    headers: ['id','target'
,'users_id'
,'groups_id'
,'events_id'
,'bookings_id'
,'date_ordered'
,'from_name'
,'from_email'
,'to'
,'cc'
,'bcc'
,'subject'
,'content'
,'docs'
,'date_sent'
,'status'
,'reply_to'
,'remittances_users_id'
,'users_groups_id'
,'users_permissions_id'
,'users_annuary_id'
,'error_message'
,'recercat_centers_id'
,'users_login_id'
,'donations_users_id'
,'donations_projects_id'
],
    rename: [translate('common.id'),translate('emails.target'),translate('emails.users_id'),translate('emails.groups_id'),translate('emails.events_id'),translate('emails.bookings_id'),translate('emails.date_ordered'),translate('emails.from_name'),translate('emails.from_email'),translate('emails.to'),translate('emails.cc'),translate('emails.bcc'),translate('emails.subject'),translate('emails.content'),translate('emails.docs'),translate('emails.date_sent'),translate('emails.status'),translate('emails.reply_to'),translate('emails.remittances_users_id'),translate('emails.users_groups_id'),translate('emails.users_permissions_id'),translate('emails.users_annuary_id'),translate('emails.error_message'),translate('emails.recercat_centers_id'),translate('emails.users_login_id'),translate('emails.donations_users_id'),translate('emails.donations_projects_id')],        
        
  }, (err, csv) => {
    const csv_new = '\uFEFF' + csv;
    downloadCSV(csv_new, translate('emails.exporter_file_name') ); 
    notify(translate('common.download_ok'),"success");
  });
};
*/