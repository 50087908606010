import { useTranslate } from 'react-admin';

import polyglotI18nProvider from 'ra-i18n-polyglot';

import {getLanguageFromStorage} from './constants';

import {caMessages} from './language_ca';
import {esMessages} from './language_es';


const messages = {
  ca: caMessages,
  es: esMessages
};

const i18nProvider = polyglotI18nProvider(locale => messages[getLanguageFromStorage()]);
export { useTranslate, i18nProvider };

