import React, { useState } from 'react';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  useRefresh, TopToolbar, useTranslate, BooleanInput, FormDataConsumer, useUpdate, SelectInput, NumberInput, ReferenceInput, CreateButton
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import EditIcon from '@material-ui/icons/Edit';
import DownloadIcon from '@material-ui/icons/GetApp';
import BorderAllIcon from '@mui/icons-material/BorderAll';

import { Link } from 'react-router-dom';


import Grid from '@mui/material/Grid';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { choicesRegion, choicesProvince, choicesCountry } from './../common/constants';
import { apiUrl, apiOrigin, apiDefaultLanguage, targets, targetsResources, modules} from './../common/constants';

import { validateMaxLength  } from './../components/Validator';


export const UsersPostalLabelsCreateButton = ({ filterValues , ...props }) => {
  
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  
  
  const { field, order } = props.currentSort;   

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };
  
  const handleAction = async values => {

    let headers = new Headers({ Accept: 'application/json' });
    headers.set('Access-Control-Allow-Origin', apiOrigin );
    headers.set('Authorization', localStorage.getItem('auth'));
    headers.set('Accept-Language', apiDefaultLanguage);
    headers.set('Role', localStorage.getItem('active_permission'));
    
    let last_row = values.last_row && values.last_row == true ? 1 : 0;
    const result = await fetch(apiUrl + '/postal_labels/pdf?associations_id=' + localStorage.getItem('associations_id') + '&groups_id='+ filterValues.groups_id + '&format=' + values.format + '&filter=' +  encodeURIComponent(JSON.stringify(filterValues.filter)) + '&q=' + filterValues.q + '&size=100000' + '&page=0&sort=' + field + ',' + order , { 
      headers: headers
    });
    
    const blob = await result.blob();
    const href = window.URL.createObjectURL(blob);
        
    var tempLink = document.createElement('a');
    tempLink.href = href;
    tempLink.setAttribute('download', translate('postal_labels.generate_pdf_filename'));
    tempLink.click();
    
    setShowDialog(false);
    notify(translate('postal_labels.generate_ok_message'),"success");
    
  };

  return (
    <>
      
        <Button onClick={handleClick} label={translate('postal_labels.generate_button_title')} >
          <BorderAllIcon />
        </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('postal_labels.generate_modal_title')}
      >
        <DialogTitle>{translate('postal_labels.generate_modal_title')}</DialogTitle>

        <FormWithRedirect
          resource=""
          save={handleAction}
          initialValues={{ groups_id: filterValues.groups_id, filters : filterValues.filter, q: filterValues.q }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <FormatDialogContent />
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                  label={translate('postal_labels.generate_modal_button')}
                  icon={<BorderAllIcon />}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
            
    </>
  );
};


const FormatDialogContent = ({ ...props}) => {

  const translate = useTranslate();  
    
  return (
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={12}>            
            {translate('postal_labels.generate_modal_desc')}
        </Grid>
        <Grid item xs={7}>
            <ReferenceInput 
                fullWidth
                sort={{ field: 'priority', order: 'ASC' }} 
                perPage={1000} 
                label={translate('postal_labels.generate_format')} 
                source="format" 
                reference="postal_labels"
                filter={{ associations_id : localStorage.getItem('associations_id') }}
            >
                <SelectInput optionText="name" optionValue="id" validate={required()} optionText={optionRenderer} />
            </ReferenceInput>  
        </Grid>
        <Grid item xs={5}>
            <CreateButton 
                component={Link}
                to={{
                    pathname: `/settings-postal-labels`
                }} 
                target="_blank"
                className="mt-10"
                label={translate('postal_labels.add_formats')}
            />
        </Grid>
        <Grid item xs={12}>            
                <br />
        </Grid>
      </Grid>
    </DialogContent>     
  );
};

const optionRenderer = record => {
    let str = record.name + ' (' + record.num_columns + ' x ' + record.num_rows + ')';
    return str;
};
