import React from 'react';
import { useState,useCallback, useEffect } from 'react';

import { Button, MenuItemLink, useDataProvider, UserMenu, useRefresh, useCreate, useUpdate, useNotify, FormWithRedirect, DateInput, SaveButton  } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { IconButton, Toolbar } from '@material-ui/core';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import {useHistory} from 'react-router-dom';
import { useTranslate  } from '../common/language';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import IconCancel from '@material-ui/icons/Cancel';

import Grid from '@mui/material/Grid';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { UserAvatarIcon, GroupsAvatarIcon, EventsAvatarIcon,RealStateAvatarIcon,AgreementsAvatarIcon,InsurancesAvatarIcon,AlertsAvatarIcon } from './../components/User';

export const Alerts = props => {

  var translate = useTranslate();
  
  const [showDialog, setShowDialog] = useState(false);
  const [postponeId, setPostponeId] = useState(0);
  
  const refresh = useRefresh();    
  const notify = useNotify();
  const history = useHistory();
  const dataProvider = useDataProvider();
  const [update, { loading, error1 }] = useUpdate();
  const [info, setInfo] = useState([]);
  const [numAlerts, setNumAlerts] = useState(0);
  const [error, setError] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const tomorrowISO = tomorrow.toISOString().slice(0, 10);
  
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    
    setAnchorEl(event.currentTarget);
    
    if (numAlerts>0)
    {
        setNumAlerts(0);

        update(
          { 
            type: 'update', 
            resource: 'alerts_users_permissions/readed' ,
            payload : { id: localStorage.getItem('active_permission'), data : { } }
          },
          {
            onSuccess: ({ data }) => {          
            },
            onFailure: ( error ) => {
            }
          }
        );
    }
    
  };
  
  
  const handleCloseClick = () => {
    setShowDialog(false);
  };
  
  const handleSubmit = async values => {
    update(
      { 
        type: 'update', 
        resource: 'alerts_users_permissions', 
        payload : { id: values.id, data : values }
      },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          notify(translate('alerts.postpone_ok'), 'success');    
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };    
  
  const handleClickItem = (link) => {
      setAnchorEl(null);
      history.push(link);  
  }
    
  const handleClickDelete = (id) => {
        
    update(
        { 
          type: 'update', 
          resource: 'alerts_users_permissions/delete' ,
          payload : { id: id, data : { } }
        },
        {
          onSuccess: ({ data }) => {   
              notify(translate('alerts.delete_ok'), 'success');
              fetchData();   
          },
          onFailure: ( error ) => {
          }
        }
      );

  }
  
  const handleClickDeleteAll = () => {
    setAnchorEl(null);
    
    update(
        { 
          type: 'update', 
          resource: 'alerts_users_permissions/delete_all' ,
          payload : { id: localStorage.getItem('active_permission'), data : { } }
        },
        {
          onSuccess: ({ data }) => {    
              notify(translate('alerts.delete_all_ok'), 'success');
              fetchData();  
          },
          onFailure: ( error ) => {
          }
        }
      );

  }
  
  const handleClickPostpone = (id) => {    
      setShowDialog(true);
      setPostponeId(id);          
  }
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const fetchData = () => {
      dataProvider
        .getList('alerts_users_permissions', {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'id', order: 'DESC' },
          filter: { users_permissions_id: localStorage.getItem('active_permission') },
        })
        .then(response => {
          const infoData = response.data;
          let num_alerts = 0;
          infoData.forEach(element => {
            if (element.status === 0) num_alerts++;
          });
          setInfo(infoData);
          setNumAlerts(num_alerts);
          setError(false); 
        })
        .catch(error => {
          setError(true);
        });
    };
    
  useEffect(() => {
            
    fetchData();
    
    // Setup interval to fetch data every 10 minutes
    const intervalId = setInterval(fetchData, 10 * 60 * 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
    
  }, []);
        
  if ( !info ) return null;
  if ( error) return null;
      
    
  return (
    <>
    <IconButton 
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}> 
    <Badge color="primary" badgeContent={numAlerts} max={99} className="alert_bagde">
    <NotificationsIcon />
    </Badge>
    </IconButton>
        <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: '85%',
            width: '30%',
          },
        }}
        TransitionComponent={Fade}
      >
        {info.length > 0 ?
        <div className="alerts_delete_all" ><span className="cursor" onClick={handleClickDeleteAll}>{translate('alerts.delete_all')}</span></div>
        :
        <div className="alerts_no_results" >{translate('alerts.no_results')}</div>
        }
        {info.map((option) => (
        <Grid container spacing={0} className={
            option.status === 0 ? 
                (option.type>3 ? 'alerts_item new alerts_item_link' : 'alerts_item new' ) 
            : 
                (option.type>3 ? 'alerts_item alerts_item_link' : 'alerts_item' )
            } 
        onClick={()=>{ if (option.type>3) handleClickItem(option.link)} }>
            <Grid item xs={10}>
              <ListItem >
                    <ListItemAvatar>
                        {option.type === 1 || option.type === 2 || option.type === 3 ?
                            <AlertsAvatarIcon/>
                        : option.type === 4 ?
                            <RealStateAvatarIcon/>
                        : option.type === 5 || option.type === 6 || option.type === 7 ?
                            <AgreementsAvatarIcon/>
                        : option.type === 8 ?
                            <InsurancesAvatarIcon/>
                        :
                            <EventsAvatarIcon />
                        }
                    </ListItemAvatar>
                    <ListItemText
                      primary={option.name}
                      secondary={option.message}
                    />          
              </ListItem>
            </Grid>
            <Grid item xs={1} className="align-items-center">   
                <Tooltip title={translate('alerts.postpone')}>        
                    <MoreTimeIcon onClick={(e)=>{ e.stopPropagation(); handleClickPostpone(option.id); }} className="cursor" />
                </Tooltip>
            </Grid>
            <Grid item xs={1} className="align-items-center">
                <Tooltip title={translate('alerts.delete')}>    
                    <HighlightOffIcon onClick={(e)=>{ e.stopPropagation(); handleClickDelete(option.id); }} className="cursor" />
                </Tooltip>                
            </Grid>
          </Grid>  
        
        ))}
      </Menu>
      
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('alerts.postpone')}
      >
        <DialogTitle>{translate('alerts.postpone')}</DialogTitle>

        <FormWithRedirect
          resource="alerts"
          save={handleSubmit}
          initialValues={{ id: postponeId, date_apply : tomorrowISO }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>
              
                <Grid container spacing={2}>
                  <Grid item xs={12}>  
                    <DateInput label={translate('alerts.date_apply')} source="date_apply" resettable />
                  </Grid> 
                </Grid>                     
                
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
      
    </>
  );
};

