// in src/posts.js
import React, { useState } from 'react';

import {
  List, 
  Datagrid,
  TextField,
  DeleteButton,
  Edit,
  SelectInput,
  TextInput,
  Button ,  
  Create,
  SimpleForm,
  CreateButton ,
  TopToolbar,
  FormTab,
  TabbedForm,
  ReferenceManyField,
  FormDataConsumer,
  useListContext, required,NumberInput,BooleanInput,SelectField,  FunctionField, ResourceContextProvider, useCreate, useRefresh, FormWithRedirect, Toolbar, SaveButton,
  useNotify,
  useRedirect,
  BooleanField,
  DateInput,
  Filter
} from 'react-admin';

import { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Divider from '@mui/material/Divider';


import { dateOnlyToCatalan  } from '../common/utils';
import { useTranslate  } from '../common/language';

import Typography from '@mui/material/Typography';


import RichTextInput from 'ra-input-rich-text';


import { TextFieldShow } from '../components/TextFieldShow';

import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { ColorInput } from 'react-admin-color-input';

import { DocsList, DocsListShow } from './docs';
import { targets, choicesAlertsType , choicesMonths, choicesDays } from './../common/constants';

import { CityInput } from './../components/Inputs';
import { BackLink } from './../components/BackLink';

import { RenderBoolean } from '../components/User';

import Box from '@material-ui/core/Box';

import { Link } from 'react-router-dom';

import { validateMaxLength, validateInteger  } from './../components/Validator';

export const AlertsList = (props) => {
  const translate = useTranslate();  
    
  let basePath='/alerts'; 
  let resource = 'alerts';
  let strRedirect = 'alerts';
    
  return (
    <ResourceContextProvider value={resource}>
      <br/>
      <Typography variant="h5" className="grey8" align="center">
        {translate('settings.alerts_desc')}              
      </Typography>  
      <List 
        basePath={basePath}   
        filter={{ associations_id : localStorage.getItem('associations_id') }}  
        actions={<ListActions />}    
        empty={<ListActions />}        
        bulkActionButtons={false}          
        perPage={1000}   
        pagination={false}
        sort={{ field: 'id', order: 'ASC' }} 
        label=""  
        title=""  
        resource={resource}
        filters={ <MyFilter />} 
        filterDefaultValues={ { only_current : true, q: "" }}
      >        
        <Datagrid 
          rowClick="edit"
          expand={<AlertsDetails />} >  
          <Avatar><NotificationsActiveIcon/></Avatar>                                  
          <TextField source="name" label={translate('common.name')} sortable={false} />    
          <FunctionField render={record => <span>{translate('alerts.type_' + record.type)}</span> } label={translate('alerts.type')} sortable={false}  />
          <TextField source="message" label={translate('alerts.message')} sortable={false} />    
          <BooleanField source="actived" label={translate('alerts.actived')} sortable={false}/>
        </Datagrid>        
      </List>
    </ResourceContextProvider>
  );
};

const MyFilter = (props) => {
    const translate = useTranslate(); 
    return (
    <Filter {...props}>         
        <TextInput label={translate('common.search')} source="q" alwaysOn validate={[validateMaxLength(50,translate('common.validation.maxLength'))]} resettable />    
        <BooleanInput label={translate('alerts.filter_only_current')} source="only_current" alwaysOn />        
    </Filter>
);
};

export const AlertsDetails = ({ id, record, resource, ...props }) => {
  const translate = useTranslate(); 
          
  return (
    <div className="usersClassroomsExpand">        
      <Grid container spacing={2}>
        <Grid item xs={10}>                    
          <TextFieldShow source={record.name} label={translate('common.name')} />                       
        </Grid>
        <Grid item xs={2}>  
          <TextFieldShow source={<RenderBoolean source={record.actived} />} label={translate('alerts.actived')} />     
        </Grid>
      </Grid>
        
      <Grid container spacing={2}>        
        <Grid item xs={4}>                    
          <FunctionField render={record => <TextFieldShow source={<SelectField source="type" choices={choicesAlertsType(translate)} /> } label={translate('alerts.type')} /> } />                        
        </Grid>
        { record.type == 1 ? 
        <Grid item xs={2}>                    
          <TextFieldShow source={dateOnlyToCatalan(record.date_alert, translate)} label={translate('alerts.date_alert')} />                   
        </Grid> : null }
        { record.type == 2 ? 
        <Grid item xs={2}>                    
          <FunctionField render={record => <TextFieldShow source={<SelectField source="date_month" choices={choicesMonths(translate)} /> } label={translate('alerts.date_month')} /> } />                        
        </Grid> : null }
        { record.type == 2 || record.type == 3  ? 
        <Grid item xs={2}>                    
          <FunctionField render={record => <TextFieldShow source={<SelectField source="date_day" choices={choicesDays(translate)} /> } label={translate('alerts.date_day')} /> } />                        
        </Grid> : null }
        <Grid item xs={2}>                              
        </Grid>
        { record.type == 1 || record.type == 2 || record.type == 3 ? 
        <Grid item xs={12}>                    
          <TextFieldShow source={record.message} label={translate('alerts.message')} />                   
        </Grid> : null }
      </Grid>
      
      <Typography variant="h6" className="grey8 titleUserShow">                        
        {translate('alerts.actions')}
      </Typography>
      <Divider fullWidth className="" />
      <br/>
      
      <Grid container spacing={2}>
        <Grid item xs={2}>                    
          <TextFieldShow source={<RenderBoolean source={record.action_send_email} />} label={translate('alerts.action_send_email')} />                   
        </Grid>
        <Grid item xs={2}>                    
          <TextFieldShow source={<RenderBoolean source={record.action_popup} />} label={translate('alerts.action_popup')} />                        
        </Grid>
      </Grid>                
        
    </div>
  );
};


const ListActions = (props) => {
    const translate = useTranslate(); 
    return (
    <TopToolbar>     
        <MyCreateButton {...props} />      
    </TopToolbar>
    );
};


export const MyCreateButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('alerts');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  
  const redirect = useRedirect();
        
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {                    
          redirect('/alerts/' + data.id);
        
        },
        onFailure: ( error ) => {
          notify( error.message , 'error');
        }
      }
    );
  };
    
  return (
    <>            
      <Button onClick={handleClick} label={translate('alerts.create')}>
        <IconContentAdd />                
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('alerts.create')}
      >
        <DialogTitle>{translate('alerts.create')}</DialogTitle>

        <FormWithRedirect
          save={handleSubmit}
          initialValues={{ associations_id: localStorage.getItem('associations_id') }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>
                <Grid container spacing={1}>                                     
                  <Grid item xs={12}>                                               
                    <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
                  </Grid>
                </Grid> 
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

const MyActions = ({ basePath, data, resource }) => {
  
  let backLink = '/alerts';
  return <BackLink link={backLink} title={ data && data.name ? data.name : null } />;
  
};


export const AlertsEdit  = ({ id, ...props }) => {

  const translate = useTranslate();        
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  let strRedirect =  `/alerts/${id}`;    
    
  const onSuccess = ({ data }) => {
    notify(translate('common.notification.updated'));        
    redirect('/alerts');        
  };
        
  return (

    <Edit 
      title={<ElementTitle {...props}  />} 
      {...props} 
      id={id}
      mutationMode="pessimistic"
      actions={<MyActions {...props} />}
      onSuccess={onSuccess}   
    >
      <SimpleForm {...props} redirect="edit" >  
                     
          <FormDataConsumer>
            {({ formData, ...rest }) => 
              <>                              
                <br/>
                <Grid container spacing={2}>                  
                  <Grid item xs={9}>   
                    <TextInput fullWidth autoFocus source="name" label={translate('common.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]}  />
                  </Grid>  
                  <Grid item xs={1}>                                 
                  </Grid>
                  <Grid item xs={2}>
                    <BooleanInput fullWidth source="actived" label={translate('alerts.actived')} />                             
                  </Grid>
                </Grid>      
                                
                <Grid container spacing={2}> 
                  <Grid item xs={4}> 
                    <SelectInput fullWidth label={translate('alerts.type')} source="type" choices={choicesAlertsType(translate)} validate={[required()]} />
                  </Grid>  
                  {formData.type == 1 ?
                  <Grid item xs={2}>                    
                    <DateInput fullWidth source="date_alert" label={translate('alerts.date_alert')} validate={[required()]} />
                  </Grid> : null }
                  {formData.type == 2 ?
                  <Grid item xs={2}>          
                    <SelectInput fullWidth label={translate('alerts.date_month')} source="date_month" choices={choicesMonths(translate)} validate={[required()]} />
                  </Grid> : null }     
                  {formData.type == 2 || formData.type == 3 ?
                  <Grid item xs={2}>           
                    <SelectInput fullWidth label={translate('alerts.date_day')} source="date_day" choices={choicesDays(translate)} validate={[required()]} />
                  </Grid> : null }                               
                </Grid>
                
                {formData.type == 1 || formData.type == 2 || formData.type == 3 ?
                <Grid container spacing={2}>     
                  <Grid item xs={12}>                    
                    <TextInput fullWidth source="message" label={translate('alerts.message')} validate={[required()]} />
                  </Grid> 
                </Grid> : null }                
                
                <Typography variant="h6" className="grey8 titleUserShow">                        
                    {translate('alerts.actions')}
                </Typography>
                <Divider fullWidth className="" />
                <br />
                  
                <Grid container spacing={0}>                   
                  <Grid item xs={12}>
                    <BooleanInput source="action_send_email" label={translate('alerts.action_send_email')} />         
                  </Grid> 
                  <Grid item xs={12}>
                    <BooleanInput source="action_popup" label={translate('alerts.action_popup')} />         
                  </Grid>
                </Grid>

              </>
            }
          </FormDataConsumer>

        </SimpleForm>
    </Edit>
  );
};

const ElementTitle = ({ record, ...props }) => {    
  const translate = useTranslate(); 
  return (<span>{translate('common.edit')} {record ? `"${record.name}"` : ''}</span>);
};


const useStyles = makeStyles({
  comment: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    maxWidth: '100%',
    display: 'block',
  },
});


export const AlertsCreate  = ({ id, ...props }) => {

  const translate = useTranslate();   
  const [info, setInfo] = useState();
    
  return (

    <Create {...props} >
      <SimpleForm 
        initialValues={{ associations_id: localStorage.getItem('associations_id') }}
        redirect="list"
      >                
        <Grid container spacing={2}>
          <Grid item xs={12}>                    
            <Typography variant="h5" className="grey">{translate('alerts.create')}</Typography>
          </Grid>  
          <Grid item xs={11}>                    
            <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
          </Grid>          
        </Grid>   

      </SimpleForm> 
    </Create>
  );
};
