// in src/posts.js
//import * as React from "react";
import React, { useState } from 'react';


import { useEffect  } from 'react';

import {
  List,
  Button,
  Datagrid,
  TextField,
  DeleteButton, 
  ReferenceManyField, 
  SingleFieldList,
  useTranslate,
  FormWithRedirect,
  TextInput,
  SaveButton,
  ResourceContextProvider,useRefresh, FunctionField, useUpdate, useNotify
} from 'react-admin';

import Box from '@material-ui/core/Box';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import EditIcon from '@material-ui/icons/Edit';
import IconContentEdit from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PublicIcon from '@mui/icons-material/Public';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import IconCancel from '@material-ui/icons/Cancel';

import Grid from '@mui/material/Grid';

import { validateMaxLength, validateInteger  } from './../components/Validator';

import { DocsCreateButton } from './../components/DocsCreateButton';
import { DocsCameraButton, DocsDeleteButton } from './../components/DocsPhotoButton';
import { DownloadButtonAuth } from './../components/DownloadButtonAuth';

import { apiUrl, apiOrigin, apiDefaultLanguage, targets, targetsResources  } from './../common/constants';
import { CopyToClipboard } from './../common/utils';

import './../common/styles.css';

import { FileIcon, defaultStyles  } from 'react-file-icon';


export const DocsList = ({ target, items_id , ...props }) => {

  const translate = useTranslate();  
  const notify = useNotify();

  let strTarget = targetsResources[target];
  let strRedirect =  `/${strTarget}/${items_id}/docs`;
  let sort = { field: 'id', order: 'DESC' };

  if (target === targets.USER_PHOTO || target === targets.TEMPLATE)
  {
    strRedirect =  `/${strTarget}/${items_id}`;
  }
  if (target === targets.CLASSROOM_PHOTO || target === targets.USER_CLASSROOM_PHOTO)
  {
    strRedirect =  `/${strTarget}/${items_id}/photos`;
    sort = { field: 'priority', order: 'ASC' };
  }
  if (target === targets.DOCUMENT_FAC)
  {
    strRedirect =  `/${strTarget}`;
    sort = { field: 'priority', order: 'DESC' };
  }
  if (target === targets.EVENT_IMAGE)
  {
    strRedirect =  `/${strTarget}/${items_id}/images`;
    sort = { field: 'priority', order: 'ASC' };
  }
  if (target === targets.USER_MORE_PHOTOS)
  {
    strRedirect =  `/${strTarget}/${items_id}/images`;
    sort = { field: 'priority', order: 'ASC' };
  }
  if (target === targets.DONATION_PROJECT_IMAGE)
  {
    strRedirect =  `/${strTarget}/${items_id}/images`;
    sort = { field: 'priority', order: 'ASC' };
  }
  if (target === targets.SETTINGS_DOCS)
  {
    strRedirect =  `/${strTarget}`;
    sort = { field: 'priority', order: 'ASC' };
  }
      
    
  let pagination=false;
  let perPage=1000;
    
  if (target === targets.GROUP)
  {
    let pagination=false;
    let perPage=1000;
  }

  const handleClickView = (textToCopy) => {        
          
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        notify(translate('docs.copy_link_done'), 'success');
      })
      .catch(err => {
        notify(translate('docs.copy_link_error'), 'error');
      });
      
  };
  
  return (  
    <>  

      <ResourceContextProvider value="docs">
        <List              
          basePath="/docs" 
          filter={{ target : target, items_id : items_id }}
          actions={<DocsCreateButton target={target} items_id={items_id} topToolbar={true} label={translate('docs.create')} /> } 
          empty={<DocsCreateButton target={target} items_id={items_id} topToolbar={true} label={translate('docs.create')} /> }     
          sort={sort} 
          label=""  
          title=""  
          bulkActionButtons={false} 
          pagination={pagination}
          perPage={perPage}
        >
          <Datagrid>                    
                     
            <FunctionField label="" render={renderFile} />
            <TextField source="name" label={translate('common.name')} sortable={false} /> 
            <FunctionField label={translate('docs.size')} render={renderSize} />
            { (target==targets.CLASSROOM_PHOTO || target==targets.DOCUMENT_FAC || target==targets.EVENT_IMAGE || target==targets.USER_MORE_PHOTOS || target==targets.DONATION_PROJECT_IMAGE || target==targets.SETTINGS_DOCS ) ?
              <FunctionField label={translate('common.priority')} render={ record => <OrderDocs record={record} direction={sort.order} />  } />
              : null }
            {(target==targets.EVENT || target==targets.EVENT_IMAGE ) ?
              <FunctionField label={translate('events.docs_mark')} render={ record => <MarkDocs record={record} />  } />
              : null }
            <DownloadButtonAuth label={translate('docs.download')} />
            <EditDocButton redirect={strRedirect} label={translate('docs.actions')} />     
            <FunctionField render={ record => record.privacy == 0 ? <CopyToClipboard label={translate('docs.copy_link')} message={apiUrl + '/docs/download/public/' + record.id} /> : null } />            
            <DeleteButton undoable={false} redirect={strRedirect} label="" />      
          </Datagrid>                            
        </List>

      </ResourceContextProvider>
    </>
  );
};


export const EditDocButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  

  const [update, { loading, error }] = useUpdate();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    update(
      { 
        type: 'update', 
        resource: 'docs', 
        payload : { id: values.id, data : { name : values.name } }
      },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };    

  return (
    <>
      <Button onClick={handleClick} label={translate('docs.edit')} startIcon={<EditIcon />} />

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('docs.edit')}
      >
        <DialogTitle>{translate('docs.edit')}</DialogTitle>

        <FormWithRedirect
          resource="docs"
          save={handleSubmit}
          initialValues={{ id : props.record.id, name : props.record.name }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>
              
                <Grid container spacing={2}>
                  <Grid item xs={12}>  
                    <TextInput label={translate('common.name')} fullWidth source="name" validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />                                        
                  </Grid> 
                </Grid>                     
                
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

function OrderDocs ({ ...props}) {
  const notify = useNotify();
  const refresh = useRefresh();  
  const [update, { loading, error }] = useUpdate();
    
  const handleAction = (action) => {
    update(
      { 
        type: 'update', 
        resource: 'docs/reorder', 
        payload : { id: props.record.id, data : 
                {       direction: action
                } }
      },
      {
        onSuccess: ({ data }) => {
          refresh();
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };
        
  return (
    <>
      <KeyboardArrowUpIcon onClick={() => handleAction(props.direction=='ASC' ? -1 : 1)} />
      <KeyboardArrowDownIcon onClick={() => handleAction(props.direction=='ASC' ? 1 : -1)} />     
    </>
  );

}


function MarkDocs ({ ...props}) {
  const notify = useNotify();
  const refresh = useRefresh();  
  const [update, { loading, error }] = useUpdate();
        
  const handleAction = (action) => {
    update(
      { 
        type: 'update', 
        resource: 'docs/mark', 
        payload : { id: props.record.id, data : 
                {       mark: action
                } }
      },
      {
        onSuccess: ({ data }) => {
          refresh();
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };
        
  return ( <>
    { props.record.mark ? 
      <AttachEmailIcon className="" onClick={() => handleAction(-1)} />
      :
      <AttachEmailIcon className="grey2" onClick={() => handleAction(1)} />
    }
  </>
  );

}


export const DocsListShow = ({ target, items_id , resource_name, ...props }) => {

  const translate = useTranslate();  
    
  let context_provider = 'docs';
  if (resource_name === 'users-fac-contacts') context_provider = 'docs-contacts';
  let base_path = '/' + context_provider;
    
  let sort = { field: 'id', order: 'DESC' };
    
  if (target === targets.DOCUMENT_FAC)
  {
    sort = { field: 'priority', order: 'DESC' };
  }
  
  if (target === targets.USER_MORE_PHOTOS)
  {
    sort = { field: 'priority', order: 'ASC' };
  }

  return (  
    <>  
      <ResourceContextProvider value={context_provider}>
        <List              
          basePath={base_path} 
          filter={{ target : target, items_id : items_id }}
          actions={null} 
          empty={<span/> }     
          sort={sort} 
          label=""  
          title=""  
          bulkActionButtons={false} 
          pagination={false}
        >
          <Datagrid
            className="header_grey6"
          >                                         
            <FunctionField label="" render={renderFile} />
            <TextField source="name" label={translate('common.name')} sortable={false} /> 
            <FunctionField label={translate('docs.size')} render={renderSize} />                        
            <DownloadButtonAuth label={translate('docs.download')} />  
          </Datagrid>                            
        </List>

      </ResourceContextProvider>
    </>
  );
};

function renderFile(record)
{         
  if (record.mimeType.includes('image') )
  {
    return (
      <ImageViewer docs_id={record.id} is_public={false} title="" className="docs_photo_list" />
    );
  }
  else
  {
    let fileExtension = record.name.split('.').pop();
    if (fileExtension === record.name) fileExtension = '';

    let classIcon = '';
    if (fileExtension === 'pdf') classIcon=defaultStyles.pdf;
    if (fileExtension === 'docx') classIcon=defaultStyles.docx;
    if (fileExtension === 'doc') classIcon=defaultStyles.doc;
    if (fileExtension === 'xlsx') classIcon=defaultStyles.xlsx;
    if (fileExtension === 'xls') classIcon=defaultStyles.xls;
    if (fileExtension === 'txt') classIcon=defaultStyles.txt;
    if (fileExtension === 'png') classIcon=defaultStyles.png;
    if (fileExtension === 'webm') classIcon=defaultStyles.webm;
    if (fileExtension === 'mp4') classIcon=defaultStyles.mp4;
    if (fileExtension === 'none') classIcon=defaultStyles.file;

    return (
      <div className="iconWrapper">
        <FileIcon extension={fileExtension} {...classIcon} />
      </div>
    );   
  }
}

function renderSize(record)
{
  if (record.size < 1000)
  {
    let newValue = Math.round( (record.size / 1000 ) * 10 ) / 10;
    return newValue + ' KB';
  }
  else if (record.size < 1000000)
  {
    let newValue = Math.round( (record.size / 1000 ) * 10 ) / 10;
    return newValue + ' KB';
  }
  else if (record.size < 1000000000)
  {
    let newValue = Math.round( (record.size / 1000000 ) * 10 ) / 10;
    return newValue + ' MB';
  }
}



export const ImageViewer = ({...props}) => {

  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
        
      let headers = new Headers({ Accept: 'application/json' });
      headers.set('Access-Control-Allow-Origin', apiOrigin );
      headers.set('Authorization', localStorage.getItem('auth'));
      headers.set('Accept-Language', apiDefaultLanguage);
      headers.set('Role', localStorage.getItem('active_permission'));

      let urlApi1 = apiUrl;
      urlApi1 += props.is_public ? '/docs/download/public/' : '/docs/download/';
      urlApi1 += props.docs_id;
                
      const response = await fetch(urlApi1 , { 
        headers: headers
      });
        
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setImageUrl(url);        
    };

    fetchImage();
  }, [props.docs_id]);
  
  return ( 
    imageUrl ?
      <>
        { props.is_div ? 
          <div className={props.className} style={{backgroundImage: 'url("' + imageUrl + '")'}}/>
          :
          props.link ? 
            <a href={imageUrl} download={props.link_name} target="_blank"><img src={imageUrl} className={props.className} title={props.title} alt={props.title} /></a> 
            : 
            <img src={imageUrl} className={props.className} title={props.title} alt={props.title} /> 
        }            
      </>
      : 
            
      <div className={props.className} style={{backgroundImage: null }} ><span></span></div>
  );
};

export const BoxPhotoUser = ({ target, items_id , edit, resource_name, quantity, className, onSuccess, camera, ...props }) => {
        
  const translate = useTranslate(); 
        
  let emptyImg = null;
  if (props.default_img==1)
    emptyImg = <div className={className+'-container'}><img src="/img/img_association.png" className={className} /></div>;
  else if (props.default_img==0)
    emptyImg = <div className={className+'-container'}><img src="/img/img_person.png" className={className} /></div>;
  else if (props.default_img==2)
  {   
    emptyImg = <div className={className+'-container'}><img src="/img/card_without_background.jpg" className={className} /></div>;
  }
  else if (props.default_img==3)
  {   
    emptyImg = <div className={className+'-container'}><img src="/img/card_google_wallet_without_background.jpg" className={className} /></div>;
  }
        
  let reference = 'docs';
  if (resource_name === 'users-fac-contacts') reference = 'docs-contacts';
                
  let per_page = quantity ? quantity : 1;
        
  return (
    <>  
      { edit ? (
                    
        <Box textAlign="right" className="photo_box">
                
          { camera ?
            <DocsCameraButton target={target} items_id={items_id} label="" icon={<CameraAltIcon />} btnClassname="btnPhotoUser" onSuccess={onSuccess} />
            : null }
                
          <DocsCreateButton target={target} items_id={items_id} label="" icon={<IconContentEdit />} btnClassname="btnPhotoUser" onSuccess={onSuccess} />
          <DocsDeleteButton target={target} items_id={items_id} label="" icon={<DeleteIcon />} onSuccess={onSuccess} />
                
          <ResourceContextProvider value="docs">
            <ReferenceManyField                    
              reference={reference}
              target="users_id"
              filter={{ target : target, items_id : items_id }}
              label=""
              className="photo_user"
              perPage={per_page}
              sort={{ field: 'priority', order: 'ASC' }}
            >
              <SingleFieldList linkType={false} empty={emptyImg} className={className}>                                
                <FunctionField render={record => <ImageViewer docs_id={record.id} className={className} is_public={false} />  }  />
              </SingleFieldList>

            </ReferenceManyField>
          </ResourceContextProvider>
                
        </Box>
      ) : (
        <Box textAlign="left">

          <ReferenceManyField                    
            reference={reference}
            target="users_id"
            filter={{ target : target, items_id : items_id }}
            label=""
            perPage={per_page}
            sort={{ field: 'priority', order: 'ASC' }}
          >
                
            <SingleFieldList linkType={false} empty={emptyImg} className={className}>                                
              <FunctionField render={record => <ImageViewer docs_id={record.id} className={className} is_public={false} />  }  />
            </SingleFieldList>

          </ReferenceManyField>

        </Box>
      )}            

    </>
  );
};


