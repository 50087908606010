import {
  TopToolbar,
} from 'react-admin';

import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Typography from '@mui/material/Typography';

export const BackLink = props => {
  
  return (
    <TopToolbar>
    <Grid container spacing={2}>
    <Grid item xs={12}>   
      <div style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}>
        <Link
          to={props.link}
          className=""
        >
          <ArrowBackIosNewIcon className="mr-2" />                        
        </Link>
        <Typography variant="h5" className="grey8">
          {props.title}     
        </Typography>
      </div>  

    </Grid>
  </Grid> 
  </TopToolbar>
  );
  
};

