// in src/posts.js
import * as React from "react";

import {
    BooleanInput,
    List, 
    SimpleList,
    Datagrid,
    TextField,
    ReferenceField,
    EditButton,
    DeleteButton,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    BooleanField ,
    ReferenceManyField,
    Pagination,
    TabbedForm,
    FormTab,
    Filter,
    ChipField, SingleFieldList,NumberInput,ImageInput ,ImageField,useListContext,ResourceContextProvider, FunctionField

} from 'react-admin';

import Button from '@mui/material/Button';


import {
    Grid,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    Typography,
} from '@material-ui/core';

import Chip from '@material-ui/core/Chip';
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';
import RichTextInput from 'ra-input-rich-text';

import { useMediaQuery } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


import { i18nProvider, useTranslate  } from '../common/language';
import { subscriptionModule, subscriptionModuleOption  } from '../common/utils';
import { targets, modules} from '../common/constants';
import {  ImageViewer } from '../resources/docs';

const useStyles = makeStyles({
    root: {
        marginTop: '1em',
    },
    media: {
        height: 140,
    },
    title: {
        paddingBottom: '0.5em',
    },
    actionSpacer: {
        display: 'flex',
        justifyContent: 'space-around',
    },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


const ModuleView: FC = props => {
    const { ids, data, basePath } = useListContext();
    const [open, setOpen] = React.useState(false);
    const classes = useStyles(props);
    const translate = useTranslate(); 
    
    const openDialog = () => {
        setOpen(true);
    };
    const closeDialog = () => {
        setOpen(false);
    };
    
    let emptyImg = <img src="/img/pixel.jpg" className="public-events-img" />;
  
    return(
        <>
        <Grid container spacing={4} className={classes.root}>
           {ids.map((id: Identifier) => (
                !( data[id].public_id === modules.CONTACTS_FAC && (!subscriptionModule(modules.MEMBER_FAC)) ||
                  data[id].public_id === modules.FEDERATION && (!subscriptionModule(modules.FEDERATION)) ||
                  data[id].public_id === modules.MEMBER_FAC && (!subscriptionModule(modules.MEMBER_FAC)) ||
                  data[id].public_id === modules.RECERCAT && (!subscriptionModule(modules.RECERCAT)) ||
                  data[id].public_id === modules.DOCUMENTS_FAC && (!subscriptionModule(modules.MEMBER_FAC))                   
                ) ?
                <Grid key={id} xs={12} sm={6} md={6} lg={4} xl={3} item>
                    <Card>
                        
                        <ResourceContextProvider value="docs">
                            <ReferenceManyField                    
                              reference="docs"
                              target="modules_id"
                              filter={{ target : targets.MODULE , items_id : data[id].id }}
                              label=""
                              className="photo_user"
                              perPage={1000}
                              sort={{ field: 'priority', order: 'ASC' }}
                            >
                              <SingleFieldList linkType={false} empty={emptyImg} >                                
                                <FunctionField render={record => <ImageViewer docs_id={record.id} is_public={true} className="public-events-img" />  }  />
                              </SingleFieldList>

                            </ReferenceManyField>
                          </ResourceContextProvider>
                        
                        <CardContent className={classes.title}>
                            { subscriptionModule(data[id].public_id) ? 
                            <Chip className="bg-green bold upper mb-10" label={translate('modules_view.actived')} />
                            : null }
                            <Typography
                                variant="h5"
                                component="h2"
                                align="left"
                            >
                                {data[id].name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" className="mt-10" >
                                <span dangerouslySetInnerHTML={{ __html: data[id].description }} />
                            </Typography>
                            
                        </CardContent>
                        <CardActions className="justify-right">
                            { subscriptionModule(data[id].public_id) ? 
                                <Button size="small" onClick={openDialog}>{translate('modules_view.to_desactive')}</Button>
                            :
                                <Button size="small" onClick={openDialog}>{translate('modules_view.to_active_button')}</Button>
                            }
                        </CardActions>
                    </Card>
                </Grid> : null 
            ))}
        </Grid>
        
        <BootstrapDialog
            onClose={closeDialog}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth
            maxWidth="md"
          >
            <DialogTitle sx={{ m: 0, p: 2 }}>
              {translate('modules_view.change_modules_title')}   
              <IconButton
                aria-label="close"
                onClick={closeDialog}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent>   
                { window.location.hostname.replaceAll('.','')=='admingestioentitatscat' || subscriptionModule(modules.RECERCAT) ? 
                translate('modules_view.to_activate')
                :
                translate('modules_view.to_activate_sifac')
                }                 
              <br />
              <br />
            </DialogContent>
            
        </BootstrapDialog>
        </>
    ) ;
};


export const ModulesViewList = props => {
    const translate = useTranslate(); 
    
    return (
            <>
            <Typography gutterBottom variant="h5" component="div" className="mt-20">
                {translate('modules_view.title')}
            </Typography>
            <ResourceContextProvider value="modules_view">
                <List
                    {...props}
                    sort={{ field: 'priority', order: 'ASC' }}
                    perPage={1000}
                    pagination={false}
                    component="div"
                    actions={false}
                    basePath="modules_view"
                >
                    <ModuleView />
                </List>
            </ResourceContextProvider>
            </>
    );
}

/*

export const ModulesViewListOld = (props) => {
    const translate = useTranslate(); 
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props}>
            <Datagrid>                   
                <TextField source="name" label={translate('common.name')}/>
                <BooleanField source="actived" label={translate('common.actived')} />
                <BooleanField source="visible" label={translate('common.visible')} />
                <TextField source="priority" label={translate('common.priority')}/>
                <EditButton />
                <DeleteButton />
            </Datagrid>
        </List>
    );
}

const ElementTitle = ({ record }) => {
    const translate = useTranslate(); 
    return <span>{translate('common.edit')} {record ? `"${record.name}"` : ''}</span>;
};

export const ModulesViewEdit  = ({ id, ...props }) => {

    const translate = useTranslate(); 
    return (

    <Edit title={<ElementTitle />} {...props} id={id} > 
    <TabbedForm>
            <FormTab
                label={translate('subscriptions.module')}
            >       
                <TextInput source="public_id" label={translate('common.name_internal')} />      
                <TextInput source="name" label={translate('common.name')} />
                <TextInput multiline source="description" label={translate('common.description')} />
                <ImageInput source="image" label={translate('common.image')} accept="image/*">
                    <ImageField source="image" title="title" />
                </ImageInput>
                <BooleanInput source="actived" label={translate('common.actived')} />
                <BooleanInput source="visible" label={translate('common.visible')} />
                <NumberInput source="priority" step={1} />
            </FormTab>

            <FormTab
                label={translate('subscriptions.options')}
            >

            <ReferenceManyField {...props}
                fullWidth
                label=""
                reference="modules_options"
                target="modules_id"
                hasCreate
            >
            
                <EditableDatagrid                    
                    undoable
                    createForm={<OptionForm initialValues={{ modules_id: id }} />}
                    editForm={<OptionForm />}
                >
                    <TextField source="public_id" label={translate('common.name_internal')} />
                    <TextField source="name" label={translate('common.name')} />
                    <TextField multiline source="description" label={translate('common.description')} />
                 
                </EditableDatagrid>
                
            </ReferenceManyField>   

            </FormTab>
    </TabbedForm>
    </Edit>
);
};

const OptionForm= props => {
    const translate = useTranslate(); 
    return (
    <RowForm {...props}>
        <TextInput source="public_id" label={translate('common.name_internal')} />
        <TextInput source="name" label={translate('common.name')} />     
        <TextInput multiline source="description" label={translate('common.description')} />   
    </RowForm>
);
};


export const ModulesViewCreate = props => {
    const translate = useTranslate(); 
    return (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="public_id" label={translate('common.name_internal')} />
            <TextInput source="name" label={translate('common.name')} /> 
            <TextInput source="description" multiline label={translate('common.description')} />
            <ImageInput source="image" label={translate('common.image')} accept="image/*">
                <ImageField source="image" title="title" />
            </ImageInput>
            <BooleanInput source="actived" label={translate('common.actived')}/>
            <BooleanInput source="visible" label={translate('common.visible')}/>
            <NumberInput source="priority" step={1} label={translate('common.priority')} />
        </SimpleForm>
    </Create>
);
};

*/