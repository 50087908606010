// in src/posts.js
import React, { useState } from 'react';

import {
  List, 
  Datagrid,
  TextField,
  DeleteButton,
  Edit,
  SelectInput,
  TextInput,
  Button ,  
  Create,
  SimpleForm,
  CreateButton ,
  TopToolbar,
  FormTab,
  TabbedForm,
  ReferenceManyField,
  FormDataConsumer,
  useListContext, required,NumberInput,BooleanInput,SelectField,  FunctionField, ResourceContextProvider, useCreate, useRefresh, FormWithRedirect, Toolbar, SaveButton,
  useNotify,
  useRedirect,
  DateField,
  DateInput,
  SimpleFormIterator,
  ArrayInput,
  ReferenceInput,
  useDataProvider,
  AutocompleteInput, 
  Filter, 
  downloadCSV
} from 'react-admin';

import jsonExport from 'jsonexport/dist';

import { useEffect } from 'react';

import { AccordionSection  } from '@react-admin/ra-form-layout';

import { makeStyles } from '@material-ui/core/styles';

import Divider from '@mui/material/Divider';


import { useTranslate  } from '../common/language';
import { UserAvatarField, UserFullNameTxt, UserInput } from '../components/User';
import { dateOnlyToCatalan, subscriptionModule, setActivePermissionData, structArrayToIdList } from './../common/utils';    

import Typography from '@mui/material/Typography';


import RichTextInput from 'ra-input-rich-text';


import { TextFieldShow } from '../components/TextFieldShow';

import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Chip from '@material-ui/core/Chip';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { ColorInput } from 'react-admin-color-input';

import { DocsList, DocsListShow } from './docs';
import { targets, choicesClassroomsType, modules  } from './../common/constants';

import { CityInput } from './../components/Inputs';

import { RenderBoolean, chipsUsers } from '../components/User';
import { ExportButton } from './../components/ExportButton';
import { BackLink } from './../components/BackLink';

import { chipsRealState, RealStateInput } from '../resources/real_state';

import Box from '@material-ui/core/Box';

import { Link } from 'react-router-dom';

import { validateMaxLength, validateInteger  } from './../components/Validator';

//empty={<EmptyClassrooms title={translate('classrooms.no_classrooms_title')} icon={MeetingRoomIcon} />}
/* 
 * Llista editable des de la pestanya general Espais (espais per associació)
 * i Llista des de l'edició de la fitxa d'users (espais d'usuari)
 * El paràmetre que ho distingeix és props.user_classroom
 */
export const AgreementsList = (props) => {
  const translate = useTranslate();  
    
  let basePath='/agreements'; 
  let resource = 'agreements';
  let strRedirect = 'agreements';
  
  let real_state_id = props.real_state_id ? props.real_state_id : 0;
    
  return (
    <ResourceContextProvider value={resource}>
      <List 
        basePath={basePath}   
        filter={{ associations_id : localStorage.getItem('associations_id'), real_state_id: real_state_id, users_id: props.users_id ? props.users_id : 0 }}  
        actions={props.show ?  <span/> : <ListActions />}    
        empty={props.show ?  <span/> : <ListActions />}        
        bulkActionButtons={false}          
        perPage={1000}   
        pagination={false}
        sort={{ field: 'id', order: 'ASC' }} 
        label=""  
        title=""  
        resource={resource}
        filters={props.show ?  <span/> : <MyFilter />} 
        filterDefaultValues={ { only_current : props.show ? false : true, q: "" }}
      >        
        <Datagrid 
          rowClick={props.show ? null : 'edit'}
          expand={<AgreementsDetails />} >  
          <Avatar><ThumbUpIcon/></Avatar>                                  
          <TextField source="name" label={translate('common.name')} sortable={false} /> 
          <FunctionField render={record => chipsUsers(record.users) } label={translate('agreements.users')} sortable={false}  />  
          { subscriptionModule(modules.REAL_STATE) ? 
          <FunctionField render={record => chipsRealState(record.real_state) } label={translate('agreements.real_state')} sortable={false}  />  
          : null }
          <DateField source="date_start" label={translate('agreements.date_start')} sortable={false} /> 
          <DateField source="date_finish" label={translate('agreements.date_finish')} sortable={false} /> 
        </Datagrid>        
      </List>
    </ResourceContextProvider>
  );
};

const MyFilter = (props) => {
    const translate = useTranslate(); 
    return (
    <Filter {...props}>         
        <TextInput label={translate('common.search')} source="q" alwaysOn validate={[validateMaxLength(50,translate('common.validation.maxLength'))]} resettable />    
        <BooleanInput label={translate('agreements.filter_only_current')} source="only_current" alwaysOn />         
    </Filter>
);
};



export const AgreementsDetails = ({ id, record, resource, ...props }) => {
  const translate = useTranslate(); 
        
  return (
    <div className="usersClassroomsExpand">        
      <Grid container spacing={2}>
        <Grid item xs={6}>                    
          <TextFieldShow source={record.name} label={translate('common.name')} />                       
        </Grid>
        <Grid item xs={6}>                                        
        </Grid>
        <Grid item xs={2}>                    
          <TextFieldShow source={dateOnlyToCatalan(record.date_start, translate)} label={translate('agreements.date_start')} />                   
        </Grid>
        <Grid item xs={2}>                    
          <TextFieldShow source={dateOnlyToCatalan(record.date_finish, translate)} label={translate('agreements.date_finish')} />                   
        </Grid>
        <Grid item xs={2}>                    
          <TextFieldShow source={dateOnlyToCatalan(record.date_alert, translate)} label={translate('agreements.date_alert')} />                   
        </Grid>
        <Grid item xs={2}>                    
          <TextFieldShow source={dateOnlyToCatalan(record.date_renewal, translate)} label={translate('agreements.date_renewal')} />                   
        </Grid>
        <Grid item xs={2}>                    
          <TextFieldShow source={record.renewal_duration} label={translate('agreements.renewal_duration')} />                   
        </Grid>
        <Grid item xs={2}>                    
          <TextFieldShow source={dateOnlyToCatalan(record.date_renewal_quote, translate)} label={translate('agreements.date_renewal_quote')} />                   
        </Grid>
        <Grid item xs={12}>                    
          <TextFieldShow source={record.comments} label={translate('agreements.comments')} />                   
        </Grid>
      </Grid>
      
      <Typography variant="h6" className="grey8 titleUserShow">                        
        {translate('agreements.users')}
      </Typography>
      <Divider fullWidth className="" />
      
      <br />
      {chipsUsers(record.users)}
            
      { subscriptionModule(modules.REAL_STATE) ? <>
      <Typography variant="h6" className="grey8 titleUserShow">                        
        {translate('agreements.real_state')}
      </Typography>
      <Divider fullWidth className="" />      
      <br />
      {chipsRealState(record.real_state)}
      </> : null }
                                
      <Typography variant="h6" className="grey8 titleUserShow">                        
        {translate('agreements.docs')}
      </Typography>
      <Divider fullWidth className="" />
        
      <br />
      <DocsListShow target={targets.AGREEMENT} items_id={record.id} />
        
    </div>
  );
};


const exporter = (items, translate, notify) => {
    
  const itemsForExport = items.map(item => {
    var itemForExport = {
      id : item.id,
      name: item.name,
      date_start: dateOnlyToCatalan(item.date_start),
      date_finish: dateOnlyToCatalan(item.date_finish), 
      date_alert: dateOnlyToCatalan(item.date_alert), 
      date_renewal: dateOnlyToCatalan(item.date_renewal), 
      renewal_duration: item.renewal_duration, 
      date_renewal_quote: dateOnlyToCatalan(item.date_renewal_quote),
      comments: item.comments,
      users: structArrayToIdList(item.users),
      real_state: subscriptionModule(modules.REAL_STATE) ? structArrayToIdList(item.real_state) : '',
    };
    return itemForExport;
  });
  jsonExport(itemsForExport, {
    headers: ['id','name','date_start','date_finish','date_alert','date_renewal','renewal_duration','date_renewal_quote','comments','users','real_state'],
    rename: [translate('common.id'), translate('common.name'), translate('agreements.date_start'), translate('agreements.date_finish'), translate('agreements.date_alert')
    , translate('agreements.date_renewal'), translate('agreements.renewal_duration'), translate('agreements.date_renewal_quote'), translate('agreements.comments'), translate('agreements.users')
    , translate('agreements.real_state')],        
        
  }, (err, csv) => {
    const csv_new = '\uFEFF' + csv;
    downloadCSV(csv_new, translate('agreements.exporter_file_name') ); 
    notify(translate('common.download_ok'),"success");
  });
};

const ListActions = (props) => {
  const translate = useTranslate(); 
  
  let filterExport = { 
        associations_id: localStorage.getItem('associations_id'),        
        q: props.filterValues && props.filterValues.q ? props.filterValues.q : '',
        only_current: props.filterValues && props.filterValues.only_current ? props.filterValues.only_current : false
    };
    
  return (
    <TopToolbar>             
      <MyCreateButton {...props} />
      <ExportButton maxResults="100000" filterValues={filterExport} alwaysEnabled={false} exporter={exporter} sort={{ field: 'id', order: 'DESC' }} /> 
    </TopToolbar>
  );
};

export const MyCreateButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('agreements');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  
  const redirect = useRedirect();
        
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {                    
          redirect('/agreements/' + data.id);
        
        },
        onFailure: ( error ) => {
          notify( error.message , 'error');
        }
      }
    );
  };
    
  return (
    <>            
      <Button onClick={handleClick} label={translate('agreements.create')}>
        <IconContentAdd />                
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('agreements.create')}
      >
        <DialogTitle>{translate('agreements.create')}</DialogTitle>

        <FormWithRedirect
          save={handleSubmit}
          initialValues={{ associations_id: localStorage.getItem('associations_id') }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>
                <Grid container spacing={1}>                                     
                  <Grid item xs={12}>                                               
                    <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
                  </Grid>
                </Grid> 
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

const MyActions = ({ basePath, data, resource }) => {
  
  let backLink = '/agreements';  
  return <BackLink link={backLink} title={ data && data.name ? data.name : null } />;
  
};


export const AgreementsEdit  = ({ id, ...props }) => {

  const translate = useTranslate();        
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  let strRedirect =  `/agreements/${id}`;    
    
  const onSuccess = ({ data }) => {
    notify(translate('common.notification.updated'));
    redirect('/agreements');        
  };
    
  return (

    <Edit 
      title={<ElementTitle {...props}  />} 
      {...props} 
      id={id}
      mutationMode="pessimistic"
      actions={<MyActions {...props} />}
      onSuccess={onSuccess}   
    >
        
      <TabbedForm        
        toolbar={<FormToolbar />}
      >    
        <FormTab
          label={translate('common.data')}
          contentClassName={classes.tab}
          path="" 
                
        > 
               
          <FormDataConsumer>
            {({ formData, ...rest }) => 
              <>                              
                <br/>
                <Grid container spacing={2}>
                  <Grid item xs={12}>   
                    <TextInput fullWidth autoFocus source="name" label={translate('common.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]}  />
                  </Grid>                             
                  <Grid item xs={2}>
                    <DateInput source="date_start" fullWidth label={translate('agreements.date_start')} />
                  </Grid>  
                  <Grid item xs={2}>
                    <DateInput source="date_finish" fullWidth label={translate('agreements.date_finish')} />
                  </Grid>  
                  <Grid item xs={2}>
                    <DateInput source="date_alert" fullWidth label={translate('agreements.date_alert')} />
                  </Grid>  
                  <Grid item xs={2}>
                    <DateInput source="date_renewal" fullWidth label={translate('agreements.date_renewal')} />
                  </Grid>  
                  <Grid item xs={2}>
                    <TextInput fullWidth source="renewal_duration" label={translate('agreements.renewal_duration')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />                             
                  </Grid>
                  <Grid item xs={2}>
                    <DateInput source="date_renewal_quote" fullWidth label={translate('agreements.date_renewal_quote')} />
                  </Grid> 
                  <Grid item xs={12}>
                    <AccordionSection className="accordion" label={translate('agreements.users')} fullWidth defaultExpanded={true}>
                        <ArrayInput source="users" label="">
                            <SimpleFormIterator 
                              disableReordering
                              getItemLabel={index => ''}
                            >
                              <UserInput source="id" user_type={-1} disabled={false} label={translate('common.name')} allowEmpty={false} required={true} resettable={false} />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </AccordionSection>   
                  </Grid>  
                  { subscriptionModule(modules.REAL_STATE) ? 
                  <Grid item xs={12}>
                    <AccordionSection className="accordion" label={translate('agreements.real_state')} fullWidth defaultExpanded={true}>
                        <ArrayInput source="real_state" label="">
                            <SimpleFormIterator 
                              disableReordering
                              getItemLabel={index => ''}
                            >
                              <RealStateInput source="id" disabled={false} label={translate('agreements.real_state')} allowEmpty={false} required={true} resettable={false} />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </AccordionSection>   
                  </Grid>   
                  : null }
                  <Grid item xs={12}>
                    <TextInput fullWidth source="comments" label={translate('real_state.comments')} />                             
                  </Grid>
                </Grid>
              </>
            }
          </FormDataConsumer>

        </FormTab> 
        
        <FormTab
            label={translate('agreements.docs')}
            contentClassName={classes.tab}
            path="docs"                 
        > 
                      
            <br />
            <AccordionSection className="accordion" label={translate('agreements.docs')} fullWidth defaultExpanded={true}>
              <DocsList target={targets.AGREEMENT} items_id={id} />
            </AccordionSection>   

        </FormTab>
            
      </TabbedForm>
    </Edit>
  );
};



const ElementTitle = ({ record, ...props }) => {    
  const translate = useTranslate(); 
  return (<span>{translate('common.edit')} {record ? `"${record.name}"` : ''}</span>);
};

const FormToolbar = ({ id , ...props }) => {
    
  let redirect = '/classrooms';
    
  if (props.resource === 'users_classrooms')
  {
    redirect = '/users/' + props.record.users_id + '/classrooms';
  }
    
  return (
    <Toolbar {...props} className="user_form_toolbar" >
      <SaveButton />
      <DeleteButton redirect={redirect} mutationMode="pessimistic" />
    </Toolbar>
  );
};

const EmptyClassrooms = ({ associations_id , ...props}) => {
  const translate = useTranslate(); 
  const { basePath, resource } = useListContext();
  return (
    <Box textAlign="center" m={1} >
      <br />
      <Typography variant="h5" paragraph className="grey8">
        {translate('classrooms.no_classrooms_title')} 
      </Typography>
      <Typography variant="body1" className="grey8">
        {translate('classrooms.no_classrooms_ask')} 
      </Typography>
      <br />
      <CreateButton />
    </Box>
  );
};


const useStyles = makeStyles({
  comment: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    maxWidth: '100%',
    display: 'block',
  },
});




export const AgreementsCreate  = ({ id, ...props }) => {

  const translate = useTranslate();   
  const [info, setInfo] = useState();
    
  return (

    <Create {...props} >
      <SimpleForm 
        initialValues={{ associations_id: localStorage.getItem('associations_id') }}
        redirect="list"
      >                
        <Grid container spacing={2}>
          <Grid item xs={12}>                    
            <Typography variant="h5" className="grey">{translate('agreements.create')}</Typography>
          </Grid>  
          <Grid item xs={11}>                    
            <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
          </Grid>          
        </Grid>   

      </SimpleForm> 
    </Create>
  );
};



