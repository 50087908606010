import React, { useState } from 'react';

import { SaveButton ,
  List,
  Datagrid,
  TextField,
  DeleteButton,
  useTranslate,
  required,    
  useCreate,
  useUpdate,
  useNotify,
  FormWithRedirect,useRefresh,
  Button,
  FunctionField, TopToolbar, NumberInput, ResourceContextProvider, TabbedShowLayout, Tab
} from 'react-admin';


import EditIcon from '@material-ui/icons/Edit';

import { useStyles  } from '../common/styles';

import Typography from '@material-ui/core/Typography';



import Divider from '@mui/material/Divider';

import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';


import { TextFieldShow } from '../components/TextFieldShow';

import Grid from '@mui/material/Grid';


import { validateInteger, validateDouble  } from './../components/Validator';

export const UsersEconomicDataList = (props) => {
  const translate = useTranslate(); 
    
  let strRedirect =  `/users/${props.users_id}/more_federation`; 
    
  return (
           
    <ResourceContextProvider value="users_economic_data" >
      <List 
        syncWithLocation 
        basePath="/users_economic_data" 
        filter={{ users_id : props.users_id }}  
        title=""
        bulkActionButtons={false}                
        actions={<UsersEconomicDataToolbar users_id={props.users_id} /> }  
        perPage={1000}
        sort={{ field: 'year', order: 'ASC' }} 
        empty={<UsersEconomicDataToolbar users_id={props.users_id} /> } 
        pagination={false}
      >

        <Datagrid   
          expand={<UsersEconomicDataDetails />} 
          className="header_grey6"
          label=""
          fullWidth
        >
          <TextField source="year" label={translate('common.year')} sortable={false} /> 
          <TextField label={translate('users_economic_data.avg_quote')} source="avg_quote" sortable={false} />
          <FunctionField label={translate('users_economic_data.incomings')} render={record => <RenderEconomicDataTotalIncoming record={record} /> } sortable={false} />
          <FunctionField label={translate('users_economic_data.expenses')} render={record => <RenderEconomicDataTotalExpenses record={record} /> } sortable={false} />
          <FunctionField label={translate('users_economic_data.inversion')} render={record => <RenderEconomicDataTotalInversion record={record} /> } sortable={false} />
          <FunctionField label={translate('users_economic_data.total')} render={record => <RenderEconomicDataTotal record={record} /> } sortable={false} />                
          <UsersEconomicDataEditButton label=""/>
          <DeleteButton undoable={false} redirect={strRedirect} label=""/>
        </Datagrid>

      </List> 
    </ResourceContextProvider>

  );
};


export const UsersEconomicDataEditButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  

  const [update, { loading, error }] = useUpdate();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };
                        
  const handleSubmit = async values => {
    update(
      { 
        type: 'update', 
        resource: 'users_economic_data', 
        payload : { id: values.id, data : 
                {       users_id: values.users_id,
                  year : values.year, 
                  avg_quote: values.avg_quote,
                  members: values.members, 
                  services: values.services, 
                  subsidies: values.subsidies,
                  rentals : values.rentals, 
                  products: values.products, 
                  privates: values.privates, 
                  others: values.others,
                  exp_rentals: values.exp_rentals,
                  exp_workers: values.exp_workers,
                  exp_material: values.exp_material,
                  exp_professionals: values.exp_professionals,
                  exp_groups: values.exp_groups,
                  exp_supplies: values.exp_supplies,
                  exp_maintenance: values.exp_maintenance,
                  exp_marketing: values.exp_marketing,
                  exp_others: values.exp_others ,
                  inv_building: values.inv_building,
                  inv_theatre: values.inv_theatre,
                  inv_bar: values.inv_bar,
                  inv_accessibility: values.inv_accessibility,
                  inv_security: values.inv_security,
                  inv_others: values.inv_others
                } }
      },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };    

  return (
    <>
      <Button onClick={handleClick} label="" startIcon={<EditIcon />} />

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
      >
        <DialogTitle>{translate('users_economic_data.edit_title')}</DialogTitle>

        <FormWithRedirect
          resource=""
          save={handleSubmit}
          initialValues={{ id : props.record.id, 
            users_id: props.record.users_id,
            year : props.record.year, 
            avg_quote : props.record.avg_quote,
            members: props.record.members, 
            services: props.record.services,
            subsidies: props.record.subsidies,
            rentals: props.record.rentals,
            products: props.record.products,
            privates: props.record.privates,
            others: props.record.others,
            exp_rentals: props.record.exp_rentals,
            exp_workers: props.record.exp_workers,
            exp_material: props.record.exp_material,
            exp_professionals: props.record.exp_professionals,
            exp_groups: props.record.exp_groups,
            exp_supplies: props.record.exp_supplies,
            exp_maintenance: props.record.exp_maintenance,
            exp_marketing: props.record.exp_marketing,
            exp_others: props.record.exp_others ,
            inv_building: props.record.inv_building,
            inv_theatre: props.record.inv_theatre,
            inv_bar: props.record.inv_bar,
            inv_accessibility: props.record.inv_accessibility,
            inv_security: props.record.inv_security,
            inv_others: props.record.inv_others
          }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <UsersEconomicDataForm edit={true} />
                            
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

const RenderEconomicDataTotalIncoming = ({ record, ...props }) => {
    
  let total_incoming = record.members + record.services + record.subsidies + record.rentals + record.products + record.privates + record.others;
  return (        
    total_incoming
  );
};

const RenderEconomicDataTotalExpenses = ({ record, ...props }) => {
    
  let total_expenses = record.exp_rentals + record.exp_workers + record.exp_material + record.exp_professionals + record.exp_groups + record.exp_supplies + record.exp_maintenance + record.exp_marketing + record.exp_others;
  return (        
    total_expenses
  );
};

const RenderEconomicDataTotalInversion = ({ record, ...props }) => {
    
  let total_inversion = record.inv_building + record.inv_theatre + record.inv_bar + record.inv_accessibility + record.inv_security + record.inv_others;
  return (        
    total_inversion
  );
};

const RenderEconomicDataTotal = ({ record, ...props }) => {
    
  let total_incoming = record.members + record.services + record.subsidies + record.rentals + record.products + record.privates + record.others;
  let total_expenses = record.exp_rentals + record.exp_workers + record.exp_material + record.exp_professionals + record.exp_groups + record.exp_supplies + record.exp_maintenance + record.exp_marketing + record.exp_others;
  let total = total_incoming - total_expenses;
  return (        
    total
  );
};

export const UsersEconomicDataShow = ({ ...props }) => {
  const translate = useTranslate();   

  return (
        
    <ResourceContextProvider value="users_economic_data" >
      <List 
        syncWithLocation 
        basePath="/users_economic_data" 
        filter={{ users_id : props.users_id }}  
        title=""
        bulkActionButtons={false}                                
        perPage={1000}
        sort={{ field: 'year', order: 'ASC' }} 
        pagination={false}
        actions={false}
        empty={<></>}
        className="users_remittances_list"
      >                            
        <Datagrid
          expand={<UsersEconomicDataDetails />} 
          className="header_grey6"
          fullWidth
        >   
          <TextField source="year" label={translate('common.year')} sortable={false}  />     
          <TextField label={translate('users_economic_data.avg_quote')} source="avg_quote" sortable={false} />
          <FunctionField label={translate('users_economic_data.incomings')} render={record => <RenderEconomicDataTotalIncoming record={record} /> } sortable={false} />
          <FunctionField label={translate('users_economic_data.expenses')} render={record => <RenderEconomicDataTotalExpenses record={record} /> } sortable={false} />
          <FunctionField label={translate('users_economic_data.inversion')} render={record => <RenderEconomicDataTotalInversion record={record} /> } sortable={false} />
          <FunctionField label={translate('users_economic_data.total')} render={record => <RenderEconomicDataTotal record={record} /> } sortable={false} />                  
        </Datagrid>

      </List> 

    </ResourceContextProvider>            

  );
};

export const UsersEconomicDataToolbar = ({ ...props }) => {

  return (
        
    <TopToolbar>
      <UsersEconomicDataCreateButton users_id={props.users_id} />
    </TopToolbar>
        
  );
};


export const UsersEconomicDataCreateButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('users_economic_data');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  
    

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };


  return (
    <>            
      <Button onClick={handleClick} label={translate('users_economic_data.create_button')}>
        <IconContentAdd />                
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('users_economic_data.create')}
      >
        <DialogTitle>{translate('users_economic_data.create')}</DialogTitle>

        <FormWithRedirect
          resource="users_economic_data"
          save={handleSubmit}
          initialValues={{ users_id : props.users_id }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <UsersEconomicDataForm edit={false} />
                            
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

const UsersEconomicDataForm = ({ edit, ...props }) => {
  const translate = useTranslate(); 
  const classes = useStyles();
    
  return (
    <DialogContent className="users_economic_data_form">
      <br />
      <Grid container spacing={2} >
        <Grid item xs={9}>     
          <Typography className="grey8">{translate('common.year')}                                 
          </Typography>
        </Grid> 
        <Grid item xs={3}>  
          { edit ? (
            <NumberInput label="" fullWidth source="year" step={1} disabled />                                        
          ) : (
            <NumberInput label="" fullWidth source="year" step={1} validate={[required(),validateInteger(translate('common.validation.number'))]} />                                        
          )}
        </Grid> 
      </Grid>
      
      <Grid container spacing={2} >
        <Grid item xs={9}>     
          <Typography className="grey8">{translate('users_economic_data.avg_quote')}                                 
          </Typography>
        </Grid> 
        <Grid item xs={3}>  
          <NumberInput label="" fullWidth source="avg_quote" step={1} validate={[validateDouble(translate('common.validation.number'))]} /> 
        </Grid> 
      </Grid>
                
      <TabbedShowLayout
        syncWithLocation={false}>
           
        <Tab
          label={translate('users_economic_data.incomings')}
          contentClassName={classes.tab}
          fullWidth
          path=""
        >          
          <br />                    
          <Grid container spacing={2}>
            <Grid item xs={9}>     
              <Typography className="grey8">{translate('users_economic_data.members')}                                   
              </Typography>
            </Grid> 
            <Grid item xs={3}>     
              <NumberInput label="" fullWidth source="members" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
            </Grid> 
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={9}>     
              <Typography className="grey8">{translate('users_economic_data.services')}                                   
              </Typography>
            </Grid> 
            <Grid item xs={3}>     
              <NumberInput label="" fullWidth source="services" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
            </Grid> 
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={9}>     
              <Typography className="grey8">{translate('users_economic_data.subsidies')}                                   
              </Typography>
            </Grid> 
            <Grid item xs={3}>     
              <NumberInput label="" fullWidth source="subsidies" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
            </Grid> 
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={9}>     
              <Typography className="grey8">{translate('users_economic_data.rentals')}                                   
              </Typography>
            </Grid> 
            <Grid item xs={3}>     
              <NumberInput label="" fullWidth source="rentals" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
            </Grid> 
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={9}>     
              <Typography className="grey8">{translate('users_economic_data.products')}                                   
              </Typography>
            </Grid> 
            <Grid item xs={3}>     
              <NumberInput label="" fullWidth source="products" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
            </Grid> 
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={9}>     
              <Typography className="grey8">{translate('users_economic_data.privates')}                                   
              </Typography>
            </Grid> 
            <Grid item xs={3}>     
              <NumberInput label="" fullWidth source="privates" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
            </Grid> 
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={9}>     
              <Typography className="grey8">{translate('users_economic_data.others')}                                   
              </Typography>
            </Grid> 
            <Grid item xs={3}>     
              <NumberInput label="" fullWidth source="others" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
            </Grid> 
          </Grid>
        </Tab>
        <Tab
          label={translate('users_economic_data.expenses')}
          contentClassName={classes.tab}
          fullWidth
          path=""
        >      
          <br />
          <Grid container spacing={2}>
            <Grid item xs={9}>     
              <Typography className="grey8">{translate('users_economic_data.exp_rentals')}                                   
              </Typography>
            </Grid> 
            <Grid item xs={3}>     
              <NumberInput label="" fullWidth source="exp_rentals" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
            </Grid> 
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={9}>     
              <Typography className="grey8">{translate('users_economic_data.exp_workers')}                                   
              </Typography>
            </Grid> 
            <Grid item xs={3}>     
              <NumberInput label="" fullWidth source="exp_workers" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
            </Grid> 
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={9}>     
              <Typography className="grey8">{translate('users_economic_data.exp_material')}                                   
              </Typography>
            </Grid> 
            <Grid item xs={3}>     
              <NumberInput label="" fullWidth source="exp_material" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
            </Grid> 
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={9}>     
              <Typography className="grey8">{translate('users_economic_data.exp_professionals')}                                   
              </Typography>
            </Grid> 
            <Grid item xs={3}>     
              <NumberInput label="" fullWidth source="exp_professionals" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
            </Grid> 
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={9}>     
              <Typography className="grey8">{translate('users_economic_data.exp_groups')}                                   
              </Typography>
            </Grid> 
            <Grid item xs={3}>     
              <NumberInput label="" fullWidth source="exp_groups" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
            </Grid> 
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={9}>     
              <Typography className="grey8">{translate('users_economic_data.exp_supplies')}                                   
              </Typography>
            </Grid> 
            <Grid item xs={3}>     
              <NumberInput label="" fullWidth source="exp_supplies" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
            </Grid> 
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={9}>     
              <Typography className="grey8">{translate('users_economic_data.exp_maintenance')}                                   
              </Typography>
            </Grid> 
            <Grid item xs={3}>     
              <NumberInput label="" fullWidth source="exp_maintenance" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
            </Grid> 
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={9}>     
              <Typography className="grey8">{translate('users_economic_data.exp_marketing')}                                   
              </Typography>
            </Grid> 
            <Grid item xs={3}>     
              <NumberInput label="" fullWidth source="exp_marketing" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
            </Grid> 
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={9}>     
              <Typography className="grey8">{translate('users_economic_data.exp_others')}                                   
              </Typography>
            </Grid> 
            <Grid item xs={3}>     
              <NumberInput label="" fullWidth source="exp_others" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
            </Grid> 
          </Grid>
        </Tab>
        <Tab
          label={translate('users_economic_data.inversion')}
          contentClassName={classes.tab}
          fullWidth
          path=""
        >      
          <br />
          <Grid container spacing={2}>
            <Grid item xs={9}>     
              <Typography className="grey8">{translate('users_economic_data.inv_building')}                                   
              </Typography>
            </Grid> 
            <Grid item xs={3}>     
              <NumberInput label="" fullWidth source="inv_building" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
            </Grid> 
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={9}>     
              <Typography className="grey8">{translate('users_economic_data.inv_theatre')}                                   
              </Typography>
            </Grid> 
            <Grid item xs={3}>     
              <NumberInput label="" fullWidth source="inv_theatre" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
            </Grid> 
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={9}>     
              <Typography className="grey8">{translate('users_economic_data.inv_bar')}                                   
              </Typography>
            </Grid> 
            <Grid item xs={3}>     
              <NumberInput label="" fullWidth source="inv_bar" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
            </Grid> 
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={9}>     
              <Typography className="grey8">{translate('users_economic_data.inv_accessibility')}                                   
              </Typography>
            </Grid> 
            <Grid item xs={3}>     
              <NumberInput label="" fullWidth source="inv_accessibility" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
            </Grid> 
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={9}>     
              <Typography className="grey8">{translate('users_economic_data.inv_security')}                                   
              </Typography>
            </Grid> 
            <Grid item xs={3}>     
              <NumberInput label="" fullWidth source="inv_security" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
            </Grid> 
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={9}>     
              <Typography className="grey8">{translate('users_economic_data.inv_others')}                                   
              </Typography>
            </Grid> 
            <Grid item xs={3}>     
              <NumberInput label="" fullWidth source="inv_others" parse={value => value ? value : 0 } validate={[validateDouble(translate('common.validation.number'))]} />                                        
            </Grid> 
          </Grid>                    
        </Tab>
      </TabbedShowLayout>
                        
    </DialogContent>
  );
    
    
};
    
export const UsersEconomicDataDetails = ({ id, record, resource, ...props }) => {
  const translate = useTranslate(); 
    
  let total_incomings = record.members + record.services + record.subsidies + record.rentals + record.products + record.privates + record.others;
  let total_expenses = record.exp_rentals + record.exp_workers + record.exp_material + record.exp_professionals + record.exp_groups + record.exp_supplies + record.exp_maintenance + record.exp_marketing + record.exp_others;
  let total_inversion = record.inv_building + record.inv_theatre + record.inv_bar + record.inv_accessibility + record.inv_security + record.inv_others;
    
  let total = total_incomings - total_expenses;

  return (
    <div className="usersEconomicDataExpand">
      <Grid container spacing={2}>
        <Grid item xs={2} >
          <FunctionField render={record => <TextFieldShow source={record.year} label={translate('common.year')} /> } />
        </Grid>
        <Grid item xs={2} >
          <FunctionField render={record => <TextFieldShow source={record.avg_quote} label={translate('users_economic_data.avg_quote')} /> } />
        </Grid>            
      </Grid>
      
      <br />
      <Divider className="" />
      
      <Grid container spacing={2}>
        <Grid item xs={1} >
          
        </Grid>
        <Grid item xs={1} >
          
        </Grid>
        <Grid item xs={3} >
          <FunctionField render={record => <TextFieldShow source={total_incomings} label={translate('users_economic_data.incomings')} /> } />
        </Grid>
        <Grid item xs={3} >
          <FunctionField render={record => <TextFieldShow source={total_expenses} label={translate('users_economic_data.expenses')} /> } />
        </Grid>
        <Grid item xs={3} >
          <FunctionField render={record => <TextFieldShow source={total_inversion} label={translate('users_economic_data.inversion')} /> } />
        </Grid>
        <Grid item xs={1} >
          <FunctionField render={record => <TextFieldShow source={total} label={translate('users_economic_data.total')} /> } />
        </Grid>            
      </Grid>
        
      <br />
      <Divider className="" />
                
      <Grid container spacing={2}>
        <Grid item xs={2} >
        </Grid>
        <Grid item xs={3} >
          <FunctionField render={record => <TextFieldShow source={record.members} label={translate('users_economic_data.members')} /> } /><br />
          <FunctionField render={record => <TextFieldShow source={record.services} label={translate('users_economic_data.services')} /> } /><br />
          <FunctionField render={record => <TextFieldShow source={record.subsidies} label={translate('users_economic_data.subsidies')} /> } /><br />
          <FunctionField render={record => <TextFieldShow source={record.rentals} label={translate('users_economic_data.rentals')} /> } /><br />
          <FunctionField render={record => <TextFieldShow source={record.products} label={translate('users_economic_data.products')} /> } /><br />
          <FunctionField render={record => <TextFieldShow source={record.privates} label={translate('users_economic_data.privates')} /> } /><br />
          <FunctionField render={record => <TextFieldShow source={record.others} label={translate('users_economic_data.others')} /> } />
        </Grid>
        <Grid item xs={3} >
          <FunctionField render={record => <TextFieldShow source={record.exp_rentals} label={translate('users_economic_data.exp_rentals')} /> } /><br />
          <FunctionField render={record => <TextFieldShow source={record.exp_workers} label={translate('users_economic_data.exp_workers')} /> } /><br />
          <FunctionField render={record => <TextFieldShow source={record.exp_material} label={translate('users_economic_data.exp_material')} /> } /><br />
          <FunctionField render={record => <TextFieldShow source={record.exp_professionals} label={translate('users_economic_data.exp_professionals')} /> } /><br />
          <FunctionField render={record => <TextFieldShow source={record.exp_groups} label={translate('users_economic_data.exp_groups')} /> } /><br />
          <FunctionField render={record => <TextFieldShow source={record.exp_supplies} label={translate('users_economic_data.exp_supplies')} /> } /><br />
          <FunctionField render={record => <TextFieldShow source={record.exp_maintenance} label={translate('users_economic_data.exp_maintenance')} /> } /><br />
          <FunctionField render={record => <TextFieldShow source={record.exp_marketing} label={translate('users_economic_data.exp_marketing')} /> } /><br />
          <FunctionField render={record => <TextFieldShow source={record.exp_others} label={translate('users_economic_data.exp_others')} /> } />
        </Grid>            
        <Grid item xs={3} >
          <FunctionField render={record => <TextFieldShow source={record.inv_building} label={translate('users_economic_data.inv_building')} /> } /><br />
          <FunctionField render={record => <TextFieldShow source={record.inv_theatre} label={translate('users_economic_data.inv_theatre')} /> } /><br />
          <FunctionField render={record => <TextFieldShow source={record.inv_bar} label={translate('users_economic_data.inv_bar')} /> } /><br />
          <FunctionField render={record => <TextFieldShow source={record.inv_accessibility} label={translate('users_economic_data.inv_accessibility')} /> } /><br />
          <FunctionField render={record => <TextFieldShow source={record.inv_security} label={translate('users_economic_data.inv_security')} /> } /><br />
          <FunctionField render={record => <TextFieldShow source={record.inv_others} label={translate('users_economic_data.inv_others')} /> } /><br />
        </Grid>
        <Grid item xs={1} >
        </Grid>
      </Grid>
        
      <br /> 
    </div>
  );
};