import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@mui/material/Pagination';

const useStyles = makeStyles((theme) => ({
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
}));

const Paginador = ({ page, perPage, total, onPageChange }) => {
    
  const classes = useStyles();
  const totalPages = Math.ceil(total / perPage);
  
  const handlePageChangeNew = (event, value) => {
    onPageChange(value);
  };

  return (    
    <div className={classes.paginationContainer}>
      <Pagination className="my-pagination" count={totalPages} page={page} hidePrevButton={page === 1} hideNextButton={page === totalPages || totalPages === 0} onChange={handlePageChangeNew} size="large" />
    </div>
  );
};

export default Paginador;
