// in src/posts.js
import * as React from 'react';

import { 
  Datagrid,
  TextField,
  TextInput,
  ReferenceManyField, required,DateField ,DateInput
} from 'react-admin';



import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';





import { useFormState } from 'react-final-form';


import { useTranslate  } from '../common/language';



import { validateMaxLength  } from './../components/Validator';
import { usersEditedBooksType  } from './../common/constants';


/* LLISTAT DES DE PAGINA DE USERS */

export const UsersEditedBooksShow = (props) => {
  const translate = useTranslate();
  return (
    <ReferenceManyField                    
      reference="users_edited_books"
      target="users_id"
      label=""
      filter={{ type : props.type }}
      sort={{ field: 'datePublication', order: 'ASC' }}
      perPage={1000}
    >
        { props.type === usersEditedBooksType.HIGHLIGHTED_DATES ?
            <Datagrid    
                className="users_remittances_list header_grey6"
            >
                <DateField source="date_publication" label={translate('users_federation.edited_books_date')} sortable={false}/>
                <TextField source="name" label={translate('users_federation.event')} sortable={false}/>        
            </Datagrid>   
        :
            <Datagrid    
              className="users_remittances_list header_grey6"
            >
              <TextField source="name"  label={props.type === usersEditedBooksType.NOTABLE_INDIVIDUALS ? translate('common.name') : props.type === usersEditedBooksType.LINKS ? translate('common.link') : translate('common.title')} sortable={false}/>
              { ( props.type === usersEditedBooksType.BOOKS || props.type === usersEditedBooksType.BOOK_ENTITY || props.type === usersEditedBooksType.HIGHLIGHTED_DATES ) ? 
                <DateField source="date_publication" label={translate('users_federation.edited_books_date')} sortable={false}/>
                : null }
              { !(props.type === usersEditedBooksType.BOOKS || props.type === usersEditedBooksType.BOOK_ENTITY || props.type === usersEditedBooksType.HIGHLIGHTED_DATES || props.type === usersEditedBooksType.NOTABLE_INDIVIDUALS || props.type === usersEditedBooksType.LINKS) ? 
                <TextField source="period" label={translate('users_federation.edited_books_period')} sortable={false} />
                : null }
      </Datagrid> }

    </ReferenceManyField>                  
  );
};

export const UsersEditedBooksEdit= (props) => {
  const translate = useTranslate();
  let users_id = props.id;
    
  return (
    <ReferenceManyField                    
      reference="users_edited_books"
      target="users_id"
      label=""
      filter={{ type : props.type }}
      sort={{ field: 'datePublication', order: 'ASC' }}
      perPage={1000}
    >
        { props.type === usersEditedBooksType.HIGHLIGHTED_DATES ?
        <EditableDatagrid                       
            createForm={<UsersEditedBooksFormHighlightedDates initialValues={{ users_id: users_id, type: props.type }} />}
            editForm={<UsersEditedBooksFormHighlightedDates />}
            label=""
            hasCreate
            fullWidth                        
        >       
            <DateField source="date_publication" label={translate('users_federation.edited_books_date')} sortable={false} />
            <TextField source="name" label={translate('users_federation.event')} sortable={false} />        
        </EditableDatagrid>   
        :
      <EditableDatagrid                       
        createForm={<UsersEditedBooksForm initialValues={{ users_id: users_id, type: props.type }} />}
        editForm={<UsersEditedBooksForm />}
        label=""
        hasCreate
        fullWidth                        
      >       
        <TextField source="name" label={props.type === usersEditedBooksType.NOTABLE_INDIVIDUALS ? translate('common.name') : props.type === usersEditedBooksType.LINKS ? translate('common.link') : translate('common.title')} sortable={false} />
        { (props.type === usersEditedBooksType.BOOKS || props.type === usersEditedBooksType.BOOK_ENTITY || props.type === usersEditedBooksType.HIGHLIGHTED_DATES )? 
          <DateField source="date_publication" label={translate('users_federation.edited_books_date')} sortable={false} />
          : null }
        { !(props.type === usersEditedBooksType.BOOKS || props.type === usersEditedBooksType.BOOK_ENTITY || props.type === usersEditedBooksType.HIGHLIGHTED_DATES || props.type === usersEditedBooksType.NOTABLE_INDIVIDUALS || props.type === usersEditedBooksType.LINKS ) ? 
          <TextField source="period" label={translate('users_federation.edited_books_period')} sortable={false} />
          : null }    
      </EditableDatagrid> }

    </ReferenceManyField> 
  );
};


const UsersEditedBooksFormHighlightedDates = ({ ...props}) => {
  const translate = useTranslate();   
  const { values } = useFormState();
    
  let type = props.initialValues ? props.initialValues.type : props.record.type;
  return (
    <RowForm {...props}  >    
      <DateInput source="date_publication" label={translate('users_federation.edited_books_date')} validate={[required()]} />
      <TextInput fullWidth source="name" label={translate('users_federation.event')} validate={[required(), validateMaxLength(100,translate('common.validation.maxLength'))]} />        
    </RowForm>
  );
};

const UsersEditedBooksForm = ({ ...props}) => {
  const translate = useTranslate();   
  const { values } = useFormState();
    
  let type = props.initialValues ? props.initialValues.type : props.record.type;
  return (
    <RowForm {...props}  >                
      <TextInput fullWidth source="name" label={type === usersEditedBooksType.NOTABLE_INDIVIDUALS ? translate('common.name') : type === usersEditedBooksType.LINKS ? translate('common.link') : translate('common.title')} validate={[required(), validateMaxLength(100,translate('common.validation.maxLength'))]} />  
      { (type === usersEditedBooksType.BOOKS || type === usersEditedBooksType.BOOK_ENTITY || type === usersEditedBooksType.HIGHLIGHTED_DATES ) ? 
        <DateInput source="date_publication" label={translate('users_federation.edited_books_date')} validate={[required()]} />
        : null }
      { !(type === usersEditedBooksType.BOOKS || type === usersEditedBooksType.BOOK_ENTITY || type === usersEditedBooksType.HIGHLIGHTED_DATES || type === usersEditedBooksType.NOTABLE_INDIVIDUALS || type === usersEditedBooksType.LINKS) ? 
        <TextInput fullWidth source="period" label={translate('users_federation.edited_books_period')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />   
        : null }
    </RowForm>
  );
};
