import React, { useState } from 'react';

import { SaveButton ,
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DeleteButton,
  ReferenceInput,
  SelectInput,
  TextInput,
  useTranslate,
  required,    
  useCreate,
  useUpdate,
  useNotify,
  FormWithRedirect,useRefresh,
  Button,
  FunctionField, TopToolbar, NumberInput, ResourceContextProvider,
  ArrayField, ArrayInput,SimpleFormIterator 
} from 'react-admin';


import EditIcon from '@material-ui/icons/Edit';

import { useStyles  } from '../common/styles';




import Divider from '@mui/material/Divider';

import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { choicesAdult } from './../common/constants';

import { TextFieldShow } from '../components/TextFieldShow';

import Grid from '@mui/material/Grid';


import { validateMaxLength, validateInteger  } from './../components/Validator';

export const UsersActivitiesList = (props) => {
  const translate = useTranslate(); 
    
  let strRedirect =  `/users/${props.users_id}/more_federation`; 
    
  return (
           
    <ResourceContextProvider value="users_activities" >
      <List 
        syncWithLocation 
        basePath="/users_activities" 
        filter={{ users_id : props.users_id }}  
        title=""
        bulkActionButtons={false}                
        actions={<CustomToolbar users_id={props.users_id} /> }  
        perPage={1000}
        sort={{ field: 'year', order: 'ASC' }} 
        empty={<CustomToolbar users_id={props.users_id} /> } 
        pagination={false}
      >

        <Datagrid   
          expand={<UsersActivitiesDetails />} 
          className="header_grey6"
          label=""
          fullWidth
        >
          <TextField source="year" label={translate('common.year')} sortable={false} /> 
          <FunctionField label={translate('users_activities.total_quantity')} render={record => <RenderTotalQuantity record={record} /> } sortable={false} />
          <FunctionField label={translate('users_activities.total_assistants')} render={record => <RenderTotalAssistants record={record} /> } sortable={false} /> 
          <CustomEditButton label=""/>
          <DeleteButton undoable={false} redirect={strRedirect} label=""/>
        </Datagrid>

      </List> 
    </ResourceContextProvider>

  );
};


export const CustomEditButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  

  const [update, { loading, error }] = useUpdate();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };
                        
  const handleSubmit = async values => {
    update(
      { 
        type: 'update', 
        resource: 'users_activities', 
        payload : { id: values.id, data : 
                {       users_id: values.users_id,
                  year : values.year, 
                  items: values.items
                } }
      },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };    

  return (
    <>
      <Button onClick={handleClick} label="" startIcon={<EditIcon />} />

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
      >
        <DialogTitle>{translate('users_activities.edit')}</DialogTitle>

        <FormWithRedirect
          resource=""
          save={handleSubmit}
          initialValues={{ id : props.record.id, 
            users_id: props.record.users_id,
            year : props.record.year, 
            items: props.record.items
          }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <CustomForm edit={true} />
                            
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

const RenderTotalQuantity = ({ record, ...props }) => {
    
  let total_quantity = 0;
  if (record.items)
  {
    record.items.forEach(element => { if (element.quantity) total_quantity+=element.quantity; } );
  }
    
  return ( total_quantity );
    
};

const RenderTotalAssistants = ({ record, ...props }) => {
    
  let total_assistants = 0;
  if (record.items)
  {
    record.items.forEach(element => { if (element.assistants) total_assistants+=element.assistants; } );
  }
    
  return ( total_assistants );
    
};

export const UsersActivitiesShow = ({ ...props }) => {
  const translate = useTranslate();   

  return (
        
    <ResourceContextProvider value="users_activities" >
      <List 
        syncWithLocation 
        basePath="/users_activities" 
        filter={{ users_id : props.users_id }}  
        title=""
        bulkActionButtons={false}                                
        perPage={1000}
        sort={{ field: 'year', order: 'ASC' }} 
        pagination={false}
        actions={false}
        empty={<></>}
        className="users_remittances_list"
      >                            
        <Datagrid
          expand={<UsersActivitiesDetails />} 
          className="header_grey6"
          fullWidth
        >   
          <TextField source="year" label={translate('common.year')} sortable={false}  />                        
          <FunctionField label={translate('users_activities.total_quantity')} render={record => <RenderTotalQuantity record={record} /> } sortable={false} />
          <FunctionField label={translate('users_activities.total_assistants')} render={record => <RenderTotalAssistants record={record} /> } sortable={false} />           
        </Datagrid>

      </List> 

    </ResourceContextProvider>            

  );
};

export const CustomToolbar = ({ ...props }) => {

  return (
        
    <TopToolbar>
      <CustomCreateButton users_id={props.users_id} />
    </TopToolbar>
        
  );
};


export const CustomCreateButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('users_activities');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  
    

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };


  return (
    <>            
      <Button onClick={handleClick} label={translate('users_activities.create_button')}>
        <IconContentAdd />                
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('users_activities.create')}
      >
        <DialogTitle>{translate('users_activities.create')}</DialogTitle>

        <FormWithRedirect
          resource="users_activities"
          save={handleSubmit}
          initialValues={{ users_id : props.users_id }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <CustomForm edit={false} />
                            
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

const CustomForm = ({ edit, ...props }) => {
  const translate = useTranslate(); 
  const classes = useStyles();
    
  return (
    <DialogContent className="users_activities_form">
      <br />
      <Grid container spacing={2} >
        <Grid item xs={3}>  
          { edit ? (
            <NumberInput label={translate('common.year')} fullWidth source="year" step={1} disabled />                                        
          ) : (
            <NumberInput label={translate('common.year')} fullWidth source="year" step={1} validate={[required(),validateInteger(translate('common.validation.number'))]} />                           
          )}
        </Grid> 
      </Grid>
                
      <ArrayInput source="items" label="">
        <SimpleFormIterator 
          disableReordering                     
        >
          <ReferenceInput sort={{ field: 'priority', order: 'ASC' }} perPage={1000} label={translate('users_activities.name')} source="activities_users_id" reference="activities_users">
            <SelectInput fullWidth optionText="name" optionValue="id" validate={required()} />
          </ReferenceInput> 
          <NumberInput source="quantity" step={1} validate={[validateInteger(translate('common.validation.number'))]} label={translate('users_activities.quantity')} />
          <NumberInput source="assistants" step={1} validate={[validateInteger(translate('common.validation.number'))]} label={translate('users_activities.assistants')} />
          <SelectInput fullWidth label={translate('users_activities.adult')} source="adult" choices={choicesAdult(translate)} resettable /> 
          <TextInput fullWidth source="comment" validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} label={translate('common.comment')} />
                            
        </SimpleFormIterator>
      </ArrayInput>
                        
    </DialogContent>
  );
    
    
};
    

export const UsersActivitiesDetails = ({ id, record, resource, ...props }) => {
  const translate = useTranslate(); 
        
  let total_quantity = 0;
  if (record.items)
  {
    record.items.forEach(element => { if (element.quantity) total_quantity+=element.quantity; } );
  }
    
  let total_assistants = 0;
  if (record.items)
  {
    record.items.forEach(element => { if (element.assistants) total_assistants+=element.assistants; } );
  }
        
  return (
    <div className="usersEconomicDataExpand">
      <Grid container spacing={2}>
        <Grid item xs={2} >
          <FunctionField render={record => <TextFieldShow source={record.year} label={translate('common.year')} /> } />
        </Grid>
        <Grid item xs={3} >
          <FunctionField render={record => <TextFieldShow source={total_quantity} label={translate('users_activities.total_quantity')} /> } />
        </Grid>
        <Grid item xs={3} >
          <FunctionField render={record => <TextFieldShow source={total_assistants} label={translate('users_activities.total_assistants')} /> } />
        </Grid>
      </Grid>
        
      <br />
      <Divider className="" />
      <br />
      <ArrayField source="items">
        <Datagrid>
          <ReferenceField label={translate('users_activities.name')} source="activities_users_id" reference="activities_users" link={false} > 
            <TextField source="name" />                               
          </ReferenceField>
          <TextField source="quantity" label={translate('users_activities.quantity')} />
          <TextField source="assistants" label={translate('users_activities.assistants')} />
          <FunctionField render={record => record.adult ? translate('users_shows.adult_' + record.adult) : '' } label={translate('users_activities.adult')} />
          <TextField source="comment" label={translate('common.comment')} />
        </Datagrid>
      </ArrayField>
        
      <br /> 
    </div>
  );
};