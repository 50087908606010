// in src/posts.js
//import * as React from "react";
import React from 'react';

import {
  Datagrid,
  TextField,
  ReferenceInput,
  SelectInput,
  TextInput, 
  ReferenceManyField,
  useTranslate, TopToolbar, CreateButton,required, FunctionField
} from 'react-admin';


import Avatar from '@mui/material/Avatar';

import { useFormState } from 'react-final-form';

import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';








import './../common/styles.css';



import { validateMaxLength  } from './../components/Validator';


export const SocialNetsList = ({ target, items_id , ...props }) => {

  const translate = useTranslate(); 

  return (  

    <ReferenceManyField                    
      reference="social_nets"
      target="users_id"
      label=""
      filter={{ target : target, items_id : items_id }}
      perPage={1000}
    >

      <EditableDatagrid                       
        createForm={<SocialNetsFormNew initialValues={{ associations_id: localStorage.getItem('associations_id') , target: target, items_id: items_id }} />}
        editForm={<SocialNetsFormEdit />}
        label=""
        hasCreate
        fullWidth
      >
        <FunctionField label="" render={record => <Avatar><img src={'/img/' + record.net.image} width="40" height="40" /></Avatar> } />
        <TextField source="net.name" label={translate('social_nets.title')} sortable={false} /> 
        <FunctionField label="" render={record => {
          let net_link = record.net.url + '/' + record.link;
          return (<a href={net_link} target="_blank"
          >
            {record.link}
          </a>);
        }
        } />

      </EditableDatagrid>

    </ReferenceManyField>

  );
};

const ListActions = (props) => (
  <TopToolbar>
    <CreateButton/>  
  </TopToolbar>
);

const SocialNetsFormNew= (props) => {
  const translate = useTranslate();   
  const { values } = useFormState();
  return (
    <RowForm {...props}  >
      <span />
      <ReferenceInput label={translate('social_nets.title')} source="nets_id" reference="nets" perPage={1000} >
        <SelectInput fullWidth optionValue="id" validate={required()} optionText="name" />
      </ReferenceInput> 
      <TextInput fullWidth source="link" label={translate('social_nets.link')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />   
    </RowForm>
  );
};

const SocialNetsFormEdit= (props) => {
  const translate = useTranslate();   
  const { values } = useFormState();
  return (
    <RowForm {...props}  >
      <span />
      <ReferenceInput label={translate('social_nets.title')} source="nets_id" reference="nets" perPage={1000}>
        <SelectInput fullWidth optionValue="id" validate={required()} optionText="name" disabled />
      </ReferenceInput> 
      <TextInput fullWidth source="link" label={translate('social_nets.link')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />   
    </RowForm>
  );
};

export const SocialNetsListShow = ({ target, items_id , reference, ...props }) => {

  const translate = useTranslate(); 

  return (  

    <ReferenceManyField                    
      reference={reference}
      target="users_id"
      label=""
      filter={{ target : target, items_id : items_id }}
      perPage={1000} 
    >

      <Datagrid     
        fullWidth
        className="users_show_social_nets_datagrid"
      >
        <FunctionField label="" render={record => <Avatar className="avatar"><img src={'/img/' + record.net.image} width="30" height="30" /></Avatar> } />
        <FunctionField label="" render={record => {
          let net_link = record.link.includes(record.net.url) ? record.link : record.net.url + '/' + record.link;
          return (<a href={net_link} target="_blank"
          >
            {record.link}
          </a>);
        }
        } />
                

      </Datagrid>

    </ReferenceManyField>

  );
};
