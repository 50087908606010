// in src/posts.js
import React, { useState } from 'react';

import {
  List, 
  Datagrid,
  TextField,
  DeleteButton,
  Edit,
  SelectInput,
  TextInput,
  Button ,
  Create, 
  CreateButton ,
  TopToolbar,
  FormTab,
  TabbedForm,
  ReferenceManyField,
  FormDataConsumer,
  useListContext, required,NumberInput,BooleanInput,SelectField,  FunctionField, ResourceContextProvider, useCreate, useRefresh, FormWithRedirect, Toolbar, SaveButton,
  useNotify,
  useRedirect,
  SimpleForm,
  DateField ,
  DateInput,
  useDataProvider,
  AutocompleteInput,
  downloadCSV
} from 'react-admin';

import { useEffect } from 'react';

import jsonExport from 'jsonexport/dist';

import { ExportButton } from './../components/ExportButton';

import { AccordionSection  } from '@react-admin/ra-form-layout';

import PaymentIcon from '@material-ui/icons/Payment';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

import Chip from '@material-ui/core/Chip';

import { makeStyles } from '@material-ui/core/styles';

import Divider from '@mui/material/Divider';


import { useTranslate  } from '../common/language';

import Typography from '@mui/material/Typography';


import RichTextInput from 'ra-input-rich-text';


import { TextFieldShow } from '../components/TextFieldShow';

import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { ColorInput } from 'react-admin-color-input';

import { DocsList, DocsListShow } from './docs';
import { subscriptionModule, structArrayToIdList } from '../common/utils';
import { targets, choicesClassroomsType, choicesRealStateActivitiesType, choicesRealStateActivitiesInsuranceWho, modules  } from './../common/constants';

import { CityInput } from './../components/Inputs';
import { BackLink } from './../components/BackLink';
import { dateOnlyToCatalan } from './../common/utils';        

import { UserInput, RenderBoolean, chipsUsers } from '../components/User';
import { chipsRealState, chipRealState } from '../resources/real_state';

import Box from '@material-ui/core/Box';

import { Link } from 'react-router-dom';

import { validateMaxLength, validateInteger  } from './../components/Validator';

export const RealStateActivitiesList = (props) => {
  const translate = useTranslate();  
    
  let basePath='/real_state_activities';
  let resource = 'real_state_activities';
  let strRedirect =  `/real_state/${props.real_state_id}/activities`;
      
  return (
    <ResourceContextProvider value={resource}>
      <List 
        basePath={basePath}   
        filter={{ associations_id : localStorage.getItem('associations_id'), real_state_id: props.real_state_id ? props.real_state_id : 0, users_id: props.users_id ? props.users_id : 0 }}  
        actions={ props.show ?  <span/> : <ListActions real_state_id={props.real_state_id}  /> }    
        empty={ props.show ? <span/> : <ListActions real_state_id={props.real_state_id} /> }        
        bulkActionButtons={false}          
        perPage={1000}   
        pagination={false}
        sort={{ field: 'id', order: 'ASC' }} 
        label=""  
        title=""  
        resource={resource}
      >        
        <Datagrid 
          rowClick={props.show ? null : 'edit'}
          expand={<RealStateActivitiesDetails users_id={props.users_id ? props.users_id : 0} />} >  
          <Avatar><AccountBoxIcon/></Avatar>                                  
          <TextField source="name" label={translate('real_state_activities.name')} sortable={false} /> 
          { props.users_id ?
            <FunctionField render={record => chipRealState(record.real_state_id, record.real_state_name ) } label={translate('real_state_activities.real_state')} sortable={false}  />  
          :
            <FunctionField render={record => chipsUsers(record.users) } label={translate('real_state_activities.user')} sortable={false}  />  
          }
          <FunctionField render={record => <span>{record.type > 0 ? translate('real_state_activities.type_' + record.type) : record.type_other}</span> } label={translate('real_state_activities.type')} sortable={false}  />  
          { subscriptionModule(modules.AGREEMENTS) ? 
          <FunctionField render={record => chipAgreement(record.agreements_id,record.agreements_name) } label={translate('real_state_activities.agreements_id')} sortable={false}  />  
          : null }
          <DateField source="date_start" label={translate('real_state_activities.date_start')} sortable={false} /> 
          <DateField source="date_finish" label={translate('real_state_activities.date_finish')} sortable={false} /> 
        </Datagrid>        
      </List>
    </ResourceContextProvider>
  );
};

export const chipAgreement = ( agreements_id,agreements_name ) => {
  if (!agreements_id) {
    return null; 
  }
  return (
    <div>
    <Link key={agreements_id.id} to={`/agreements/${agreements_id}`} className="chip" >
      <Chip label={agreements_name} className="chip" clickable />
    </Link>
    </div>
  );
  
};


export const RealStateActivitiesDetails = ({ id, record, resource, ...props }) => {
  const translate = useTranslate(); 
      
  return (
    <div className="usersClassroomsExpand">        
      <Grid container spacing={2}>
        <Grid item xs={4}>                    
          <TextFieldShow source={record.name} label={translate('real_state_activities.name')} />                       
        </Grid>        
        { props.users_id ?
            <Grid item xs={4}>               
              <TextFieldShow source={record.real_state_name} label={translate('real_state_activities.real_state')} />                 
            </Grid>
        :
            <Grid item xs={4}> 
              { record.users && record.users[0] ? 
              <TextFieldShow source={record.users[0].name} label={translate('real_state_activities.user')} />   
              : null }
            </Grid>
        }
        <Grid item xs={4}>                    
          <TextFieldShow source={record.insurance_who ? translate('real_state_activities.insurance_who_'+record.insurance_who) : ''} label={translate('real_state_activities.insurance_who')} />               
        </Grid>
        <Grid item xs={3}>                    
          <FunctionField render={
          record => record.type != 0 ? 
            <TextFieldShow source={translate('real_state_activities.type_'+record.type)} label={translate('real_state_activities.type')} /> 
            : 
            <TextFieldShow source={record.type_other} label={translate('real_state_activities.type')} />
          } />                        
        </Grid>
        {record.type == 1 && subscriptionModule(modules.AGREEMENTS) ?
        <Grid item xs={9}>                    
          <TextFieldShow source={record.agreements_name} label={translate('real_state_activities.agreements_id')} />                       
        </Grid>
        : 
        <Grid item xs={9}>
        
        </Grid>
        }
        
        <Grid item xs={3}>                    
          <TextFieldShow source={dateOnlyToCatalan(record.date_start)} label={translate('real_state_activities.date_start')} />                   
        </Grid>
        <Grid item xs={3}>                    
          <TextFieldShow source={dateOnlyToCatalan(record.date_finish)} label={translate('real_state_activities.date_finish')} />                   
        </Grid>
        <Grid item xs={3}>                    
          <TextFieldShow source={dateOnlyToCatalan(record.date_alert)} label={translate('real_state_activities.date_alert')} />                   
        </Grid>
        <Grid item xs={3}>                    
                            
        </Grid>
        
        <Grid item xs={12}>                    
          <TextFieldShow source={record.relations} label={translate('real_state_activities.relations')} />                   
        </Grid>
        <Grid item xs={12}>                    
          <TextFieldShow source={record.comments} label={translate('real_state_activities.comments')} />                   
        </Grid>
      </Grid>                
        
      <Typography variant="h6" className="grey8 titleUserShow">                        
        {translate('real_state_activities.docs_contracts')}
      </Typography>
      <Divider fullWidth className="" /><br/>
        
      <DocsListShow target={targets.REAL_STATE_ACTIVITY_CONTRACT} items_id={record.id} />
        
      <Typography variant="h6" className="grey8 titleUserShow">                        
        {translate('real_state_activities.docs_insurances')}
      </Typography>
      <Divider fullWidth className="" /><br/>
        
      <DocsListShow target={targets.REAL_STATE_ACTIVITY_INSURANCE} items_id={record.id} />
              
    </div>
  );
};

const exporter = (items, translate, notify) => {
    
  const itemsForExport = items.map(item => {
    var itemForExport = {
      real_state_id: item.real_state_id,
      id : item.id,
      users_id : structArrayToIdList(item.users), 
      type : item.type != null ? translate('real_state_activities.type_'+item.type) : '', 
      type_other : item.type_other, 
      name : item.name, 
      agreements_id : subscriptionModule(modules.AGREEMENTS) ? item.agreements_id : '', 
      date_start : dateOnlyToCatalan(item.date_start), 
      date_finish : dateOnlyToCatalan(item.date_finish), 
      date_alert : dateOnlyToCatalan(item.date_alert), 
      insurance_who : item.insurance_who != null ? translate('real_state_activities.insurance_who_' + item.insurance_who) : '', 
      relations : item.relations, 
      comments : item.comments
    };
    return itemForExport;
  });
  jsonExport(itemsForExport, {
    headers: ['real_state_id','id','users_id','type','type_other','name','agreements_id','date_start','date_finish','date_alert','insurance_who','relations','comments',],
    rename: [translate('real_state_activities.real_state_id'), translate('common.id'),translate('real_state_activities.user'),translate('real_state_activities.type'),translate('real_state_activities.type_other'),translate('real_state_activities.name'),
    translate('real_state_activities.agreements_id'),translate('real_state_activities.date_start'),translate('real_state_activities.date_finish'),
    translate('real_state_activities.date_alert'),translate('real_state_activities.insurance_who'),translate('real_state_activities.relations'),
    translate('real_state_activities.comments')],                
  }, (err, csv) => {
    const csv_new = '\uFEFF' + csv;
    downloadCSV(csv_new, translate('real_state_activities.exporter_file_name') ); 
    notify(translate('common.download_ok'),"success");
  });
};


const ListActions = (props) => {
  const translate = useTranslate(); 
    
    let filterExport = { 
        associations_id: localStorage.getItem('associations_id'),        
        real_state_id: props.real_state_id,
    };
    
  return (
    <TopToolbar>             
      <MyCreateButton {...props} />
      <ExportButton maxResults="100000" filterValues={filterExport} alwaysEnabled={false} exporter={exporter} sort={{ field: 'id', order: 'DESC' }} /> 
    </TopToolbar>
  );
};


export const MyCreateButton = ({ real_state_id , ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('real_state_activities');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  
  const redirect = useRedirect();
    
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {                    
          redirect('/real_state_activities/' + data.id);        
        },
        onFailure: ( error ) => {
          notify( error.message , 'error');
        }
      }
    );
  };
    
  return (
    <>            
      <Button onClick={handleClick} label={translate('real_state_activities.create')}>
        <IconContentAdd />                
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('real_state_activities.create')}
      >
        <DialogTitle>{translate('real_state_activities.create')}</DialogTitle>

        <FormWithRedirect
          save={handleSubmit}
          initialValues={{ associations_id: localStorage.getItem('associations_id') , real_state_id : real_state_id, type: 0 }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>
                <Grid container spacing={1}>                                     
                  <Grid item xs={12}>                                               
                    <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
                  </Grid>
                </Grid> 
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

const MyActions = ({ basePath, data, resource }) => {

  const translate = useTranslate();   
  
  if (!data || !data.real_state_id) return null;
  
  let backLink = '/real_state/' + data.real_state_id + '/activities';
  return <BackLink link={backLink} title={data && data.name ? data.name : null} />;
       
};


export const RealStateActivitiesEdit  = ({ id, ...props }) => {

  const translate = useTranslate();        
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
    
  const onSuccess = ({ data }) => {
      notify(translate('common.notification.updated'));
      redirect('/real_state/' + data.real_state_id + '/activities');
      refresh();        
  };
        
  return (

    <Edit 
      title={<ElementTitle {...props}  />} 
      {...props} 
      id={id}
      mutationMode="pessimistic"
      actions={<MyActions {...props} />}
      onSuccess={onSuccess}   
    >
        
      <TabbedForm        
        toolbar={<FormToolbar />}
      >    
        <FormTab
          label={translate('common.data')}
          contentClassName={classes.tab}
          path="" 
                
        > 
               
          <FormDataConsumer>
            {({ formData, ...rest }) => 
              <>                              
                <br/>
                <Grid container spacing={2}>
                  <Grid item xs={12}>   
                    <TextInput fullWidth autoFocus source="name" label={translate('real_state_activities.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]}  />
                  </Grid> 
                  <Grid item xs={6}>
                    <UserInput source="users_id" user_type={-1} disabled={false} label={translate('real_state_activities.user')} allowEmpty={true} required={false} resettable={true} />
                  </Grid> 
                  <Grid item xs={6}> 
                    <SelectInput fullWidth label={translate('real_state_activities.insurance_who')} source="insurance_who" choices={choicesRealStateActivitiesInsuranceWho(translate)} />
                  </Grid> 
                  <Grid item xs={3}> 
                    <SelectInput fullWidth label={translate('real_state_activities.type')} source="type" choices={choicesRealStateActivitiesType(translate)} />
                  </Grid>                     
                  { formData.type == 0 ?
                  <Grid item xs={9}>                       
                    <TextInput fullWidth autoFocus source="type_other" label={translate('real_state_activities.type_other')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]}  />                                                      
                  </Grid> 
                  : formData.type == 1 && subscriptionModule(modules.AGREEMENTS) ? 
                  <Grid item xs={9}>                       
                    <AgreementsInput source="agreements_id" disabled={false} label={translate('real_state_activities.agreements_id')} allowEmpty={true} required={false} resettable={true} />
                  </Grid> 
                  : <Grid item xs={9}></Grid> }             
                  
                  <Grid item xs={2}>
                    <DateInput source="date_start" fullWidth label={translate('real_state_activities.date_start')} />
                  </Grid>  
                  <Grid item xs={2}>
                    <DateInput source="date_finish" fullWidth label={translate('real_state_activities.date_finish')} />
                  </Grid>  
                  <Grid item xs={2}>
                    <DateInput source="date_alert" fullWidth label={translate('real_state_activities.date_alert')} />
                  </Grid>                    
                  <Grid item xs={12}>   
                    <TextInput fullWidth autoFocus source="relations" label={translate('real_state_activities.relations')} />
                  </Grid> 
                  <Grid item xs={12}>   
                    <TextInput fullWidth autoFocus source="comments" label={translate('real_state_activities.comments')} />
                  </Grid> 
                  
                </Grid>

              </>
            }
          </FormDataConsumer>

        </FormTab> 
        
        <FormTab
            label={translate('real_state_activities.docs')}
            contentClassName={classes.tab}
            path="docs"                 
        >             
                        
            <br />
            <AccordionSection className="accordion" label={translate('real_state_activities.docs_contracts')} fullWidth defaultExpanded={true}>              
              <DocsList target={targets.REAL_STATE_ACTIVITY_CONTRACT} items_id={id} />
            </AccordionSection>   
          
            <br />
            <AccordionSection className="accordion" label={translate('real_state_activities.docs_insurances')} fullWidth defaultExpanded={true}>
              <DocsList target={targets.REAL_STATE_ACTIVITY_INSURANCE} items_id={id} />
            </AccordionSection> 
          
          <br />
          <br />
          
        </FormTab>        
    
      </TabbedForm>
    </Edit>
  );
};

export const AgreementsInput = ({ ...props }) => {

  const translate = useTranslate(); 
    
  const dataProvider = useDataProvider();
  const [info, setInfo] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
    
  const disabled = props.disabled && props.disabled==true ? true : false;
  const resettable = props.resettable != null ? props.resettable : true;
  const label = props.label ? props.label : translate('common.name');
  const validate = props.required ? [required()] : [];
    
  useEffect(() => {
    dataProvider
      .getList('agreements', {
        pagination: { page: 0, perPage: 10000 },
        sort: { field: 'id', order: 'ASC' },
        filter: { associations_id: localStorage.getItem('associations_id') },
      })
      .then(({ data }) => {
        setInfo(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) return (<span>{translate('common.loading')}</span>);
  if (error) return null;
  if (!info) return null;
  
  return (
    <AutocompleteInput 
      optionText={ (record)=> record.name } 
      suggestionLimit={50}
      resettable={resettable}
      clearAlwaysVisible={true}
      validate={validate}
      fullWidth
      disabled={disabled}
      choices={info}
      source={props.source}
      label={label}
    />
                
  );
};

const ElementTitle = ({ record, ...props }) => {    
  const translate = useTranslate(); 
  return (<span>{translate('common.edit')} {record ? `"${record.name}"` : ''}</span>);
};

const FormToolbar = ({ id , ...props }) => {
    
  let redirect = '/real_state/' + props.record.real_state_id + '/activities';
    
  return (
    <Toolbar {...props} className="user_form_toolbar" >
      <SaveButton />
      <DeleteButton redirect={redirect} mutationMode="pessimistic" />
    </Toolbar>
  );
};


const useStyles = makeStyles({
  comment: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    maxWidth: '100%',
    display: 'block',
  },
});




export const RealStateActivitiesCreate  = ({ id, ...props }) => {

  const translate = useTranslate();   
  const [info, setInfo] = useState();
    
  return (

    <Create {...props} >
      <SimpleForm 
        initialValues={{ associations_id: localStorage.getItem('associations_id') }}
        redirect="list"
      >                
        <Grid container spacing={2}>
          <Grid item xs={12}>                    
            <Typography variant="h5" className="grey">{translate('real_state.create')}</Typography>
          </Grid>  
          <Grid item xs={12}>                    
            <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
          </Grid>          
        </Grid>   

      </SimpleForm> 
    </Create>
  );
};
