// in src/posts.js
import * as React from 'react';

import { 
  Datagrid,
  SelectInput,
  ReferenceManyField, required,FunctionField, DateTimeInput
} from 'react-admin';


import { EditableDatagrid, RowForm  } from '@react-admin/ra-editable-datagrid';





import { useFormState } from 'react-final-form';


import { useTranslate  } from '../common/language';





/* LLISTAT DES DE PAGINA DE USERS */

export const UsersSchedulesShow = (props) => {
  const translate = useTranslate();
  return (
    <ReferenceManyField                    
      reference="users_schedules"
      target="users_id"
      label=""
      sort={{ field: 'dayWeek,timeStart', order: 'ASC' }}
    >
      <Datagrid     
        className="users_remittances_list header_grey6"
      >                        
        <FunctionField
          label={translate('users_federation.schedules_day_week')}
          render={record => translate('common.day_week_complete_' + record.day_week ) }
          sortable={false}
        />
        <FunctionField label={translate('users_federation.schedules_time_finish')} render={record => 
          record.time_start ? record.time_start.substring(0,5) : ''
        } sortable={false}  />
        <FunctionField label={translate('users_federation.schedules_time_finish')} render={record => 
          record.time_finish ? record.time_finish.substring(0,5) : ''
        } sortable={false}  />
      </Datagrid>

    </ReferenceManyField>                     
  );
};

var dayWeekFormat = function (value) {
  return 'holaaaaa';
}; 

export const UsersSchedulesEdit= (props) => {
  const translate = useTranslate();
  let users_id = props.id;
    
  return (
    <ReferenceManyField                    
      reference="users_schedules"
      target="users_id"
      label=""
      sort={{ field: 'dayWeek,timeStart', order: 'ASC' }}
    >
      <EditableDatagrid                       
        createForm={<UsersSchedulesForm initialValues={{ users_id: users_id, time_start: '09:00', time_finish: '22:00' }} />}
        editForm={<UsersSchedulesForm userId={{users_id}} />}
        label=""
        hasCreate
        fullWidth                        
      >
        <FunctionField
          label={translate('users_federation.schedules_day_week')}
          render={record => translate('common.day_week_complete_' + record.day_week ) }
          sortable={false}
        />
        <FunctionField label={translate('users_federation.schedules_time_finish')} render={record => 
          record.time_start ? record.time_start.substring(0,5) : ''
        } sortable={false}  />
        <FunctionField label={translate('users_federation.schedules_time_finish')} render={record => 
          record.time_finish ? record.time_finish.substring(0,5) : ''
        } sortable={false}  />
      </EditableDatagrid>

    </ReferenceManyField> 
  );
};

const UsersSchedulesForm = ({ ...props}) => {
  const translate = useTranslate();   
  const { values } = useFormState();
        
  return (
    <RowForm {...props} >     
      <SelectInput label={translate('users_federation.schedules_day_week')} source="day_week" choices={[
        { id: 1, name: translate('common.day_week_complete_1') },
        { id: 2, name: translate('common.day_week_complete_2') },
        { id: 3, name: translate('common.day_week_complete_3') },
        { id: 4, name: translate('common.day_week_complete_4') },
        { id: 5, name: translate('common.day_week_complete_5') },
        { id: 6, name: translate('common.day_week_complete_6') },
        { id: 7, name: translate('common.day_week_complete_7') },
      ]} validate={[required()]} />
                
      <DateTimeInput format={formatTime} parse={timeParser} type="time" source="time_start" label={translate('users_federation.schedules_time_start')} validate={[required()]} />
      <DateTimeInput format={formatTime} parse={timeParser} type="time" source="time_finish" label={translate('users_federation.schedules_time_finish')} validate={[required()]} /> 
        
    </RowForm>
  );
};

const timeParser = (v) => {
  return v;
};

var formatTime = function (value) {    
  return value;
};