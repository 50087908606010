// in src/Dashboard.js
import React, { useState, useEffect } from 'react';




import Grid from '@mui/material/Grid';
import Box from '@material-ui/core/Box';



import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';


import { useCreate, useRefresh, useDataProvider,
  useNotify
} from 'react-admin';



import { parse } from 'query-string';




import { useTranslate  } from '../common/language';

import { makeStyles } from '@material-ui/core/styles';


import IconButton from '@mui/material/IconButton';






const useStyles = makeStyles((theme) => ({  
  largeIconOK: {
    '& svg': {
      fontSize: 200,
      color: '#007E12'
    }
  }
  ,
  largeIconKO: {
    '& svg': {
      fontSize: 200,
      color: '#FF7373'
    }
  }
}));


export const CheckMembers = props => {
    
  const translate = useTranslate();  
  const dataProvider = useDataProvider();
  const classes = useStyles();
    
  const [info, setInfo] = useState();
  const [checkMemberData, setCheckMemberData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorApi, setErrorApi] = useState();
  const [create] = useCreate('users_annuary/public/form');
  const refresh = useRefresh(); 
  const notify = useNotify();
  const [formSent, setFormSent] = useState();
    
  const { id: id_string, hash: hash_string } = parse(props.location.search);
    
  useEffect(() => {
    dataProvider.getOne('users_groups/public/check_members/' + id_string, { id: hash_string })
      .then(( { data } ) => { 
        setCheckMemberData(data);    
        setLoading(false);  
      })
      .catch(error => {      
        setError(true);
        setLoading(false);                  
      });
  }, []);
        
  if (loading) return null;
    
  if (error || checkMemberData.status !== 1) return (
    <Box sx={{ width: '90%', maxWidth: 800, bgcolor: '#efefef', padding: '0px' }} className="add_member_box" >
      <Grid container spacing={2}>
        <Grid item xs={12} align="center">                      
          <IconButton className={classes.largeIconKO}>
            <CancelOutlinedIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} align="center">  
          {translate('check_members.not_found')}
          <br />
          <br />
        </Grid>
      </Grid>                 
    </Box>);
            
    
    
  return (
    <Box sx={{ width: '90%', maxWidth: 800, bgcolor: '#efefef', padding: '0px' }} className="add_member_box" >
      <Grid container spacing={2}>
        <Grid item xs={12} align="center">  
          {checkMemberData.status === 1 ?                             
            <IconButton className={classes.largeIconOK}>
              <CheckCircleOutlineIcon />
            </IconButton>
            :
            <IconButton className={classes.largeIconKO}>
              <CancelOutlinedIcon />
            </IconButton>
          }
        </Grid>                    
        <Grid item xs={12} align="center">  
          <b>{translate('check_members.checker_name')}</b><br />{checkMemberData.name}
        </Grid>
        <Grid item xs={12} align="center">  
          <b>{translate('check_members.checker_num_member')}</b><br />{checkMemberData.num_member}
        </Grid>
        <Grid item xs={12} align="center">  
          <b>{translate('check_members.checker_date')}</b><br />{checkMemberData.date_checker}
          <br />
          <br />
        </Grid>                    
      </Grid>                 
    </Box>
  );
};

/*

<br/><br/><br/><MyLoading />

<Grid item xs={12} align="center">  
    {translate('check_members.status_' + checkMemberData.status)}
</Grid>
<Grid item xs={12} align="center">  
    {checkMemberData.association_name}
</Grid>
<Grid item xs={12} align="center">  
    {checkMemberData.group_name}
</Grid>
<Grid item xs={12} align="center">  
    {checkMemberData.name}
</Grid>
<Grid item xs={12} align="center">  
    {checkMemberData.date_join}
</Grid>
<Grid item xs={12} align="center">  
    {checkMemberData.date_left}
</Grid>
*/