// in src/posts.js
import React from 'react';






import { Card, CardContent, CardHeader } from '@material-ui/core';


import { useTranslate  } from '../common/language';




import Grid from '@mui/material/Grid';





import { targets, modules, modulesOptions } from './../common/constants';




import { DocsList, DocsListShow  } from './docs';
import { subscriptionModule  } from '../common/utils';

        
export const DocsFacList = (props) => {
  const translate = useTranslate();    
  return (
    <>
      <DcoumentsFACHeader />
      <br/>
      {subscriptionModule(modules.DOCUMENTS_FAC,modulesOptions.DOCUMENTS_FAC_W) ?
        <DocsList target={targets.DOCUMENT_FAC} items_id={0} />
        : null }
      {subscriptionModule(modules.DOCUMENTS_FAC,modulesOptions.DOCUMENTS_FAC_R) ?
        <DocsListShow target={targets.DOCUMENT_FAC} items_id={0} />
        : null }
    </>
  );
};



const DcoumentsFACHeader = props => {
  const translate = useTranslate();        
  return (
    <>
            
      <Card fullWidth className="contacts_fac_header">
        <CardHeader title={translate('documents_fac.title')} />
        <CardContent>
          <Grid container spacing={12}>
            <Grid item xs={12} align="justify">
              <div className="" dangerouslySetInnerHTML={{ __html: translate('documents_fac.description') }} /> 
            </Grid>
          </Grid>
        </CardContent>                
      </Card>            
    </>
  );
};