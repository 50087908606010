import * as React from 'react';

import {
  Datagrid,
  TextField,
  ReferenceInput,
  SelectInput,
  TextInput, 
  ReferenceManyField,
  useTranslate,
  required,
  FunctionField, DateField, DateInput
} from 'react-admin';

import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';



import { useFormState } from 'react-final-form';
import { UserAvatarIcon, UserFullNameTxt, UserInput } from '../components/User';


import { validateMaxLength  } from './../components/Validator';

export const UsersContactsList= ({ ...props }) => {
  const translate = useTranslate();   
    
  const target = props.rel ? 'users_rel_id' : 'users_id';
    
  const responsabilities = props.responsabilities ? true : false;
    
  const initial_values = props.rel ? { users_rel_id: props.users_id , comment: '' } : { users_id: props.users_id , comment: '' };

  return (
           
    <ReferenceManyField                    
      reference="users_contacts"
      target={target}
      label=""
      perPage={1000}
      filter={{ responsabilities : responsabilities }}
    >

      <EditableDatagrid                       
        createForm={<UserContactForm initialValues={initial_values} edit={false} rel={props.rel} responsabilities={responsabilities} />}
        editForm={<UserContactForm edit={true} rel={props.rel} responsabilities={responsabilities} />}
        label=""
        hasCreate
        fullWidth
      >           
        {props.rel ?
          <FunctionField label="" render={record => <UserAvatarIcon record={record.user} /> } /> 
          :
          <FunctionField label="" render={record => <UserAvatarIcon record={record.user_rel} /> } /> 
        }                        
        <FunctionField label={translate('users.first_name')} render={record => <UserFullNameTxt record={props.rel ? record.user : record.user_rel} /> } /> 
        {responsabilities ? 
          <TextField source="responsability_name" label={translate('users_groups.responsability')} sortable={false} />
          : null }
        {responsabilities ? 
          <DateField source="date_join" label={translate('users_groups.date_join')} sortable={false}/>
          : null }
        {responsabilities ? 
          <DateField source="date_left" label={translate('users_groups.date_left')} sortable={false}/>
          : null }
        <TextField source="comment" label={translate('common.comment')} onClick={ (e) => { e.stopPropagation(); } } sortable={false} />   
      </EditableDatagrid>

    </ReferenceManyField> 
  );
};

const UserContactForm= ({edit, rel, ...props}) => {
  const translate = useTranslate();   
  const { values } = useFormState();

  const user_type = rel ? 1 : 0;
  const source = rel ? 'users_id' : 'users_rel_id';

  return (
    <RowForm {...props}  >
      <span />
      <UserInput source={source} user_type={user_type} disabled={edit} label={translate('common.name')} allowEmpty={false} required={true} />
      {props.responsabilities ? 
        <ReferenceInput label={translate('users_groups.responsability')} source="responsabilities_id" reference="responsabilities" perPage={100} sort={{ field: 'priority', order: 'ASC' }} perPage={1000} >
          <SelectInput fullWidth optionText="name" optionValue="id" validate={required()} />
        </ReferenceInput> 
        : null }
      {props.responsabilities ? 
        <DateInput fullWidth source="date_join" label={translate('users_groups.date_join')} />
        : null }
      {props.responsabilities ? 
        <DateInput fullWidth source="date_left" label={translate('users_groups.date_left')} />
        : null }
      <TextInput source="comment" label={translate('common.comment')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />  
    </RowForm>
  );
};


export const UsersContactsShow= ({ reference, rowClick, rel, ...props }) => {
  const translate = useTranslate();   
    
  const target = rel ? 'users_rel_id' : 'users_id';
  const responsabilities = props.responsabilities ? true : false;
  const only_valid_contacts = props.only_valid_contacts ? props.only_valid_contacts : 0;

  const postRowClick = (id, basePath, record) => {
    if (rel)
      return '/' + rowClick + '/' + record.users_id + '/show';
    else
      return '/' + rowClick + '/' + record.users_rel_id + '/show';
  };
        
  return (
           
    <ReferenceManyField                    
      reference={reference}
      target={target}
      label=""
      perPage={1000}
      filter={{ responsabilities : responsabilities, only_valid_contacts : only_valid_contacts }}
    >

      <Datagrid                       
        label=""
        fullWidth
        className="users_remittances_list header_grey6"
        rowClick={postRowClick}
      >
        {rel ?
          <FunctionField label="" render={record => <UserAvatarIcon record={record.user} /> } /> 
          :
          <FunctionField label="" render={record => <UserAvatarIcon record={record.user_rel} /> } /> 
        }                        
        <FunctionField label={translate('users.first_name')} render={record => <UserFullNameTxt record={rel ? record.user : record.user_rel} /> } /> 
                        
        {responsabilities ? 
          <TextField source="responsability_name" label={translate('users_groups.responsability')} sortable={false} />
          : null }
        {responsabilities ? 
          <DateField source="date_join" label={translate('users_groups.date_join')} sortable={false} />
          : null }
        {responsabilities ? 
          <DateField source="date_left" label={translate('users_groups.date_left')} sortable={false} />
          : null }
                        
        <TextField source="comment" label={translate('common.comment')} onClick={ (e) => { e.stopPropagation(); } } sortable={false} />   
      </Datagrid>

    </ReferenceManyField> 

  );
};
