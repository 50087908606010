// in src/posts.js
import * as React from 'react';

import {
  BooleanInput,
  TextField,
  TextInput,
  BooleanField ,
  ReferenceManyField,
  useTranslate,NumberInput,required
} from 'react-admin';

import { useFormState } from 'react-final-form';

import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';

import { makeStyles } from '@material-ui/core/styles';

//import { useStyles  } from '../common/styles';

import { validateMaxLength, validateInteger  } from './../components/Validator';

const choicesPeriodQuote = [
  { id: 0, name: 'Mensual' },
  { id: 1, name: 'Bimensual' },
  { id: 2, name: 'Trimestral' },
  { id: 3, name: 'Quatrimestral' },
  { id: 4, name: 'Semestral' },
  { id: 5, name: 'Anual' },
];

const useStyles = makeStyles({
  rowCell: {
    padding: '6px 5px 6px 5x'
  },
});

export const GroupQuotePeriodList= (props) => {
  const translate = useTranslate();   
  const { values } = useFormState();
  const classes = useStyles();
    
  return (
    <ReferenceManyField                    
      reference="groups_quotes_periods"
      target="groups_quotes_id"
      label="Periodicitats"
      sort={{ field: 'priority', order: 'ASC' }}
      perPage={1000}
    >

      <EditableDatagrid                       
        createForm={<GroupQuotePeriodEdit initialValues={{ groups_quotes_id: props.record.id, quantity: 0, month_1: 0,month_2: 0,month_3: 0,month_4: 0,month_5: 0,month_6: 0,month_7: 0,month_8: 0,month_9: 0,month_10: 0,month_11: 0,month_12: 0, status: true, priority: 2 }} />}
        editForm={<GroupQuotePeriodEdit />}
        label={translate('groups_quotes.title')}
        hasCreate
        className="table-quotes-periods"
      >
        <TextField source="name" label={translate('common.name')} sortable={false} />            
        <TextField source="month_1" label={translate('common.month_1')} sortable={false} /> 
        <TextField source="month_2" label={translate('common.month_2')} sortable={false} /> 
        <TextField source="month_3" label={translate('common.month_3')} sortable={false} /> 
        <TextField source="month_4" label={translate('common.month_4')} sortable={false} /> 
        <TextField source="month_5" label={translate('common.month_5')} sortable={false} /> 
        <TextField source="month_6" label={translate('common.month_6')} sortable={false} /> 
        <TextField source="month_7" label={translate('common.month_7')} sortable={false} /> 
        <TextField source="month_8" label={translate('common.month_8')} sortable={false} /> 
        <TextField source="month_9" label={translate('common.month_9')} sortable={false} /> 
        <TextField source="month_10" label={translate('common.month_10')} sortable={false} /> 
        <TextField source="month_11" label={translate('common.month_11')} sortable={false} /> 
        <TextField source="month_12" label={translate('common.month_12')} sortable={false} />             
        <BooleanField source="status" label={translate('common.actived_female')} sortable={false} /> 
        <TextField source="priority" step={1} label={translate('common.priority')} sortable={false} />
      </EditableDatagrid>

    </ReferenceManyField> 
  );
};


export const GroupQuotePeriodEdit= (props) => {
  const translate = useTranslate();   
  const { values } = useFormState();
  const classes = useStyles();

  return (
    <RowForm {...props} >
      <TextInput source="name" label={translate('common.name')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />   
      <NumberInput source="month_1" label="" parse={value => value ? value : 0 } validate={[validateInteger(translate('common.validation.number'))]} /> 
      <NumberInput source="month_2" label="" parse={value => value ? value : 0 } validate={[validateInteger(translate('common.validation.number'))]} /> 
      <NumberInput source="month_3" label="" parse={value => value ? value : 0 } validate={[validateInteger(translate('common.validation.number'))]} /> 
      <NumberInput source="month_4" label="" parse={value => value ? value : 0 } validate={[validateInteger(translate('common.validation.number'))]} /> 
      <NumberInput source="month_5" label="" parse={value => value ? value : 0 } validate={[validateInteger(translate('common.validation.number'))]} /> 
      <NumberInput source="month_6" label="" parse={value => value ? value : 0 } validate={[validateInteger(translate('common.validation.number'))]} /> 
      <NumberInput source="month_7" label="" parse={value => value ? value : 0 } validate={[validateInteger(translate('common.validation.number'))]} /> 
      <NumberInput source="month_8" label="" parse={value => value ? value : 0 } validate={[validateInteger(translate('common.validation.number'))]} /> 
      <NumberInput source="month_9" label="" parse={value => value ? value : 0 } validate={[validateInteger(translate('common.validation.number'))]} /> 
      <NumberInput source="month_10" label="" parse={value => value ? value : 0 } validate={[validateInteger(translate('common.validation.number'))]} /> 
      <NumberInput source="month_11" label="" parse={value => value ? value : 0 } validate={[validateInteger(translate('common.validation.number'))]} /> 
      <NumberInput source="month_12" label="" parse={value => value ? value : 0 } validate={[validateInteger(translate('common.validation.number'))]} /> 
      <BooleanInput source="status" label="" /> 
      <NumberInput source="priority" step={1} label={translate('common.priority')} parse={value => value ? value : 0 } validate={[validateInteger(translate('common.validation.number'))]}  />
    </RowForm>
  );
};

