// in src/posts.js
import React, { useState } from 'react';

import {
  List, 
  Datagrid,
  TextField,
  DeleteButton,
  Edit,
  SelectInput,
  TextInput,
  Button ,
  Create, 
  CreateButton ,
  TopToolbar,
  FormTab,
  TabbedForm,
  ReferenceManyField,
  FormDataConsumer,
  useListContext, required,NumberInput,BooleanInput,SelectField,  FunctionField, ResourceContextProvider, useCreate, useRefresh, FormWithRedirect, Toolbar, SaveButton,
  useNotify,
  useRedirect,
  SimpleForm,
  DateField ,
  DateInput,
  useDataProvider,
  AutocompleteInput,
  downloadCSV
} from 'react-admin';

import { useEffect } from 'react';

import jsonExport from 'jsonexport/dist';

import { ExportButton } from './../components/ExportButton';

import { AccordionSection  } from '@react-admin/ra-form-layout';

import PaymentIcon from '@material-ui/icons/Payment';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

import { makeStyles } from '@material-ui/core/styles';

import Divider from '@mui/material/Divider';


import { useTranslate  } from '../common/language';

import Typography from '@mui/material/Typography';


import RichTextInput from 'ra-input-rich-text';


import { TextFieldShow } from '../components/TextFieldShow';

import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { ColorInput } from 'react-admin-color-input';

import { DocsList, DocsListShow } from './docs';
import { targets, choicesRealStatePaymentsType, choicesRealStatePaymentsPayer } from './../common/constants';

import { CityInput } from './../components/Inputs';
import { dateOnlyToCatalan, structArrayToIdList } from './../common/utils';        

import { UserInput, RenderBoolean } from '../components/User';
import { BackLink } from './../components/BackLink';

import Box from '@material-ui/core/Box';

import { Link } from 'react-router-dom';

import { validateMaxLength, validateInteger  } from './../components/Validator';

export const RealStatePaymentsList = (props) => {
  const translate = useTranslate();  
    
  let basePath='/real_state_payments';
  let resource = 'real_state_payments';
  let strRedirect =  `/real_state/${props.real_state_id}/payments`;
      
  return (
    <ResourceContextProvider value={resource}>
      <List 
        basePath={basePath}   
        filter={{ associations_id : localStorage.getItem('associations_id'), real_state_id: props.real_state_id }}  
        actions={props.show ? <span/> : <ListActions real_state_id={props.real_state_id}  />}    
        empty={props.show ? <span/> : <ListActions real_state_id={props.real_state_id} />}        
        bulkActionButtons={false}          
        perPage={1000}   
        pagination={false}
        sort={{ field: 'datePayment', order: 'DESC' }} 
        label=""  
        title=""  
        resource={resource}
      >        
        <Datagrid 
          rowClick="edit"
          expand={<RealStatePaymentsDetails />} >  
          <Avatar><PaymentIcon/></Avatar>   
          <DateField source="date_payment" label={translate('real_state_payments.date_payment')} sortable={false} /> 
          <FunctionField render={record => <span>{record.type > 0 ? translate('real_state_payments.type_' + record.type) : record.type_other}</span> } label={translate('real_state_payments.type')} sortable={false}  />  
          <TextField source="amount" label={translate('real_state_payments.amount')} sortable={false} />  
          <FunctionField render={record => <span>{record.payer > 0 ? translate('real_state_payments.payer_' + record.payer) : record.payer_other}</span> } label={translate('real_state_payments.payer')} sortable={false}  />  
          <TextField source="comments" label={translate('real_state_payments.comments')} sortable={false} />           
        </Datagrid>        
      </List>
    </ResourceContextProvider>
  );
};



/* 
 * Llista no editable des de la fitxa d'users (espais d'usuari)
 */
/*
export const UsersClassroomsShow = (props) => {
  const translate = useTranslate();    
  return (
    <ReferenceManyField                    
      reference="users_classrooms"
      target="users_id"
      label=""
      filter={{ associations_id : localStorage.getItem('associations_id') }}  
    >

      <Datagrid          
        hasCreate
        className="table-quotes-periods users_remittances_list header_grey6"
        expand={<MyDetails />} 
      >
        <Avatar><MeetingRoomIcon/></Avatar>                                  
        <TextField source="name" label={translate('common.name')} sortable={false} />    
        <FunctionField render={record => <span>{translate('classrooms.type' + record.type)}</span> } label={translate('common.type')} sortable={false}  />   
        <TextField source="m2" label={translate('classrooms.m2')} sortable={false} />  
        <TextField source="seats" label={translate('classrooms.seats')} sortable={false} />                    
      </Datagrid>

    </ReferenceManyField> 
  );
};*/


export const RealStatePaymentsDetails = ({ id, record, resource, ...props }) => {
  const translate = useTranslate(); 
      
  return (
    <div className="usersClassroomsExpand">        
      <Grid container spacing={2}>
        <Grid item xs={4}>                    
          <TextFieldShow source={dateOnlyToCatalan(record.date_payment)} label={translate('real_state_payments.date_payment')} />                   
        </Grid>
        <Grid item xs={4}>                    
          <TextFieldShow source={record.type > 0 ? translate('real_state_payments.type'+record.type) : record.type_other} label={translate('real_state_payments.type')} />               
        </Grid>        
        <Grid item xs={4}>                    
          <TextFieldShow source={record.amount} label={translate('real_state_payments.amount')} />                       
        </Grid> 
        <Grid item xs={4}>                    
          <TextFieldShow source={record.payer > 0 ? translate('real_state_payments.payer_'+record.payer) : record.payer_other} label={translate('real_state_payments.payer')} />               
        </Grid>
        <Grid item xs={4}>                    
          <TextFieldShow source={<RenderBoolean source={record.financing} />} label={translate('real_state_payments.financing')} />   
        </Grid>  
        <Grid item xs={4}>                 
            {record.financing ?
            <TextFieldShow source={record.financing_type} label={translate('real_state_payments.financing_type')} />                       
            : null }
        </Grid>   
        <Grid item xs={12}>                    
          <TextFieldShow source={record.comments} label={translate('real_state_payments.comments')} />                       
        </Grid>
      </Grid>
                
      <Typography variant="h6" className="grey8 titleUserShow">                        
        {translate('real_state_payments.docs_billing')}
      </Typography>
      <Divider fullWidth className="" /><br/>
        
      <DocsListShow target={targets.REAL_STATE_PAYMENT} items_id={record.id} />
              
    </div>
  );
};


const exporter = (items, translate, notify) => {
    
  const itemsForExport = items.map(item => {
    var itemForExport = {
      real_state_id: item.real_state_id,
      id : item.id,
      type : item.type != null ? translate('real_state_payments.type_'+item.type) : '', 
      type_other : item.type_other, 
      date_payment : dateOnlyToCatalan(item.date_payment), 
      amount : item.amount != null ? item.amount.toFixed(2).replace('.', ',') : '',        
      payer : item.payer != null ? translate('real_state_payments.payer_' + item.payer) : '', 
      payer_other : item.payer_other, 
      financing: item.financing != null ? item.financing : '',
      financing_type : item.financing_type, 
      comments : item.comments
    };
    return itemForExport;
  });
  jsonExport(itemsForExport, {
    headers: ['real_state_id','id','type','type_other','date_payment','amount','payer','payer_other','financing','financing_type','comments',],
    rename: [translate('real_state_activities.real_state_id'), translate('common.id'),
    translate('real_state_payments.type'),translate('real_state_payments.type_other'),translate('real_state_payments.date_payment'),
    translate('real_state_payments.amount'),translate('real_state_payments.payer'),translate('real_state_payments.payer_other'),
    translate('real_state_payments.financing'),translate('real_state_payments.financing_type'),translate('real_state_payments.comments')],                
  }, (err, csv) => {
    const csv_new = '\uFEFF' + csv;
    downloadCSV(csv_new, translate('real_state_payments.exporter_file_name') ); 
    notify(translate('common.download_ok'),"success");
  });
};

const ListActions = (props) => {
  const translate = useTranslate(); 
  
  let filterExport = { 
    associations_id: localStorage.getItem('associations_id'),        
    real_state_id: props.real_state_id
  };
    
  return (
    <TopToolbar>             
      <MyCreateButton {...props} />
      <ExportButton maxResults="100000" filterValues={filterExport} alwaysEnabled={false} exporter={exporter} sort={{ field: 'id', order: 'DESC' }} /> 
    </TopToolbar>
  );
};


export const MyCreateButton = ({ real_state_id , ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('real_state_payments');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  
  const redirect = useRedirect();
    
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {                    
          redirect('/real_state_payments/' + data.id);        
        },
        onFailure: ( error ) => {
          notify( error.message , 'error');
        }
      }
    );
  };
  
  const today = new Date().toISOString().slice(0, 10);
    
  return (
    <>            
      <Button onClick={handleClick} label={translate('real_state_payments.create')}>
        <IconContentAdd />                
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('real_state_payments.create')}
      >
        <DialogTitle>{translate('real_state_payments.create')}</DialogTitle>

        <FormWithRedirect
          save={handleSubmit}
          initialValues={{ associations_id: localStorage.getItem('associations_id') , real_state_id : real_state_id, type: 0, payer: 3 , date_payment: today }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>
                <FormDataConsumer>
            {({ formData, ...rest }) => 
              <>                              
                <br/>
                <Grid container spacing={1}>                                     
                  <Grid item xs={4}> 
                    <SelectInput fullWidth label={translate('real_state_payments.type')} source="type" choices={choicesRealStatePaymentsType(translate)} validate={[required()]} />
                  </Grid>                   
                  { formData.type == 0 ?
                  <Grid item xs={8}>                       
                    <TextInput fullWidth autoFocus source="type_other" label={translate('real_state_payments.type_other')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]}  />                                                      
                  </Grid> 
                  : <Grid item xs={8}></Grid> } 
                  
                  <Grid item xs={4}>
                    <DateInput source="date_payment" fullWidth label={translate('real_state_payments.date_payment')} validate={[required()]} />
                  </Grid>  
                  <Grid item xs={4}>
                    <NumberInput source="amount" fullWidth label={translate('real_state_payments.amount')} validate={[required()]} />
                  </Grid> 
                </Grid> 
                </>
            }
          </FormDataConsumer>
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

const MyActions = ({ basePath, data, resource }) => {

  const translate = useTranslate();   
  
  if (!data || !data.real_state_id) return null;
  
  let backLink = '/real_state/' + data.real_state_id + '/payments';
  let title = data && data.type && data.type > 0 ? translate('real_state_payments.type_' + data.type) : data && data.type == 0 ? data.type_other : null;
      
  return <BackLink link={backLink} title={title} />;
  
};


export const RealStatePaymentsEdit  = ({ id, ...props }) => {

  const translate = useTranslate();        
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
    
  const onSuccess = ({ data }) => {
      notify(translate('common.notification.updated'));
      redirect('/real_state/' + data.real_state_id + '/payments');
      refresh();        
  };
        
  return (

    <Edit 
      title={<ElementTitle {...props}  />} 
      {...props} 
      id={id}
      mutationMode="pessimistic"
      actions={<MyActions {...props} />}
      onSuccess={onSuccess}   
    >
        
      <TabbedForm        
        toolbar={<FormToolbar />}
      >    
        <FormTab
          label={translate('common.data')}
          contentClassName={classes.tab}
          path="" 
                
        > 
               
          <FormDataConsumer>
            {({ formData, ...rest }) => 
              <>                              
                <br/>
                <Grid container spacing={2}>
                  <Grid item xs={3}> 
                    <SelectInput fullWidth label={translate('real_state_payments.type')} source="type" choices={choicesRealStatePaymentsType(translate)} />
                  </Grid>                   
                  { formData.type == 0 ?
                  <Grid item xs={9}>                       
                    <TextInput fullWidth autoFocus source="type_other" label={translate('real_state_payments.type_other')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]}  />                                                      
                  </Grid> 
                  : <Grid item xs={9}></Grid> }        
                  
                  <Grid item xs={2}>
                    <DateInput source="date_payment" fullWidth label={translate('real_state_payments.date_payment')} />
                  </Grid>  
                  <Grid item xs={2}>
                    <NumberInput source="amount" fullWidth label={translate('real_state_payments.amount')} />
                  </Grid>  
                  
                  <Grid item xs={2}> 
                    <SelectInput fullWidth label={translate('real_state_payments.payer')} source="payer" choices={choicesRealStatePaymentsPayer(translate)} />
                  </Grid>                   
                  { formData.payer == 0 ?
                  <Grid item xs={6}>                       
                    <TextInput fullWidth source="payer_other" label={translate('real_state_payments.payer_other')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]}  />                                                      
                  </Grid> 
                  : <Grid item xs={6}></Grid> }   
                  
                  <Grid item xs={2}>
                    <BooleanInput source="financing" label={translate('real_state_payments.financing')} />  
                  </Grid>  
                  { formData.financing == true ?
                  <Grid item xs={10}>                       
                    <TextInput fullWidth source="financing_type" label={translate('real_state_payments.financing_type')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]}  />                                                      
                  </Grid> 
                  : <Grid item xs={10}></Grid> } 
                  
                  <Grid item xs={10}>                       
                    <TextInput fullWidth source="comments" label={translate('real_state_payments.comments')} />                                                      
                  </Grid> 
                                    
                </Grid>

              </>
            }
          </FormDataConsumer>

        </FormTab> 
        
        <FormTab
            label={translate('real_state_payments.docs')}
            contentClassName={classes.tab}
            path="docs"                 
        >             
                        
            <br />
            <AccordionSection className="accordion" label={translate('real_state_payments.docs_billing')} fullWidth defaultExpanded={true}>              
              <DocsList target={targets.REAL_STATE_PAYMENT} items_id={id} />
            </AccordionSection>   
                    
          <br />
          <br />
          
        </FormTab>        
    
      </TabbedForm>
    </Edit>
  );
};

export const AgreementsInput = ({ ...props }) => {

  const translate = useTranslate(); 
    
  const dataProvider = useDataProvider();
  const [info, setInfo] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
    
  const disabled = props.disabled && props.disabled==true ? true : false;
  const resettable = props.resettable != null ? props.resettable : true;
  const label = props.label ? props.label : translate('common.name');
  const validate = props.required ? [required()] : [];
    
  useEffect(() => {
    dataProvider
      .getList('agreements', {
        pagination: { page: 0, perPage: 10000 },
        sort: { field: 'id', order: 'ASC' },
        filter: { associations_id: localStorage.getItem('associations_id') },
      })
      .then(({ data }) => {
        setInfo(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) return (<span>{translate('common.loading')}</span>);
  if (error) return null;
  if (!info) return null;
  
  return (
    <AutocompleteInput 
      optionText={ (record)=> record.name } 
      suggestionLimit={50}
      resettable={resettable}
      clearAlwaysVisible={true}
      validate={validate}
      fullWidth
      disabled={disabled}
      choices={info}
      source={props.source}
      label={label}
    />
                
  );
};

const ElementTitle = ({ record, ...props }) => {    
  const translate = useTranslate(); 
  return (<span>{translate('common.edit')} {record ? `"${record.name}"` : ''}</span>);
};

const FormToolbar = ({ id , ...props }) => {
    
  let redirect = '/real_state/' + props.record.real_state_id + '/payments';
    
  return (
    <Toolbar {...props} className="user_form_toolbar" >
      <SaveButton />
      <DeleteButton redirect={redirect} mutationMode="pessimistic" />
    </Toolbar>
  );
};


const useStyles = makeStyles({
  comment: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    maxWidth: '100%',
    display: 'block',
  },
});




export const RealStatePaymentsCreate  = ({ id, ...props }) => {

  const translate = useTranslate();   
  const [info, setInfo] = useState();
    
  return (

    <Create {...props} >
      <SimpleForm 
        initialValues={{ associations_id: localStorage.getItem('associations_id') }}
        redirect="list"
      >                
        <Grid container spacing={2}>
          <Grid item xs={12}>                    
            <Typography variant="h5" className="grey">{translate('real_state.create')}</Typography>
          </Grid>  
          <Grid item xs={12}>                    
            <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
          </Grid>      
          
        </Grid>   

      </SimpleForm> 
    </Create>
  );
};
