// in src/Dashboard.js
import React, { useState } from 'react';




import Grid from '@mui/material/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@mui/material/Typography';



import {
  TextInput, required, useCreate, useRefresh, FormWithRedirect, SaveButton, useDataProvider,
  useNotify,
  PasswordInput
    
} from 'react-admin';

import { validateEmail,validateMinMaxLengthAndCharacters  } from '../components/Validator';

import { parse } from 'query-string';





import { useTranslate  } from '../common/language';



import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));



export const ForgotPassword = props => {
    
  const translate = useTranslate();  
  const dataProvider = useDataProvider();
    
  const [info, setInfo] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [errorApi, setErrorApi] = useState();
  const [create] = useCreate('auth/forgot-password');
  const refresh = useRefresh(); 
  const notify = useNotify();
  const [formSent, setFormSent] = useState();
    
  const [open, setOpen] = React.useState(false);

  const openErrorDialog = () => {
    setOpen(true);
  };
  const closeErrorDialog = () => {
    setOpen(false);
  };
             
  const handleSubmit = values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {              
          setFormSent(true);
          setErrorApi('');
        },
        onFailure: ( error ) => {
          setErrorApi(error.message);
          openErrorDialog();                                    
        }
      }
    );
  };
    
  var imgName = window.location.hostname.replaceAll('.','');
    
  if (formSent)
  {
    return (
      <Box sx={{ width: '50%', maxWidth: 800, bgcolor: 'background.paper', padding: '20px' }} className="add_member_box" >
        <Grid container spacing={1} >
          <Grid item xs={12} sm={12}>
            <center><img src={'/img/'+imgName+'_200x200.png'} /></center>
          </Grid>
        </Grid> 
                                
        <div className="add_member_form_sent" dangerouslySetInnerHTML={{ __html: translate('auth.forgot_password_ok') }} />
                                
      </Box>
    );
  }
    
  var imgName = window.location.hostname.replaceAll('.','');
    
  return (
            
    <FormWithRedirect
      resource="auth"        
      save={handleSubmit}
      initialValues={{ }}
      render={formProps => (
        <>     
          <Box sx={{ width: '50%', maxWidth: 800, bgcolor: 'background.paper', padding: '20px' }} className="add_member_box" >
                                
            <Grid container spacing={1} >
              <Grid item xs={12} sm={12}>
                <center><img src={'/img/'+imgName+'_200x200.png'} /></center>
              </Grid>
            </Grid> 
                
            <Grid container spacing={1} >
              <Grid item xs={12} sm={12}>
                <h3>{translate('auth.forgot_password_title')}</h3>
                <br />
                {translate('auth.forgot_password_desc')}
              </Grid>
            </Grid>  
                 
            <br />
                 
            <Grid container spacing={1} >
              <Grid item xs={12} sm={12}>
                <TextInput fullWidth label={translate('users.email')} fullWidth source="email" validate={[validateEmail(),required()]} />
              </Grid>
            </Grid>                                    
                
            <br /><br />      
            <SaveButton
              saving={formProps.saving}
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              label={translate('auth.send_button')}
              icon={<span />}
            />
            <br/><br />
            <a href="/#/login">{translate('auth.forgot_password_link')}</a>

          </Box>
            
          <BootstrapDialog
            onClose={closeErrorDialog}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogTitle sx={{ m: 0, p: 2 }}>
              {translate('auth.error_title')}   
              <IconButton
                aria-label="close"
                onClick={closeErrorDialog}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
    
            <DialogContent dividers>
              <Typography gutterBottom>
                {errorApi}
              </Typography>
            </DialogContent>
          </BootstrapDialog>
      
        </>
            
            
      )}
    />
        
  );
  
};



export const ResetPassword = props => {
    
  const translate = useTranslate();  
  const dataProvider = useDataProvider();
    
  const [info, setInfo] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [errorApi, setErrorApi] = useState();
  const [create] = useCreate('auth/reset-password');
  const refresh = useRefresh(); 
  const notify = useNotify();
  const [formSent, setFormSent] = useState();
    
  const { rc: rc_string } = parse(props.location.search);
  const rc = rc_string ? rc_string : '';
    
  const [open, setOpen] = React.useState(false);

  const openErrorDialog = () => {
    setOpen(true);
  };
  const closeErrorDialog = () => {
    setOpen(false);
  };
             
  const handleSubmit = values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {              
          setFormSent(true);
          setErrorApi('');
        },
        onFailure: ( error ) => {
          setErrorApi(error.message);
          openErrorDialog();                                    
        }
      }
    );
  };
    
  var imgName = window.location.hostname.replaceAll('.','');
    
  if (formSent)
  {
    return (
      <Box sx={{ width: '50%', maxWidth: 800, bgcolor: 'background.paper', padding: '20px' }} className="add_member_box" >
        <Grid container spacing={1} >
          <Grid item xs={12} sm={12}>
            <center><img src={'/img/'+imgName+'_200x200.png'} /></center>
          </Grid>
        </Grid> 
                                
        <div className="add_member_form_sent" dangerouslySetInnerHTML={{ __html: translate('auth.reset_password_ok') }} />
        <br/>
        <center><a href="/#/login">{translate('auth.reset_password_ok_link')}</a></center>
                                
      </Box>
    );
  }
    
  var imgName = window.location.hostname.replaceAll('.','');
    
  return (
            
    <FormWithRedirect
      resource="auth"        
      save={handleSubmit}
      initialValues={{ rc: rc }}
      render={formProps => (
        <>     
          <Box sx={{ width: '50%', maxWidth: 800, bgcolor: 'background.paper', padding: '20px' }} className="add_member_box" >
                                
            <Grid container spacing={1} >
              <Grid item xs={12} sm={12}>
                <center><img src={'/img/'+imgName+'_200x200.png'} /></center>
              </Grid>
            </Grid> 
                
            <Grid container spacing={1} >
              <Grid item xs={12} sm={12}>
                <br />
                {translate('auth.reset_password_title')}
              </Grid>
            </Grid>  
                 
            <br />
                 
            <Grid container spacing={1} >
              <Grid item xs={12} sm={12}>
                <PasswordInput fullWidth source="pswd" label={translate('settings.profile_password')} validate={[validateMinMaxLengthAndCharacters(translate('common.validation.minMaxLengthAndCharacters')),required()]} />                            
              </Grid>
            </Grid>                                    
                
            <br /><br />      
            <SaveButton
              saving={formProps.saving}
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              label={translate('auth.reset_password_button')}
              icon={<span />}
            />

          </Box>
            
          <BootstrapDialog
            onClose={closeErrorDialog}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogTitle sx={{ m: 0, p: 2 }}>
              {translate('auth.error')}   
              <IconButton
                aria-label="close"
                onClick={closeErrorDialog}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
    
            <DialogContent dividers>
              <Typography gutterBottom>
                {errorApi}
              </Typography>
            </DialogContent>
          </BootstrapDialog>
      
        </>
                        
      )}
    />
        
  );
  
};
