import {    
  useTranslate
} from 'react-admin';

export const PDFField = ({ url, ...props }) => {
    
  const translate = useTranslate();   
  const className = props.className  ? props.className : 'pdfFieldContainer';
  
  return (   
    <div className={className}>
      {url ? <iframe src={url} title="PDF"></iframe> : <p>{translate('groups_cards.pdf_card_preview')}</p>}
    </div>
  );

};