import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(function (theme) {

  return ({
    appBar : {
      background: '#FF6600',
    },
    menu :{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginTop: '0.5em',
      marginBottom: '1em'
    },
    filter_list_item :
    {
    	background: '#FF6600',
    	marginTop: '300px',
    },
    tab: {
      maxWidth: '100%',
      display: 'block',
    }
  });
});

export { useStyles };