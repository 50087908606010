// in src/Dashboard.js
import React, { useState, useEffect } from 'react';




import Grid from '@mui/material/Grid';
import Box from '@material-ui/core/Box';



import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { RecercatHeader } from './../components/RecercatHeader';


import { useCreate, useRefresh, useDataProvider,
  useNotify
} from 'react-admin';



import { parse } from 'query-string';




import { useTranslate  } from '../common/language';

import { makeStyles } from '@material-ui/core/styles';


import IconButton from '@mui/material/IconButton';






const useStyles = makeStyles((theme) => ({  
  largeIconOK: {
    '& svg': {
      fontSize: 100,
      color: '#007E12'
    }
  }
  ,
  largeIconKO: {
    '& svg': {
      fontSize: 200,
      color: '#FF7373'
    }
  }
}));


export const RecercatValidateRequest = props => {
    
  const translate = useTranslate();  
  const dataProvider = useDataProvider();
  const classes = useStyles();
    
  const [info, setInfo] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorApi, setErrorApi] = useState();
  const [create] = useCreate('users_annuary/public/form');
  const refresh = useRefresh(); 
  const notify = useNotify();
  const [formSent, setFormSent] = useState();
    
  const { associations_id: associations_id_string, code: code } = parse(props.location.search);
    
  useEffect(() => {
    dataProvider.getOne('recercat_centers/public/recercat_validate_email/' + associations_id_string, { id: code })
      .then(( { data } ) => { 
        setInfo(data);    
        setLoading(false);  
      })
      .catch(error => {      
        setError(true);
        setLoading(false);                  
      });
  }, []);
        
  if (loading) return null;
    
  // Mostra error de validació
  if (error || info && info.done !== true) return (
    <>
      <RecercatHeader />
      <Box sx={{ width: '90%', maxWidth: 800, bgcolor: 'background.paper', padding: '20px' }} className="add_member_box recercat" >
        <h1>{translate('recercat_centers.form_title_validate_ko')}</h1>
        <Grid container spacing={2}>
          <Grid item xs={12} align="center">  
            <div dangerouslySetInnerHTML={{ __html: !error ? translate('recercat_centers.validation_email_code_'+info.code) : translate('recercat_centers.validation_email_error') }} />
            <br />
            <br />
          </Grid>
        </Grid>                 
      </Box>
    </>);
            
  // Validació OK
  return (
    <>
      <RecercatHeader />
      <Box sx={{ width: '90%', maxWidth: 800, bgcolor: 'background.paper', padding: '20px' }} className="add_member_box recercat" >
        <h1>{translate('recercat_centers.form_title_validate_ok')}</h1>
        <Grid container spacing={2}>
          <Grid item xs={12} align="center">                                                 
            <IconButton className={classes.largeIconOK}>
              <CheckCircleOutlineIcon />
            </IconButton>
          </Grid>                    
          <Grid item xs={12} align="center">  
            <div dangerouslySetInnerHTML={{ __html: translate('recercat_centers.validation_email_code_'+info.code) }} />
          </Grid>   
        </Grid>                 
      </Box>
    </>
  );
};
