// in src/Dashboard.js
import React, { useState, useEffect } from 'react';




import Grid from '@mui/material/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@mui/material/Typography';



import Carousel from 'react-material-ui-carousel';


import {
  TextInput, required,NumberInput,  useCreate, useRefresh, FormWithRedirect, SaveButton, useDataProvider, Loading,
  useNotify,
  DateTimeInput 
} from 'react-admin';

import { validateMaxLength, validateEmail  } from '../components/Validator';
import { DownloadPublicDoc  } from '../components/DownloadButtonAuth';
import MyLoading from '../components/MyLoading';


import { parse } from 'query-string';


import Divider from '@mui/material/Divider';


import { useTranslate  } from '../common/language';


import { ImageViewer } from '../resources/docs';


import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';





export const ClassroomsRequests = props => {
    
  const translate = useTranslate();  
  const dataProvider = useDataProvider();
    
  const [info, setInfo] = useState();
  const [classrooms, setClassrooms] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [errorApi, setErrorApi] = useState();
  const [create] = useCreate('users/public');
  const refresh = useRefresh(); 
  const notify = useNotify();
  const [formSent, setFormSent] = useState();
    
  const { associations_id: associations_id_string } = parse(props.location.search);
  const associations_id = associations_id_string ? parseInt(associations_id_string, 10) : '';
    
  const [open, setOpen] = React.useState(false);

  const openErrorDialog = () => {
    setOpen(true);
  };
  const closeErrorDialog = () => {
    setOpen(false);
  };
    
  useEffect(() => {
    dataProvider.getOne('associations/public', { id: associations_id })
      .then(( { data } ) => { 
                
        dataProvider.getList('classrooms/public', {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'priority', order: 'ASC' },
          filter: { associations_id: associations_id } } )
          .then(response => {
            setClassrooms(response.data);
            setInfo(data);
            setLoading(false);
            setError(false);
                    
          })
          .catch(error => {                
            setLoading(false);  
            setError(true);
          });
            
                
      })
      .catch(error => {                
        setLoading(false);  
        setError(true);
      });
  }, []);
    
  if (loading) return <MyLoading />;
  if (error) return <center><br /><br/>{translate('classrooms_requests.association_not_found')}</center>;
  if (!info) return null;
    
  if (!info.classrooms_requests_actived)
    return <center><br /><br/>{translate('add_member.form_not_actived')}</center>;    
  else
    return (
      <Box sx={{ width: '90%', maxWidth: 800, bgcolor: 'background.paper', padding: '20px' }} className="add_member_box" >
        <Grid container spacing={1}>
          <Grid item xs={12}>  
            <HeaderPage info={info} title={translate('classrooms_requests.form_title')} />
            {info.classrooms_requests_text} 
            <br/><br/>
          </Grid>
        </Grid>   
                
        <br/>
              
        <Grid container spacing={2}>
          {classrooms.map((item) => (     
                    
            <Grid item xs={12} sm={6} lg={4} textAlign="center" className="classrooms_list_item_container">  
              <a href={'/#/public/classrooms-requests-item?classrooms_id=' + item.id} className="classrooms_list_item_link">
                { item.photos.length > 0 ?
                  <ImageViewer docs_id={item.photos[0].id} className="classrooms_list_item" is_public={true} />
                  : null }
                <h3 align="center" className="grey8">{item.name}</h3>
              </a>
            </Grid>                
          ))}
        </Grid>
    
      </Box>
    );
};

export const ClassroomsRequestsItem = props => {
    
  const translate = useTranslate();  
  const dataProvider = useDataProvider();
    
  const [info, setInfo] = useState();
  const [classrooms, setClassrooms] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [errorApi, setErrorApi] = useState();
  const [create] = useCreate('classrooms-requests/public');
  const refresh = useRefresh(); 
  const notify = useNotify();
  const [formSent, setFormSent] = useState();
    
  const { classrooms_id: classrooms_id_string } = parse(props.location.search);
  const classrooms_id = classrooms_id_string ? parseInt(classrooms_id_string, 10) : '';
    
  const [open, setOpen] = React.useState(false);

  const openErrorDialog = () => {
    setOpen(true);
  };
  const closeErrorDialog = () => {
    setOpen(false);
  };
    
  useEffect(() => {
    dataProvider.getOne('classrooms/public', { id: classrooms_id })
      .then(( { data } ) => { 
        setClassrooms(data);
        
        dataProvider.getOne('associations/public', { id: data.associations_id })
          .then(( { data } ) => { 
            setInfo(data);
            setLoading(false);
            setError(false);
          })
          .catch(error => {                
            setLoading(false);  
            setError(true);
          });
      })
      .catch(error => {                
        setLoading(false);  
        setError(true);
      });
  }, []);
    
  const handleSubmit = values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {              
          setFormSent(true);
          setErrorApi('');
        },
        onFailure: ( error ) => {
          setErrorApi(error.message);
          openErrorDialog();                                    
        }
      }
    );
  };
    
  if (loading) return <MyLoading />;
  if (error) return <center><br /><br/>{translate('classrooms_requests.association_not_found')}</center>;
  if (!info) return null;
    
  if (formSent)
  {
    return (
      <Box sx={{ width: '90%', maxWidth: 800, bgcolor: 'background.paper', padding: '20px' }} className="add_member_box" >
        <Grid container spacing={1}>
          <Grid item xs={12}>  
            <HeaderPage info={info} title="" />
          </Grid>
        </Grid>                   
        <div className="add_member_form_sent" dangerouslySetInnerHTML={{ __html: info.classrooms_requests_form_sent }} />                                
      </Box>
    );
  }
    
  if (!info.classrooms_requests_actived)
    return <center><br /><br/>{translate('add_member.form_not_actived')}</center>;    
  else
    return (
      <>
        <Box sx={{ width: '90%', maxWidth: 800, bgcolor: 'background.paper', padding: '20px' }} className="add_member_box" >
          <Grid container spacing={1}>
            <Grid item xs={12}>  
              <HeaderPage info={info} title={classrooms.name} />
            </Grid>
          </Grid>   
                
          { classrooms.photos.length > 1 ? 
            <Carousel className="classrooms_carrousel_photos">
              { classrooms.photos.map( (item, i) => <div className="classrooms_carrousel_item_container"><ImageViewer docs_id={item.id} className="classrooms_carrousel_item" is_public={true} /></div> ) }
            </Carousel>   
            : null }
          { classrooms.photos.length === 1 ? 
            <div className="classrooms_carrousel_photos">
              <ImageViewer docs_id={classrooms.photos[0].id} className="classrooms_carrousel_item" is_public={true} />
            </div>
            : null }
                
          { classrooms.docs.length > 0 ? 
            <div className="classrooms_doc_link">
              <DownloadPublicDoc doc={classrooms.docs[0]} />
            </div>
            : null }
                
          <br />
          <Grid container spacing={2} >
            <div className="classrooms_requests_description" spacing={2} dangerouslySetInnerHTML={{ __html: classrooms.description }} />
          </Grid>  
          <br />
                
          <br />
          { classrooms.is_requestable ? 
                
            <>
              <Divider className="" />
              <Grid container spacing={0}>
                
                <FormWithRedirect
                  resource=""        
                  save={handleSubmit}
                  initialValues={{ classrooms_id : classrooms.id }}
                  render={formProps => (
                    <>     
                      <Grid container spacing={1} >
                        <Grid item xs={12} sm={12}>
                          <Typography variant="h6" className="grey8 mt-20" align="center">
                            {translate('classrooms_requests.form_request_title')}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} className="grey8 mt-20 mb-20">
                        {translate('classrooms_requests.form_event_data_title')}
                      </Grid>
                                
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                          <TextInput className="add_member_input" fullWidth source="event_name" label={translate('classrooms_requests.form_event_name')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />
                        </Grid>
                        <Grid item xs={8} sm={4}>
                          <DateTimeInput className="add_member_input" fullWidth source="event_date" label={translate('classrooms_requests.form_event_date')} validate={[required()]} />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                          <NumberInput className="add_member_input" fullWidth source="event_duration" label={translate('classrooms_requests.form_event_duration')} validate={[required()]} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <TextInput className="add_member_input" fullWidth multiline source="event_recurrency" label={translate('classrooms_requests.form_event_recurrency')} validate={[]} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <TextInput className="add_member_input" fullWidth multiline source="event_description" label={translate('classrooms_requests.form_event_description')} validate={[required()]} />
                        </Grid>                                    
                        <Grid item xs={12} sm={12}>
                          <TextInput className="add_member_input" fullWidth multiline source="event_resources" label={translate('classrooms_requests.form_event_resources')} validate={[]} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <TextInput className="add_member_input" fullWidth multiline source="comments" label={translate('classrooms_requests.form_event_comments')} validate={[]} />
                        </Grid>
                      </Grid>
                                
                      <Grid container spacing={1} className="grey8 mt-20 mb-20">
                        {translate('classrooms_requests.form_requester_data_title')}
                      </Grid>
                                
                      <Grid container spacing={1} >
                        <Grid item xs={12} sm={4}>
                          <TextInput className="add_member_input" label={translate('users.first_name')} fullWidth source="first_name" validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextInput className="add_member_input" label={translate('users.last_name_1')} fullWidth source="last_name_1" validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextInput className="add_member_input" label={translate('users.last_name_2')} fullWidth source="last_name_2" validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <TextInput className="add_member_input" fullWidth source="email" label={translate('users.email')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength')),validateEmail()]} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextInput className="add_member_input" fullWidth source="phone" label={translate('classrooms_requests.form_event_phone')} validate={[required(),validateMaxLength(25,translate('common.validation.maxLength'))]} />
                        </Grid>
                      </Grid>  

                      <div className="add_member_lopd" dangerouslySetInnerHTML={{ __html: info.classrooms_requests_lopd }} />

                      <div className="add_member_button" >
                        <SaveButton
                          saving={formProps.saving}
                          handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                          label={translate('classrooms_requests.form_send')}
                          icon={<span />}
                        />
                      </div>

                      <br />

                    </>


                  )}
                />
              </Grid> 
            </>
            : null }
        </Box>
          
        <BootstrapDialog
          onClose={closeErrorDialog}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle sx={{ m: 0, p: 2 }}>
            {translate('classrooms_requests.form_request_title')}   
            <IconButton
              aria-label="close"
              onClick={closeErrorDialog}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            <Typography gutterBottom>
              {errorApi}
            </Typography>
          </DialogContent>
        </BootstrapDialog>
          
      </>
    );
};

export const HeaderPage = ({info,title,...props}) => {
  const translate = useTranslate();  
  return ( 
    <>
      { info.classrooms_requests_logo ? 
        <>
          { info.image > 0 ? 
            <center><ImageViewer docs_id={info.image} className="docs_logo_add_member" is_public={true} /></center>
            : 
            <Typography variant="h4" className="grey8" align="center">
              <br/>{info.name}          
            </Typography>
          } 
          <br />
          <Typography variant="h5" className="grey8" align="center">              
            {title}
          </Typography>
          <br /><br />
        </>
        : null }
    </>
  );
};



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


