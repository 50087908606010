// in src/posts.js
import * as React from 'react';

import { 
  ReferenceManyField, 
  SingleFieldList,
  useTranslate, FunctionField
} from 'react-admin';

import { useFormState } from 'react-final-form';

import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';


import { UserAvatarField, UserFullNameTxt, ChipFieldUserName, UserInput } from '../components/User';

/* visibility: hidden!important; */

import './../common/styles.css';


export const UsersFamiliesList= ({ users_id , ...props }) => {
  const translate = useTranslate();   

  return (
    <ReferenceManyField                    
      reference="users_families"
      target="users_id"
      label=""
      perPage={1000}
    >

      <EditableDatagrid                       
        createForm={<UserFamilyFormNew initialValues={{ users_id: users_id }} />}
        editForm={<UserFamilyFormNew />}
        label=""
        hasCreate
        noEdit={true}
      >
        <UserAvatarField />   
        <FunctionField label={translate('users.first_name')} render={record => <UserFullNameTxt record={record} /> } />                                 

      </EditableDatagrid>

    </ReferenceManyField> 
  );
};

const UserFamilyFormNew= (props) => {
  const translate = useTranslate();   
  const { values } = useFormState();

  return (
    <RowForm {...props}  >
      <span />  
      <UserInput source="users_referred_id" user_type={0} disabled={false} label={translate('users_families.name')} allowEmpty={false} required={true} />        
    </RowForm>
  );
};

export const UsersFamiliesShow= ({ users_id , ...props }) => {
  const translate = useTranslate();   

  return (
    <ReferenceManyField                    
      reference="users_families"
      target="users_id"
      label=""
      perPage={1000}
    >
      <SingleFieldList className="users_show_family_title" linkType={false}>
        <ChipFieldUserName />
      </SingleFieldList>

    </ReferenceManyField>
  );
};




