// in src/posts.js
import React, { useState } from 'react';

import {
  List, 
  Datagrid,
  TextField,
  DeleteButton,
  Edit,
  SelectInput,
  TextInput,
  Button ,
  Create,
  SimpleForm,
  CreateButton ,
  TopToolbar,
  FormTab,
  TabbedForm,
  ReferenceManyField,
  FormDataConsumer,
  useListContext, required,NumberInput,BooleanInput,SelectField,  FunctionField, ResourceContextProvider, useCreate, useRefresh, FormWithRedirect, Toolbar, SaveButton,
  useNotify,
  useRedirect,
  DateField  , 
  Filter, 
  downloadCSV,
  DateInput,
  SimpleFormIterator,
  ArrayInput,
} from 'react-admin';

import { useEffect } from 'react';

import jsonExport from 'jsonexport/dist';

import { AccordionSection  } from '@react-admin/ra-form-layout';


import { makeStyles } from '@material-ui/core/styles';

import Divider from '@mui/material/Divider';


import { useTranslate  } from '../common/language';

import Typography from '@mui/material/Typography';


import RichTextInput from 'ra-input-rich-text';


import { TextFieldShow } from '../components/TextFieldShow';

import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import GavelIcon from '@material-ui/icons/Gavel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Chip from '@material-ui/core/Chip';

import { ColorInput } from 'react-admin-color-input';

import { DocsList, DocsListShow } from './docs';
import { targets, modules, choicesClassroomsType  } from './../common/constants';
import { dateOnlyToCatalan, subscriptionModule, setActivePermissionData, structArrayToIdList } from './../common/utils';  

import { CityInput } from './../components/Inputs';
import { ExportButton } from './../components/ExportButton';
import { BackLink } from './../components/BackLink';
import { RenderBoolean } from '../components/User';
import { chipsRealState, RealStateInput } from '../resources/real_state';

import Box from '@material-ui/core/Box';

import { Link } from 'react-router-dom';

import { validateMaxLength, validateInteger  } from './../components/Validator';


export const InsurancesList = (props) => {
  const translate = useTranslate();  
    
  let basePath='/insurances'; 
  let resource = 'insurances';
  let strRedirect = 'insurances';
  let real_state_id = props.real_state_id ? props.real_state_id : 0;
    
  return (
    <ResourceContextProvider value={resource}>
      <List 
        basePath={basePath}   
        filter={{ associations_id : localStorage.getItem('associations_id'), real_state_id: real_state_id }}  
        actions={props.show ?  <span/> : <ListActions />}    
        empty={props.show ?  <span/> : <ListActions />}        
        bulkActionButtons={false}          
        perPage={1000}   
        pagination={false}
        sort={{ field: 'id', order: 'ASC' }} 
        label=""  
        title=""  
        resource={resource}
        filters={props.show ?  <span/> : <MyFilter />} 
        filterDefaultValues={ { only_current : props.show ? false : true, q: "" }}
      >        
        <Datagrid 
          rowClick={props.show ? null : 'edit'}
          expand={<InsurancesDetails />} >  
          <Avatar><GavelIcon/></Avatar>                                  
          <TextField source="name" label={translate('common.name')} sortable={false} />  
          { subscriptionModule(modules.REAL_STATE) ? 
          <FunctionField render={record => chipsRealState(record.real_state) } label={translate('insurances.real_state')} sortable={false}  />  
          : null }
          <DateField source="date_start" label={translate('insurances.date_start')} sortable={false} /> 
          <DateField source="date_finish" label={translate('insurances.date_finish')} sortable={false} /> 
        </Datagrid>        
      </List>
    </ResourceContextProvider>
  );
};

const MyFilter = (props) => {
    const translate = useTranslate(); 
    return (
    <Filter {...props}>         
        <TextInput label={translate('common.search')} source="q" alwaysOn validate={[validateMaxLength(50,translate('common.validation.maxLength'))]} resettable />    
        <BooleanInput label={translate('insurances.filter_only_current')} source="only_current" alwaysOn />        
    </Filter>
);
};

export const InsurancesDetails = ({ id, record, resource, ...props }) => {
  const translate = useTranslate(); 
        
  return (
    <div className="usersClassroomsExpand">        
      <Grid container spacing={2}>
        <Grid item xs={12}>   
          <TextFieldShow source={record.name} label={translate('common.name')} />
        </Grid> 
        <Grid item xs={2}>                    
          <TextFieldShow source={dateOnlyToCatalan(record.date_start)} label={translate('insurances.date_start')} /> 
        </Grid>
        <Grid item xs={2}>                      
          <TextFieldShow source={dateOnlyToCatalan(record.date_finish)} label={translate('insurances.date_finish')} /> 
        </Grid>        
        <Grid item xs={2}>                     
          <TextFieldShow source={dateOnlyToCatalan(record.date_alert)} label={translate('insurances.date_alert')} /> 
        </Grid>                   
        <Grid item xs={2}>                     
          <TextFieldShow source={dateOnlyToCatalan(record.date_renewal)} label={translate('insurances.date_renewal')} /> 
        </Grid>
        <Grid item xs={4}>                     
          <TextFieldShow source={record.renewal_duration} label={translate('insurances.renewal_duration')} /> 
        </Grid>
        <Grid item xs={12}>                    
          <TextFieldShow source={record.comments} label={translate('insurances.comments')} />                       
        </Grid>
      </Grid>
      
      { subscriptionModule(modules.REAL_STATE) ? <>
      <Typography variant="h6" className="grey8 titleUserShow">                        
        {translate('agreements.real_state')}
      </Typography>
      <Divider fullWidth className="" />      
      <br />
      {chipsRealState(record.real_state)}
      </> : null }
                                
      <Typography variant="h6" className="grey8 titleUserShow">                        
        {translate('insurances.docs')}
      </Typography>
      <Divider fullWidth className="" />
      <br />  
      <DocsListShow target={targets.INSURANCE} items_id={record.id} />
                
    </div>
  );
};

const exporter = (items, translate, notify) => {
    
  const itemsForExport = items.map(item => {
    var itemForExport = {
      id : item.id,
      name: item.name,
      real_state: subscriptionModule(modules.REAL_STATE) ? structArrayToIdList(item.real_state) : '',
      date_start: dateOnlyToCatalan(item.date_start),
      date_finish: dateOnlyToCatalan(item.date_finish), 
      date_alert: dateOnlyToCatalan(item.date_alert), 
      date_renewal: dateOnlyToCatalan(item.date_renewal), 
      renewal_duration: item.renewal_duration, 
      comments: item.comments
    };
    return itemForExport;
  });
  jsonExport(itemsForExport, {
    headers: ['id', 'name', 'real_state','date_start','date_finish','date_alert','date_renewal','renewal_duration','comments'],
    rename: [translate('common.id'),translate('common.name'), translate('insurances.real_state'), translate('insurances.date_start'), translate('insurances.date_finish')
    , translate('insurances.date_alert'), translate('insurances.date_renewal'),translate('insurances.renewal_duration'), translate('insurances.comments')],        
        
  }, (err, csv) => {
    const csv_new = '\uFEFF' + csv;
    downloadCSV(csv_new, translate('insurances.exporter_file_name') ); 
    notify(translate('common.download_ok'),"success");
  });
};


const ListActions = (props) => {
    const translate = useTranslate(); 
    
    let filterExport = { 
        associations_id: localStorage.getItem('associations_id'),        
        q: props.filterValues && props.filterValues.q ? props.filterValues.q : '',
        only_current: props.filterValues && props.filterValues.only_current ? props.filterValues.only_current : false
    };
    
    return (
    <TopToolbar>     
        <MyCreateButton {...props} />    
        <ExportButton maxResults="100000" filterValues={filterExport} alwaysEnabled={false} exporter={exporter} sort={{ field: 'id', order: 'DESC' }} /> 
    </TopToolbar>
    );
};

export const MyCreateButton = ({ users_id , ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('insurances');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  
  const redirect = useRedirect();
        
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {                    
          redirect('/insurances/' + data.id);
        
        },
        onFailure: ( error ) => {
          notify( error.message , 'error');
        }
      }
    );
  };
    
  return (
    <>            
      <Button onClick={handleClick} label={translate('insurances.create')}>
        <IconContentAdd />                
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('insurances.create')}
      >
        <DialogTitle>{translate('insurances.create')}</DialogTitle>

        <FormWithRedirect
          save={handleSubmit}
          initialValues={{ associations_id: localStorage.getItem('associations_id') }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>
                <Grid container spacing={1}>                                     
                  <Grid item xs={12}>                                               
                    <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
                  </Grid>
                </Grid> 
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

const MyActions = ({ basePath, data, resource }) => {

  let backLink = '/insurances';
  return <BackLink link={backLink} title={ data && data.name ? data.name : null } />;
        
};


export const InsurancesEdit  = ({ id, ...props }) => {

  const translate = useTranslate();        
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  let strRedirect =  `/insurances/${id}`;    
    
  const onSuccess = ({ data }) => {
    notify(translate('common.notification.updated'));        
    redirect('/insurances');        
  };
        
  return (

    <Edit 
      title={<ElementTitle {...props}  />} 
      {...props} 
      id={id}
      mutationMode="pessimistic"
      actions={<MyActions {...props} />}
      onSuccess={onSuccess}   
    >
        
      <TabbedForm        
        toolbar={<FormToolbar />}
      >    
        <FormTab
          label={translate('classrooms.data')}
          contentClassName={classes.tab}
          path="" 
                
        > 
               
          <FormDataConsumer>
            {({ formData, ...rest }) => 
              <>                              
                <br/>
                <Grid container spacing={2}>
                  <Grid item xs={12}>   
                    <TextInput fullWidth autoFocus source="name" label={translate('common.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]}  />
                  </Grid> 
                  <Grid item xs={2}>
                    <DateInput source="date_start" fullWidth label={translate('insurances.date_start')} />
                  </Grid>
                  <Grid item xs={2}>
                    <DateInput source="date_finish" fullWidth label={translate('insurances.date_finish')} />
                  </Grid>
                  <Grid item xs={2}>
                    <DateInput source="date_alert" fullWidth label={translate('insurances.date_alert')} />
                  </Grid>
                  <Grid item xs={2}>
                    <DateInput source="date_renewal" fullWidth label={translate('insurances.date_renewal')} />
                  </Grid>
                  <Grid item xs={4}>   
                    <TextInput fullWidth autoFocus source="renewal_duration" label={translate('insurances.renewal_duration')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]}  />
                  </Grid> 
                  
                  { subscriptionModule(modules.REAL_STATE) ? 
                  <Grid item xs={12}>
                    <AccordionSection className="accordion" label={translate('agreements.real_state')} fullWidth defaultExpanded={true}>
                        <ArrayInput source="real_state" label="">
                            <SimpleFormIterator 
                              disableReordering
                              getItemLabel={index => ''}
                            >
                              <RealStateInput source="id" disabled={false} label={translate('agreements.real_state')} allowEmpty={false} required={true} resettable={false} />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </AccordionSection>   
                  </Grid>   
                  : null }
                  
                  <Grid item xs={12}>   
                    <TextInput fullWidth autoFocus source="comments" label={translate('insurances.comments')} />
                  </Grid>
                  
                </Grid>                                

              </>
            }
          </FormDataConsumer>

        </FormTab> 
        
        <FormTab
            label={translate('insurances.docs')}
            contentClassName={classes.tab}
            path="docs"                 
        > 
            
          <DocsList target={targets.INSURANCE} items_id={id} />
          <br />
          <br />
        </FormTab>
    
      </TabbedForm>
    </Edit>
  );
};

const ElementTitle = ({ record, ...props }) => {    
  const translate = useTranslate(); 
  return (<span>{translate('common.edit')} {record ? `"${record.name}"` : ''}</span>);
};

const FormToolbar = ({ id , ...props }) => {
    
  let redirect = '/insurances';
    
  return (
    <Toolbar {...props} className="user_form_toolbar" >
      <SaveButton />
      <DeleteButton redirect={redirect} mutationMode="pessimistic" />
    </Toolbar>
  );
};


const useStyles = makeStyles({
  comment: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    maxWidth: '100%',
    display: 'block',
  },
});


export const InsurancesCreate  = ({ id, ...props }) => {

  const translate = useTranslate();   
  const [info, setInfo] = useState();
    
  return (

    <Create {...props} >
      <SimpleForm 
        initialValues={{ associations_id: localStorage.getItem('associations_id') }}
        redirect="list"
      >                
        <Grid container spacing={2}>
          <Grid item xs={12}>                    
            <Typography variant="h5" className="grey">{translate('insurances.create')}</Typography>
          </Grid>  
          <Grid item xs={11}>                    
            <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
          </Grid>          
        </Grid>   

      </SimpleForm> 
    </Create>
  );
};
