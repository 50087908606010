import * as React from 'react';


/*import { memo } from 'react';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';
import { useRecordContext } from 'ra-core';

import PropTypes from 'prop-types';

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};


var fieldPropTypes = {
    addLabel: PropTypes.bool,
    sortBy: PropTypes.string,
    sortByOrder: PropTypes.oneOf(['ASC', 'DESC']),
    source: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    sortable: PropTypes.bool,
    className: PropTypes.string,
    cellClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    textAlign: PropTypes.oneOf([
        'inherit',
        'left',
        'center',
        'right',
        'justify',
    ]),
    emptyText: PropTypes.string,
};


var sanitizeFieldRestProps = function (_a) {
    var addLabel = _a.addLabel, allowEmpty = _a.allowEmpty, basePath = _a.basePath, cellClassName = _a.cellClassName, className = _a.className, emptyText = _a.emptyText, formClassName = _a.formClassName, fullWidth = _a.fullWidth, headerClassName = _a.headerClassName, label = _a.label, linkType = _a.linkType, link = _a.link, locale = _a.locale, record = _a.record, refetch = _a.refetch, resource = _a.resource, sortable = _a.sortable, sortBy = _a.sortBy, sortByOrder = _a.sortByOrder, source = _a.source, textAlign = _a.textAlign, translateChoice = _a.translateChoice, props = __rest(_a, ["addLabel", "allowEmpty", "basePath", "cellClassName", "className", "emptyText", "formClassName", "fullWidth", "headerClassName", "label", "linkType", "link", "locale", "record", "refetch", "resource", "sortable", "sortBy", "sortByOrder", "source", "textAlign", "translateChoice"]);
    return props;
};

export var TextFieldShow2 = memo(function (props) {
    var className = props.className, source = props.source, emptyText = props.emptyText, rest = __rest(props, ["className", "source", "emptyText"]);
    var record = useRecordContext(props);
    var value = get(record, source);
    return (React.createElement(Typography, __assign({ component: "span", variant: "body2", className: className }, sanitizeFieldRestProps(rest)), value != null && typeof value !== 'string'
        ? JSON.stringify(value)
        : value || emptyText));
});
// what? TypeScript loses the displayName if we don't set it explicitly
TextFieldShow.displayName = 'TextFieldShow';
// @ts-ignore
TextFieldShow.defaultProps = {
    addLabel: true,
};
// @ts-ignore
TextFieldShow.propTypes = __assign(__assign({}, Typography.propTypes), fieldPropTypes);*/

export var TextFieldShow = function (props) {
  var className = props.className, source = props.source, label = props.label,  emptyText = props.emptyText;
  /*var record = useRecordContext(props);
    var value = get(record, source);*/
  let link_with_protocol = '';
  if (props.link && source && source.length > 0 )
  {
    if (source.includes('https://') || source.includes('http://')) link_with_protocol = source;
    else link_with_protocol = 'https://' + source;
            
  }
  return (<><br/><span className="custom-field-title grey6">{label}</span><br/>{ props.link ? <a href={link_with_protocol} target="_blank">{source}</a> : source }</>);
};

export var TextHtmlShow = function (props) {
  var className = props.className, source = props.source, label = props.label,  emptyText = props.emptyText;
  
  return (<div dangerouslySetInnerHTML={{ __html: source }} />);
};

