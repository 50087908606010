
import { email
} from 'react-admin';

var EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape


export const validateMaxLength = (max, message = '') => {    
  let msg = message.replace('%{max}', max);
  return (
    value => value && value.length > max ? msg : undefined
  );
};

export const validateMinMaxLengthAndCharacters = (message = '') => {  
    
  let msg = message.replace('%{max}', 15);
  msg = msg.replace('%{min}', 8);
    
  var decimal=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    
  return (value => value && value.match(decimal) ? undefined : msg);

};

/*
export var regex = lodashMemoize(function (pattern, message) {
    if (message === void 0) { message = 'ra.validation.regex'; }
    return function (value, values) {
        return !isEmpty(value) && typeof value === 'string' && !pattern.test(value)
            ? getMessage(message, { pattern: pattern }, value, values)
            : undefined;
    };
}, function (pattern, message) {
    return pattern.toString() + message;
});*/

/*export const validateEmail = ( message = '') => {    
    return (
    value => value && !email() ? message : undefined
    );
};*/
export const validateEmail = () => {    
  return (
    email()
  );
};

export const validateTrue = (message = '') => {
    
  return (
    value => !value ? message : undefined
  );
};


export const validateInteger = (message = '') =>
{    
  return (
    value => value && ( isNaN(Number(value)) || !(isNaN(Number(value))) && (value > 10000000 || value < 0) ) ? message : undefined
  );
};

export const validateIntegerLowerOrEqualThan = (limit,message = '') =>
{    
  let msg = message.replace('%{max}', limit);
  return (
    value => value && ( isNaN(Number(value)) || !(isNaN(Number(value))) && (value > limit ) ) ? msg : undefined
  );
};

export const validateDouble = (message = '') =>
{    
  return (
    value => value && ( isNaN(Number(value)) || !(isNaN(Number(value))) && (value > 100000000 || value < -100000000) ) ? message : undefined
  );
};

export const validateDoubleGreatZero = (message = '') =>
{    
  return (
    value => value && ( isNaN(Number(value)) || !(isNaN(Number(value))) && (value <= 0 ) ) ? message : undefined
  );
};

export const validateDoubleGreatOrEqualThan = (limit, message = '') =>
{    
  return (
    value => value && ( isNaN(Number(value)) || !(isNaN(Number(value))) && (value < limit ) ) ? message : undefined
  );
};
    
export const validateNumber = (message = 'Must be a number') =>
  value => value && isNaN(Number(value)) ? message : undefined;
    
export const validatePercent = (message = '') =>
{    
  return (
    value => value && ( isNaN(Number(value)) || !(isNaN(Number(value))) && (value > 100 || value < 0) ) ? message : undefined
  );
};