
import { Tooltip as TooltipSvg } from 'react-svg-tooltip';


const MapPoint = (props) => {
        
  let point_x = props.point.nitems>=10 ? props.point.x-7.5 : props.point.x-3.5;
  let point_y = props.point.y+4;
        
  const id_circle = 'circle-' + props.point.id;
  const id_text = 'text-' + props.point.id;
    
  return (
    <>
      <circle cx={props.point.x} cy={props.point.y} r="10" stroke="black" strokeWidth="1" fill="black" id={id_circle} />
      <text className="markPoint" x={point_x} y={point_y} fill="white" id={id_text} >{props.point.nitems}</text>
    </>
  );
};


const TooltipRegion = (props) => {

  let length = (props.point.name.length+5) * 8;

  return (
    <TooltipSvg triggerRef={props.reference} >
      <rect
        x={15}
        y={2}
        width={length}
        height={30}
        rx={0.5}
        ry={0.5}
        fill="white"
        strokeWidth="1"
        stroke="black"
      ></rect>
      <text x={25} y={22} fontSize={14} fill="black" className="tooltip">
        {props.point.name} ({props.point.nitems})
      </text>
    </TooltipSvg>   
  );
};


export const MapCatalonia = (props) => {    
  return (
    <>	

      <g id="zone-2" className={props.hover==2 ? 'zone hover' : 'zone'} ref={props.points[1].pathRef} >
    
        <path d="M20.3,343.2l5.2,1l0.6,5.2l3.9,1.9l1-2.3l6.5-0.6c0,0,2.6,4.6,3.9,4.6h7.8l10.4-1l2.9,6.2l8.5-0.7l5.2,0.7
		h6.5l3.9,2.6l7.2-4.2l6.8-0.6l0.3,2l-5.5,4.8l-8.5,3.9l-4.2,3.9l-8.2,11.4l-9.8,0.3l0.4-4.2l7.1,3.3l8.4-12h-6.5l-2.6,2.6l-8.2-0.3
		l-2.6,4.2l-5.8,5.8l-1.7,5.5l-10-3.6l0.3-4.6l-5.5-5.5l-8.5-0.3l-6.5-5.5l-0.3-3.9l3.9-3l-4.2-3.6l-2.6,1l-1.6-2l1-2.9l-3.9,0.6
		l-4.6-2.6l6.8-4.5L20.3,343.2z"/>
        <path d="M89.6,331.2l7.8-10.1l-5.2-0.3L86,323l-12.3-3.9l-5.2,2.9l-5.5-1.9l1.3-4.9l-3.2-2l1.9-2.9l-1-4.9l-0.5,0.2
		l0.5,0.5l-5.8,5.2l-4.9,4.2l-12,1.6l-2.6,3.3v7.5l-10,11.4l-1-1l-4.9,4.6l0,0.4l4.8,0.9l0.6,5.2l3.9,1.9l1-2.3l6.5-0.6
		c0,0,2.6,4.6,3.9,4.6h7.8l10.4-1l2.9,6.2l8.5-0.7l5.2,0.7h6.5l3.9,2.6l7.2-4.2l0.6-0.1l-1.6-1.9l-6.5-10.1l-4.2,1.3l5.2,2.3
		l-4.2,3.2l-5.5-4.2l-0.3-4.6l5.2-2.6l0.6-3.2L89.6,331.2z"/>   
        <path d="M38.6,271.6v4.6l7.8-3.3l4.6,5.8c0,0,3-1.9,3-0.6c0,1.3-0.1,5.8-0.1,5.8l-1,3.9l4.6,6.2l-3.6,6.5l3.3,0.3
		l4.9,5.2l-5.8,5.2l-4.9,4.2l-12,1.6l-2.6,3.3v7.5l-10,11.4l-3.3-3.5l-0.6-7.2l-1.9-0.6l2.3-5.8l4.2-1.3l-0.6-14.7l-5.9-3.9
		l-0.3-6.8l-2.6-1.6l0.6-4.2l2.3-3.2l3,2.3l1.3-3.6l5.5-0.3l0.3-6.5l1.3-6.5H38.6z"/>
        <polygon points="120.9,260.6 114.3,256.7 108.8,261.9 108.4,264.2 111.4,264.5 111.7,269 103.2,272.6 106.2,275.6 
	105.5,279.8 101.6,279.4 101.3,282.1 104.3,285 101.6,292.5 94.2,291.8 95.1,295 99.7,297 97.7,302.9 92.9,301.9 87.7,307.4 
	87.3,314.6 89.9,318.1 86.7,326 94.8,323 104.9,313.9 109.1,307.7 115,305.1 118.5,302.6 125.3,302.6 128.2,300.8 127.7,300.9 
	125.7,297.4 126.4,292.8 129.9,294.7 130.2,290.8 133.5,294.1 137.7,290.5 132.5,286 137.7,280.1 136.4,277.8 136.4,277.8 
	135.1,277.2 131.6,270 121.8,269 122.1,264.2 119.8,261.2 "/>
        <path d="M62,306.1 M99.7,297l-4.6-2l-0.3-1.1l-2.3,1.1l-1.6,6.2l-3.9-4.6l-5.5-2.3l-3.9-3.9l1.3-2.6l-3-2.6v-2.6
		l-1.6-3l3.9-0.6l0.6-4.2l-1.9-0.4V272l2.3-1.9l-2.3-3.9l4.2-3.3l1.1-1l-4.3-0.9l-5.2,1.3l-5.8,1.7l-2.4-4.1l-0.2,0.5l-2.3-1.9
		l-4.6,5.5l-2.6,0.3l-4.9-7.8l-3.6,3.9l1.3,3.2l-1,2.6l-6.8-1l-1.3,6.5v4.6l7.8-3.3l4.6,5.8c0,0,3-1.9,3-0.6c0,1.3-0.1,5.8-0.1,5.8
		l-1,3.9l4.6,6.2l-3.6,6.5l3.3,0.3l4.4,4.7l0.5-0.2l1,4.9l-1.9,2.9l3.2,2l-1.3,4.9l5.5,1.9l5.2-2.9L86,323l2.2-0.8l1.7-4.1l-2.6-3.5
		l0.4-7.2l5.2-5.5l4.8,1L99.7,297z"/>
        
        <polygon points="164.5,274.1 162.4,273.9 157.9,272 156.6,274.2 158.5,276.9 155.9,278.8 150.7,273.6 146.8,278.5 
	145.2,276.2 143.9,278.8 137.7,275.2 136.4,277.8 137.7,280.1 132.5,286 137.7,290.5 133.5,294.1 130.2,290.8 129.9,294.7 
	126.4,292.8 125.7,297.4 127.7,300.9 131.9,299.9 135.1,304.8 137.7,297 148.5,293.8 153,291.8 163.7,291.5 174.5,285.6 175,284.8 
	174.5,285 173.2,282.1 173.5,279.8 168.9,279.4 167.6,281.4 165.7,278.8 162.8,279.1 163.7,274.5 "/>
        <path d="M137.7,233.9l-8.8,3l-8.4-5.2l0.6,5.2l3.2,2.9l-8.1,8.8l-3.9,2.6l-1.6,4.6l-6.2,1v3.6l4.1,2.1l0.1-0.5
		l5.5-5.2l6.6,4l1.9-1l7.8,4.9l4.9-3.3l4.6,0.3l4.2-9.4l8.1-9.4l6.5,1.9l3.9-2.3l7.1-1.7l2.3-3.5c-1.3-0.9-3.2-2.3-4.3-3
		c-1.5-1-4.3-2.5-4.1-4.1s1.4-2.3,2.6-2.8c1.2-0.5,2.3-0.6,2.8-1.2c0.5-0.7-0.5-0.8-0.8-1.1c-0.3-0.4-0.4-1.1,0.1-1.7
		s0.8-1.6-0.2-2.5c-0.8-0.6-1.7-0.4-2.3-0.1c0,0-0.7,2.5-0.7,2.5l-2.3,0.6l-4.9-1.9h-14.6l-2,3.9l-4.5,1L137.7,233.9z"/>
        <path d="M110.9,259.9L110.9,259.9l-2.1,2L110.9,259.9z M104.6,258.3h-5.2l-4.9-4.9l-2,5.9l-5.8-1.7l-5.5,5.2l-4.2,3.3
	l2.3,3.9l-2.3,1.9v2.6l1.9,0.4l-0.6,4.2l-3.9,0.6l1.6,3v2.6l3,2.6l-1.3,2.6l3.9,3.9l5.5,2.3l3.9,4.6l1.6-6.2l2.3-1.1l-0.6-2.1
	l7.5,0.6l2.6-7.5l-3-2.9l0.3-2.6l3.9,0.3l0.6-4.2l-3-3l8.5-3.6l-0.3-4.5l-3-0.3l0.3-1.8l-4.1-2.1V258.3z"/>
        <text transform="matrix(1 0 0 1 39.3697 367.3295)" >MONTSIÀ</text>
        <text transform="matrix(1 0 0 1 46.0831 336.7045)" >BAIX EBRE</text>
        <text transform="matrix(1 0 0 1 80.733 277.0365)">PRIORAT</text>
        <text transform="matrix(1 0 0 1 60.7408 295.6215)" >RIBERA </text>
        <text transform="matrix(1 0 0 1 60.9371 302.6215)" >D’EBRE</text>
        <text transform="matrix(1 0 0 1 111.3087 285.4154)" >BAIX </text>
        <text transform="matrix(1 0 0 1 109.944 292.4154)" >CAMP</text>
        <text transform="matrix(1 0 0 1 138.5177 287.3686)">TARRAGONÈS</text>
        <text transform="matrix(1 0 0 1 141.6471 233.6361)" >CONCA</text>
        <text transform="matrix(1 0 0 1 134.4581 240.6361)" >DE BARBARÀ</text>
        <text transform="matrix(1 0 0 1 33.0699 300.1498)" >TERRA</text>
        <text transform="matrix(1 0 0 1 35.338 307.1498)">ALTA</text>
        <MapPoint point={props.points[1]} />
      </g>
      <g id="zone-3" className={props.hover==3 ? 'zone hover' : 'zone'} ref={props.points[2].pathRef} >
        <path d="M126.5,25.7l-0.2-0.7l-4-0.8l-3.4,1.3l-2.3-3.6c0,0-2.9,2.3-4,2.9c-1,0.5-4.5-5-4.5-5l-2.6,0.3l-6.3-3.6h-3.7
		l-4.7-1.6l-1.8-2.9l-4.2,0.3l-4.2,3.4l1.6,2.6l-0.8,4.2L78.9,25l2.3,2.7l-3.6,3.1l4.2,3.7l1.3,8.7l3.6,7.3l2.4,2.3h5.5l3.1-1.6
		l1.1,2.3h2.9l0.5-1.6l3.2-2.6l1.8,1l-0.5,2.9l2.3,0.3l2.7,2.9l0.8,0.1l1.8-2.4l2.1-0.3l1.3-14.2l3.7-2.6l2.9,1.3V25.8L126.5,25.7z"
        />
        <path d="M171.3,57.9l2.3-2.1l-3.4-4l-0.3-4.5l-2.1-1.6l-1-4.7H165l-3.2-2.3l1.6-1.9l-3.9-1.6l-3.2,1.6l-5.5,0.6
		l-2.1-2.4l-2.1,1l-3.6,0.3l-2.1,1.9l-3.6-2.7l-0.8-6.6l-6-3.4l-5.8,0.3v12.7l-2.9-1.3l-3.7,2.6L116.5,54l-2.1,0.3l-1.8,2.4l7.6,0.9
		l0.4-0.9c1.8,1.5,3,2.6,3,2.8c0,0.5-3.2,2-5.6,3.1l1.2,2.1l2.4,0.5l1.6,3.9l-1.3,11.8l2.3,1.9l-5.8,9.2l-0.8,5h-3.4v1.6l2.4,2.3
		l-0.3,1.6l2.3,0.3l3.2,2.7l7.1,1.6h4.2l3.6,1.6l1.1-0.3V108l-2.7-1.6v-2.9l4-1.9c0,0,1.2-5,2.3-5.8c1.1-0.8,5.5-2.6,5.5-2.6
		l0.8-6.9l4-3.1v-3.4L156,79l1.6,1.8l1.9-3.6l3.9-4l-0.3-4c0,0,2.9,1.6,3.4,0.8c0.5-0.8,3.4-5.2,3.4-5.2s0.2,0,0.6,0l0.3-1.6
		l2.9-2.6L171.3,57.9z"/>
        <path d="M83.6,71.9l-0.5,1.8l0.5,3.2l4.5,7.1L86.5,87l3.2,2.3l-2.9,3.2l2.9,1.6l2.9-3.2l3.7,2.4l-1.6,1.8l-5.5,0.3
		l-1,1.3l2.6,3.7l5-0.5l5.8,1l2.4,1.8c0,0,2.1-0.8,2.1-1.8c0-1-0.3-5-0.3-5l-2.1-3.4l-2.6-1.1l-2.3-3.1l0.3-6l4.7,0.3v-3.7l4-4.7
		l4.2,0.5l-0.8-5.3l4.7-2.1L115,64c0,0,8.6-3.6,8.6-4.4c0-0.2-1.2-1.3-3-2.8l-0.4,0.9l-8.4-1l-2.7-2.9l-2.3-0.3l0.5-2.9l-1.8-1
		l-3.2,2.6l-0.5,1.6h-2.9l-1.1-2.3l-3.1,1.6h-1.8L92,53.7v5.6l-2.6,1.8l0.8,2.3L85.4,68l2.1,2.1l-1.4,2.7L83.6,71.9z"/>
        <path d="M233.8,104.8c-0.2,0.1-0.3,0.2,0,0.4c0.4,0.4,0.7,0.4,1.2,0.7C235.8,106.2,234.9,105.6,233.8,104.8 M237.4,105
		l4.1-7.9l-4.2-1.6c0,0-3.4-1.4-2.9-3.7s-1-7.3-1-7.3L221,79l-1.4-3.4l-9.4-1l-5.3-2.1l-6.6,0.8l0.3,3.2l-9.2,1.6l2.1,3.7l0.3,4.4
		l6.4,3.2l0.5,7.9l-1.4,7.4l0.3,3.9c0,0,1.1,0.3,2.6,0.8c-0.2-0.8-0.8-1.6-0.8-1.6l4-0.1l3.4-1.6l3.8,0.9l2-1.9l2.7,0.3l11.1-3.8
		l5,1.6c0,0,1.4,0.9,2.5,1.7C234.5,104.6,236.5,104.9,237.4,105"/>
        <path d="M190.9,80.6l-4.1,0.5c0,0-2.1,2.4-2.9,2.9c-0.8,0.5-6-1-6-1l-5.5-1.6l0.3-4.5l-2.9-2.9l7.7-2.6
		c0,0-2.9-4.5-3.4-5.5c-0.5-1-4-1-4-1s-2.9,4.4-3.4,5.2c-0.5,0.8-3.4-0.8-3.4-0.8l0.3,4l-3.9,4l-1.9,3.6L156,79l-4.2,0.8v3.4l-4,3.1
		l-0.8,6.9c0,0-4.4,1.8-5.5,2.6c-1.1,0.8-2.3,5.8-2.3,5.8l-4,1.9v2.9l2.7,1.6v5l-2.1,1.9l3.4,3.6l0.8,1.9l-2.9,0.8l4.4,3.6l-3.6,2.7
		l-1,5.5l0.3,5.5l7.3,0.5c0,0,2.3,3.4,2.9,5c0.5,1.6,0,3.4,0,3.4l-1,2.4l3.4,2.9l-2.1,6.9l1.1,3.6l4.2-3.6l3.4-5l5.3-2.7l0.5-11.6
		l-2.3,0.8l-2.1-3.9l0.5-3.7l4-3.4l-1-2.3l10-0.3l1.6,1.8l8.7-7.1l0.8-2.9l5.3,0.3l3.6,1.1l13.5-7.4l-0.7-5.9l-0.3,0.2l-4,0.1
		c0,0,0.6,0.8,0.8,1.6c-1.5-0.4-2.6-0.8-2.6-0.8l-0.3-3.9l1.4-7.4l-0.5-7.9l-6.4-3.2l-0.3-4.4L190.9,80.6z"/>        
        <path d="M152.3,169.2l-1,2.6l-6.2,6.5l-0.3,3.6l-3.9-0.3l-3.9,5.8l0,0l1,4.2c0,0-2.4,4.9-1.7,6.2
		c0.7,1.3,2.6,2.3,2.6,2.3l3.2-2.9l-2.9,5.9l0.6,9.1l-3.9,3.6l6.9,2.9l0.3,3.3h14.9l4.9,1.9l2.3-0.6l0.6-2.5
		c-0.7,0.3-2.2,0.7-1.9-0.3c0.3-1,0.5-1.7,0.7-2.4s-0.2-1.1-0.9-1.1s-2.4-1.1-2.6-1.8c-0.2-0.7,0.4-2.1,1.2-2.5
		c0.9-0.5,2.4-1.1,5-1.2c2.6-0.1,2.4-0.3,2.6-0.6c0.2-0.3-1.7-3.5-2.1-5.5c-0.4-1.9-1.5-2.5-1.9-3c-0.4-0.5-3.2-2-2.6-3.1
		c0.7-1.1,2.3-2.8,2.3-3.8c0.1-1-0.4-4.6-0.2-5.6c0.3-1,1.8-3.3,2.3-3.8c0.6-0.5,1.1-1.2,2.9-0.9c0.5,0.1,1.4,0.3,2.3,0.5l3.8-3.9
		l-1.9-2.9l2.4-2.6l1.8-7.2l-4.5-0.5c0,0-1,3.4-1.3,4.5c-0.3,1-4.7-3.1-4.7-3.1l-0.5-2.4l-4.2-2.6l-2.9,0.5l-0.8,3.4l-4.2,2.4
		c0,0-0.5-2.1-0.8-3.2c0-0.1,0-0.2-0.1-0.3L152.3,169.2z"/>    
        <path d="M121,232l-0.5-0.3L121,232z M143.6,221.9h-0.3l-0.3-3.3l-6.9-2.9l3.9-3.6l-0.6-9.1l2.9-5.9l-3.2,2.9
		c0,0-1.9-1-2.6-2.3c-0.7-1.3,1.7-6.2,1.7-6.2l-1-4.2l-3.6-0.3l1-3.6l-1.3-1.6l-4.6,0.3c0,0-1.3-1,0-1.7c1.3-0.6,3.9-1.3,3.9-1.3
		V177l-3.9-3l-2.6,5.2l-2.3,6.5l-3.2,2.3l-2.3-2l-2.6,2l-2.3-1.6l0.6,2.9l-2.6,1.6l1,2.6l2.3-0.3l0,4.4l3.5,1.4l9.8-7.1l-7.1,9.4
		l-2.6,2.6l-2.6-0.6l0.3,3.9l-3,4.2l0.6,5.5l-2,2.2l2,4.3l-1,5.5l7.8,2.3c0,0,0.3,0.3,0.7,0.8l7.7,4.8l8.8-3l-0.6-7.1l4.5-1
		L143.6,221.9z"/>
        <path d="M83.8,208.6c-0.3-1,4.6-4.2,4.6-4.2v-3.9l0.1-1l-0.8,1.3l-2.3-1.9l0.6-3l-6.5,3l-3.6-3.9l0.7-2.3L66.2,179
		l-0.3-6.7l0-0.8l-0.2,0.7l-1.8-0.1l-0.3,3.6L61,179l-3.6-0.3l-4.2,5.5l0.3,3.6l-9.7-1l1,5.5l-3.6-1.7l-2.3,4.9l-4.2,4.9l0.3,3.9
		l3.6,3.9l-1,5.5h10l1,6.9l1,1.6l-8.5,10.1l-5.5-0.3l1.3,4.6v6.5l-3.2,2.6l5.9,8.5l-3,2.3l3.4,8.8l6.7,1l1-2.6l-1.3-3.2l3.6-3.9
		l4.9,7.8l2.6-0.3l4.6-5.5l2.3,1.9l0.2-0.5l0.8-1.8l0.6-8.7l4.2-0.3l2-5.2l-2.3-4.2l5.8-4.6l3.6-0.6l0.6-4.9l1.6-1.9l4.2-0.3
		l2.3-4.2l-3.6-2.6l1.9-3l3.9,1l4.6-4.6l-4.2-1.6l-2.9,3.3C87.7,215.4,84.1,209.6,83.8,208.6"/>  
        <path d="M136.1,133.1l-4.9,5.2l-0.3,3.9l-3.5,0.6l3.2,4.5l-4.2,5.2l-1-5.9l-4.8-0.3l-1,2.3l-4.5-0.6h-6.5l-2.9-4.6
		l-2.6,1.6h-3.9l-9.8-1.6c0,0-0.4-2.3-2.3-2c-1.9,0.3-4.5,0.7-4.5,0.7l-1.8-1l-3.7,4.6l-7.2,9.4l-1.9-1.3l-3.3,5.2l2.6,2.6l2.9,0.3
		l-1,5.2l2,1.6l-2.3,1.7l-2.9,1l0.3,7.5l10.4,13.7l-0.7,2.3l3.6,3.9l6.5-3l-0.6,3l2.3,1.9l1.3-2.3l3.9,0.6l1.6-2.3l4.2,0.3l2.3-2.3
		l1.3-3.3l3.6,1v3.9l2.6,2.3l6.2-0.3l-0.1-5.2l-2.3,0.3l-1-2.6l2.6-1.6l-0.6-2.9l2.3,1.6l2.6-2l2.3,2l3.2-2.3l2.3-6.5l2.6-5.2l3.9,3
		v2.3c0,0-2.6,0.6-3.9,1.3c-1.3,0.6,0,1.7,0,1.7l4.6-0.3l1.3,1.6l-1,3.6l3.6,0.3l3.9-5.9l3.9,0.3l0.3-3.6l6.2-6.5l1-2.6l4.6-2.3
		l-3-8.9l-1.1,1.6l-4.2,3.6l-1.1-3.6l2.1-6.9l-3.4-2.9l1-2.4c0,0,0.5-1.8,0-3.4c-0.5-1.6-2.9-5-2.9-5l-7.3-0.5l-0.3-5.5L136.1,133.1
		z"/>
        <path d="M120.8,201.4l7.1-9.4l-9.8,7.1l-3.5-1.4l0,0.8l-6.2,0.3l-2.6-2.3v-3.9l-3.6-1l-1.3,3.3l-2.3,2.3l-4.2-0.3
		l-1.6,2.3l-3.9-0.6l-0.5,0.9l-0.1,1v3.9c0,0-4.9,3.2-4.6,4.2c0.3,1,3.9,6.8,3.9,6.8l2.9-3.3l4.2,1.6l-4.6,4.6l-3.9-1l-1.9,3
		l3.4,2.5l3.7-3.5l4.6,1.7l3.6-3.3l2.6,2.6l3.3-4.2l5.5,1.9l0.6,1.2l2-2.2l-0.6-5.5l3-4.2l-0.3-3.9l2.6,0.6L120.8,201.4z"/>
    
        <path d="M120.5,231.7l0.7,0.4c-0.4-0.5-0.7-0.8-0.7-0.8l-7.8-2.3l1-5.5l-2.6-5.5l-5.5-1.9l-3.3,4.2l-2.6-2.6l-3.6,3.3
		l-4.6-1.7l-3.7,3.5l0.2,0.1l-2.3,4.2l-4.2,0.3l-1.6,1.9l-0.6,4.9l-3.6,0.6l-5.8,4.6l2.3,4.2l-2,5.2l-4.2,0.3l-0.6,8.7l-0.8,1.8
		l2.4,4.1l5.8-1.7l5.2-1.3l4.3,0.9l4.4-4.2l5.8,1.7l2-5.9l4.9,4.9h5.2v-1.7l6.2-1l1.6-4.6l3.9-2.6l8.1-8.8l-3.2-2.9L120.5,231.7z"/>
        <path d="M136.7,108.7l-3.6-1.6h-4.2l-7.1-1.6l-3.2-2.7l-2.3-0.3l0.3-1.6l-2.4-2.3v-1.6h3.4l0.8-5l5.8-9.2l-2.3-1.9
		l1.3-11.8l-1.6-3.9l-2.4-0.5l-1.2-2.1c-1.6,0.7-3,1.3-3,1.3l0.8,3.2l-4.7,2.1l0.8,5.3l-4.2-0.5l-4,4.7v3.7l-4.7-0.3l-0.3,6l2.3,3.1
		l2.6,1.1l2.1,3.4c0,0,0.3,4,0.3,5c0,1-2.1,1.8-2.1,1.8l-2.4-1.8l-5.8-1l-5,0.5l-0.2-0.3l-2.5,2.9l0.3,7.2l-3.3,4.2l0.3,3.3
		l-2.6,3.6l1.3,3.5l-1.9,1.7l0.6,8.7l-2,1.7l-2.9,2.9l4.6,2.6c0,0,2.6-0.4,4.5-0.7s2.3,2,2.3,2l9.8,1.6h3.9l2.6-1.6l2.9,4.6h6.6
		l4.5,0.6l1-2.3l4.8,0.3l1,5.9l4.2-5.2l-3.2-4.5l3.5-0.6l0.3-3.9l4.9-5.2l0.8-0.3l1-5.4l3.6-2.7l-4.4-3.6l2.9-0.8l-0.8-1.9l-3.4-3.6
		l2.1-1.9v-4.5L136.7,108.7z"/>          
        
        <path d="M205.3,126c0-0.7-0.4-1.6-0.4-1.6l-2.4,1.6l-4-1.3c0,0-0.7,1-1.4,1.1c-0.6,0.1-1-0.1-1.5-0.4
		c-0.8-0.6-0.2-1.7-0.9-2.3l-3.3-2.6l-0.3,0.2l-3.6-1.1l-5.3-0.3l-0.8,2.9l-8.7,7.1l-1.6-1.8l-10,0.3l1,2.3l-4,3.4l-0.5,3.7l2.1,3.9
		l2.3-0.8l-0.5,11.6l-5.3,2.7l-3.2,4.6l0.8-1.2l0.9,2.5c0.3,0,0.6,0,0.7,0.3c0.1,0.3,0,0.9-0.1,1.6l1.5,4.4l-1.5,0.7
		c0,0.1,0,0.2,0.1,0.3c0.3,1,0.8,3.2,0.8,3.2l4.2-2.4l0.8-3.4l2.9-0.5l4.2,2.6l0.5,2.4c0,0,4.5,4.2,4.7,3.1c0.3-1,1.3-4.5,1.3-4.5
		l4.5,0.5l-1.8,7.2l-2.4,2.6l1.9,2.9l-4.1,4.3c0.2,0.3,0.3,0.7,0.2,1c-0.2,0.9-1.3,2.2-1.2,2.9c0.2,0.8,1.3,1.5,2,0.8
		c0.8-0.7,0.5-2.3,1-2.5c0.6-0.3,1.7-0.7,2.1,0.7c0.4,1.4,1.2,2.3,2.8,2.7c1.6,0.4,1.2,0.9,2.1,0.5c0.9-0.4,1.8-2.1,2.4-2.4
		c0.6-0.3,2.6-1.3,3.6-0.3c0.3,0.3,1,1.4,1.9,3c0-0.1,0-0.1,0-0.2c-0.1-0.9-1-2.3-0.9-2.8c0.1-0.9,1.4-0.8,1.2-3.1
		c-0.1-1.2,0.3-2.7,0.9-3.2c0.6-0.5,1.3-1.1,1.1-2.4c-0.1-1.2,1.2-1.4,1.6-1.5c0.4-0.2,0.2-2,0.3-2.4s0.4-1.2,0.4-1.6
		c0-0.7-1-1.3-1.5-1.6c-0.5-0.3-2-0.9-3.1-0.9c-1.2,0-1.9-0.5-1.5-1.2c0.4-0.6,2.5-2.5,3.5-3c0.9-0.5,2.9-1.9,3.4-2.3
		c0.4-0.3,1.8-2.2,2.4-3.8l-2.5-0.8v-4.2l0.4-0.9l1.9-0.1l0.5,0.9l0.9,1.2l3.9-1.5l0.7-3.2l-2.3-2l-0.8,3.2l-3.9-0.8l-1.5-1.1
		l0.7-2.2l1.7,0.4l1.8-1.9l1.2-2.7l-0.8-0.9l-0.7,0.7l-0.9-0.3l2.4-7.1l3-0.9l-2.3-5.4l1.6-4l1.8-1
		C205.1,127.4,205.3,126.8,205.3,126"/>
                
        <text transform="matrix(1 0 0 1 92.7657 171.7001)" >NOGUERA</text>    
        <text transform="matrix(1 0 0 1 81.7687 243.1352)" >GARRIGUES</text>
        <text transform="matrix(1 0 0 1 86.109 38.5673)">VALL D’ARAN</text>
        <text transform="matrix(1 0 0 1 152.2306 111.08)">ALT URGELL</text>
        <text transform="matrix(1 0 0 1 140.275 193.6869)" >SEGARRA</text>
        <text transform="matrix(1 0 0 1 134.2687 61.5063)" >PALLARS </text>
        <text transform="matrix(1 0 0 1 135.8229 68.5063)" >SOBIRÀ</text>
        <text transform="matrix(1 0 0 1 96.5807 63.9213)">ALTA</text>
        <text transform="matrix(1 0 0 1 87.7047 70.9213)" >RIBAGORÇA</text>
        <text transform="matrix(1 0 0 1 199.8727 95.351)">LA CERDANYA</text>
        <text transform="matrix(1 0 0 1 100.9908 124.5619)" >PALLARS </text>
        <text transform="matrix(1 0 0 1 104.4908 131.5619)" >JUSSÀ</text>
        <text transform="matrix(1 0 0 1 57.1217 223.4857)" >SEGRIÀ</text>
        <text transform="matrix(1 0 0 1 96.1188 204.4042)" >PLA</text>
        <text transform="matrix(1 0 0 1 88.734 211.4042)" >D’URGELL</text>
        <text transform="matrix(1 0 0 1 116.2159 220.0209)" >URGELL</text>
        <text transform="matrix(1 0 0 1 166.399 151.5473)" >SOLSONÈS</text>
        <MapPoint point={props.points[2]} />
      </g>

      <g id="zone-5" className={props.hover==5 ? 'zone hover' : 'zone'} ref={props.points[4].pathRef} >
        <path d="M252.7,264.4c-0.7-0.3-2.8-1.4-3.3-1.9c-0.5-0.5-0.3-1.1-0.6-1.9c-0.3-0.8-0.7-1.6-1.3-2.5
		c-0.5-0.9-0.3-1.7,0.3-2.3c0.6-0.7,1.2-2.1,1.1-2.6c-0.1-0.4-0.6-0.7-0.9-1.3c-0.2-0.5,0-1.9-0.5-2.2c-0.4-0.3-1.5-0.3-2.1-1
		c-0.6-0.6,0-1.5,0.2-2.1c0.2-0.6,0-0.9-0.9-1.9c-0.9-1-0.5-0.9-1.9-1.1c-1.4-0.2-3.2,0.2-6.2-0.2c-2.9-0.4-3.8-1.2-4.5-2
		c-0.8-0.7-2-1.5-3.1-2.5c-1.2-1.1-1.9-3-1.9-3.9s1.5-2,2.3-2.8c0.9-0.8,0.8-1.7,0.6-2.1c-0.1-0.4-1.2-1.3-2.2-2.9
		c-0.9-1.6-2.5-4.5-3.1-5.1c-0.5-0.5-2.8-0.8-3.5-1.3c-0.5-0.4-1.7-1.9-2.1-2.5c-0.8,0-3,0.5-4-1c-0.2-0.2-0.3-0.5-0.4-0.7l-0.9,0
		l0,1.6c0,0-1.1,4.3-0.7,5.6c0.4,1.3,3.7,5.9,4.2,6.6c0.5,0.7,1.5,1.8,0.8,2.5c-0.1,0.2-0.6,0.6-1.2,1.2c0,0,0,0,0,0
		c0.8,0.8,3.3,3.5,3.7,4.2c0.4,0.9,0.2,1.6,0.8,2.6c0.6,1,2.3,2.7,2,4.4c-0.3,1.7-2.4,5.7-2.4,7c0,1.4-0.7,2.5,0.5,3.7
		c1.2,1.2,1.2,1.5,0.5,2.7c-0.7,1.2-2.5,4-3.9,4.5c-0.8,0.3-1.5,0.5-2.1,0.4c0,0.1-0.3,1.4,0.2,1.9c0.6,0.6,2.5,1,2.6,1.7
		c0.1,0.8,0.3,1.7,1.3,1.8c1,0.1,2.9-0.8,3.8-0.6c1,0.2,2.9,0.6,3.3,1.5c0.4,1,0.5,1.5,0.3,1.6c0,0,0,0,0,0c1.1,0.3,3.5,0.7,5,0.7
		c1.8,0,4.3-0.2,5.5-0.4c1.2-0.2,3.3-0.5,4.9-1.1c1.6-0.5,3.5-1.3,4.3-1.5c1-0.2,2-0.2,2.6-0.8c0.6-0.6,1.8-0.9,2.4-1.4
		c0.7-0.5,1-0.6,1.5-1.5C254.2,265.3,253.5,264.7,252.7,264.4"/> 
        <text transform="matrix(1 0 0 1 229.1119 257.2123)" >BAIX </text>
        <text transform="matrix(1 0 0 1 220.88 264.2123)">LLOBREGAT</text>
        <MapPoint point={props.points[4]} />
      </g>
      <g id="zone-4" className={props.hover==4 ? 'zone hover' : 'zone'} ref={props.points[3].pathRef} >
        <path d="M268.4,240.4c-0.6-0.4-1.5-2-1.6-2.8c-0.1-0.8,0.1-1.8-0.4-1.9c-0.1,0-0.9-0.1-0.9-0.1
		c0.1,0.2-0.2,0.5-0.5,0.9c-0.3,0.4-0.3,0.9-0.3,1.4c0,0.5,0.2,1.3-0.7,1.3c-1,0-1.5-0.2-2.4-0.2c-0.9,0.1-1.8,0.1-2.3,0.9
		c-0.5,0.8-1,1.7-1.3,2.6c-0.3,0.9-1,1.1-1.6,1.2c-0.6,0.1-1.8,0.5-2.4,1.1c-0.6,0.5-2,1-2.8,1c-0.7,0-4.2,0.3-4.2,0.3l-1.2-0.1
		c0.1,0.2,0.1,0.4,0,0.7c-0.2,0.6-0.8,1.5-0.2,2.1c0.6,0.6,1.7,0.6,2.1,1c0.4,0.3,0.2,1.7,0.5,2.2c0.2,0.5,0.8,0.8,0.9,1.3
		c0.1,0.4-0.5,1.9-1.1,2.6c-0.6,0.7-0.8,1.4-0.3,2.3c0.5,0.9,0.9,1.7,1.3,2.5c0.3,0.8,0.1,1.4,0.6,1.9c0.5,0.5,2.6,1.5,3.3,1.9
		c0.4,0.2,0.9,0.5,1.1,0.8c0,0,0.1,0,0.1-0.1c0-0.3,1.2-3.3,1.5-4.2c0.3-0.9,1-3.1,1.2-3.5c0.2-0.5,1.3-0.8,1.5-0.2
		c0.2,0.6-1.4,2.7-1.1,2.6c0.3-0.1,1.5-2.2,1.5-2.7c0-0.5,0.2-0.8,0.4-1.5c0.2-0.7,1.4-1.3,2-2c0.5-0.7,1.4-2.3,1.8-3
		c0.4-0.7,1-1.6,1.4-1.9c0.4-0.3,0.7-0.3,1.1-1.2c0.3-0.9,2.2-2.5,2.4-3.2c0.2-0.7,1.4-2,1.4-2.5C268.9,241.2,269,240.8,268.4,240.4
		"/>
    
        <text transform="matrix(1 0 0 1 249.944 252.9066)" >BARC</text>
        <text transform="matrix(1 0 0 1 263.5518 252.9066)" className="outside">ELONÈS</text>
    
        <MapPoint point={props.points[3]} />
      </g>
      <g id="zone-6" className={props.hover==6 ? 'zone hover' : 'zone'} ref={props.points[5].pathRef} >    
        <path d="M265.5,235.6c-0.1-0.2-0.2-0.2-1-0.8c-0.7-0.6-1.7-1.9-2-2.5c-0.3-0.6-0.1-1.5,0.2-2.6c0.3-1.2,0-0.9,0.3-2.9
		c0.3-2-0.4-1.2-1-2.5c-0.6-1.3,0.2-1.4,0.3-2.1c0.1-0.7,0.3-1.3,0.2-3c-0.2-1.7-1.6-1.6-2.5-1.7c-0.9-0.1-1.9,0-2.1-1.1
		c-0.2-1.1-0.6-2.1-1.2-3.6c-0.5-1.5-2.4-2-3.5-2.5c-1.2-0.5-2.3-1.3-2.6-2.5c-0.2-1.2,0.9-1.1,2.3-1.4c1.4-0.3,1.7-1.4,1.8-2.7
		c0.1-1.2-0.6-2.9-0.8-3.5c-0.1-0.6-2.1-0.7-3.4-0.5c-1.3,0.2-1.9,0.6-2.7,0c-0.8-0.6-2.2-1.2-3.1-1.3c-0.9-0.1-4,0-4,0l-1.7,0.9
		c0.2,0.2,1.7,2.2,1.6,2.9c0,0.8-0.4,2.1-0.4,2.8c0,0.7,0,2.7-0.5,3c-0.4,0.3-4.8,1.1-5.5,1.2c-0.7,0.1-2.8-1.1-3.6-1.4
		c-0.8-0.3-3.5-0.5-3.8,0c-0.3,0.5-0.4,4.7-0.6,5.5c-0.3,0.8-2.3,2.8-3,2.8c-0.7,0-1.7,1.3-1.8,1.8c-0.1,0.6-1.8,0.6-2.3,0.6
		c-0.1,0-0.2,0-0.4,0l0,0c0.1,0,0.1,0,0.2,0c0.4,0.5,1.6,2.1,2.1,2.5c0.6,0.4,2.9,0.7,3.5,1.3c0.5,0.5,2.1,3.5,3.1,5.1
		c0.9,1.6,2,2.5,2.2,2.9c0.1,0.4,0.2,1.2-0.6,2.1c-0.9,0.8-2.3,2-2.3,2.8c0,0.1,0,0.3,0.1,0.5l1.3,2.8c0.2,0.2,0.3,0.4,0.5,0.6
		c1.2,1.1,2.3,1.8,3.1,2.5c0.8,0.7,1.6,1.6,4.5,2c2.9,0.4,4.7,0,6.2,0.2c1.4,0.2,1,0.1,1.9,1.1c0.5,0.5,0.8,0.9,0.9,1.1l1.2,0.1
		c0,0,3.5-0.3,4.2-0.3c0.7,0,2.2-0.5,2.8-1c0.6-0.5,1.8-1,2.4-1.1c0.6-0.1,1.3-0.3,1.6-1.2c0.3-0.9,0.8-1.8,1.3-2.6
		c0.5-0.8,1.4-0.8,2.3-0.9c0.9-0.1,1.4,0.2,2.4,0.2c0.9,0,0.7-0.8,0.7-1.3c0-0.5,0-1,0.3-1.4C265.2,236.1,265.6,235.9,265.5,235.6"
        />
        <path d="M310.6,204.8c-0.3-0.9-0.5-0.3-1.1-0.5c-0.6-0.3-1.3-1.1-1.3-1.9c-0.1-0.8,0.8-2,1-2.8
		c0.2-0.7,0.3-1.3-0.2-1.9c-0.5-0.7-1.4-0.5-2-0.4c-0.6,0.1-2.2,0.3-2.8,0.1c-0.6-0.2-0.5-0.8-2.3-1.9c-1.7-1.1-1.9-2.9-2.2-3.3
		c-0.3-0.4-1.8-0.1-2.6-0.5c-0.8-0.3-2-1.6-2.7-2.6c-0.7-0.9-1.1-1-1.9-1.1c-0.9-0.1-1.9-0.9-2.8-1.8c-0.6-0.7-1.6-1.6-2.1-2
		c-0.1,0.1-0.1,0.2-0.2,0.2c-0.8,0.6-2.5,1.5-3.1,1.6c-0.6,0.2-1.5,1-1.8,1.7c-0.2,0.7-0.5,2-0.6,2.5c-0.1,0.5-0.6,1.7-1.4,1.5
		c-0.8-0.2-1.4-1.1-2.2-2.3c-0.9-1.2-1.6-2.5-2.2-2.6c-0.6-0.1-1.6,0.3-2.3,0.9c-0.7,0.6-1.4,0.5-2.2,0.2c-0.8-0.3-2.2-1-2.7-0.7
		c-0.5,0.2-1.3,1.1-1.4,1.8c-0.1,0.7,0.3,2.9,0.6,3.8c0.3,0.8,1.6,2.9,1.5,3.4c-0.2,0.7-1.8,0.8-2.7,0.8l-0.2,4.6c0,0-5.9,0.4-6-0.6
		c-0.1-1-0.1-1.3-1-1c-0.9,0.3-4.9,0.3-5.5,0.4c0,0,0,0-0.1,0c0.1,0.6,0.8,2.2,0.7,3.5c-0.1,1.2-0.4,2.4-1.8,2.7
		c-1.4,0.3-2.6,0.2-2.3,1.4c0.2,1.2,1.4,1.9,2.6,2.5c1.2,0.5,3,1,3.5,2.5s0.9,2.5,1.2,3.6c0.2,1.1,1.2,0.9,2.1,1.1
		c0.9,0.1,2.3,0,2.5,1.7c0.2,1.7-0.1,2.3-0.2,3c-0.1,0.7-0.9,0.8-0.3,2.1c0.6,1.3,1.3,0.4,1,2.5c-0.3,2,0,1.8-0.3,2.9
		c-0.3,1.2-0.5,2-0.2,2.6c0.3,0.6,1.2,1.9,2,2.5c0.7,0.6,0.9,0.6,1,0.8c0.1,0.1-0.1,0.3-0.2,0.5l1.4-0.2c0,0,3.6-2.8,4.2-3.2
		c0.6-0.4,1.8,0.2,2.4,0.1c0.6-0.1,1.8-1.9,2.2-2.4s0.3-3.1,0.5-3.6c0.1-0.5,1.7-2.3,2.6-3c0.8-0.7,2.7-3.8,3.2-4.4
		c0.5-0.5,1.8-0.4,2.3-1c0.5-0.6,1.6-1.1,2.8-3c1.2-1.9,3.1-2.2,4.2-2.2c1.1,0,3.7,0.2,4.9,0.7c1.3,0.5,4.2,0,5.2-0.5
		c0.9-0.5,2-2.8,3-4c0.9-1.2,1.6-0.9,2.4-1c0.8-0.1,2.5-0.5,3.1-0.8C310.1,207.2,310.9,205.7,310.6,204.8"/>
        <path d="M330.6,206.6c-0.5-0.8,0.2-2.4,0.5-3.6c0.3-1.2,0.5-3.2,0.6-4.4c0.1-1.1-0.6-2.6-1.2-2.9
		c-0.6-0.3-3.9-0.1-5.1-0.1c-1.2,0-3,1.4-3.9,1.8c-0.9,0.4-1.8,0.9-3,1.1c-1.1,0.1-1.5,0.3-2.4,0.7c-0.9,0.4-1.6,2.6-1.9,3
		c-0.3,0.5-1,0.9-2.4,0.9c-1.1,0.1-1.4,0.8-1.5,1.2c0.1,0.1,0.2,0.2,0.2,0.4c0.3,0.9-0.5,2.4-1.1,2.8c-0.6,0.3-2.3,0.7-3.1,0.8
		c-0.8,0.1-1.4-0.2-2.4,1c-0.9,1.2-2,3.6-3,4c-0.9,0.5-3.9,0.9-5.2,0.5c-1.3-0.5-3.8-0.7-4.9-0.7c-1.1,0-3,0.4-4.2,2.2
		c-1.2,1.9-2.3,2.4-2.8,3c-0.5,0.6-1.7,0.5-2.3,1c-0.5,0.5-2.4,3.7-3.2,4.4c-0.8,0.7-2.4,2.6-2.6,3c-0.1,0.5-0.1,3.1-0.5,3.6
		s-1.6,2.2-2.2,2.4c-0.6,0.1-1.8-0.5-2.4-0.1c-0.6,0.4-4.2,3.2-4.2,3.2l-0.1,0c0.2,0.4,0.1,1.1,0.2,1.7c0.1,0.8,1,2.4,1.6,2.8
		c0.2,0.2,0.4,0.3,0.4,0.5c2.6-1,6-2.3,6.8-2.6c1.3-0.5,3.2-1,8-2.8c4.8-1.8,7.3-4.5,9.1-5.7c1.8-1.1,2.4-3.8,3.6-4
		c1.2-0.3,3.4-0.9,4.1-1.5c0.7-0.7,2.6-2.3,3.4-2.4c0.8-0.1,2.4,0,3.2-0.7c0.9-0.7,1.3-1.2,2.2-1.6c0.9-0.5,8.3-3.9,9.4-4.4
		c1.1-0.5,6-2.6,9.8-3.6c3.9-1,3.9-1.6,4-2.5C332.7,207.9,331,207.4,330.6,206.6"/>    
        
        <path d="M267.1,197c-0.2,0-0.4,0-0.6,0c-0.6,0-4.6,0.3-5.3-0.5c-0.3-0.3-0.5-3.1-0.5-3.5c0-0.4-0.6-4.7-0.6-5.1
		l-0.5-1.9l1.7-3.3v-3.4c0,0-1-1.7-1.5-1.4c-0.5,0.3-3.1,0.1-3.1,0.1s-2.1-3.9-2.5-4.6c-0.5-0.7-0.5-1.5-0.2-1.7
		c0.3-0.2,1.7-1.4,1.8-2c0.2-0.7-0.3-0.9-1.2-1c-0.9-0.1-3.4-0.3-4.6-0.2c-1.2,0.2-2.8,1.1-3,1.3c0,0-1.4,1.2-3.2,0.4
		c-0.4-0.2-0.9-0.4-1.6-0.8c-1.1,0.1-3-1.6-2.8-0.7c0.3,0.9,0.5,5.3-0.8,6.1c-1.3,0.8-4.9,4.3-3,4.6c1.8,0.3,2.5,1.7,2.8,2.1
		c0.3,0.4,2.2-0.1,2.6,0.9c0.4,1.1,1.7,2.6,0.1,2.8c-1.6,0.1-9.3,3.6-9.3,3.6l-4.3,0.7c0,0,1.2,4.5,1.7,4.5c0.5,0,2.9-0.8,4.2-0.9
		c1.3-0.1,4.2,0.7,4.1,1.4c-0.1,0.8,1.3,4.3,2,4.9c0,0,0,0,0,0l1.7-0.9c0,0,3.1-0.1,4,0c0.9,0.1,2.3,0.7,3.1,1.3s1.5,0.2,2.7,0
		c1.3-0.2,3.3-0.1,3.4,0.5c0,0,4.7-0.1,5.6-0.4c0.9-0.3,0.9,0,1,1s6,0.6,6,0.6S267.1,197,267.1,197"/> 
        
        <path d="M298.4,152.9c-0.4-0.5-0.9-1.5-0.9-2c0-0.5,0.4-1.3,0.2-1.7c-0.2-0.5-0.9-1.4-1.3-2.1
		c-0.4-0.7,0.4-2.3-0.1-2.9c-0.5-0.6-3-1.9-4.2-2.1c-1.3-0.2-2.2,0.6-2.9,1.1s-1.1,0.1-1.6-0.5c-0.5-0.5-1-2.6-1.3-3.4
		c-0.2-0.9-1.6-2.3-2-2.4c-0.5-0.1-1.7-0.3-2-0.6c-0.2-0.3,0.3-1.6,0.5-2.4c0.2-0.9-1.4-1-1.8-1.3c-0.4-0.2-2-1.5-2.2-2.3
		c-0.2-0.8,0.9-2,1.6-2.4c0.6-0.5,1.2-1.1,1.2-1.5s-1.4-1.3-2.1-1.2c-0.3,0-1.6,0.7-2,1c-0.4,0.2-2.7,1.2-3.2,1.4
		c-0.5,0.2-1.2-0.5-2-0.7c-0.9-0.2-2.2,0.5-2.3,1c-0.1,0.5,1,1.5,1.4,2.3c0.4,0.7,0,0.5-0.9,0.7c-0.9,0.2-1.6,0.5-2.5,0.2
		c-0.9-0.2-3.1-0.1-4.2,0.2c-1,0.2-2,1-2.7,1.5c-0.7,0.5-1.6,0.3-2.4-0.3c-0.7-0.6-1.6-0.6-2.7-0.6c-1.1,0-2.2,1.5-2.8,1.9
		c-0.6,0.4-2.3,0.5-2.8,0.1c-0.5-0.4-1.6-2-2.9-3.1c-1.3-1.2-2.3-0.2-2.7,0.2c-0.5,0.3-0.6,1.8-0.6,1.8l-0.4,0
		c0.1,0.1,0.1,0.1,0.1,0.1l-2.3,2.4l2.2,2l-0.8,1.1l-0.9,0.1l-1.7,2.3l-1.1-1.2l-0.3-1.1l-2,1.6l0.5,5.9l-1.8,2.7
		c0,0,0.8,0.7,1.9,0.9c1.1,0.3,0.1,3.4,0.1,3.4l-2.5,5.6c0.4,0,0.7,0,0.7,0l3.7,0.5c0,0,2-0.1,2.1,1.4c0.1,1.5-0.5,2.4-0.7,2.5
		c-0.2,0.1,0.1,1.6,0.5,2.3c0.4,0.7,2.2,3.8,1.2,4.3c-0.1,0.1,0.8,0.7,1.2,0.9c1.8,0.8,3.2-0.4,3.2-0.4c0.2-0.2,1.9-1.1,3-1.3
		s3.7,0.1,4.6,0.2c0.9,0.1,1.4,0.3,1.2,1c-0.2,0.7-1.5,1.8-1.8,2c-0.3,0.2-0.3,1,0.2,1.7c0.5,0.7,2.5,4.6,2.5,4.6s2.6,0.1,3.1-0.1
		c0.5-0.3,1.5,1.4,1.5,1.4v3.4l-1.7,3.3l0.5,1.9c0.1,0.3,0.6,4.7,0.6,5.1c0,0.4,0.2,3.1,0.5,3.5c0.7,0.8,4.7,0.5,5.3,0.5
		c0.6,0,2.9,0,3.2-0.8c0.2-0.5-1.1-2.6-1.5-3.4c-0.3-0.8-0.7-3.1-0.6-3.8c0.1-0.7,0.8-1.6,1.4-1.8c0.5-0.2,1.9,0.5,2.7,0.7
		c0.8,0.3,1.5,0.4,2.2-0.2c0.7-0.6,1.7-1,2.3-0.9c0.6,0.1,1.3,1.4,2.2,2.6c0.9,1.2,1.4,2.1,2.2,2.3c0.8,0.2,1.3-1,1.4-1.5
		c0.1-0.5,0.3-1.8,0.6-2.5c0.2-0.7,1.2-1.5,1.8-1.7c0.6-0.2,2.3-1,3.1-1.6c0.8-0.6,0.8-1.9,0.9-2.5c0.1-0.6,0.8-2.5,1.1-3
		c0.5-0.6,1.5-1,2-1c0.5,0,1.1-0.5,1.3-0.8c0.2-0.3,0.9-2.7,1-3.4c0.2-0.7-0.8-1.2-0.8-1.8c0-0.5,0.7-0.6,1.2-1.2
		c0.5-0.6,2.3-4,2.3-4.5c0.1-1-0.5-1.8-1.2-2c-0.7-0.2-1.8,0.3-2.5,0.3c-0.8,0-0.8-1.5-0.9-2.3c-0.1-1-0.7-1.2-0.9-2
		c-0.2-1.1,1.4-1,1.8-0.9c0.4,0.2,1,0.1,1.3-0.2c0.3-0.2,2.4-2.6,2.7-3c0.2-0.4-0.3-1.3-0.3-1.6c0-0.7,1.6-0.2,2-0.5
		C298.9,154.1,298.8,153.4,298.4,152.9"/>
                
        <text transform="matrix(1 0 0 1 236.8961 223.143)">VALLÈS</text>
        <text transform="matrix(1 0 0 1 230.211 230.143)">OCCIDENTAL</text>
        <text transform="matrix(1 0 0 1 270.4122 203.7953)">VALLÈS</text>
        <text transform="matrix(1 0 0 1 267.2267 210.7953)">ORIENTAL</text>
        <text transform="matrix(1 0 0 1 286.8409 220.5883)" >MARESME</text>
        <text transform="matrix(1 0 0 1 236.3917 192.329)" >MOIANÈS</text>
        <text transform="matrix(1 0 0 1 259.6602 160.661)" >OSONA</text>
        
        <MapPoint point={props.points[5]} />
      </g>
      <g id="zone-8" className={props.hover==8 ? 'zone hover' : 'zone'} ref={props.points[7].pathRef} >
        <path d="M189.9,192.4c-0.9-1.6-1.7-2.7-1.9-3c-1-1-3,0-3.6,0.3c-0.6,0.3-1.5,2-2.4,2.4c-0.9,0.4-0.5-0.2-2.1-0.5
		c-1.6-0.4-2.4-1.2-2.8-2.7c-0.4-1.4-1.5-1-2.1-0.7c-0.6,0.3-0.3,1.8-1,2.5c-0.8,0.7-1.9-0.1-2-0.8c-0.2-0.8,1-2.1,1.2-2.9
		c0.2-0.9-0.4-1.6-2.2-1.9c-1.8-0.3-2.3,0.4-2.9,0.9c-0.6,0.5-2.1,2.8-2.3,3.8c-0.3,1,0.2,4.6,0.2,5.6c-0.1,1-1.6,2.7-2.3,3.8
		c-0.7,1.1,2.2,2.7,2.6,3.1c0.4,0.5,1.5,1.1,1.9,3c0.4,1.9,2.3,5.2,2.1,5.5c-0.2,0.3,0,0.5-2.6,0.6c-2.6,0.1-4.2,0.7-5,1.2
		c-0.9,0.5-1.4,1.8-1.2,2.5c0.2,0.7,1.9,1.8,2.6,1.8s1.1,0.4,0.9,1.1c-0.2,0.7-0.4,1.3-0.7,2.4c-0.3,1,1.4,0.5,2,0.3
		c0.6-0.2,1.5-0.5,2.3,0.1c1.1,0.8,0.7,1.9,0.2,2.5c-0.5,0.6-0.4,1.3-0.1,1.7c0.3,0.4,1.2,0.5,0.8,1.1c-0.5,0.7-1.6,0.7-2.8,1.2
		c-1.2,0.5-2.4,1.2-2.6,2.8c-0.2,1.6,2.7,3.1,4.1,4.1c1.5,1,4.8,3.3,5.5,3.9c0.7,0.6,0.5,2.1,0.3,2.4c-0.2,0.3-1,1.2-0.9,2.7
		c0.1,1.4,2,1.7,3,1.7c1,0,2.4-0.2,2.8-0.6c0.4-0.4,1.4-1,2.2-1.3c0.9-0.3,3-0.3,3.9-0.4c0.9-0.1,1.5-0.8,1.6-1.2
		c0.1-0.5,0.2-1.1-0.6-2.2c-0.7-1.1-0.6-1.7-0.4-2.1c0.3-0.4,2.9-4,3.5-4.7c0.6-0.7,1.4,0,2.3,0.4c0.9,0.4,2.9,0.7,3.8,0.7
		c0.9,0.1,2.9-0.5,3.6,0.1c0.7,0.6,0.8,1.7,1.9,3.5c1.1,1.8,2.1,2.2,2.5,2.5c0.4,0.3,3,1.3,4.1,2.1c1.2,0.8,2.5,0,2.7-0.4
		c0.2-0.4,7.7-7.3,8.4-8.1c0.7-0.8-0.3-1.9-0.8-2.5c-0.5-0.6-3.8-5.3-4.2-6.6c-0.4-1.3,0.7-5.6,0.7-5.6l0.1-4
		c-0.3-1-0.4-1.8-0.4-1.9c-0.1-0.4-1.3-2.5-2-2.5c-0.8,0-3.6,0.2-4.3,0.9c-0.7,0.7-3.1,2.9-3.5,3c-0.3,0.1-1.3,0.5-2,0.1
		c-0.7-0.4-2.4-1.3-2.7-2.1c-0.3-0.8-1.3-2.7-2.1-3.1c-0.8-0.4-2.6-0.6-2.8-2.5c-0.2-1.9-0.1-3-1.5-3.2c-1.4-0.2-3.3-0.6-3.8-1.1
		c-0.6-0.6-2.5-1.9-2.5-2.8c0-0.6,0.8-0.8,1.4-1.1c0.6-0.3,2.4-0.4,2.2-1.2c-0.1-0.4-0.8-1.6-0.7-2.1
		C189.3,194.2,189.9,193.3,189.9,192.4"/>
        <path d="M162.8,242.4l-3.9,2.3l-6.5-1.9l-8.1,9.4l-4.2,9.4l-4.6-0.3l-4.9,3.3l-7.8-4.9l-3,1.6l2.3,3l-0.3,4.8l9.8,1
		l3.5,7.1l1.3,0.6l1.3-2.5l6.2,3.5l1.3-2.6l1.6,2.3l3.9-4.9l5.2,5.2l2.6-1.9l-1.9-2.6l1.3-2.3l4.6,1.9l2,0.2l3.8-2.4l-5.2-1l6.2-2.6
		l-4.5-2.3l3.9-1.3l-0.6-1.7l-4.2-1.3l4.9-5.8l8.5,1.3l3.1-2.7c-0.7-0.4-1.3-0.9-1.7-1.6c-0.8-1.3-2.6-0.9-3.4-0.8
		c-0.8,0.1-2.1,1.5-1.8,0c0.4-1.5,0.3-0.6,0.3-1.2c0-1-0.1-0.6-0.4-1.4c-0.3-0.9-0.1-2.9,0.3-3.6c0.2-0.3,0.3-0.8,0.4-1.2
		c-0.5-0.3-0.9-0.7-1-1.3c-0.1-1.4,0.7-2.4,0.9-2.7c0.2-0.3,0.4-1.8-0.3-2.4c-0.2-0.2-0.6-0.5-1.2-0.9l-2.3,3.5L162.8,242.4z"/>
        <path d="M221.5,241.2c-0.6-1-0.4-1.7-0.8-2.6c-0.3-0.7-2.9-3.4-3.7-4.2c-2.3,2.2-7,6.6-7.2,6.9
		c-0.2,0.4-1.6,1.2-2.7,0.4c-1.2-0.8-3.7-1.9-4.1-2.1c-0.4-0.3-1.4-0.7-2.5-2.5c-1.1-1.8-1.2-2.9-1.9-3.5c-0.7-0.6-2.8-0.1-3.6-0.1
		c-0.9-0.1-2.9-0.4-3.8-0.7c-0.9-0.4-1.7-1-2.3-0.4c-0.6,0.7-3.2,4.3-3.5,4.7c-0.3,0.4-0.4,1.1,0.4,2.1s0.7,1.7,0.6,2.2
		c-0.1,0.5-0.7,1.1-1.6,1.2c-0.9,0.1-3,0.1-3.9,0.4c-0.9,0.3-1.9,0.9-2.2,1.3c-0.4,0.4-1.8,0.6-2.8,0.6c-0.5,0-1.4-0.1-2-0.4
		c-0.1,0.4-0.2,0.9-0.4,1.2c-0.4,0.7-0.6,2.7-0.3,3.6c0.3,0.9,0.3,0.5,0.4,1.4c0,0.6,0.1-0.3-0.3,1.2c-0.4,1.5,1,0.1,1.8,0
		c0.8-0.1,2.6-0.5,3.4,0.8c0.8,1.3,2.6,2.2,3.8,2.4c1.2,0.3,2.2,0.9,2.3,1.7c0.1,0.8-0.3,1.4-1.3,2.3c-1,0.9,0.2,1.7,0.3,2.4
		c0.1,0.7,0.1,0.9,1.4,2.1c1.3,1.3,2.5,1.1,2.2,3c-0.3,1.8,1.9,1.3,2.8,1.4c0.9,0.1,0.8,0.7,0.6,1.3c-0.3,0.6,0.2,2.6-1.6,2.9
		c-1.8,0.3,1.3,2.4,2.6,2.7c1,0.2,2.1,2.5,3.1,1.9c1-0.6,3.1-2.3,3.1-2.8c0-0.5,0.2-1.2,0.5-1.8c0.3-0.7,2.3-3.4,2.8-4.4
		c0.5-1,2.2-0.9,4-0.9s2.4-1.6,2.9-2.3c0.5-0.8,1.6-1.8,2.1-1.5c0.5,0.3,1.5,0.4,2.1,0.1c0.6-0.3,1.6-0.2,2.6,0.4
		c1,0.6,2.2,0.6,3.6,0s3.2-3.4,3.9-4.5c0.7-1.2,0.7-1.5-0.5-2.7c-1.2-1.2-0.5-2.3-0.5-3.7c0-1.4,2.1-5.3,2.4-7
		C223.8,243.9,222.1,242.2,221.5,241.2"/>
        <path d="M195.5,276.3c-0.3,0.2-0.6,0.4-0.9,0.6c-1,0.6-2.1-1.8-3.1-1.9c-1.3-0.2-4.4-2.4-2.6-2.7
		c1.8-0.3,1.3-2.3,1.6-2.9c0.3-0.6,0.3-1.2-0.6-1.3c-0.9-0.1-3.1,0.4-2.8-1.4c0.3-1.8-1-1.7-2.2-3s-1.3-1.5-1.4-2.1
		c-0.1-0.7-1.3-1.5-0.3-2.4c1-0.9,1.4-1.5,1.3-2.3c-0.1-0.8-1.1-1.4-2.3-1.7c-0.6-0.1-1.4-0.4-2.2-0.9l-3.1,2.7l-8.5-1.3l-4.9,5.8
		l4.2,1.3l0.6,1.7l-3.9,1.3l4.5,2.3l-6.2,2.6l5.2,1l-4.6,2.9l-1,4.6l3-0.3l1.9,2.6l1.3-2l4.6,0.3l-0.3,2.3l1.3,2.9l4.9-1l12-0.6
		l5.3-1.6c-0.4-0.9-0.4-2.3-0.5-2.8C196.1,278.4,195.7,277,195.5,276.3"/>
        <path d="M227.3,270.4c-0.4-1-2.3-1.3-3.3-1.5c-1-0.2-2.9,0.7-3.8,0.6c-1-0.1-1.2-1.1-1.3-1.8c-0.1-0.8-2-1.2-2.6-1.7
		c-0.5-0.5-0.2-1.8-0.2-1.9c-0.5,0-1-0.2-1.4-0.4c-1-0.6-2-0.7-2.6-0.4c-0.6,0.3-1.6,0.2-2.1-0.1c-0.5-0.3-1.6,0.7-2.1,1.5
		c-0.5,0.8-1.2,2.3-2.9,2.3c-1.7,0-3.5-0.2-4,0.9c-0.5,1-2.5,3.7-2.8,4.4c-0.3,0.7-0.5,1.4-0.5,1.8c0,0.4-1.2,1.4-2.2,2.2
		c0.2,0.8,0.6,2.2,0.7,2.7c0.1,0.8,0.1,3.2,1.1,3.6c0.7,0.3,1.7-0.7,2.3-1.2c0.6-0.5,2.1-0.6,2.5-0.5c0.4,0.1,0.7,1,1.6,0.9
		c1-0.2,1.1-1.1,1.5-1.4c0.5-0.4,2.3-0.3,3.3-0.8c1-0.5,4-2,4.8-2.4c0.8-0.4,3.6,0,4.4-0.3c0.9-0.3,3.5-1.3,4.6-2.4
		c1.2-1.1,5-2.3,5.2-2.4C227.7,271.9,227.6,271.4,227.3,270.4"/>
                
        <path d="M191.8,168.6c-0.9,0.5-3,2.4-3.5,3c-0.4,0.6,0.4,1.2,1.5,1.2c1.2,0,2.6,0.6,3.1,0.9c0.5,0.3,1.5,0.8,1.5,1.6
		c0,0.3-0.4,1.1-0.4,1.6c0,0.4,0.1,2.2-0.3,2.4c-0.4,0.2-1.8,0.4-1.6,1.5c0.2,1.3-0.5,1.9-1.1,2.4c-0.6,0.5-1,1.9-0.9,3.2
		c0.2,2.3-1.1,2.2-1.2,3.1c-0.1,0.5,0.8,1.9,0.9,2.8c0.1,0.9-0.6,1.9-0.7,2.6c-0.1,0.5,0.7,1.6,0.7,2.1c0.1,0.8-1.6,0.9-2.2,1.2
		c-0.6,0.2-1.4,0.5-1.4,1.1c0.1,0.9,1.9,2.2,2.5,2.8c0.6,0.6,2.4,1,3.8,1.1c1.4,0.2,1.3,1.3,1.5,3.2c0.2,1.9,1.9,2,2.8,2.5
		c0.8,0.4,1.7,2.3,2.1,3.1c0.3,0.8,2,1.7,2.7,2.1c0.7,0.4,1.7,0,2-0.1c0.3-0.1,2.8-2.3,3.5-3c0.7-0.7,3.5-1,4.3-0.9
		c0.8,0,1.9,2.1,2,2.5c0.1,0.4,0.6,3.5,1.7,5.1c1.1,1.6,3.7,0.9,4.2,1c0.5,0,2.2,0,2.3-0.6c0.1-0.6,1.1-1.8,1.8-1.8
		c0.7,0,2.7-2,3-2.8c0.3-0.8,0.4-5,0.6-5.5c0.3-0.5,3-0.4,3.8,0c0.8,0.3,2.9,1.6,3.6,1.4c0.7-0.1,5.1-0.9,5.5-1.2
		c0.4-0.3,0.5-2.3,0.5-3c0-0.7,0.3-2,0.4-2.8c0-0.8-1.7-3-1.7-3c-0.7-0.5-2.1-4.1-2-4.9c0.1-0.8-2.8-1.6-4.1-1.4
		c-1.3,0.1-3.7,0.9-4.2,0.9c-0.5,0-1.7-4.5-1.7-4.5l4.3-0.7c0,0,7.8-3.4,9.3-3.6c1.6-0.1,0.3-1.7-0.1-2.8c-0.4-1.1-2.4-0.5-2.6-0.9
		c-0.3-0.4-0.9-1.8-2.8-2.1c-1.8-0.3,1.7-3.8,3-4.6c1.3-0.8,1.1-5.1,0.8-6.1c-0.3-0.9,2.1,1.2,3.2,0.7c1.1-0.5-0.7-3.6-1.2-4.3
		c-0.4-0.7-0.7-2.2-0.5-2.3c0.2-0.1,0.8-1,0.7-2.5s-2.1-1.4-2.1-1.4l-3.7-0.5c0,0-0.3,0-0.7,0l-1.2,2.6l-0.9,4.7L231,164l-0.1-3.4
		l-1.7-1.3l-8.1,5.4l1.1,1.6l-0.8,1.7l-0.8-0.5l-1.2,0.4l-1.5-0.1c0,0-1,0.7-0.8,0.7c0.3,0,0.4,0.9,0.4,0.9l-1.2,0.3l-1.6-1.5
		l-0.4-1.1l-1.3-1.2l-2.2,0.8l-1.3,1.1l-1.6-0.9l-3.2-0.3l-3-3.6l-3-0.1l-1-0.3c-0.6,1.7-2,3.5-2.4,3.8
		C194.7,166.7,192.7,168.1,191.8,168.6"/>
                
        <text transform="matrix(1 0 0 1 149.298 258.976)">ALT</text>
        <text transform="matrix(1 0 0 1 146.3229 265.976)" >CAMP</text>
        <text transform="matrix(1 0 0 1 172.5587 269.9662)" >BAIX </text>
        <text transform="matrix(1 0 0 1 166.9239 276.9662)" >PENEDÈS</text>
        <text transform="matrix(1 0 0 1 199.6559 275.349)" >GARRAF</text>
        <text transform="matrix(1 0 0 1 179.2228 222.0023)" >ANOIA</text>
        <text transform="matrix(1 0 0 1 197.7447 251.3109)">ALT</text>
        <text transform="matrix(1 0 0 1 190.4928 258.3109)" >PENEDÈS</text>
        <text transform="matrix(1 0 0 1 203.6178 190.453)" >BAGES</text>
        <MapPoint point={props.points[7]} />
      </g>

      <g id="zone-1" className={props.hover==1 ? 'zone hover' : 'zone'} ref={props.points[0].pathRef} >
        <polygon points="319.3,158.3 317.3,162.4 321.4,164.1 322.6,166.9 329.1,167.4 331.2,171 333.4,168.4 336,168.2 
	336,174.7 334.6,176.8 335.4,178 337.7,176.8 342.5,178 343.2,185 339.9,187.1 341.5,191 345.9,190.3 349.5,193.1 351.8,191.5 
	353.1,188.3 351,184.3 355,180.2 352.4,177.3 352.8,174.2 351.7,171.2 345.2,171.7 344,169.8 347.3,165.7 351.5,164.2 351.9,159.9 
	357.2,157.6 356.2,154.4 352.4,153.9 352.8,149.9 355.8,148.2 356,142.4 353.6,140.5 353.6,137.3 347.8,137.2 345.2,144.1 
	343.5,144.6 341.5,143.1 340.2,146.5 338.2,146.5 337.5,149.6 336.2,147.7 334.1,147.7 332.7,149.4 330.5,148.2 331.2,145.3 
	328.6,142.3 323.8,153.4 318.5,155.8 "/>
        <path d="M300.7,100.1l2.6-0.5l-8.6-1.1v-1.6l-1.3,0.3l-0.3-3.4l-2.1-0.3v-2.9l-9.7-1.8l-4.7-4l-2.7,1l-3.1-2.3l-4,1
		l-2.6,2.1l-2.7,0.8l-1.3-1.9l-4,0.3l-4.2,4v2.9l-2.6,0.8l-1,2.3l-6.6,0.3l-0.7,0.9l0.3,0.1l-4.1,7.9c0,0,0,0,0,0
		c0.3,0,0.5,0.1,0.5,0.1l3.6-1.3l5.5,3.4l-4.2,5c-1.3,1.6-2.3,4.3-0.5,5.3c2.2,1.2-1.4,0.2-0.6,2.6c0.8,2.4,4.5,3.2,4.5,3.2
		l-3.4,3.2l-4-4.8l-0.5,0.3l-0.3,3.7c0,0,0.7-1.8,1.8,3.6c0.1,0.5,2.9,2.8,3.6,3.3l0.4,0c0,0,0.2-1.5,0.6-1.8
		c0.5-0.3,1.5-1.3,2.7-0.2c1.3,1.2,2.4,2.7,2.9,3.1c0.5,0.4,2.2,0.3,2.8-0.1c0.6-0.4,1.7-1.9,2.8-1.9c1.1,0,2,0,2.7,0.6
		c0.7,0.6,1.6,0.8,2.4,0.3c0.7-0.5,1.7-1.3,2.7-1.5c1-0.2,3.3-0.4,4.2-0.2c0.9,0.2,1.6-0.1,2.5-0.2c0.9-0.2,1.3,0,0.9-0.7
		c-0.4-0.7-1.5-1.8-1.4-2.3c0.1-0.5,1.5-1.3,2.3-1c0.9,0.2,1.5,0.9,2,0.7c0.5-0.2,2.8-1.2,3.2-1.4c0.4-0.2,1.7-0.9,2-1
		c0.4,0,1,0.2,1.4,0.5l-0.4-3.5l-2-2.3l3.6,0.9l0.7-2.3l-3.1-1.2l-0.2-2l4.5-1.9l-0.7-2.7l3.3-0.5l1-2.6l3.2,0.2l0.7,1.6l3.6-1.4
		v-2.7l4.1,0.5c0,0,0,1.5-0.5,3.6s4.3,0.2,5.3,0.2s1-2.6,1-2.6l-3.3-0.5v-2.9l-1-1.1V100.1z"/>
        <path d="M396.4,103.3l-5.3-4.2l-3.7,1.7l-1.9-2.4l-2.4-0.5l-1.5,2.4l-3.3-3.6l-2.4-4.4v-3.3l3.2-4.1l-8-2l-1.2,2.7
		l-6.3-4.8l-0.9-3.4l-3.4,1.5l-4.3-0.2l-2.2-2l-2.6,0.2v3.4l-6.5-1l-6.3,2.6l-3.6,5.5l-4.1,0.7l-1.7-3.3l-8.5,4.1v2.7l-3.6,0.6
		l4.3,5.1l-1.1,2.1l-12.5-3.3l-4.3,3.8l3.6,3.8l3.1-2.3l4.4,4.3c0,0,3.3-1.2,3.6,0.5c0.2,1.7,0.2,6.5,0.2,6.5l2.3,0.5l1.2-2.9l2.9,2
		l0.2,3.1l1.9,0.2l1.5-4.1l3.8,1.7l-1.1,4.1l1.9,5l2.9-0.5l4.1,4.8l3.8-3.1l3.4,4.1l-1.5,2.7l0.7,6.6l8.1,0.2v-0.4l6-4.3l5.3,4.5
		l5-2.6l2.4,1l1.5,3.1l5-2l-4.5-2.3l-2.2-3.6c0,0-1-9.8-0.5-11.1c0.5-1.2,4.8-8.2,4.8-8.2h2.4l4.3,5.3l4.3-3.1l2.4,2.1l2.8-2.8
		l-1.6-4.5L396.4,103.3z"/>
        <path d="M331.9,122.5l-1.9-5l1.1-4.1l-3.8-1.7l-1.5,4.1l-1.9-0.2l-0.2-3.1l-2.9-2l-1.2,2.9l-2.3-0.5c0,0,0-4.8-0.2-6.5
		c-0.2-1.7-3.6-0.5-3.6-0.5l-4.4-4.3l-3.1,2.3l-3.6-3.8l0.5-0.4l-2.2,0.4v2.4l1,1.1v2.9l3.3,0.5c0,0,0,2.6-1,2.6
		c-1,0-5.8,1.9-5.3-0.2c0.5-2.1,0.5-3.6,0.5-3.6l-4.1-0.5v2.7l-3.6,1.4l-0.7-1.6l-3.2-0.2l-1,2.6l-3.3,0.5l0.7,2.7l-4.5,1.9l0.2,2
		l3.1,1.2l-0.7,2.3l-3.6-0.9l2,2.3l0.4,3.5c0.3,0.2,0.6,0.5,0.6,0.7c0,0.4-0.5,1-1.2,1.5c-0.6,0.5-1.7,1.6-1.6,2.4
		c0.2,0.8,1.8,2,2.2,2.3c0.4,0.2,2,0.4,1.8,1.3c-0.2,0.9-0.7,2.1-0.5,2.4c0.2,0.3,1.5,0.5,2,0.6c0.5,0.1,1.8,1.5,2,2.4
		c0.2,0.9,0.8,2.9,1.3,3.4c0.5,0.5,0.9,0.9,1.6,0.5c0.7-0.5,1.6-1.3,2.9-1.1c1.3,0.2,3.8,1.5,4.2,2.1c0.1,0.1,0.1,0.2,0.1,0.4
		l0.9-0.2l6,5l1-2.3l2,1.9l3.1-1l3.4,2.9l4.3,0.2l1.4,4.7l5.3-2.4l4.8-11.2l-0.2-0.3l-2.9-1l-3.1-2l-3.6,2.2l-4.4-1.7l2.9-2.7
		l-1-7.4l1.7-0.5l-0.2-3.6l1.6-0.2l0.7,1.4h3.4l1.4-1.4l2.9,0.6l2.2-1.4l0.6-5l0.1,0L331.9,122.5z"/>
        <polygon points="317.3,136.9 314.4,139.5 318.8,141.3 322.3,139.1 325.4,141 328.4,142 331.2,145.3 330.5,148.2 
	332.7,149.4 334.1,147.7 336.2,147.7 337.5,149.6 338.2,146.5 340.2,146.5 341.5,143.1 343.5,144.6 345.2,144.1 347.8,137.2 
	345.4,137.1 344.7,130.5 346.2,127.8 342.7,123.7 338.9,126.8 334.9,122 331.9,122.5 330.8,119.4 330.7,119.4 330.1,124.4 
	327.9,125.8 324.9,125.2 323.6,126.5 320.1,126.5 319.4,125.2 317.8,125.4 318,129 316.3,129.5 "/>
        <path d="M354.5,194.8l2.4-1.7l2.3-2.1l3.2,0.5l3.8-3.7l-0.5-3.8l5.5-5.3l2.2,1.5l6.5-4.4l0.1-3.1l3.9-2.4l-0.2-3.1
		l2.6-3.8l-0.5-2.9l-3.8-3.1c0,0-1-3.3-1-4.2c0-0.9,2.7-8.1,2.7-8.1l-5-8.5l-5,2l-1.5-3.1l-2.4-1l-5,2.6l-5.3-4.5l-6,4.3v3.7
		l2.4,1.9l-0.2,5.8l-2.9,1.7l-0.4,4.1l3.8,0.5l1,3.2l-5.3,2.3l-0.5,4.3l-4.2,1.5l-3.3,4.1l1.2,2l6.5-0.5l1.1,2.9l-0.4,3.1l2.6,2.9
		l-4.1,4.1l2.1,4.1l-2.1,5.3L354.5,194.8z"/>
        <path d="M339.9,187.1l3.3-2.1l-0.7-7l-4.8-1.2l-2.3,1.2l-0.7-1.2l1.4-2.1v-6.5l-2.6,0.2l-2.2,2.6l-2.1-3.6l-6.5-0.5
		l-1.2-2.8l-4.1-1.7l2-4.1l-2.2-7.2l-4.3-0.2l-3.4-2.9l-3.1,1l-2-1.9l-1,2.3l-6-5l-0.9,0.2c0.1,0.7-0.4,1.9-0.1,2.5
		c0.4,0.7,1.2,1.6,1.3,2.1c0.2,0.5-0.2,1.3-0.2,1.7c0,0.5,0.5,1.4,0.9,2c0.4,0.5,0.5,1.2,0.1,1.4c-0.4,0.2-2-0.2-2,0.5
		c0,0.4,0.5,1.3,0.3,1.6c-0.2,0.4-2.3,2.7-2.7,3c-0.3,0.2-0.9,0.3-1.3,0.2c-0.4-0.2-2-0.3-1.8,0.8c0.2,0.8,0.8,1,0.9,2
		c0.1,0.8,0.1,2.3,0.9,2.3c0.8,0,1.8-0.5,2.5-0.3c0.7,0.2,1.4,1,1.2,2c-0.1,0.5-1.8,3.9-2.3,4.5c-0.5,0.6-1.3,0.7-1.2,1.2
		c0,0.5,1,1.1,0.8,1.8c-0.2,0.7-0.8,3.1-1,3.4c-0.2,0.3-0.8,0.8-1.3,0.8c-0.5,0-1.5,0.3-2,1c-0.3,0.5-1,2.4-1.1,3
		c-0.1,0.6-0.1,1.6-0.7,2.3c0.5,0.4,1.4,1.3,2.1,2c0.9,1,1.9,1.8,2.8,1.8c0.9,0.1,1.2,0.1,1.9,1.1c0.7,0.9,1.9,2.2,2.7,2.6
		c0.8,0.3,2.3,0.1,2.6,0.5c0.3,0.4,0.5,2.2,2.2,3.3c1.7,1.1,1.7,1.7,2.3,1.9c0.6,0.2,2.2,0,2.8-0.1c0.6-0.1,1.4-0.3,2,0.4
		c0.5,0.7,0.4,1.2,0.2,1.9c-0.2,0.7-1.1,2-1,2.8c0.1,0.8,0.7,1.6,1.3,1.9c0.4,0.2,0.6,0,0.8,0.1c0.1-0.4,0.4-1.1,1.5-1.2
		c1.4-0.1,2.1-0.5,2.4-0.9c0.3-0.5,1-2.6,1.9-3c0.9-0.4,1.2-0.5,2.4-0.7c1.1-0.1,2-0.6,3-1.1c0.9-0.4,2.7-1.8,3.9-1.8
		c1.2,0,4.5-0.2,5.1,0.1c0.6,0.3,1.3,1.8,1.2,2.9c-0.1,1.1-0.3,3.2-0.6,4.4c-0.3,1.2-1,2.8-0.5,3.6c0.3,0.5,1.2,0.9,1.7,1.4l6-3.9
		l6.8-1.2l4.3-1.6l5.4-6.3l-0.1-0.2l-3.6-1.2l0.8-2.1l-2.3,1.6l-3.6-2.8l-4.4,0.7L339.9,187.1z"/>
        <path d="M247,107.2l-5.5-3.4l-3.6,1.3c0,0-3.2-0.6-4.1-0.3c1.1,0.8,2,1.5,1.2,1.1c-0.5-0.2-0.8-0.3-1.2-0.6
		c-0.3-0.2-0.2-0.4,0-0.4c-1.1-0.8-2.5-1.7-2.5-1.7l-5-1.6l-11.1,3.8l-2.7-0.3l-2,1.9l-3.8-0.9l-3.4,1.6l0.3-0.2l0.7,5.9l-13.1,7.2
		l3.3,2.6c0.8,0.6,0.2,1.7,0.9,2.3c0.5,0.3,0.9,0.5,1.5,0.4c0.7-0.1,1.4-1.1,1.4-1.1l4,1.3l2.4-1.6c0,0,0.5,1,0.4,1.6
		c-0.1,0.8-0.2,1.4-0.9,1.8l-1.8,1l-1.6,4l2.3,5.4l-3,0.9l-2.4,7.1l0.9,0.3l0.7-0.7l0.8,0.9l-1.2,2.7l-1.8,1.9l-1.7-0.4l-0.7,2.2
		l1.5,1.1l3.9,0.8l0.8-3.2l2.3,2l-0.7,3.2l-3.9,1.5l-0.9-1.2l-0.5-0.9l-1.9,0.1l-0.4,0.9v4.2l3.5,1.1l3,0.1l3,3.6l3.2,0.3l1.6,0.9
		l1.3-1.1l2.2-0.8l1.3,1.2l0.4,1.1l1.6,1.5l1.2-0.3c0,0-0.1-0.9-0.4-0.9c-0.3,0,0.8-0.7,0.8-0.7l1.5,0.1l1.2-0.4l0.8,0.5l0.8-1.7
		l-1.1-1.6l8.1-5.4l1.7,1.3l0.1,3.4l1.8,1.9l0.9-4.7l3.8-8.2c0,0,0.9-3.1-0.1-3.4c-1.1-0.3-1.9-0.9-1.9-0.9l1.8-2.7l-0.5-5.9l2-1.6
		l0.3,1.1l1.1,1.2l1.7-2.3l0.9-0.1l0.8-1.1l-2.2-2l2.3-2.4c0,0-3.7-2.9-3.8-3.4c-1.1-5.5-1.8-3.6-1.8-3.6l0.3-3.7l0.5-0.3l4,4.8
		l3.4-3.2c0,0-3.7-0.7-4.5-3.2c-0.8-2.4,2.8-1.4,0.6-2.6c-1.8-1-0.8-3.7,0.5-5.3L247,107.2z"/>
                
        <polygon points="235.2,81.4 239.4,83.2 242.1,81.4 237.9,78 239.4,75.6 236.8,73.7 233.1,78 "/>
        <path d="M232.1,123.8c0-1.2,0.9-2.1,2-2.1c1.1,0,2,0.9,2,2.1c0,1.2-0.9,2.1-2,2.1C233,125.9,232.1,125,232.1,123.8"/>
    
        <text transform="matrix(1 0 0 1 254.8575 109.3861)" >RIPOLLÈS</text>
        <text transform="matrix(1 0 0 1 327.0909 162.0893)" >GIRONÈS</text>
        <text transform="matrix(1 0 0 1 334.2374 107.9721)">ALT EMPORDÀ</text>
        <text transform="matrix(1 0 0 1 287.3663 129.3783)" >GARROTXA</text>
        <text transform="matrix(1 0 0 1 324.5919 132.2201)" >PLA DE </text>
        <text transform="matrix(1 0 0 1 322.128 139.2201)">L'ESTANY</text>    
        <text transform="matrix(1 0 0 1 362.8683 160.7519)" >BAIX</text>
        <text transform="matrix(1 0 0 1 356.4561 167.7519)" >EMPORDÀ</text>
        <text transform="matrix(1 0 0 1 308.6104 182.5922)">SELVA</text>
        <text transform="matrix(1 0 0 1 207.3697 137.6239)" >BERGUEDÀ</text>
    
        <MapPoint point={props.points[0]} />
      </g>

      { props.points.map( (item, index) => {      
        return <TooltipRegion point={item} reference={props.points[index].pathRef} />;
      }) }

    </>

  );    
};
