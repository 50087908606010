import React from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import { createBrowserHistory as createHistory } from 'history';

import Grid from '@mui/material/Grid';
import Box from '@material-ui/core/Box';

import { useTranslate  } from '../common/language';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    padding: '2rem',
  },
  message: {
    fontSize: '1.5rem',
    marginBottom: '2rem',
  },
});

export const NotFound = () => {
  const classes = useStyles();
  const translate = useTranslate();  
  const history = createHistory();
    
  var imgName = window.location.hostname.replaceAll('.','');
    
  return (
    <Box sx={{ width: '50%', maxWidth: 800, bgcolor: 'background.paper', padding: '20px' }} className="add_member_box" >
      <Grid container spacing={1} >
        <Grid item xs={12} sm={12}>
          <center><img src={'/img/'+imgName+'_200x200.png'} /></center>
        </Grid>
      </Grid> 
                                
      <div className="add_member_form_sent" dangerouslySetInnerHTML={{ __html: translate('common.notFoundPage') }} />
                      
      <br /><br /><br />
      <center>
        <Button variant="contained" onClick={history.goBack}>{translate('common.goBack')}</Button>
      </center>     
    </Box>
  );
    
};