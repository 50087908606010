// in src/Dashboard.js
import React, { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@mui/material/Typography';
import Button from '@material-ui/core/Button';

import {
  TextInput, required,BooleanInput, useCreate, useRefresh, FormWithRedirect, SaveButton, useDataProvider,
  useNotify,
  PasswordInput
    
} from 'react-admin';

import { validateEmail,validateMinMaxLengthAndCharacters, validateTrue  } from '../components/Validator';

import { parse } from 'query-string';

import {useHistory} from 'react-router-dom';

import { projectUrl } from '../common/constants';
import { useTranslate  } from '../common/language';



import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


const Header = () => {
    
    var domain = window.location.hostname.replaceAll('.','');
    
    return (
        <Grid container spacing={1} >
          <Grid item xs={12} sm={12}>
          <center><a href={projectUrl}><img src={'/img/'+domain+'_200x200.png'} /></a></center>
          </Grid>
        </Grid>
    );
}


export const SignUp = props => {
    
  const translate = useTranslate();  
  const dataProvider = useDataProvider();
    
  const [info, setInfo] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [errorApi, setErrorApi] = useState();
  const [create] = useCreate('auth/signup');
  const refresh = useRefresh(); 
  const notify = useNotify();
  const [formSent, setFormSent] = useState();
  const [openLopd, setOpenLopd] = React.useState(false);
  const [openTerms, setOpenTerms] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  
  const [footer, setFooter] = React.useState("");
  const [terms, setTerms] = React.useState("");
  const [lopd, setLopd] = React.useState("");
    
  const history = useHistory();
  
  useEffect(() => {
    dataProvider.getOne('settings_legal', { id: 1 })
      .then(( { data } ) => {   
        setFooter(data.signup_footer);
        setLopd(data.lopd);
        setTerms(data.terms);
      })
      .catch(error => {                                
      });
  }, []);

  const openErrorDialog = () => {
    setOpen(true);
  };
  const closeErrorDialog = () => {
    setOpen(false);
  };
             
  const handleSubmit = values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {              
          setFormSent(true);
          setErrorApi('');
        },
        onFailure: ( error ) => {
          setErrorApi(error.message);
          openErrorDialog();                                    
        }
      }
    );
  };
    
  const openLopdDialog = () => {
    setOpenLopd(true);
  };
  const closeLopdDialog = () => {
    setOpenLopd(false);
  };
  
  const openTermsDialog = () => {
    setOpenTerms(true);
  };
  const closeTermsDialog = () => {
    setOpenTerms(false);
  };
  
  var login_url = projectUrl + '/#/login';
    
  var domain = window.location.hostname.replaceAll('.','');
   
  if (domain != 'admingestioentitatscat' && domain != 'localhost') return <span>Sign-up not available: {domain}</span>;
  if (!lopd || !terms || !footer ) return null;
  
  if (formSent)
  {
    return (
      <Box sx={{ width: '90%', maxWidth: 600, bgcolor: 'background.paper', padding: '20px' }} className="signup_box" >
        <Header />                                
        <div className="add_member_form_sent" dangerouslySetInnerHTML={{ __html: translate('signup.ok') }} />   
        <div className="text-center"><br /><br /><Button size="medium" className="btn-green" variant="contained" onClick={() => { 
            history.push('/login' );
        }}>{translate('signup.ok_login')}</Button></div>
      </Box>
    );
  }
    
  return (
            
    <FormWithRedirect
      resource="auth"        
      save={handleSubmit}
      initialValues={{language: 0}}
      render={formProps => (
        <>     
          <Box sx={{ width: '90%', maxWidth: 600, bgcolor: 'background.paper', padding: '20px' }} className="signup_box pt-0" >                                
            <Header />                
            <Grid container spacing={1} >
              <Grid item xs={12} sm={12}>
                <h3 className="mt-0 mb-0">{translate('signup.register')}</h3>
                <br />
                <div className="justify" dangerouslySetInnerHTML={{ __html: translate('signup.desc')}} /> 
              </Grid>
            </Grid>  
                 
            <br />
                 
            <Grid container spacing={1} >
              <Grid item xs={12} sm={12}>
                <TextInput fullWidth label={translate('signup.name')} fullWidth source="name" validate={[required()]} className="bg-grey" />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextInput fullWidth label={translate('users.email')} fullWidth source="email" validate={[validateEmail(),required()]} className="bg-grey mt-0"  />
              </Grid>                     
            </Grid>    
            
            <br />
            <Grid spacing={2}>
                <Grid item xs={12} className="signup-lopd">
                <div className="justify" dangerouslySetInnerHTML={{ __html: footer }} />
                <a href='#' onClick={(e)=>{e.preventDefault(); openLopdDialog();}}>{translate('signup.form_lopd_link')}</a>.
<br /><br />
                </Grid>
            </Grid>
            <Grid spacing={2}>
              <Grid item xs={12}>
                <BooleanInput className="add_member_input" fullWidth source="lopd" label={translate('signup.form_lopd')} validate={[validateTrue(translate('signup.form_lopd_validator'))]} />
              </Grid>
            </Grid>
            
            <Grid spacing={2}>
              <Grid item xs={12}>
                <BooleanInput className="add_member_input" fullWidth source="terms" label={<>{translate('signup.form_terms')} <a href='#' onClick={(e)=>{e.preventDefault(); openTermsDialog();}}>{translate('signup.form_terms_link')}</a></>} validate={[validateTrue(translate('signup.form_terms_validator'))]} />
              </Grid>
            </Grid>
            
            <Grid spacing={2}>
              <Grid item xs={12}>
                <BooleanInput className="add_member_input" fullWidth source="newsletter" label={translate('signup.form_newsletter')} />
              </Grid>
            </Grid>
                
            <br />  
            <div className="text-center">
            <SaveButton
              saving={formProps.saving}
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              label={translate('signup.send_button')}
              icon={<span />}
            /><br />{translate('signup.link_or')} <a href="/#/login">{translate('ra.auth.sign_in')}</a>
            </div>
            
            <br/><br />
          </Box>
            
          <Dialog
            onClose={closeLopdDialog}
            aria-labelledby="customized-dialog-title"
            open={openLopd}
            fullWidth            
            maxWidth="lg"
          >
            <DialogTitle sx={{ m: 0, p: 2 }}>
              {translate('signup.lopd')}   
              <IconButton
                aria-label="close"
                onClick={closeLopdDialog}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent>   
              <div dangerouslySetInnerHTML={{ __html: lopd }} />
            </DialogContent>
          </Dialog>
          
          <Dialog
            onClose={closeLopdDialog}
            aria-labelledby="customized-dialog-title"
            open={openTerms}
            fullWidth            
            maxWidth="lg"
          >
            <DialogTitle sx={{ m: 0, p: 2 }}>
              {translate('signup.terms')}   
              <IconButton
                aria-label="close"
                onClick={closeTermsDialog}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent>   
              <div dangerouslySetInnerHTML={{ __html: terms }} />
            </DialogContent>
          </Dialog>
              
          <Dialog
            onClose={closeErrorDialog}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth            
            maxWidth="sm"
          >
            <DialogTitle sx={{ m: 0, p: 2 }}>
              {translate('signup.register')}   
              <IconButton
                aria-label="close"
                onClick={closeErrorDialog}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
    
            <DialogContent dividers>
              <Typography gutterBottom>
                {errorApi}
              </Typography>
            </DialogContent>
          </Dialog>
                
        </>
            
            
      )}
    />
        
  );
  
};

