// in src/posts.js
import * as React from 'react';

import { useState, useEffect } from 'react';

import {
  BooleanInput,
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  BooleanField ,
  ReferenceManyField,
  TabbedForm,
  FormTab,NumberInput, RichTextField , Toolbar, SaveButton,
  useDataProvider, required

} from 'react-admin';

import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';
import RichTextInput from 'ra-input-rich-text';

import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useTranslate  } from '../common/language';

import { useStyles  } from '../common/styles';
import { DocsList  } from './docs';

import Grid from '@mui/material/Grid';




import { targets } from './../common/constants';

import { validateMaxLength, validateInteger  } from './../components/Validator';


export const ModulesList = (props) => {
  const translate = useTranslate(); 
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  return (

    <List {...props}
      bulkActionButtons={false}
    >
      <Datagrid>                   
        <TextField source="name" label={translate('common.name')} sortable={false}/>
        <BooleanField source="actived" label={translate('common.actived')} sortable={false}/>
        <BooleanField source="visible" label={translate('common.visible')} sortable={false}/>
        <TextField source="priority" label={translate('common.priority')} sortable={false}/>
        <EditButton />
      </Datagrid>
    </List>
  );
};

const ElementTitle = ({ record }) => {
  const translate = useTranslate(); 
  return <span>{translate('common.edit')} {record ? `"${record.name}"` : ''}</span>;
};

const customToolbarStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const CustomToolbar = props => (
  <Toolbar {...props} classes={customToolbarStyles()}>
    <SaveButton />
    <DeleteButton undoable={false} />
  </Toolbar>
);




export const ModulesEdit  = ({ id, ...props }) => {

  const translate = useTranslate(); 
  const classes = useStyles();

  const dataProvider = useDataProvider();
  const [info, setInfo] = useState();
    
  useEffect(() => {
        
    dataProvider.getOne('modules', { id: id })
      .then(({ data }) => {     
        setInfo(data);
      })
      .catch(error => {           
      });
       
  }, []);

  return (

    <>
      <br />
      <Typography variant="h5" className="grey8" >
        {info ? info.name : null }              
      </Typography>

      <Edit 
        title="" 
        {...props}  
        id={id}
        mutationMode="pessimistic" > 

        <TabbedForm>

          <FormTab
            label={translate('subscriptions.module')}
            contentClassName={classes.tab}
            fullWidth
            path=""
          >     
            <br />  
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <BooleanInput fullWidth source="actived" label={translate('common.actived')}/>
              </Grid>
              <Grid item xs={3}>
                <BooleanInput fullWidth source="visible" label={translate('common.visible')}/>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={7}>  
                <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />                     
              </Grid>
              <Grid item xs={3}>  
                <TextInput fullWidth source="public_id" label={translate('common.name_internal')} validate={[required(),validateMaxLength(30,translate('common.validation.maxLength'))]} />
              </Grid>
              <Grid item xs={2}>  
                <NumberInput source="priority" step={1} label={translate('common.priority')} parse={value => value ? value : 0 } validate={[validateInteger(translate('common.validation.number'))]}  />
              </Grid>
            </Grid>
                                    
            <RichTextInput source="description" label={translate('common.description')} />
          </FormTab>

          <FormTab
            label={translate('subscriptions.options')}
            contentClassName={classes.tab}
            fullWidth
            path="options"
          >

            <ReferenceManyField {...props}
              fullWidth
              label=""
              reference="modules_options"
              target="modules_id"
              hasCreate
              perPage={1000}
              sort={{ field: 'priority', order: 'ASC' }}
            >
                
              <EditableDatagrid                                        
                createForm={<OptionForm initialValues={{ modules_id: id }} />}
                editForm={<OptionForm />}
              >
                <TextField source="public_id" label={translate('common.name_internal')} sortable={false} />
                <TextField source="name" label={translate('common.name')} sortable={false} />
                <RichTextField multiline source="description" label={translate('common.description')} sortable={false} />
                <TextField source="priority" label={translate('common.priority')} sortable={false} />
                     
              </EditableDatagrid>
                    
            </ReferenceManyField>   

          </FormTab>

          <FormTab
            label={translate('subscriptions.docs')}
            contentClassName={classes.tab}
            fullWidth
            path="docs"
          >  
            <DocsList target={targets.MODULE} items_id={id} />
            <br />
          </FormTab>

        </TabbedForm>



      </Edit>

    </>
  );
};

const OptionForm= props => {
  const translate = useTranslate(); 
  return (
    <RowForm {...props}>
      <TextInput fullWidth source="public_id" label={translate('common.name_internal')} validate={[required(),validateMaxLength(10,translate('common.validation.maxLength'))]} />
      <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />     
      <RichTextInput source="description" label={translate('common.description')} />   
      <NumberInput fullWidth source="priority" label={translate('common.priority')} parse={value => value ? value : 0 } validate={[validateInteger(translate('common.validation.number'))]} /> 
    </RowForm>
  );
};

export const ModulesCreate = props => {
  const translate = useTranslate(); 
  return (
    <Create {...props}>
      <SimpleForm>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <BooleanInput fullWidth source="actived" label={translate('common.actived')}/>
          </Grid>
          <Grid item xs={3}>
            <BooleanInput fullWidth source="visible" label={translate('common.visible')}/>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={7}>  
            <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />                     
          </Grid>
          <Grid item xs={3}>  
            <TextInput fullWidth source="public_id" label={translate('common.name_internal')} validate={[validateMaxLength(10,translate('common.validation.maxLength'))]} />
          </Grid>
          <Grid item xs={2}>  
            <NumberInput source="priority" step={1} label={translate('common.priority')} parse={value => value ? value : 0 } validate={[validateInteger(translate('common.validation.number'))]}  />
          </Grid>
        </Grid>
                        
        <RichTextInput source="description" label={translate('common.description')} />
            
      </SimpleForm>
    </Create>
  );
};

