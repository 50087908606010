import React, { useState } from 'react';
import {
  required,
  Button,
  SaveButton,
  useCreate,
  useUpdate,
  useNotify,
  FormWithRedirect,
  useRefresh, TopToolbar, useTranslate, ReferenceInput, SelectInput, Confirm, RadioButtonGroupInput, ResourceContextProvider, FunctionField, List, Datagrid, TextField, DeleteButton, RefreshButton, downloadCSV
} from 'react-admin';

import jsonExport from 'jsonexport/dist';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DeleteIcon from '@mui/icons-material/Delete';




import MailOutlineIcon from '@mui/icons-material/MailOutline';


import Grid from '@mui/material/Grid';



import { UserAvatarField, UserFullNameTxt, UserInput } from '../components/User';


import { SendEmailIconStatus } from './../components/Emails';
import { ExportButton } from './../components/ExportButton';
import { targetsEmails,emailsStatus } from './../common/constants';



export const BookingsList = ({ events_id , ...props }) => {
    
  const translate = useTranslate();
    
  return ( 
            
    <ResourceContextProvider value="bookings" >
      <List  
        basePath="bookings"
        filter={{ events_id : events_id }}  
        title="" 
        bulkActionButtons={false}                
        sort={{ field: 'id', order: 'DESC' }} 
        perPage={20}
        actions={<BookingsToolbar events_id={props.record.id} empty={false} /> } 
        empty={<BookingsToolbar events_id={props.record.id} empty={true} /> }
        bulkActionButtons={false}
      >
        <Datagrid>  
          <UserAvatarField />
          <FunctionField render={record => <UserFullNameTxt record={record}/> } label={translate('common.name')} sortable={false} />
          <TextField source="email" label={translate('common.email')} sortable={false} />
          <FunctionField label={translate('bookings.status_0')} render={record => 
            <SendEmailIconStatus record={record} target={targetsEmails.EVENT_INVITE}  />
          } sortable={false}  />
          <FunctionField label={translate('bookings.status_1')} render={record => 
            <SendEmailIconStatus record={record} target={targetsEmails.EVENT_REMINDER}  />
          } sortable={false}  />
          <DeleteButton undoable={false} label="" />
        </Datagrid>                             
      </List>
    </ResourceContextProvider>
  );
};

                
const BookingsToolbar = ({ events_id , ...props }) => {

  const translate = useTranslate();
    
  let filterExport = {
    events_id : events_id        
  };
    
  return (        
    <TopToolbar>            
      <BookingsCreateButton events_id={events_id} label_button={translate('bookings.create_user_button')} label_title={translate('bookings.create_user_title')} type={1} />
      <BookingsCreateButton events_id={events_id} label_button={translate('bookings.create_group_button')} label_title={translate('bookings.create_group_title')} type={2} />
      { !props.empty ? <BookingsSendAllButton events_id={events_id} action={0} /> : null } 
      { !props.empty ? <BookingsSendAllButton events_id={events_id} action={1} /> : null } 
      { !props.empty ? <ExportButton maxResults="100000" filterValues={filterExport} alwaysEnabled={true} exporter={exporter} sort={{ field: 'id', order: 'DESC' }} />  : null }             
      { !props.empty ? <BookingsDeleteAllButton events_id={events_id} label_button={translate('bookings.delete_all_button')} label_title={translate('bookings.delete_all_button_title')}  /> : null }             
      <RefreshButton label=""/>
    </TopToolbar>        
  );
};


const exporter = (items, translate, notify) => {
        
  const eventsForExport = items.map(booking => {
    var eventForExport = {
      id : booking.id,
      first_name : booking.first_name, 
      last_name_1 : booking.last_name_1, 
      last_name_2 : booking.last_name_2, 
      email: booking.email,
      invite: booking.invite_status === emailsStatus.SENT_TO_SMTP_OK ? translate('common.yes') : translate('common.no'),
      reminder: booking.reminder_status === emailsStatus.SENT_TO_SMTP_OK ? translate('common.yes') : translate('common.no'),
    };
    return eventForExport;
  });
  jsonExport(eventsForExport, {
    headers: ['id', 'first_name', 'last_name_1', 'last_name_2','email','invite','reminder'],
    rename: [translate('bookings.exporter_header_id'), translate('users.first_name'), translate('users.last_name_1'), translate('users.last_name_2'), translate('users.email'), translate('bookings.status_0'), translate('bookings.status_1')],        
        
  }, (err, csv) => {
    const csv_new = '\uFEFF' + csv;
    downloadCSV(csv_new, translate('bookings.exporter_file_name') ); 
    notify(translate('common.download_ok'),"success");
  });
};

export const BookingsCreateButton = ({ events_id ,  ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('bookings');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  


  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify( error.message , 'error');
        }
      }
    );
  };
    
  return (
    <>            
      <Button onClick={handleClick} label={props.label_button} >
        <IconContentAdd />                
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={props.label_title}
      >
        <DialogTitle>{props.label_title}</DialogTitle>

        <FormWithRedirect
          save={handleSubmit}
          initialValues={{ events_id : events_id, type: props.type }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}> 
                    {props.type== 1 ? 
                      <UserInput source="users_id" user_type={-1} disabled={false} label={translate('common.name')} allowEmpty={false} required={true} />
                      : 
                      <ReferenceInput label={translate('users.group')} source="groups_id" reference="groups" filter={{ associations_id : localStorage.getItem('associations_id') }}>
                        <SelectInput fullWidth optionText="path" optionValue="id" validate={required()} />
                      </ReferenceInput>        
                    }
                  </Grid> 
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};


export const BookingsDeleteAllButton = ({ events_id ,  ...props }) => {

  const [open, setOpen] = useState(false);
  const [update, { loading, error }] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  

  const handleClick = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleConfirm = async values => {
        
    update(
      { 
        type: 'update', 
        resource: 'bookings/delete_all', 
        payload : { id: events_id }
      },
      {
        onSuccess: ({ data }) => {
          setOpen(false);
          refresh();
        },
        onFailure: ( error ) => {                                        
          notify(error.message, 'error');
        }
      }
    );

  };
    
  return (
    <>            
      <Button onClick={handleClick} label={props.label_button} >
        <DeleteIcon />                
      </Button>

      <Confirm
        isOpen={open}
        loading={loading}
        title={translate('bookings.delete_all_button_title')}
        content={translate('bookings.delete_all_ask')}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
            
    </>
  );
};


export const BookingsSendAllButton = ({ events_id ,  ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [update, { loading, error }] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  


  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    update(
      { 
        type: 'update', 
        resource: 'bookings/send_email_all/' + values.type + '/' + props.action , 
        payload : { id: events_id, data : values }
      },
      {
        onSuccess: ({ data }) => {
          notify(translate('bookings.email_sent_ok'));
          refresh();
        },
        onFailure: ( error ) => {                                        
          notify(error.message, 'error');
        }
      }
    );
  };
    
  return (
    <>            
      <Button onClick={handleClick} label={translate('bookings.email_send_all_button_' + props.action)}  >
        <MailOutlineIcon />                
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('bookings.email_send_all_button_title_' + props.action)}
      >
        <DialogTitle>{translate('bookings.email_send_all_button_title_' + props.action)}</DialogTitle>

        <FormWithRedirect
          save={handleSubmit}
          initialValues={{ events_id : events_id, type: 0, action: props.action }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}> 
                    <RadioButtonGroupInput label="" row={false} source="type" choices={[
                      { id: 0, name: translate('bookings.email_only_pending') },
                      { id: 1, name: translate('bookings.email_all') }
                    ]} />
                  </Grid> 
                  <Grid item xs={12}> 
                    {translate('bookings.email_send_all_advise')}
                    <br /><br />
                  </Grid> 
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                  icon={<MailOutlineIcon />}
                  label={translate('bookings.email_send_button')}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};
