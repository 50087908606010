

import React, { useState, useEffect } from 'react';

import {
  List, 
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  DeleteButton,
  Edit,
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  Button ,
  CreateButton ,
  TopToolbar,
  Toolbar,
  FormTab,
  TabbedForm,
  FormDataConsumer,
  useListContext,
  Filter, required, ChipField,ReferenceArrayField, SingleFieldList, ArrayField,DateField ,DateInput, NumberInput,RadioButtonGroupInput,
  ResourceContextProvider,FunctionField, BooleanInput, Show, SimpleShowLayout, useRefresh, useRedirect,
  NumberField, ShowButton, useNotify, useDataProvider, Loading, Error, SaveButton, FormWithRedirect, useCreate, downloadCSV,
  ReferenceArrayInput,CheckboxGroupInput,  SelectField 
} from 'react-admin';

import { AccordionSection  } from '@react-admin/ra-form-layout';

import { useTranslate  } from '../common/language';
import { TextFieldShow } from '../components/TextFieldShow';
import MyLoading from '../components/MyLoading';
import { validateMaxLength, validateEmail, validatePercent  } from './../components/Validator';
import { RenderDate, RenderBoolean, ChipFieldUserName } from '../components/User';
import { choicesGenderUser } from './../common/constants';
import Chip from '@material-ui/core/Chip';

import Grid from '@mui/material/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@mui/material/Divider';
import RichTextInput from 'ra-input-rich-text';
    
export const fetchCustomFields = (associations_id, target, setCustomFields, setError, dataProvider, only_form_web, only_form_annuary) => {
    
  dataProvider.getList('custom_fields/public', {
    pagination: { page: 0, perPage: 1000 },
    sort: { field: 'priority', order: 'ASC' },
    filter: { associations_id: associations_id, target: target, only_form_web : only_form_web, only_form_annuary: only_form_annuary }
  })
    .then(response => {
      setCustomFields(response.data);
      setError(false);
    })
    .catch(error => {
      console.log(error);
      setError(true);
    });
};

export const CustomFieldsEdit  = ({ associations_id, target, title, prefix, only_form_web, only_form_annuary, ...props }) => {

  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate(); 
  const dataProvider = useDataProvider();
  
  const [customFields, setCustomFields] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);  
    
  useEffect(() => {
    fetchCustomFields(associations_id, target, setCustomFields, setError, dataProvider, only_form_web, only_form_annuary );
  }, []);

  if (!customFields) return null;
    
  return (         
    customFields.length > 0 && title ?
    <AccordionSection className="accordion" label={translate('custom_fields.title_' + target)} fullWidth defaultExpanded={true}>
        <CustomFieldsForm customFields={customFields} prefix={prefix ? prefix : ''} />
    </AccordionSection>
    : customFields.length > 0 ?
        <CustomFieldsForm customFields={customFields} prefix={prefix ? prefix : ''} />
    : null );
}

export const CustomFieldsForm = ({customFields, prefix, ...props}) => {
    
    const translate = useTranslate(); 
        
    return (
        customFields.map((element, index) => {

            let validations=[];
            if (element.required) validations.push(required());
            if (element.max_length != -1) validations.push(validateMaxLength(element.max_length, translate('common.validation.maxLength')));

            return (<Grid spacing={2}><Grid item xs={12} key={index}>
                { element.type === 1 ?
                <TextInput 
                    fullWidth 
                    source={prefix + 'custom_fields.field_' + element.id} 
                    label={element.name} 
                    validate={validations}                     
                />
                :
                element.type === 2 ?
                <TextInput 
                    fullWidth 
                    source={prefix + 'custom_fields.field_' + element.id} 
                    label={element.name} 
                    validate={validations} 
                    multiline
                />
                :
                element.type === 3 ?
                <RichTextInput 
                    fullWidth 
                    source={prefix + 'custom_fields.field_' + element.id} 
                    label={element.name} 
                    validate={validations} 
                    multiline
                />
                :
                element.type === 4 ?
                <NumberInput 
                    fullWidth 
                    source={prefix + 'custom_fields.field_' + element.id} 
                    label={element.name} 
                    validate={validations} 
                />
                :
                element.type === 5 ?
                <DateInput
                    fullWidth
                    source={prefix + 'custom_fields.field_' + element.id}
                    label={element.name}
                    validate={validations}                                        
                    resettable
                  />
                :
                element.type === 6 ?
                <BooleanInput 
                    fullWidth 
                    source={prefix + 'custom_fields.field_' + element.id} 
                    label={element.name}  
                    validate={validations} 
                />
                :
                element.type === 7 ?
                <SelectInput fullWidth resettable source={prefix + 'custom_fields.field_' + element.id} choices={element.choices} optionText="name" optionValue="id" label={element.name} validate={validations}  />
                :
                element.type === 8 ?                
                <FormDataConsumer>
                {({ formData, ...rest }) => {
                  
                  const fieldKey = prefix + `custom_fields.field_${element.id}`;
                  const values = formData[fieldKey] ? formData[fieldKey].split(',').map(Number) : [];

                  return (
                    <CheckboxGroupInput 
                      label={element.name}
                      source={fieldKey}
                      choices={element.choices}
                      row={true} 
                      validate={validations}
                      {...props}
                      format={(value) => (value ? value.split(',').map(Number) : [])}        
                      parse={(value) => (value ? value.join(',') : '')}
                      fullWidth
                    />
                  );
                }}
              </FormDataConsumer>
                : null }
                        
            </Grid></Grid>);
        })
    );
}
    
export const CustomFieldsShow = ({ associations_id, target, title, prefix, ...props }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  
  const [customFields, setCustomFields] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false); 
  
  let aux_prefix = prefix ? prefix : '';
  
  useEffect(() => {
    fetchCustomFields(associations_id, target, setCustomFields, setError, dataProvider, false, false);
  }, []);

  if (!customFields) return null;

  return customFields.length > 0 ? (
    <>
      {title ?
      <><br />
      <Typography variant="h6" className="grey8 titleUserShow">
        {translate('custom_fields.title_' + target)}
      </Typography>
      <Divider /></> 
      : null }

      <Grid container spacing={2}>
        {customFields.map((element, index) => {
          return (
            <Grid item xs={12} key={index}>
              <Grid item xs={4}>
                {element.type === 1 ||
                element.type === 2 ? (
                  <FunctionField
                    render={(record) => (
                      <TextFieldShow
                        source={
                          record[aux_prefix + 'custom_fields'] && record[aux_prefix + 'custom_fields']['field_' + element.id]
                            ? record[aux_prefix + 'custom_fields']['field_' + element.id]
                            : ''
                        }
                        label={element.name}
                      />
                    )}
                  />
                ) : element.type === 3 ? (
                  <FunctionField
                    render={(record) => (
                      <TextFieldShow
                        source={<div dangerouslySetInnerHTML={{ __html: record[aux_prefix + 'custom_fields'] && record[aux_prefix + 'custom_fields']['field_' + element.id]
                            ? record[aux_prefix + 'custom_fields']['field_' + element.id]
                            : '' }} />                          
                        }
                        label={element.name}
                      />
                    )}
                  />
                ) : element.type === 4 ? (
                  <FunctionField
                    render={(record) => (
                      <TextFieldShow
                        source={
                          record[aux_prefix + 'custom_fields'] && record[aux_prefix + 'custom_fields']['field_' + element.id]
                            ? record[aux_prefix + 'custom_fields']['field_' + element.id]
                            : ''
                        }
                        label={element.name}
                      />
                    )}
                  />
                ) : element.type === 5 ? (
                  <FunctionField
                    render={(record) => (
                      <TextFieldShow
                        source={
                          <RenderDate
                            source={
                              record[aux_prefix + 'custom_fields'] && record[aux_prefix + 'custom_fields']['field_' + element.id]
                                ? record[aux_prefix + 'custom_fields']['field_' + element.id]
                                : ''
                            }
                          />
                        }
                        label={element.name}
                      />
                    )}
                  />
                ) : element.type === 6 ? (
                  <FunctionField
                    render={(record) => (
                      <TextFieldShow
                        source={
                          <RenderBoolean
                            source={
                              record[aux_prefix + 'custom_fields'] && record[aux_prefix + 'custom_fields']['field_' + element.id]
                                ? record[aux_prefix + 'custom_fields']['field_' + element.id] === '1'
                                  ? true
                                  : false
                                : false
                            }
                          />
                        }
                        label={element.name}
                      />
                    )}
                  />
                
                ) : element.type === 7 ? (
                  <FunctionField
                    render={(record) => (
                      <TextFieldShow
                        source={
                          record[aux_prefix + 'custom_fields'] && record[aux_prefix + 'custom_fields']['field_' + element.id] && Number(record[aux_prefix + 'custom_fields']['field_' + element.id]) != null
                            ? element.choices.find(item => item.id === Number(record[aux_prefix + 'custom_fields']['field_' + element.id])).name 
                            : '' 
                        }
                        label={element.name}
                      />
                    )}
                  />
                
                ) : element.type === 8 ? (
                  <FunctionField
                    render={(record) => {
                      const arr =
                        record[aux_prefix + 'custom_fields'] && record[aux_prefix + 'custom_fields']['field_' + element.id]
                          ? record[aux_prefix + 'custom_fields']['field_' + element.id].split(',').map((value) => {
                              return {
                                id: Number(value),
                                name: element.choices.find(item => item.id === Number(value)).name,
                              };
                            })
                          : [];

                      return (
                              
                        <FunctionField
                            render={(record) => (
                              <TextFieldShow
                                source={
                                  <Grid container spacing={1}>
                                    {arr.map((item) => (
                                      <Grid item key={item.id}>
                                        <Chip label={item.name} />
                                      </Grid>
                                    ))}
                                  </Grid>
                                }
                                label={element.name}
                              />
                            )}
                        />                  
                        
                      );
                    }}
                  />
                ) : null}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  ) : null;
};

