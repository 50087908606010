// in src/posts.js
import React, { useState } from 'react';

import {
  List, 
  Datagrid,
  TextField,
  DeleteButton,
  Edit,
  SelectInput,
  TextInput,
  Button ,  
  Create,
  SimpleForm,
  CreateButton ,
  TopToolbar,
  FormTab,
  TabbedForm,
  ReferenceManyField,
  FormDataConsumer,
  useListContext, required,NumberInput,BooleanInput,SelectField,  FunctionField, ResourceContextProvider, useCreate, useRefresh, FormWithRedirect, Toolbar, SaveButton,
  useNotify,
  useRedirect,
  DateInput ,
  ReferenceArrayInput,
  SelectArrayInput,
  SimpleFormIterator,
  ArrayInput,
  ReferenceInput,
  ArrayField,
  SingleFieldList,
  ChipField,
  EditButton,
  useDataProvider,Show, SimpleShowLayout, useShowController, ShowButton, Filter, downloadCSV, AutocompleteInput
} from 'react-admin';

import { Link } from 'react-router-dom';

import jsonExport from 'jsonexport/dist';

import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';

import { useEffect } from 'react';
import { useFormState } from 'react-final-form';

import Chip from '@material-ui/core/Chip';
import { AccordionSection  } from '@react-admin/ra-form-layout';

import { makeStyles } from '@material-ui/core/styles';

import Divider from '@mui/material/Divider';


import { useTranslate  } from '../common/language';

import Typography from '@mui/material/Typography';


import RichTextInput from 'ra-input-rich-text';


import { TextFieldShow } from '../components/TextFieldShow';

import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import GiteIcon from '@mui/icons-material/Gite';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { ColorInput } from 'react-admin-color-input';

import { DocsList, DocsListShow } from './docs';
import { targets, choicesClassroomsType, choicesRealStateUse , modules } from './../common/constants';
import { subscriptionModule, setActivePermissionData,structArrayToIdList  } from '../common/utils';
import { dateOnlyToCatalan } from './../common/utils';  

import { CityInput } from './../components/Inputs';
import { ExportButton } from './../components/ExportButton';
import { BackLink } from './../components/BackLink';
import { RenderBoolean } from '../components/User';

import { RealStateActivitiesList,RealStateActivitiesEdit, RealStateActivitiesCreate  } from '../resources/real_state_activities';
import { RealStatePaymentsList,RealStatePaymentsEdit, RealStatePaymentsCreate  } from '../resources/real_state_payments';
import { InsurancesList } from '../resources/insurances';
import { AgreementsList } from '../resources/agreements';

import Box from '@material-ui/core/Box';

import { validateMaxLength, validateInteger  } from './../components/Validator';

export const RealStateList = (props) => {
  const translate = useTranslate();  
    
  let basePath='/real_state'; 
  let resource = 'real_state';
  let strRedirect = 'real_state';
    
  return (
    <ResourceContextProvider value={resource}>
      <List 
        basePath={basePath}   
        filter={{ associations_id : localStorage.getItem('associations_id') }}  
        actions={<ListActions />}    
        empty={<ListActions />}        
        bulkActionButtons={false}          
        perPage={1000}   
        pagination={false}
        sort={{ field: 'id', order: 'ASC' }} 
        label=""  
        title=""  
        resource={resource}
        filters={<MyFilter />} 
        filterDefaultValues={ { only_current : true, q: "" }}
      >        
        <Datagrid 
          rowClick="show"
           >  
          <Avatar><GiteIcon/></Avatar>                                  
          <TextField source="name" label={translate('common.name')} sortable={false} />    
          <TextField source="identifier" label={translate('real_state.identifier')} sortable={false} />             
          <TextField source="address" label={translate('real_state.address')} sortable={false} />   
          <TextField source="city" label={translate('real_state.city')} sortable={false} />   
          <FunctionField render={record => <span>{record.use > 0 ? translate('real_state.use_' + record.use) : record.use_others}</span> } label={translate('real_state.use')} sortable={false}  />  
        </Datagrid>        
      </List>
    </ResourceContextProvider>
  );
};

const MyFilter = (props) => {
    const translate = useTranslate(); 
    return (
    <Filter {...props}>         
        <TextInput label={translate('common.search')} source="q" alwaysOn validate={[validateMaxLength(50,translate('common.validation.maxLength'))]} resettable />                      
        <BooleanInput label={translate('real_state.filter_only_current')} source="only_current" alwaysOn />          
    </Filter>
);
};

export const RealStateDetails = ({ id, record, resource, ...props }) => {
  const translate = useTranslate(); 
        
  return (
    <div className="usersClassroomsExpand">        
      
        
    </div>
  );
};

const exporter = (items, translate, notify) => {
    
  const itemsForExport = items.map(item => {
    var itemForExport = {
      id : item.id,
      identifier : item.identifier,
      name : item.name,
      description: item.description, 
      address: item.address, 
      city: item.city, 
      use: item.use != null ? translate('real_state.use_'+item.use) : '', 
      use_others: item.use_others, 
      acquisition_date: dateOnlyToCatalan(item.acquisition_date), 
      sale_date: dateOnlyToCatalan(item.sale_date), 
      amount: item.amount != null ? item.amount.toFixed(2).replace('.', ',') : '',    
      guaranteed_value: item.guaranteed_value != null ? item.guaranteed_value.toFixed(2).replace('.', ',') : '',    
      cadastral_reference: item.cadastral_reference, 
      comments: item.comments,
      agreements: subscriptionModule(modules.AGREEMENTS) ? structArrayToIdList(item.agreements) : '',
      insurances: subscriptionModule(modules.INSURANCES) ? structArrayToIdList(item.insurances)  : '',
    };
    return itemForExport;
  });
  jsonExport(itemsForExport, {
    headers: ['id', 'identifier', 'name','description','address','city','use','use_others','acquisition_date','sale_date','amount','guaranteed_value','cadastral_reference','comments','agreements','insurances'],
    rename: [translate('common.id'), translate('real_state.identifier'), translate('common.name'), translate('real_state.description'), 
        translate('real_state.address'), translate('real_state.city'), translate('real_state.use'), translate('real_state.use_others'), 
        translate('real_state.acquisition_date'), translate('real_state.sale_date'), translate('real_state.amount'), translate('real_state.guaranteed_value'), 
        translate('real_state.cadastral_reference'),translate('real_state.comments'),translate('real_state.agreements'),translate('real_state.insurances') ],        
        
  }, (err, csv) => {
    const csv_new = '\uFEFF' + csv;
    downloadCSV(csv_new, translate('real_state.exporter_file_name') ); 
    notify(translate('common.download_ok'),"success");
  });
};



const ListActions = (props) => {
    const translate = useTranslate(); 
        
    let filterExport = { 
        associations_id: localStorage.getItem('associations_id'),        
        q: props.filterValues && props.filterValues.q ? props.filterValues.q : '',
        only_current: props.filterValues && props.filterValues.only_current ? props.filterValues.only_current : false
    };

    return (
    <TopToolbar>     
        <MyCreateButton {...props} />      
        <ExportButton maxResults="100000" filterValues={filterExport} alwaysEnabled={false} exporter={exporter} sort={{ field: 'id', order: 'DESC' }} /> 
    </TopToolbar>
    );
};

export const MyCreateButton = ({ users_id , ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('real_state');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  
  const redirect = useRedirect();
        
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {                    
          redirect('/real_state/' + data.id);
        
        },
        onFailure: ( error ) => {
          notify( error.message , 'error');
        }
      }
    );
  };
    
  return (
    <>            
      <Button onClick={handleClick} label={translate('real_state.create')}>
        <IconContentAdd />                
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('real_state.create')}
      >
        <DialogTitle>{translate('real_state.create')}</DialogTitle>

        <FormWithRedirect
          save={handleSubmit}
          initialValues={{ associations_id: localStorage.getItem('associations_id') }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>
                <Grid container spacing={1}>                                     
                  <Grid item xs={12}>                                               
                    <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
                  </Grid>
                </Grid> 
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

const MyActions = ({ basePath, data, resource }) => {

  const translate = useTranslate();   
    
  let backLink = '/real_state';
  return <BackLink link={backLink} title={data && data.name ? data.name : null} />;
        
};


export const RealStateEdit  = ({ id, ...props }) => {

  const translate = useTranslate();        
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  let strRedirect =  `/real_state/${id}`;    
    
  const onSuccess = ({ data }) => {
    notify(translate('common.notification.updated'));        
    redirect('/real_state');        
  };
        
  return (

    <Edit 
      title={<ElementTitle {...props}  />} 
      {...props} 
      id={id}
      mutationMode="pessimistic"
      actions={<RealStateActions show={false} {...props} />}
      onSuccess={onSuccess}   
    >
        
      <TabbedForm        
        toolbar={<FormToolbar />}
      >    
        <FormTab
          label={translate('common.data')}
          contentClassName={classes.tab}
          path="" 
                
        > 
               
          <FormDataConsumer>
            {({ formData, ...rest }) => 
              <>                              
                <br/>
                <Grid container spacing={2}>
                  <Grid item xs={9}>   
                    <TextInput fullWidth autoFocus source="name" label={translate('common.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]}  />
                  </Grid> 
                  <Grid item xs={3}>   
                    <TextInput fullWidth autoFocus source="identifier" label={translate('real_state.identifier')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]}  />
                  </Grid>                   
                  <Grid item xs={12}>
                    <TextInput fullWidth source="description" label={translate('real_state.description')} />                             
                  </Grid>
                  <Grid item xs={3}> 
                    <SelectInput fullWidth label={translate('real_state.use')} source="use" choices={choicesRealStateUse(translate)} />
                  </Grid>   
                  <Grid item xs={9}>   
                    { formData.use === 0 ?
                        <TextInput fullWidth autoFocus source="use_others" label={translate('real_state.use_others')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]}  />
                    : null }
                  </Grid> 
                  <Grid item xs={6}>
                    <TextInput fullWidth source="address" label={translate('real_state.address')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />                             
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput fullWidth source="city" label={translate('real_state.city')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />                             
                  </Grid>
                  <Grid item xs={2}>
                    <DateInput source="acquisition_date" fullWidth label={translate('real_state.acquisition_date')} />
                  </Grid>
                  <Grid item xs={2}>
                    <DateInput source="sale_date" fullWidth label={translate('real_state.sale_date')} />
                  </Grid>                  
                  <Grid item xs={4}>
                    <TextInput fullWidth source="cadastral_reference" label={translate('real_state.cadastral_reference')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />                             
                  </Grid>
                  <Grid item xs={2}>
                    <NumberInput fullWidth source="amount" label={translate('real_state.amount')} />                             
                  </Grid>
                  <Grid item xs={2}>
                    <NumberInput fullWidth source="guaranteed_value" label={translate('real_state.guaranteed_value')} />                             
                  </Grid>
                  { subscriptionModule(modules.INSURANCES) ? 
                  <Grid item xs={12}>
                    <AccordionSection className="accordion" label={translate('real_state.insurances')} fullWidth defaultExpanded={true}>
                        <ArrayInput source="insurances" label="">
                            <SimpleFormIterator 
                              disableReordering
                              getItemLabel={index => ''}
                            >
                              <ReferenceInput 
                                sort={{ field: 'id', order: 'ASC' }} 
                                perPage={10000} 
                                label={translate('real_state.insurance')} 
                                source="id" 
                                reference="insurances"
                                filter={{ associations_id: localStorage.getItem('associations_id') }}
                                fullWidth
                                >
                                    <SelectInput fullWidth optionText="name" optionValue="id" validate={required()} />
                              </ReferenceInput> 
                            </SimpleFormIterator>
                        </ArrayInput>
                    </AccordionSection>   
                  </Grid>  
                  : null }
                  { subscriptionModule(modules.AGREEMENTS) ? 
                  <Grid item xs={12}>
                    <AccordionSection className="accordion" label={translate('real_state.agreements')} fullWidth defaultExpanded={true}>
                        <ArrayInput source="agreements" label="">
                            <SimpleFormIterator 
                              disableReordering
                              getItemLabel={index => ''}
                            >
                              <ReferenceInput 
                                sort={{ field: 'id', order: 'ASC' }} 
                                perPage={10000} 
                                label={translate('real_state.agreements')} 
                                source="id" 
                                reference="agreements"
                                filter={{ associations_id: localStorage.getItem('associations_id') }}
                                fullWidth
                                >
                                    <SelectInput fullWidth optionText="name" optionValue="id" validate={required()} />
                              </ReferenceInput> 
                            </SimpleFormIterator>
                        </ArrayInput>
                    </AccordionSection>   
                  </Grid>  
                  : null }
                  <Grid item xs={12}>
                    <TextInput fullWidth source="comments" label={translate('real_state.comments')} />                             
                  </Grid>
                  
                </Grid>                
                
              </>
            }
          </FormDataConsumer>

        </FormTab> 
                
        <FormTab
            label={translate('real_state.activities')}
            contentClassName={classes.tab}
            path="activities"                 
        > 
          
          <RealStateActivitiesList real_state_id={id} />
          
          <br />
          <br />
        </FormTab>
        
        <FormTab
            label={translate('real_state.payments')}
            contentClassName={classes.tab}
            path="payments"                 
        > 
          
          <RealStatePaymentsList real_state_id={id} />
          
          <br />
          <br />
        </FormTab>
        
        <FormTab
            label={translate('real_state.docs')}
            contentClassName={classes.tab}
            path="docs"                 
        > 
            
            <br />
            <AccordionSection className="accordion" label={translate('real_state.docs_deed')} fullWidth defaultExpanded={true}>
              <DocsList target={targets.REAL_STATE_DEED} items_id={id} />
            </AccordionSection>   
          
            <br />
            <AccordionSection className="accordion" label={translate('real_state.docs_map')} fullWidth defaultExpanded={true}>
              <DocsList target={targets.REAL_STATE_MAP} items_id={id} />
            </AccordionSection> 
          
          <br />
          <br />
        </FormTab>
                
      </TabbedForm>
    </Edit>
  );
};

const OptionForm= ({edit, ...props}) => {
  const translate = useTranslate();   
  const { values } = useFormState();
  return (
    <RowForm {...props}>
      <ReferenceInput label={translate('subscriptions.module')} source="modules_id" reference="modules"  sort={{ field: 'priority', order: 'ASC' }} >
        { edit ? (
          <SelectInput fullWidth optionText="name" optionValue="id" validate={required()} disabled />
        ) : (
          <SelectInput fullWidth optionText="name" optionValue="id" validate={required()} />
        ) }
      </ReferenceInput> 
    </RowForm>
  );
};

const ElementTitle = ({ record, ...props }) => {    
  const translate = useTranslate(); 
  return (<span>{translate('common.edit')} {record ? `"${record.name}"` : ''}</span>);
};

const FormToolbar = ({ id , ...props }) => {
    
  let redirect = '/classrooms';
        
  return (
    <Toolbar {...props} className="user_form_toolbar" >
      <SaveButton />
      <DeleteButton redirect={redirect} mutationMode="pessimistic" />
    </Toolbar>
  );
};

const EmptyClassrooms = ({ associations_id , ...props}) => {
  const translate = useTranslate(); 
  const { basePath, resource } = useListContext();
  return (
    <Box textAlign="center" m={1} >
      <br />
      <Typography variant="h5" paragraph className="grey8">
        {translate('classrooms.no_classrooms_title')} 
      </Typography>
      <Typography variant="body1" className="grey8">
        {translate('classrooms.no_classrooms_ask')} 
      </Typography>
      <br />
      <CreateButton />
    </Box>
  );
};


const useStyles = makeStyles({
  comment: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    maxWidth: '100%',
    display: 'block',
  },
});



export const RealStateShow = (props) => {

  const translate = useTranslate();   
  const [info, setInfo] = useState();
  const { record } = useShowController(props);
  
  if (!record) return null;
  
  return (
    <Show actions={<RealStateActions show={true} />} {...props} >
        <SimpleShowLayout>  
        
          <Typography variant="h6" className="grey8 titleUserShow">                        
            {translate('common.data')}
          </Typography>
          <Divider fullWidth className="" />
          <br />  
          
            <Grid container spacing={2}>
            <Grid item xs={9}>                    
              <TextFieldShow source={record.name} label={translate('common.name')} />                     
            </Grid>
            <Grid item xs={3}>                    
              <TextFieldShow source={record.identifier} label={translate('real_state.identifier')} />                    
            </Grid>
            <Grid item xs={12}>                    
              <TextFieldShow source={record.description} label={translate('real_state.description')} />                     
            </Grid>
            <Grid item xs={12}>                    
              <TextFieldShow source={record.use > 0 ? translate('real_state.use_'+record.use) : record.use_others} label={translate('real_state.use')} />
            </Grid>
            <Grid item xs={6}>                    
              <TextFieldShow source={record.address} label={translate('real_state.address')} />                    
            </Grid>
            <Grid item xs={6}>                    
              <TextFieldShow source={record.city} label={translate('real_state.city')} />                     
            </Grid>
            <Grid item xs={2}>  
              <TextFieldShow source={dateOnlyToCatalan(record.acquisition_date)} label={translate('real_state.acquisition_date')} />  
            </Grid>
            <Grid item xs={2}>  
              <TextFieldShow source={dateOnlyToCatalan(record.sale_date)} label={translate('real_state.sale_date')} /> 
            </Grid>
            <Grid item xs={4}>  
              <TextFieldShow source={record.cadastral_reference} label={translate('real_state.cadastral_reference')} />                 
            </Grid>
            <Grid item xs={2}>  
              <TextFieldShow source={record.amount} label={translate('real_state.amount')} />            
            </Grid>
            <Grid item xs={2}>  
              <TextFieldShow source={record.guaranteed_value} label={translate('real_state.guaranteed_value')} />           
            </Grid>
            <Grid item xs={12}>  
              <TextFieldShow source={record.comments} label={translate('real_state.comments')} />
            </Grid>
          </Grid>

          { subscriptionModule(modules.INSURANCES) ? <>
          <br />
          <Typography variant="h6" className="grey8 titleUserShow">                        
            {translate('real_state.insurances')}
          </Typography>
          <Divider fullWidth className="" />          
          <InsurancesList real_state_id={record.id} show={true} /> 
          </> : null }
          
          { subscriptionModule(modules.AGREEMENTS) ? <>
          <br />
          <Typography variant="h6" className="grey8 titleUserShow">                        
            {translate('real_state.agreements')}
          </Typography>
          <Divider fullWidth className="" />          
          <AgreementsList real_state_id={record.id} show={true} /> 
          </> : null }
          
          <br />
          <Typography variant="h6" className="grey8 titleUserShow">                        
            {translate('real_state.activities')}
          </Typography>
          <Divider fullWidth className="" />
          
          <RealStateActivitiesList real_state_id={record.id} show={true} /> 

          <br />
          <Typography variant="h6" className="grey8 titleUserShow">                        
            {translate('real_state.payments')}
          </Typography>
          <Divider fullWidth className="" />
          
          <RealStatePaymentsList real_state_id={record.id} show={true} /> 

          <br />
          <Typography variant="h6" className="grey8 titleUserShow">                        
            {translate('real_state.docs_deed')}
          </Typography>
          <Divider fullWidth className="" />
          <br />  
          <DocsListShow target={targets.REAL_STATE_DEED} items_id={record.id} /> 

          <br />
          <Typography variant="h6" className="grey8 titleUserShow">                        
            {translate('real_state.docs_map')}
          </Typography>
          <Divider fullWidth className="" />
          <br />  
          <DocsListShow target={targets.REAL_STATE_MAP} items_id={record.id} />
          
      
        </SimpleShowLayout> 
    </Show>
  );
};


const RealStateActions = ({ basePath, data, resource_name, ...props }) => {
  const translate = useTranslate();  
    
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
    
  const [info, setInfo] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const notify = useNotify();           
        
  return (
    <>      
        <TopToolbar>
            <Grid container spacing={2}>
              <Grid item xs={8}>                    
                <Typography variant="h5" className="grey8">
                  {data ? data.name : ''}                    
                </Typography>
              </Grid>
              <Grid item xs={4} align="right">                    
                {props.show ?
                    <EditButton basePath={basePath} record={data} /> 
                :
                    <ShowButton basePath={basePath} record={data} />
                }                
              </Grid>
            </Grid>            
        </TopToolbar>
    </>    
  );
};

export const RealStateCreate  = ({ id, ...props }) => {

  const translate = useTranslate();   
  const [info, setInfo] = useState();
    
  return (

    <Create {...props} >
      <SimpleForm 
        initialValues={{ associations_id: localStorage.getItem('associations_id') }}
        redirect="list"
      >                
        <Grid container spacing={2}>
          <Grid item xs={12}>                    
            <Typography variant="h5" className="grey">{translate('real_state.create')}</Typography>
          </Grid>  
          <Grid item xs={11}>                    
            <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
          </Grid>          
        </Grid>   

      </SimpleForm> 
    </Create>
  );
};


export const chipsRealState = (real_state) => {
  if (!real_state) {
    return null; 
  }
  const chips = real_state.map((item) => (
    <Link key={item.id} to={`/real_state/${item.id}/show`} className="chip" >
      <Chip label={item.name} clickable />
    </Link>
  ));

  return <div>{chips}</div>;
};

export const chipRealState = (real_state_id, real_state_name) => {

  const chips = 
    <Link key={real_state_id} to={`/real_state/${real_state_id}/show`} className="chip" >
      <Chip label={real_state_name} clickable />
    </Link>
  ;

  return <div>{chips}</div>;
};

export const RealStateInput = ({ ...props }) => {

  const translate = useTranslate(); 
    
  const dataProvider = useDataProvider();
  const [info, setInfo] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
    
  const disabled = props.disabled && props.disabled==true ? true : false;
  const resettable = props.resettable != null ? props.resettable : true;
  const label = props.label ? props.label : translate('common.name');
  const validate = props.required ? [required()] : [];
    
  useEffect(() => {
    dataProvider
      .getList('real_state', {
        pagination: { page: 0, perPage: 10000 },
        sort: { field: 'id', order: 'ASC' },
        filter: { associations_id: localStorage.getItem('associations_id'), real_state_id: 0 },
      })
      .then(({ data }) => {
        setInfo(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) return (<span>{translate('common.loading')}</span>);
  if (error) return null;
  if (!info) return null;
  
  return (
    <AutocompleteInput 
      optionText={ (record)=> record.name } 
      suggestionLimit={50}
      resettable={resettable}
      clearAlwaysVisible={true}
      validate={validate}
      fullWidth
      disabled={disabled}
      choices={info}
      source={props.source}
      label={label}
    />
                
  );
};

