import React, { useState, useEffect } from 'react';

import { 
  useDataProvider, AutocompleteInput
} from 'react-admin';

import { useTranslate  } from '../common/language';

export const CityInput = ({...props}) => {
  const translate = useTranslate(); 
  const dataProvider = useDataProvider();
  const [cities, setCities] = useState();
    
  useEffect(() => {
        
    dataProvider
      .getList('cities', {
        pagination: { page: 0, perPage: 10000 },
        sort: { field: 'name', order: 'ASC' },
        filter: { },
      })
      .then(({ data }) => {
        setCities(data);
      })
      .catch((error) => {
      });
                
  }, []);
    
  if (!cities) return null;
    
  return (
    <AutocompleteInput 
      optionText={ (record)=> record.name } 
      suggestionLimit={10000}
      resettable={true}
      clearAlwaysVisible={true}
      fullWidth
      disabled={false}
      choices={cities}
      source="cities_id"
      label={translate('events.city')}
    />
  );
};

