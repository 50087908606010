// in src/posts.js
//import * as React from "react";
import React, { useState } from 'react';

import {
  Datagrid,
  TextField,
  ReferenceInput,
  SelectInput,
  TextInput, 
  ReferenceManyField,
  useTranslate,NumberInput,NumberField,DateInput,required, DateField
} from 'react-admin';

import { useFormState, useForm } from 'react-final-form';

import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';



import { validateMaxLength, validateInteger  } from './../components/Validator';


const choicesPeriodQuote = [
  { id: 0, name: 'Mensual' },
  { id: 1, name: 'Bimensual' },
  { id: 2, name: 'Trimestral' },
  { id: 3, name: 'Quatrimestral' },
  { id: 4, name: 'Semestral' },
  { id: 5, name: 'Anual' },
];

export const UsersGroupsQuotesList= ({users_id, ...props}) => {
  const translate = useTranslate();   
  const { values } = useFormState();

  return (
    <ReferenceManyField                    
      reference="users_groups_quotes"
      target="users_id"
      label=""
      perPage={1000}
    >

      <EditableDatagrid                       
        createForm={<UsersGroupsQuotesEdit initialValues={{ users_id: users_id }} />}
        editForm={<UsersGroupsQuotesEdit />}
        label=""
        hasCreate
        className="table-quotes-periods"
      >
        <TextField source="group.name" label={translate('users_groups_quotes.group')} sortable={false} />
        <TextField source="group_quote.name" label={translate('users_groups_quotes.group_quote')} sortable={false} />
        <TextField source="groups_quotes_periods_name" label={translate('users_groups_quotes.group_quote_period')} sortable={false} /> 
        <NumberField source="discount_percent" label={translate('users_groups_quotes.discount_percent')} sortable={false} /> 
        <NumberField source="discount_quantity" label={translate('users_groups_quotes.discount_quantity')} sortable={false} /> 
        <NumberField source="manual_quantity" step={1} label={translate('users_groups_quotes.manual_quantity')} sortable={false} />
        <DateField source="discount_ini" label={translate('users_groups_quotes.discount_ini')} sortable={false} />
        <DateField source="discount_end" label={translate('users_groups_quotes.discount_end')} sortable={false} />
        <TextField source="comment" label={translate('users_groups_quotes.comment')} sortable={false} /> 
            
      </EditableDatagrid>

    </ReferenceManyField> 
  );
};


const OptionsQuotesInput = props => {
  const translate = useTranslate();   
  const { values } = useFormState();
    
  //console.log('OptionsQuotesInput');
  //console.log(values);
  //console.log(props);
    
  return (
    values.groups_id ? 
      <ReferenceInput label={translate('users_groups_quotes.group_quote')} source="groups_quotes_id" reference="groups_quotes" filter={{ groups_id : values.groups_id }} perPage={1000} >
        <SelectInput fullWidth optionText="name" optionValue="id" validate={required()} resettable />
      </ReferenceInput> : ''
  );
};

const OptionsQuotesPeriodsInput = props => {
  const translate = useTranslate();   
  const { values } = useFormState();

  //console.log('OptionsQuotesPeriodsInput');
  //console.log(values);
  //console.log(props);

  return (
    (values.groups_id && values.groups_quotes_id ) ? 
      <ReferenceInput label={translate('users_groups_quotes.group_quote_period')} source="groups_quotes_periods_id" reference="groups_quotes_periods" filter={{ groups_quotes_id : values.groups_quotes_id }} perPage={1000} >
        <SelectInput fullWidth optionText="name" optionValue="id" resettable />
      </ReferenceInput> : ''
  );
};

/*
 * <OptionsQuotesInput source="groups_quotes_id"   />
        <OptionsQuotesPeriodsInput source="groups_quotes_periods_id" /> 
 */

/*
 * onChange={event => {
            event.preventDefault();  
            form.change('groups_quotes_id', null);
            form.change('groups_quotes_periods_id', null);
            
            }}
 */
export const UsersGroupsQuotesEdit= (props) => {
  const translate = useTranslate();   
  //const form = useForm();
  const { values } = useFormState();
  const [groups_id, setGroupsId] = useState();
  const [groups_quotes_id, setGroupsQuotesId] = useState();
  const [groups_quotes_periods_id, setGroupsQuotesPeriodsId] = useState();
    
  const initialFormState = { users_id: values.id };
  const { register, handleSubmit, setValue, reset, watch } = useForm({
    defaultValues: props.initialValues ? props.initialValues : initialFormState,
  });

  /*
    const handleChange = (e) => console.log('HOLA');
    onChange={handleChange}
    */

  return (
    <RowForm {...props}  >
      <ReferenceInput 
        label={translate('users_groups_quotes.group')} 
        source="groups_id" 
        reference="groups" 
        filter={{ users_id : values.id, associations_id: localStorage.getItem('associations_id') }}  
        perPage={1000}
      >
        <SelectInput fullWidth optionText="name" optionValue="id" validate={required()} />
      </ReferenceInput> 
      <OptionsQuotesInput source="groups_quotes_id"   />
      <OptionsQuotesPeriodsInput source="groups_quotes_periods_id" /> 
      <NumberInput fullWidth source="discount_percent" step={1} label={translate('users_groups_quotes.discount_percent')} validate={[validateInteger(translate('common.validation.number'))]} />
      <NumberInput fullWidth source="discount_quantity" step={1} label={translate('users_groups_quotes.discount_quantity')} validate={[validateInteger(translate('common.validation.number'))]} />
      <NumberInput fullWidth source="manual_quantity" step={1} label={translate('users_groups_quotes.manual_quantity')} validate={[validateInteger(translate('common.validation.number'))]} />
      <DateInput fullWidth source="discount_ini" step={1} label={translate('users_groups_quotes.discount_ini')} />
      <DateInput fullWidth source="discount_end" step={1} label={translate('users_groups_quotes.discount_end')} />
      <TextInput fullWidth source="comment" label={translate('users_groups_quotes.comment')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />   
    </RowForm>
  );
};

export const UsersGroupsQuotesShowList= (props) => {
  const translate = useTranslate();   

  return (
    <ReferenceManyField                    
      reference="users_groups_quotes"
      target="users_id"
      label=""
      perPage={1000}
    >

      <Datagrid          
        hasCreate
        className="table-quotes-periods users_remittances_list header_grey6"
      >
        <TextField source="group.name" label={translate('users_groups_quotes.group')} sortable={false} />
        <TextField source="group_quote.name" label={translate('users_groups_quotes.group_quote')} sortable={false} />
        <TextField source="groups_quotes_periods_name" label={translate('users_groups_quotes.group_quote_period')} sortable={false} /> 
        <NumberField source="discount_percent" label={translate('users_groups_quotes.discount_percent')} sortable={false} /> 
        <NumberField source="discount_quantity" label={translate('users_groups_quotes.discount_quantity')} sortable={false} /> 
        <NumberField source="manual_quantity" step={1} label={translate('users_groups_quotes.manual_quantity')} sortable={false} />
        <DateField source="discount_ini" label={translate('users_groups_quotes.discount_ini')} sortable={false}/>
        <DateField source="discount_end" label={translate('users_groups_quotes.discount_end')} sortable={false}/>
        <TextField source="comment" label={translate('users_groups_quotes.comment')} sortable={false} /> 
            
      </Datagrid>

    </ReferenceManyField> 
  );
};



