// in src/Dashboard.js
import React, { useState, useEffect, createElement, Component, PureComponent } from 'react';


import ProgressBar from 'react-bootstrap/ProgressBar'

import { PieChart, Pie, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ComposedChart, Line, Area , LineChart} from 'recharts';


import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';

import Grid from '@mui/material/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@mui/material/Typography';

import EqualizerIcon from '@mui/icons-material/Equalizer';
import CakeIcon from '@mui/icons-material/Cake';
import TodayIcon from '@mui/icons-material/Today';

import EuroIcon from '@mui/icons-material/Euro';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { AssociationsList,AssociationsEdit,AssociationsCreate  } from '../resources/associations';

import { subscriptionModule, subscriptionModuleOption , LinearProgressWithLabel, dateAndTimeToCatalan } from '../common/utils';

import {
    SimpleList, 
    Datagrid,
    TextField,
    ReferenceField,
    EditButton,
    DeleteButton,
    ShowButton,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    TextInput,
    CreateButton ,
    ExportButton,
    TopToolbar,
    Pagination,
    FormTab,
    TabbedForm,
    ReferenceManyField,
    Title,
    FormDataConsumer,
    useListContext,
    Filter, required, ChipField,ReferenceArrayField, SingleFieldList, ArrayField,DateField ,DateInput,NumberInput,RadioButtonGroupInput,BooleanInput,SelectField,
    useGetOne , FunctionField, ResourceContextProvider, useCreate, useRefresh, FormWithRedirect, Toolbar, SaveButton, useDataProvider, Loading, Error, NumberField,
    CheckboxGroupInput,
    ReferenceArrayInput,
    SelectArrayInput

    
} from 'react-admin';

import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Chip from '@material-ui/core/Chip';

import { IconButton } from '@mui/material';
import MenuBookIcon from '@mui/icons-material/MenuBook';

import { i18nProvider, useTranslate  } from '../common/language';
import { apiUrl, apiOrigin, apiDefaultLanguage, targets, targetsResources, modules} from './../common/constants';

import { makeStyles } from '@material-ui/core/styles';

import { UserAvatarIcon, UserFullNameTxt, RenderAge, RenderDate } from '../components/User';

import MyLoading from '../components/MyLoading';
 
import { Map } from '../components/Map';




  
const useStylesRemittanceInfo = makeStyles(theme => ({
    card: {
        minHeight: 52,
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        '& a': {
            textDecoration: 'none'
        },
        marginBottom: 20
    },
    main: (props: Props) => ({
        overflow: 'inherit',
        padding: 16,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .icon': {
            color: theme.palette.type === 'dark' ? 'inherit' : '#FF6600',
        },
    }),
    title: {}
}));


      
export default (props) => {
    const classes = useStylesRemittanceInfo(props);
    const translate = useTranslate(); 
    const dataProvider = useDataProvider();
    const [info, setInfo] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
        
    useEffect(() => {
        
        if (localStorage.getItem('associations_id') !== 0)
        {
            dataProvider.getOne('dashboard', { id: localStorage.getItem('associations_id') })
                .then(({ data }) => {      
                    setInfo(data);
                    setLoading(false);
                })
                .catch(error => {
                    setLoading(false);    
                });
        }
        else
        {
            setLoading(false);  
        }
    }, []);

    if (loading) return <MyLoading />;
    if (error) return <Error />;
    if (!info) return (
            <AssociationsList {...props} />
    );


    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#9C9C9C'];
    
    const currentYear = new Date().getFullYear();

    const data_gender = [
      { name: translate('dashboard.members_legend_female'), value: info.statistics ? info.statistics.members_female_num : 0},
      { name: translate('dashboard.members_legend_male'), value: info.statistics ? info.statistics.members_male_num : 0},
      { name: translate('dashboard.members_legend_no_binary'), value: info.statistics ? info.statistics.members_nobinary_num : 0},      
    ];
    
    const federation_data_gender = [
      { name: translate('dashboard.members_legend_female'), value: info.statistics ? info.statistics.federations_gender_female : 0},
      { name: translate('dashboard.members_legend_male'), value: info.statistics ? info.statistics.federations_gender_male : 0},
      { name: translate('dashboard.members_legend_no_binary'), value: info.statistics ? info.statistics.federations_gender_nobinary : 0 },      
    ];
    
    /* Treiem els de gènere no informat. No han d'aparèixer al gràfic. 
     * { name: translate('dashboard.members_legend_no_informed'), value: info.statistics.members_noinformed_num },*/
    
    return (
        <>

        <Box sx={{ flexGrow: 1 }} className="dashboard_box">   
        
            {!subscriptionModule(modules.GESTIO) ? <div class="alert alert-danger">
                {translate('dashboard.alert_subscription_admin')}
            </div> : null }
          
          <Grid container spacing={2}>

            <Grid item xs={12} md={8}>
            
            <Grid container spacing={2}>
                <Grid item xs={12} md={subscriptionModule(modules.FACM) ? 6 : 5}>

                {subscriptionModule(modules.RECERCAT) ?
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {translate('recercat_centers.dashboard_resume_title')}
                        </Typography>

                        <Grid container spacing={0}>
                        <Grid item xs={12}>
                        <Box textAlign="left">
                            <br/><Typography className={classes.title} color="textSecondary">{translate('recercat_centers.dashboard_resume_num_members')}</Typography>
                            <Typography variant="h5" component="h2">{info.statistics.members_total}</Typography><br/>                         
                        </Box>
                        </Grid>
                        
                        <Grid item xs={12}>
                        <Box textAlign="left">
                            <Typography className={classes.title} color="textSecondary">{translate('recercat_centers.dashboard_resume_num_centers')}</Typography>
                            <Typography variant="h5" component="h2">{info.recercat_num_centers}</Typography>                        
                        </Box>
                        </Grid>
                        
                        </Grid>

                      </CardContent>

                    </Card>
                : subscriptionModule(modules.FEDERATION) ?
                    
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {info.groups_members_name}
                        </Typography>

                        <Grid container spacing={0}>
                        <Grid item xs={6}>
                        <Box textAlign="left">
                            <Typography className={classes.title} color="textSecondary">{translate('dashboard.federation_members')}</Typography>
                            <Typography variant="h5" component="h2">{info.statistics.federations_total}</Typography>
                            <br />
                            <Typography className={classes.title} color="textSecondary">{translate('dashboard.federation_gender')}<br />({info.statistics.federations_gender_associations} {translate('dashboard.federation_associations')},{info.statistics.federations_gender_people} {translate('dashboard.people')})</Typography>                            
                        </Box>
                        </Grid>
                        
                        <Grid item xs={6}>
                        <Box textAlign="left">
                            <Typography className={classes.title} color="textSecondary">{translate('dashboard.federation_avg_age')}<br />({info.statistics.federations_avg_age_associations} {translate('dashboard.federation_associations')},{info.statistics.federations_avg_age_people} {translate('dashboard.people')})</Typography>
                            <Typography variant="h5" component="h2">{info.statistics.federations_avg_age} {translate('dashboard.age_sufix')}</Typography>                        
                        </Box>
                        </Grid>
                        
                        </Grid>

                        <ResponsiveContainer width="100%" height={260}>
                        <PieChart >
                        <Tooltip />
                        <Legend />
                          <Pie
                            data={federation_data_gender}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                          >                          
                            {data_gender.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                          </Pie>
                        </PieChart>
                        </ResponsiveContainer>

                      </CardContent>

                      
                      <CardActions>
                      
                        {false ?
                        <CreateButton
                            component={Link}
                            to={{
                                pathname: '/users/create',
                                search: `?groups_id=${info.statistics.members_group}`,
                            }}
                            label={translate('dashboard.members_add')}
                        /> : null }
                        
                        {false ?
                        <ShowButton 
                            component={Link}
                            to={{
                                pathname: `/groups-tree/${info.statistics.members_group}`
                            }} 
                            label={translate('dashboard.members_view')}
                        />
                        : null }
                        
                        {false && info.statistics.members_group_pending > 0 ?
                        <ShowButton 
                            component={Link}
                            to={{
                                pathname: `/groups-tree/${info.statistics.members_group_pending}`
                            }} 
                            label={translate('dashboard.members_view_requests')+' ('+info.statistics.members_total_pending+')'}
                        /> : null }
                      </CardActions>
                      
                    </Card> 
                    : subscriptionModule(modules.FACM) ?                     
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {translate('dashboard.facm_auditory')}
                        </Typography>

                        <FacmChart title={translate('dashboard.facm_num_events')} dataKey="events" data={info.facm_auditory} current_year={currentYear} color_cy="#2B80D5" color_ly="#A7CAED" />
                        <FacmChart title={translate('dashboard.facm_num_entities')} dataKey="entities" data={info.facm_auditory} current_year={currentYear} color_cy="#FF9A09" color_ly="#FFD291" />
                        <FacmChart title={translate('dashboard.facm_num_assistants')} dataKey="assistants" data={info.facm_auditory} current_year={currentYear} color_cy="#198200" color_ly="#8ED17F" />

                      </CardContent>

                    </Card>  
                    :
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {info.groups_members_name}
                        </Typography>

                        <Grid container spacing={0}>
                        <Grid item xs={6}>
                        <Box textAlign="left">
                            <Typography className={classes.title} color="textSecondary">{translate('dashboard.statistics_total_members')}</Typography>
                            <Typography variant="h5" component="h2">{info.statistics.members_total}</Typography>
                            <br />
                            <Typography className={classes.title} color="textSecondary">{translate('dashboard.statistics_gender')} ({info.statistics.members_total_persons_gender} {translate('dashboard.people')})</Typography>                            
                        </Box>
                        </Grid>
                        
                        <Grid item xs={6}>
                        <Box textAlign="left">
                            <Typography className={classes.title} color="textSecondary">{translate('dashboard.statistics_avg_age')} ({info.statistics.members_total_persons_age} {translate('dashboard.people')})</Typography>
                            <Typography variant="h5" component="h2">{info.statistics.members_avg_birthday} {translate('dashboard.age_sufix')}</Typography>                        
                        </Box>
                        </Grid>
                        
                        </Grid>

                        <ResponsiveContainer width="100%" height={260}>
                        <PieChart >
                        <Tooltip />
                        <Legend />
                          <Pie
                            data={data_gender}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                          >                          
                            {data_gender.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                          </Pie>
                        </PieChart>
                        </ResponsiveContainer>

                      </CardContent>

                    </Card>     
                    }
                </Grid>
            
                { subscriptionModule(modules.FACM) ?
                <Grid item xs={12} md={6}>
                <Card className={classes.card}>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {translate('dashboard.facm_projects') }
                        </Typography>
                        
                        <FacmChart title={translate('dashboard.facm_num_events')} dataKey="events" data={info.facm_projects} current_year={currentYear} color_cy="#2B80D5" color_ly="#A7CAED" />
                        <FacmChart title={translate('dashboard.facm_num_entities')} dataKey="entities" data={info.facm_projects} current_year={currentYear} color_cy="#FF9A09" color_ly="#FFD291" />
                        <FacmChart title={translate('dashboard.facm_num_assistants')} dataKey="assistants" data={info.facm_projects} current_year={currentYear} color_cy="#198200" color_ly="#8ED17F" />
                      
                      </CardContent>                        
                    </Card> 
                </Grid>
                : null }
                
                {subscriptionModule(modules.RECERCAT) ?
                <Grid item xs={12} md={7}>
                       
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {translate('recercat_centers.dashboard_graphic_title')}
                        </Typography>

                        <br/><br/>
                        
                        <ResponsiveContainer width="100%" height={280}>
                        <LineChart
                          className="dashboard_chart_members"
                          data={info.members}
                          margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis  />
                          <Tooltip />
                          <Line name={translate('recercat_centers.dashboard_graphic_cards')} dataKey="no_informed" stackId="a" fill={COLORS[0]} />
                        </LineChart>
                        </ResponsiveContainer>
                        
                        <br/>

                      </CardContent>

                    </Card> 
                    
                </Grid> 
                : 
                subscriptionModule(modules.FEDERATION) ?
                
                <Grid item xs={12} md={7}>
                   <Card className={classes.card} >
                      <CardContent>      
                        <Typography gutterBottom variant="h5" component="div">
                          {translate('dashboard.federation_map')}
                        </Typography>
                        <Map data={info.statistics.map}/>                     
                      </CardContent>
                    </Card>  
                </Grid>                
                
                : !subscriptionModule(modules.FACM) ? 
                <Grid item xs={12} md={7}>
                       
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {translate('dashboard.members')}
                        </Typography>

                        <br/><br/>
                        <ResponsiveContainer width="100%" height={280}>
                        <BarChart
                          className="dashboard_chart_members"
                          data={info.members}
                          margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name"  />
                          <YAxis  />
                          <Tooltip />
                          <Legend />
                          <Bar name={translate('dashboard.members_legend_female')} dataKey="female" stackId="a" fill={COLORS[0]} />
                          <Bar name={translate('dashboard.members_legend_male')} dataKey="male" stackId="a" fill={COLORS[1]} />
                          <Bar name={translate('dashboard.members_legend_no_binary')} dataKey="no_binary" stackId="a" fill={COLORS[2]} />
                          <Bar name={translate('dashboard.members_legend_no_informed')} dataKey="no_informed" stackId="a" fill={COLORS[3]} />
                          <Bar name={translate('dashboard.members_legend_companies')} dataKey="companies" stackId="a" fill={COLORS[4]} />
                        </BarChart>
                        </ResponsiveContainer>

                        <br/>

                      </CardContent>

                    </Card> 
                    
                </Grid>  
                : null
                }

                { subscriptionModule(modules.REMITTANCES) && !subscriptionModule(modules.FACM) ?
                <Grid item xs={12} md={5}>

                    <Card className={classes.card}>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {translate('dashboard.remittances')}
                        </Typography>

                        <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                          className="dashboard_chart_members"
                          data={info.remittances}
                          margin={{
                            top: 30,
                            right: 0,
                            left: 0,
                            bottom: 0,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis  />
                          <Tooltip />
                          <Legend />
                          <Bar name={translate('dashboard.remittances_legend_accepted')} dataKey="accepted" stackId="a" fill={COLORS[1]} />
                          <Bar name={translate('dashboard.remittances_legend_returned')} dataKey="returned" stackId="a" fill="#FF6565" />
                        </BarChart>
                        </ResponsiveContainer>

                      </CardContent>

                      <CardActions>
                        <CreateButton
                            component={Link}
                            to={{
                                pathname: `/remittances/create`
                            }} 
                            label={translate('dashboard.remittances_add')}
                        />
                      </CardActions>

                    </Card>     

                </Grid>
                : <Grid item xs={12} md={5}></Grid> }

                {subscriptionModule(modules.RECERCAT) ?
                <span/>                
                : subscriptionModule(modules.FEDERATION) ?
                
                <Grid item xs={12} md={7}>
                       
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {translate('dashboard.members_federation')}
                        </Typography>

                        <br/><br/>
                        <ResponsiveContainer width="100%" height={280}>
                        <LineChart
                          className="dashboard_chart_members"
                          data={info.federation_members}
                          margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis type="category" dataKey="year"  domain={['dataMin', 'dataMax']} />
                          <YAxis  />
                          <Tooltip />
                          <Line name={translate('dashboard.federation_members')} dataKey="num_members" stackId="a" fill={COLORS[0]} />
                        </LineChart>
                        </ResponsiveContainer>

                        <br/>

                      </CardContent>

                    </Card> 
                    
                </Grid>
                
                : !subscriptionModule(modules.FACM) ?
                
                <Grid item xs={12} md={7}>
                   <Card className={classes.card}>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {translate('dashboard.age_pyramid')} 
                        </Typography>
                        <Typography className={classes.title} color="textSecondary">{info.statistics.members_total_persons_age_and_gender} {translate('dashboard.people')}</Typography>
                        <br/>
                        <ResponsiveContainer width="100%" height={350}>
                        <ComposedChart
                          className="dashboard_chart_members"
                          layout="vertical"
                          data={info.pyramid}
                          margin={{
                            top: 20,
                            right: 0,
                            left: 30,
                            bottom: 20,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis type="number" unit="%" margin={{ bottom: 50, top: 10}} />
                          <YAxis type="category" dataKey="name" reversed={true} interval={0} margin={{ bottom: 10, top: 10}} />
                          <Legend />
                          <Tooltip />
                          <Line type="monotone" dataKey="female" stroke={COLORS[0]} name={translate('dashboard.members_legend_female')} dot={false} strokeWidth={4} />
                          <Line type="monotone" dataKey="male" stroke={COLORS[1]} name={translate('dashboard.members_legend_male')} dot={false} strokeWidth={4} />
                          <Line type="monotone" dataKey="no_binary" stroke={COLORS[2]} name={translate('dashboard.members_legend_no_binary')} dot={false} strokeWidth={4} />                          
                        </ComposedChart>
                        </ResponsiveContainer>

                        <br/>

                      </CardContent>

                    </Card>  

                </Grid>
                : null 
                }
                
                </Grid>
            
            </Grid>
            <Grid item xs={12} md={4}>            
                
                { subscriptionModule(modules.CALENDAR) && info.events && info.events.length > 0 ?
                <Grid item xs={12} md={12}>
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          { subscriptionModule(modules.FACM) ? translate('dashboard.next_events') : translate('dashboard.today')}
                        </Typography>

                        <Box textAlign="left">
                            <List>
                            {info.events.map(item => {
                                let link = '/events/' + item.id;
                                let classrooms_name = item.classrooms_name ? ', ' + item.classrooms_name : '';
                                let secondary = subscriptionModule(modules.FACM) ? dateAndTimeToCatalan(item.start) + classrooms_name : item.start.substring(11,16) + classrooms_name ;
                                return(
                                    <ListItem className="birthdays-list">
                                      <ListItemButton component={Link} to={{ pathname: link }}>
                                        <ListItemIcon>
                                          <TodayIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={item.title} secondary={ secondary }  />
                                      </ListItemButton>
                                    </ListItem>
                                )
                            }
                            )}
                            </List>
                        </Box>
                        
                        <ShowButton 
                            component={Link}
                            to={{
                                pathname: `/events`
                            }} 
                            label={translate('dashboard.events_view')}
                        />
                        
                      </CardContent>
                    </Card>   
                </Grid>
                : null }
                
                { !subscriptionModule(modules.RECERCAT) && !subscriptionModule(modules.FACM) && info.last_joins_pending && info.last_joins_pending.length > 0 ?
                <Grid item xs={12} md={12}>
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {translate('dashboard.last_joins_pending')}
                        </Typography>

                        <Box textAlign="left">
                            <List>
                            {info.last_joins_pending.slice(0, 3).map(item => {
                                let link = '/users/' + item.id + '/show';
                                let user_complete_name = item ? (<UserFullNameTxt record={item} />) : '';
                                let secondary = item.email;
                                return(
                                    <ListItem className="last_joins_list">
                                      <ListItemButton component={Link} to={{ pathname: link }}>
                                        <ListItemIcon>
                                          <UserAvatarIcon record={item} />
                                        </ListItemIcon>
                                        <ListItemText primary={user_complete_name} secondary={ secondary }  />
                                      </ListItemButton>
                                    </ListItem>
                                )
                            }
                            )}
                            </List>
                        </Box>
                        
                      </CardContent>
                      
                      <CardActions>                        
                        {info.last_joins_pending.length > 3 ?
                        <ShowButton 
                            icon={null}
                            component={Link}
                            to={{
                                pathname: `/groups-tree/${info.statistics.members_group_pending}`
                            }} 
                            label={ (subscriptionModule(modules.FEDERATION) ? translate('dashboard.members_federation_view_requests') : translate('dashboard.members_view_requests'))+' ('+info.statistics.members_total_pending+')'}
                        /> : null }
                      </CardActions>
                      
                    </Card>   
                </Grid>
                : null }
                
                { !subscriptionModule(modules.RECERCAT) && !subscriptionModule(modules.FACM) && info.last_joins && info.last_joins.length > 0 ?
                <Grid item xs={12} md={12}>
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {info.groups_members_name + ' ' + translate('dashboard.last_joins')}
                        </Typography>

                        <Box textAlign="left">
                            <List>
                            {info.last_joins.map(item => {
                                let link = '/users/' + item.id + '/show';
                                let user_complete_name = item ? (<UserFullNameTxt record={item} />) : '';
                                let secondary = item.email;
                                return(
                                    <ListItem className="last_joins_list">
                                      <ListItemButton component={Link} to={{ pathname: link }}>
                                        <ListItemIcon>
                                          <UserAvatarIcon record={item} />
                                        </ListItemIcon>
                                        <ListItemText primary={user_complete_name} secondary={ secondary }  />
                                      </ListItemButton>
                                    </ListItem>
                                )
                            }
                            )}
                            </List>
                        </Box>
                        
                      </CardContent>
                      
                      <CardActions>
                        <CreateButton
                            component={Link}
                            to={{
                                pathname: '/users/create',
                                search: `?groups_id=${info.statistics.members_group}`,
                            }}
                            label={translate('dashboard.members_add')}
                        />     
                        <ShowButton 
                            icon={null}
                            component={Link}
                            to={{
                                pathname: `/groups-tree/${info.statistics.members_group}`
                            }} 
                            label={(subscriptionModule(modules.FEDERATION) ? translate('dashboard.members_federation_view') : translate('dashboard.members_view')) +' ('+ (subscriptionModule(modules.FEDERATION) ? info.statistics.federations_total : info.statistics.members_total ) +')'}
                        />                                           
                      </CardActions>
                      
                    </Card>   
                </Grid>
                : null }
                
                {subscriptionModule(modules.FEDERATION) ?
                <Grid item xs={12} md={12}>
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {translate('dashboard.birthdays')} {info.birthdays_current_year}
                        </Typography>

                        <Box textAlign="left">
                            <List>
                            {info.birthdays.map(item => {
                                let link = '/users/' + item.id + '/show';
                                return(
                                    <ListItem className="birthdays-list">
                                      <ListItemButton component={Link} to={{ pathname: link }}>
                                        <ListItemIcon>
                                          <CakeIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={item.name} secondary={ item.years_old + ' ' + translate('dashboard.age_sufix') }  />
                                      </ListItemButton>
                                    </ListItem>
                                )
                            }
                            )}
                            </List>
                        </Box>
                      </CardContent>
                    </Card>   
                </Grid>
                : null }
                
                <Grid item xs={12} md={12}>                    
                    <Card className={classes.card}>
                      <CardContent>                        
                        <Typography gutterBottom variant="h5" component="div">                            
                            {translate('dashboard.help')}
                        </Typography>
                        <Box textAlign="left">
                            { subscriptionModule(modules.RECERCAT) ?
                                <Typography className={classes.title} color="textSecondary"><span dangerouslySetInnerHTML={{ __html: translate('dashboard.help_description') }} /></Typography>
                            :
                            window.location.hostname.replaceAll('.','')=='admingestioentitatscat' ? 
                                <>
                                <Typography className={classes.title} color="textSecondary"><span dangerouslySetInnerHTML={{ __html: translate('dashboard.help_description') }} /></Typography>
                                <a href="docs/manual.pdf" target="blank" >
                                <Button startIcon={<MenuBookIcon />} className="button-blue">
                                  {translate('dashboard.help_manual_link')}
                                </Button>
                                </a>
                                </>
                            :
                                <>
                                <Typography className={classes.title} color="textSecondary"><span dangerouslySetInnerHTML={{ __html: translate('dashboard.help_description_sifac') }} /></Typography>                                
                                <a href="docs/manual_sifac.pdf" target="blank" >
                                <Button startIcon={<MenuBookIcon />} className="button-blue">
                                  {translate('dashboard.help_manual_link')}
                                </Button>
                                </a>
                                </>
                            }                             
                        </Box>
                      </CardContent>
                    </Card>   
                </Grid>        
                
                {!subscriptionModule(modules.RECERCAT) ?
                <Grid item xs={12} md={12}>                    
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {translate('dashboard.subscription')}
                        </Typography>

                        <Box textAlign="left">
                            <br />
                            
                            <Grid container spacing={2}>
                            <Grid item xs={6} md={6}>   
                            <Typography className={classes.title} color="textSecondary">{translate('dashboard.subscription_name')} </Typography>
                            <Typography className="">{info.subscription_info.module_option_name}</Typography>    
                            </Grid>
                            <Grid item xs={6} md={6}>  
                            { info.subscription_info.module_option_public_id === 'FREE' ?
                            <>
                            <Typography className={classes.title} color="textSecondary">{translate('dashboard.subscription_date_expiration')}</Typography>
                            <Typography className=""><RenderDate source={info.subscription_info.date_expiration} /></Typography>
                            </> : null }
                            </Grid>
                            </Grid>
                            
                            <br />
                            
                            <Typography className="">{info.subscription_info.users_num} / {info.subscription_info.users_num_max} {translate('dashboard.subscription_users')}</Typography>
                            <LinearProgressWithLabel value={info.subscription_info.users_num_percent} />
                            <br/>
                            
                            <Typography className="">{info.subscription_info.docs_size} / {info.subscription_info.docs_size_max} {translate('dashboard.subscription_size')}</Typography>
                            <LinearProgressWithLabel value={info.subscription_info.docs_size_percent}  />
                            
                            <br/>
                            <Typography className={classes.title} color="textSecondary">{translate('dashboard.subscription_active_modules')}</Typography>                                                        
                            {activeModulesList(translate)}
                            <br/>                            
                            <Link
                                to={{
                                    pathname: `/modules-view`
                                }} 
                                className="upper"
                            ></Link> 
                        </Box>
                      </CardContent>
                    </Card>                     
                </Grid> : null }
                    
            </Grid>
            

          </Grid>
        </Box>
               
        </>
    )
};


const activeModulesList = (translate) => {
    
    if (localStorage.getItem('subscriptions'))
      {
        let subscriptions = JSON.parse(localStorage.getItem('subscriptions'));
        
        let a = subscriptions.map((item, index) => (
                    <Chip key={index} label={item.modules.name} className="mr-10 mt-10"/>
            ));
    
        return ( 
                <>
                {a}
                <br />
                <CreateButton 
                    component={Link}
                    to={{
                        pathname: `/modules-view`
                    }} 
                    className="mt-10"
                    label={translate('dashboard.subscription_view_all_modules')}
                />
                </>
        );

      }    
}
    
export const FacmChart = ({title, data, dataKey, current_year, color_cy, color_ly, ...props}) => {

    let last_year = current_year-1;
    let dataKey_current_year = dataKey + '_cy_acc';
    let dataKey_last_year = dataKey + '_ly_acc';
    
    return (
    <ResponsiveContainer width="100%" height={180}>
        <LineChart data={data} margin={{ top: 20, right: 50, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis
            yAxisId="left"
            label={{ value: title, angle: -90, dx: -25 }}            
          />
          <Tooltip />                          
          <Line yAxisId="left" type="monotone" dataKey={dataKey_last_year} stroke={color_ly} name={last_year} />
          <Line yAxisId="left" type="monotone" dataKey={dataKey_current_year} stroke={color_cy} strokeWidth={3} name={current_year} />          
        </LineChart>
    </ResponsiveContainer>
    );
}


/*
                
                          <Bar barSize={3} name={translate('dashboard.members_legend_female')} dataKey="female" stackId="a" fill={COLORS[0]} />
                          <Bar barSize={3} name={translate('dashboard.members_legend_male')} dataKey="male" stackId="b" fill={COLORS[1]} />
                          <Bar barSize={3} name={translate('dashboard.members_legend_no_binary')} dataKey="no_binary" stackId="c" fill={COLORS[2]} />
                          <Bar barSize={3} name={translate('dashboard.members_legend_no_informed')} dataKey="no_informed" stackId="d" fill={COLORS[3]} />
 */
const data = [
{
    "name": "90+",
    "female": 1,
    "male": 2,
    "no_binary": 1,
    "no_informed": 0
  },
{
    "name": "85-89",
    "female": 1.2,
    "male": 1.2,
    "no_binary": 1.2,
    "no_informed": 1.2
  },
  {
    "name": "80-84",
    "female": 2.3,
    "male": 1.3,
    "no_binary": 2.3,
    "no_informed": 1.3
  },
{
    "name": "75-79",
    "female": 1.3,
    "male": 1.3,
    "no_binary": 3.3,
    "no_informed": 1.3
  },
  {
    "name": "70-74",
     "female": 1.3,
    "male": 1.3,
    "no_binary": 1.3,
    "no_informed": 1.3
  },
  {
    "name": "65-69",
     "female": 1.3,
    "male": 1.3,
    "no_binary": 1.3,
    "no_informed": 1.3
  },
  {
    "name": "60-64",
     "female": 3.3,
    "male": 1.3,
    "no_binary": 1.3,
    "no_informed": 1.3
  },
  {
    "name": "55-59",
     "female": 1.3,
    "male": 1.3,
    "no_binary": 1.3,
    "no_informed": 1.3
  },
  {
    "name": "50-54",
     "female": 4.3,
    "male": 4.3,
    "no_binary": 1.3,
    "no_informed": 1.3
  },
  {
    "name": "45-49",
     "female": 1.3,
    "male": 5.3,
    "no_binary": 5.3,
    "no_informed": 5.3
  },
  {
    "name": "40-44",
     "female": 6.3,
    "male": 6.3,
    "no_binary": 6.3,
    "no_informed": 6.3
  },
  {
    "name": "35-39",
     "female": 1.3,
    "male": 1.3,
    "no_binary": 1.3,
    "no_informed": 1.3
  },
  {
    "name": "30-34",
     "female": 7.3,
    "male": 7.3,
    "no_binary": 7.3,
    "no_informed": 7.3
  },
  {
    "name": "25-29",
     "female": 1.3,
    "male": 1.3,
    "no_binary": 1.3,
    "no_informed": 1.3
  },
  {
    "name": "20-24",
     "female": 1.3,
    "male": 1.3,
    "no_binary": 1.3,
    "no_informed": 1.3
  },
  {
    "name": "15-19",
     "female": 1.3,
    "male": 1.3,
    "no_binary": 1.3,
    "no_informed": 1.3
  },
  {
    "name": "10-14",
     "female": 1.3,
    "male": 1.3,
    "no_binary": 1.3,
    "no_informed": 1.3
  },
  {
    "name": "5-9",
     "female": 1.3,
    "male": 1.3,
    "no_binary": 1.3,
    "no_informed": 1.3
  },
  {
    "name": "0-5",
     "female": 1.3,
    "male": 1.3,
    "no_binary": 1.3,
    "no_informed": 1.3
  }
]

/*
<CardActions>
<Button size="small">{translate('dashboard.subscription_change')}</Button>
</CardActions>
*/




const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};