// in src/posts.js
import * as React from 'react';
import { useState, useEffect } from 'react';

import {
  BooleanInput,
  Datagrid,
  DeleteButton,
  TextInput,
  SelectInput,
  BooleanField ,
  ReferenceManyField,
  useTranslate,
  ResourceContextProvider, Button, TopToolbar,NumberInput,useRefresh,SelectField,RadioButtonGroupInput,required,
  FormDataConsumer, useNotify, SaveButton, useCreate, FormWithRedirect,Confirm, useDataProvider, useUpdate, FunctionField
} from 'react-admin';

import { ColorField, ColorInput } from 'react-admin-color-input';

import { useFormState } from 'react-final-form';


import Grid from '@mui/material/Grid';


import Typography from '@material-ui/core/Typography';

import Divider from '@mui/material/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import EmailIcon from '@mui/icons-material/Email';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import DownloadIcon from '@mui/icons-material/Download';
import IconCancel from '@material-ui/icons/Cancel';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import IconContentAdd from '@material-ui/icons/Add';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';


import { targets , apiUrl, apiOrigin, apiDefaultLanguage, modules, cardTypes } from './../common/constants';
import { BoxPhotoUser } from './docs';

import { subscriptionModule  } from '../common/utils';
import { PDFField  } from '../common/fields';


import { validateMaxLength, validatePercent  } from './../components/Validator';
import { fetchCustomFields } from './../components/CustomFields';


export const GroupsCardsList = ({ groups_id, ...props }) => {
  const translate = useTranslate();  
  const refresh = useRefresh();
  
  const dataProvider = useDataProvider();  
  const [customFields, setCustomFields] = useState();  
  const [error, setError] = useState(false);   
  const [pdfUrl, setPdfUrl] = useState(null);
  const [title, setTitle] = useState('');
        
  const updatePDFCardView = () => {
                
    let headers = new Headers({ Accept: 'application/json' });
    headers.set('Access-Control-Allow-Origin', apiOrigin );
    headers.set('Authorization', localStorage.getItem('auth'));
    headers.set('Accept-Language', apiDefaultLanguage);
    headers.set('Role', localStorage.getItem('active_permission'));

    fetch(apiUrl + '/groups_cards/demo/' + groups_id,{ 
      headers: headers
    })
      .then(response => response.blob() )
      .then(data => setPdfUrl(URL.createObjectURL(data)+'#toolbar=0&view=Fit,page=1')
      )
      .catch(error => console.error(error));
  };
  
  
  const handleChangeType = (value) => {
    
    if (value < 1000000)
    {
        setTitle(translate('groups_cards.type_' + value + '_default_title'));
    }
    else
    {
        customFields.map((element, index) => {
            
            if (element.id == (value-1000000)) setTitle(element.name);
                
        });
        
    }
        
        
  };    
     
  useEffect(() => {    
      
    if (subscriptionModule(modules.CUSTOM_FIELDS))  
    {
        fetchCustomFields(localStorage.getItem('associations_id'), targets.USER, setCustomFields, setError, dataProvider, false, false );
    }    
    updatePDFCardView();    

    }, []);
  
  if (subscriptionModule(modules.CUSTOM_FIELDS) && !customFields) return null;
  
  return (  
    <>                 
      <TopToolbar>                
        <GroupsCardsSendButton groups_id={groups_id} />
      </TopToolbar>
      
      { props.card_digital ?
      <>
      <Typography variant="h6" className="grey8 titleUserShow">                        
        {translate('groups_cards.settings_pdf')}
      </Typography>
      <Divider fullWidth className="" />
      <br />
                  
      <Grid container spacing={2}>   
        <Grid item xs={5}>       
          <ResourceContextProvider value="groups_cards" >
            <TopToolbar>
              <GroupsCardsCreateButton groups_id={groups_id} card_type={cardTypes.PDF} onSuccess={updatePDFCardView} customFields={customFields} title={title} handleChangeType={handleChangeType} setTitle={setTitle} />
            </TopToolbar>
            <ReferenceManyField                    
              reference="groups_cards"
              target="groups_id"
              filter={{ card_type : cardTypes.PDF }}
              label=""
              perPage={1000}
              sort={{ field: 'positionY', order: 'ASC' }}
            >
              <Datagrid className="groups_cards"> 
                <SelectField source="type" choices={choicesTypeGroupCard(translate,cardTypes.PDF,customFields)} optionText="name" optionValue="id" label={translate('groups_cards.type')} sortable={false} />                
                <ColorField label={translate('groups_cards.font_color')} source="font_color" sortable={false} />
                <BooleanField label={translate('groups_cards.status')} source="status" sortable={false} />
                <GroupsCardsEditButton onSuccess={updatePDFCardView} card_type={cardTypes.PDF} label="" customFields={customFields} title={title} handleChangeType={handleChangeType} setTitle={setTitle} />
                <DeleteButton undoable={false} onSuccess={()=>{ window.location.reload(); }} label=""  />
              </Datagrid>                                                    
            </ReferenceManyField>
          </ResourceContextProvider>          
        </Grid>
        <Grid item xs={2}>  
            <br /><br /><br /><b>{translate('groups_cards.background')}</b><br /><br />
            <BoxPhotoUser target={targets.GROUP_CARD} items_id={groups_id} edit={true} default_img={2} className="docs_card" onSuccess={updatePDFCardView} />
        </Grid>
        <Grid item xs={1}></Grid>  
        <Grid item xs={4}>   
          <TopToolbar>
            <DownloadDemoCard groups_id={groups_id} />
          </TopToolbar>
          <PDFField url={pdfUrl} loading={translate('groups_cards.pdf_card_preview')} {...props} />                    
          <br /><br />
        </Grid>
      </Grid> 
      </> : null }
      
      { subscriptionModule(modules.WALLETS) && props.card_digital_wallets ?
      <>
      <Typography variant="h6" className="grey8 titleUserShow">                        
        {translate('groups_cards.settings_wallets_google')}
      </Typography>
      <Divider fullWidth className="" />
      <br />      
      
      <Grid container spacing={2}>      
      
        <Grid item xs={8}>  
            <GroupsCardsItems groups_id={groups_id} card_type={cardTypes.GOOGLE_WALLET_CONFIG} title={title} setTitle={setTitle} handleChangeType={handleChangeType} customFields={customFields} label_create={translate('groups_cards.create_config')} label_edit={translate('groups_cards.edit_config')} />                        
            <br /><br />
            <GroupsCardsItems groups_id={groups_id} card_type={cardTypes.GOOGLE_WALLET} title={title} setTitle={setTitle} handleChangeType={handleChangeType} customFields={customFields} />            
        </Grid> 
        <Grid item xs={1}></Grid>  
        <Grid item xs={3}>  
            <br /><b>{translate('groups_cards.google_wallet_icon')}</b><br /><br />
            <BoxPhotoUser target={targets.GROUP_CARD_GOOGLE_WALLET_ICON} items_id={groups_id} edit={true} default_img={3} className="docs_card_google_wallet_icon" />        
            <b>{translate('groups_cards.background_google_wallet')}</b><br /><br />
            <BoxPhotoUser target={targets.GROUP_CARD_GOOGLE_WALLET} items_id={groups_id} edit={true} default_img={3} className="docs_card_google_wallet_image" />            
        </Grid>
      </Grid>
      
      </> : null }
      
      <br />
      
      { subscriptionModule(modules.WALLETS) && props.card_digital_wallets ?
      <>
      <Typography variant="h6" className="grey8 titleUserShow">                        
        {translate('groups_cards.settings_wallets_apple')}
      </Typography>
      <Divider fullWidth className="" />
      <br />
            
      <Grid container spacing={2}>      
      
        <Grid item xs={8}>  
            <GroupsCardsItems groups_id={groups_id} card_type={cardTypes.APPLE_WALLET_CONFIG} title={title} setTitle={setTitle} handleChangeType={handleChangeType} customFields={customFields} label_create={translate('groups_cards.create_config')} label_edit={translate('groups_cards.edit_config')} />            
            <br /><br/>
            <GroupsCardsItems groups_id={groups_id} card_type={cardTypes.APPLE_WALLET} title={title} setTitle={setTitle} handleChangeType={handleChangeType} customFields={customFields} />            
        </Grid> 
        <Grid item xs={1}></Grid>  
        <Grid item xs={3}>  
            <br /><b>{translate('groups_cards.apple_wallet_icon')}</b><br /><br />
            <BoxPhotoUser target={targets.GROUP_CARD_APPLE_WALLET_ICON} items_id={groups_id} edit={true} default_img={3} className="docs_card_apple_wallet_icon" />            
        </Grid>
      </Grid>
      </> : null }
            
      <br />
        
    </>
  );
};
     
      
const GroupsCardsItems = ({ groups_id, card_type, title, setTitle, handleChangeType, customFields, label_create, label_edit, ...props}) => {

    const translate = useTranslate();  
    
    return  (
       <ResourceContextProvider value="groups_cards" >
            { card_type === cardTypes.GOOGLE_WALLET || card_type === cardTypes.APPLE_WALLET ?
            <TopToolbar>
              <GroupsCardsCreateButton groups_id={groups_id} card_type={card_type} customFields={customFields} onSuccess={()=>{ }} title={title} handleChangeType={handleChangeType} setTitle={setTitle} label={label_create} />
            </TopToolbar>
            : null }
            <ReferenceManyField                    
              reference="groups_cards"
              target="groups_id"
              filter={{ card_type : card_type }}
              label="hola"
              perPage={1000}
              sort={{ field: 'position,priority', order: 'ASC' }}
            >
              <Datagrid className="groups_cards"> 
                { card_type === cardTypes.GOOGLE_WALLET || card_type === cardTypes.APPLE_WALLET ?
                <SelectField source="position" choices={choicesPosition(translate, card_type)} optionText="name" optionValue="id" label={translate('groups_cards.position')} sortable={false} />  
                : null }
                { card_type === cardTypes.GOOGLE_WALLET_CONFIG || card_type === cardTypes.APPLE_WALLET_CONFIG ?
                <SelectField source="type" choices={choicesTypeGroupCardConfig(translate,card_type)} optionText="name" optionValue="id" label={translate('groups_cards.config')} sortable={false} />                                
                : null }
                { !(card_type === cardTypes.GOOGLE_WALLET_CONFIG || card_type === cardTypes.APPLE_WALLET_CONFIG) ?
                <SelectField source="type" choices={choicesTypeGroupCard(translate,card_type,customFields)} optionText="name" optionValue="id" label={translate('groups_cards.type')} sortable={false} />                                
                : null } 
                { card_type === cardTypes.GOOGLE_WALLET_CONFIG || card_type === cardTypes.APPLE_WALLET_CONFIG ?
                <FunctionField render={record => <span>{[23,25,26].includes(record.type) ? <ColorField source="font_color" record={{ font_color: record.font_color }} /> : ''}</span> } label={translate('groups_cards.font_color')} source="font_color" sortable={false}  />
                : null }
                { !(card_type === cardTypes.GOOGLE_WALLET_CONFIG || card_type === cardTypes.APPLE_WALLET_CONFIG) ?
                <FunctionField render={record => <span>{record.title ? record.title : ''}</span> } label={translate('groups_cards.name')} sortable={false}  />
                : null }
                <BooleanField label={translate('groups_cards.status')} source="status" sortable={false} />
                <GroupsCardsEditButton onSuccess={()=>{ }} card_type={card_type} label="" customFields={customFields} title={title} handleChangeType={handleChangeType} setTitle={setTitle} label={label_edit} label="Accions" />
                { card_type === cardTypes.GOOGLE_WALLET || card_type === cardTypes.APPLE_WALLET ?
                <DeleteButton undoable={false} onSuccess={()=>{ window.location.reload(); }} label=""  />
                : null }
              </Datagrid>                                                    
            </ReferenceManyField>
          </ResourceContextProvider>
    );
}
      
export const GroupsCardsCreateButton = ({ groups_id , card_type, onSuccess, customFields, title, handleChangeType, setTitle, label, ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('groups_cards');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  
        
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
          onSuccess();
        },
        onFailure: ( error ) => {
          notify( error.message , 'error');
        }
      }
    );
  };
    
  return (
    <>            
      <Button onClick={handleClick} label={label ? label : translate('groups_cards.create')}>
        <IconContentAdd />                
      </Button>

      <Dialog                
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={label ? label : translate('groups_cards.create')}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{label ? label : translate('groups_cards.create')}</DialogTitle>

        <FormWithRedirect
          save={handleSubmit}
          initialValues={{ groups_id: groups_id, card_type: card_type, pos_x: 0, pos_y: 0, size_x: 100, size_y: 10,num_lines: 2, font_size: 5,font_color: '#000000', status: true, alignment: 0, uppercase: true, opacity: 50, rectangle_round: 0.5, title: '', message: '', title: title }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContentCards card_type={card_type} customFields={customFields} title={title} handleChangeType={handleChangeType} setTitle={setTitle} />
                        
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

export const GroupsCardsEditButton = ({ onSuccess, card_type, customFields, title, handleChangeType, setTitle, ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  

  const [update, { loading, error }] = useUpdate();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    update(
      { 
        type: 'update', 
        resource: 'groups_cards', 
        payload : { id: values.id, data : values }
      },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
          onSuccess();
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };    
  
  

  return (
    <>
      <Button onClick={handleClick} label="" startIcon={<EditIcon />} />

      <Dialog
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('groups_cards.edit')}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{translate('groups_cards.edit')}</DialogTitle>

        <FormWithRedirect
          resource="groups_cards"
          save={handleSubmit}
          initialValues={props.record}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContentCards card_type={card_type} customFields={customFields} title={title} handleChangeType={handleChangeType} setTitle={setTitle} />
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

const DialogContentCards = ({title, handleChangeType, setTitle, ...props}) => {
    
  const translate = useTranslate();
            
  return (
    <DialogContent>
      <Grid container spacing={2}>      
        
        {props.card_type === cardTypes.GOOGLE_WALLET || props.card_type === cardTypes.APPLE_WALLET ?  
        <Grid item xs={3}> 
          <SelectInput fullWidth label={translate('groups_cards.position')} source="position" choices={choicesPosition(translate, props.card_type)} validate={[required()]} />
        </Grid> 
        : null }
        
        <Grid item xs={6}> 
          <SelectInput fullWidth label={translate('groups_cards.type')} source="type" choices={props.card_type === cardTypes.GOOGLE_WALLET_CONFIG || props.card_type === cardTypes.APPLE_WALLET_CONFIG ? choicesTypeGroupCardConfig(translate,props.card_type) : choicesTypeGroupCard(translate,props.card_type,props.customFields)} validate={[required()]} onChange={(e) => { handleChangeType(e.target.value) } } disabled={props.card_type === cardTypes.GOOGLE_WALLET_CONFIG || props.card_type === cardTypes.APPLE_WALLET_CONFIG} />
        </Grid> 
        
        { props.card_type === cardTypes.PDF || props.card_type === cardTypes.GOOGLE_WALLET_CONFIG || props.card_type === cardTypes.APPLE_WALLET_CONFIG ?   
            <ColorInputConditioned />
        : null }
        
        { props.card_type === cardTypes.GOOGLE_WALLET || props.card_type === cardTypes.APPLE_WALLET ?                                              
            <Grid item xs={3}>
              <TitleInputConditioned title={title} setTitle={setTitle} />          
            </Grid>   
        : null }         
        
      </Grid>          
      
      <Grid container spacing={2}>  
         
        <Grid item xs={6}> 
          <MessageInputConditioned />
        </Grid>
        
      </Grid>
      
      {props.card_type === cardTypes.PDF ?
      <Grid container spacing={2}>       
        <Grid item xs={3}> 
          <NumberInput fullWidth label={translate('groups_cards.pos_x')} source="pos_x" validate={[required(),validatePercent(translate('common.validation.number'))]} />
        </Grid>
        <Grid item xs={3}> 
          <NumberInput fullWidth label={translate('groups_cards.pos_y')} source="pos_y" validate={[required(),validatePercent(translate('common.validation.number'))]} />
        </Grid>
        <Grid item xs={3}> 
          <NumberInput fullWidth label={translate('groups_cards.size_x')} source="size_x" validate={[required(),validatePercent(translate('common.validation.number'))]} />        
        </Grid>
        <Grid item xs={3}> 
          <NumberInputSizeYConditioned /> 
        </Grid>
      </Grid>
      : null }
      <Grid container spacing={2}>     
        {props.card_type === cardTypes.PDF ?
        <Grid item xs={3}> 
          <NumberInputNumLinesConditioned /> 
        </Grid>
        : null }
        {props.card_type === cardTypes.PDF ?
        <Grid item xs={3}> 
          <SelectInputAlignmentConditioned />                
        </Grid>
        : null }
        {props.card_type === cardTypes.PDF ?
        <Grid item xs={3}> 
          <NumberInputFontSizeConditioned /> 
        </Grid>
        : null }
        
        {props.card_type === cardTypes.PDF || props.card_type === cardTypes.GOOGLE_WALLET || props.card_type === cardTypes.APPLE_WALLET ?
        <Grid item xs={3}> 
          <BooleanInputUppercaseConditioned /> 
        </Grid>  
        : null }
        
      </Grid>
      <Grid container spacing={2}>                          
        <Grid item xs={3}> 
          <OpacityInputConditioned />
        </Grid>
        <Grid item xs={3}> 
          <RectangleRoundInputConditioned />
        </Grid>                    
      </Grid>
      <Grid container spacing={2}> 
        
        { props.card_type === cardTypes.PDF || props.card_type === cardTypes.GOOGLE_WALLET || props.card_type === cardTypes.APPLE_WALLET ?
        <Grid item xs={3}> 
          <NumberInput fullWidth label={translate('common.priority')} source="priority" />
        </Grid> 
        : null }
        
        <Grid item xs={3}> 
          <BooleanInput label={translate('groups_cards.status')} source="status" />
        </Grid> 
      </Grid>
      
      <br /><br/><br /><br/><br /><br/>    
    </DialogContent>
  );

};

const NumberInputSizeYConditioned = props => {
  const translate = useTranslate();   
  const { values } = useFormState();        
  return (
    ( values.type===1 || values.type===6 || values.type===22) ? 
      <NumberInput fullWidth label={translate('groups_cards.size_y')} source="size_y" validate={[required(),validatePercent(translate('common.validation.number'))]} />  : <span />
  );
};

const NumberInputNumLinesConditioned = props => {
  const translate = useTranslate();   
  const { values } = useFormState();        
  return (
    ( values.type!==1 && values.type!==6 && values.type!==10 && values.type!==22 ) ? 
      <NumberInput fullWidth label={translate('groups_cards.num_lines')} source="num_lines" validate={[required()]} />  : <span />
  );
};

const NumberInputFontSizeConditioned = props => {
  const translate = useTranslate();   
  const { values } = useFormState();        
  return (
    ( values.type!==1 && values.type!==6 && values.type!==10 && values.type!==22 ) ? 
      <NumberInput fullWidth label={translate('groups_cards.font_size')} source="font_size" validate={[required(),validatePercent(translate('common.validation.number'))]} />  : <span />
  );
};


const BooleanInputUppercaseConditioned = props => {
  const translate = useTranslate();   
  const { values } = useFormState();        
  return (
    ( values.type!==1 && values.type!==6 && values.type!==10 && values.type!==22 && values.type!==23 && values.type!==25 && values.type!==26 ) ? 
      <BooleanInput fullWidth label={translate('groups_cards.uppercase')} source="uppercase" />  : <span />
  );
};

const ColorInputConditioned = props => {
  const translate = useTranslate();   
  const { values } = useFormState();  
  
  return (
      (values.card_type === cardTypes.PDF && values.type!==1 && values.type!==6 && values.type!==10) || 
      (values.card_type === cardTypes.GOOGLE_WALLET_CONFIG && values.type===23) || 
      (values.card_type === cardTypes.APPLE_WALLET_CONFIG && (values.type===23 || values.type===25 || values.type===26)) ? 
      <Grid item xs={3} className="">
        <ColorInput fullWidth className="color-input-cards" fullWidth source="font_color" label={translate('groups_cards.font_color')}  validate={[required(),validateMaxLength(7,translate('common.validation.maxLength'))]} /> 
      </Grid>
      : <span />
  );
};

const TitleInputConditioned = (props) => {
  const translate = useTranslate();   
  const { values } = useFormState();  

  return (
    [0,2,3,4,5,7,8,9,11,12,13,14,15,16,17,18,19,20,21,24,27,28].includes(values.type) || values.type >= 1000000 ? (
      <TextInput
        fullWidth
        label={translate('groups_cards.title')}
        source="title"
        value={props.title} 
        onChange={(e)=>props.setTitle(e.target.value)}
        validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]}
      />
    ) : (
      <span />
    )
  );
};

const SelectInputAlignmentConditioned = props => {
  const translate = useTranslate();   
  const { values } = useFormState();        
  return (
    ( values.type!==1 && values.type!==6 && values.type!==10 && values.type!==22 ) ? 
      <SelectInput fullWidth label={translate('groups_cards.alignment')} source="alignment" choices={choicesAlignmentGroupCard(translate)} validate={[required()]} />  : <span />
  );
};

const OpacityInputConditioned = props => {
  const translate = useTranslate();   
  const { values } = useFormState();        
  return (
    ( values.type==22 ) ? 
      <NumberInput fullWidth label={translate('groups_cards.opacity')} source="opacity" validate={[required(),validatePercent(translate('common.validation.number'))]} />  : <span />
  );
};

const RectangleRoundInputConditioned = props => {
  const translate = useTranslate();   
  const { values } = useFormState();        
  return (
    ( values.type==22 ) ? 
      <NumberInput fullWidth label={translate('groups_cards.rectangle_round')} source="rectangle_round" validate={[required(),validatePercent(translate('common.validation.number'))]} />  : <span />
  );
};

const MessageInputConditioned = props => {
  const translate = useTranslate();   
  const { values } = useFormState();        
  return (
    ( [24].includes(values.type) ) ? 
      <TextInput fullWidth label={translate('groups_cards.message')} source="message" validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />  : <span />
  );
};

const choicesTypeGroupCard = (translate,card_type,customFields) => { 

  let arrFields = [                        
    { id: 0, name: translate('groups_cards.type_0') },
    { id: 19, name: translate('groups_cards.type_19') },
    { id: 20, name: translate('groups_cards.type_20') },
    { id: 21, name: translate('groups_cards.type_21') },    
    { id: 2, name: translate('groups_cards.type_2') },
    { id: 3, name: translate('groups_cards.type_3') },
    { id: 4, name: translate('groups_cards.type_4') },
    { id: 13, name: translate('groups_cards.type_13') },
    { id: 14, name: translate('groups_cards.type_14') },
    { id: 15, name: translate('groups_cards.type_15') },
    { id: 16, name: translate('groups_cards.type_16') },
    { id: 5, name: translate('groups_cards.type_5') }     
  ];
  
  if (card_type == cardTypes.PDF) {
       arrFields.push ({ id: 6, name: translate('groups_cards.type_6')  });
       arrFields.push ({ id: 1, name: translate('groups_cards.type_1')  });
  }
  
  arrFields.push ({ id: 7, name: translate('groups_cards.type_7')  });
  arrFields.push ({ id: 8, name: translate('groups_cards.type_8')  });
  arrFields.push ({ id: 9, name: translate('groups_cards.type_9')  });
  
  if (card_type == cardTypes.PDF) {
    arrFields.push ({ id: 10, name: translate('groups_cards.type_10')  });
  }
  
  arrFields.push ({ id: 11, name: translate('groups_cards.type_11')  });
  arrFields.push ({ id: 12, name: translate('groups_cards.type_12')  });
      
  if (card_type == cardTypes.PDF) {
      arrFields.push ({ id: 22, name: translate('groups_cards.type_22')  });
  }
    
  if (subscriptionModule(modules.RECERCAT)){ 
    arrFields.push ({ id: 17, name: translate('groups_cards.type_17')  });
    arrFields.push ({ id: 18, name: translate('groups_cards.type_18')  });
  }
  
  arrFields.push ({ id: 24, name: translate('groups_cards.type_24')  });
  
  if (customFields)
  {
    customFields.map((element, index) => {
       arrFields.push({ id: 1000000+element.id, name: element.name }); 
    });    
  }
    
  return (arrFields);
};


const choicesTypeGroupCardConfig = (translate,card_type) => { 

  let arrFields = [];

  arrFields.push ({ id: 10, name: translate('groups_cards.type_10')  });
  arrFields.push ({ id: 23, name: translate('groups_cards.type_23')  });
    
  if (card_type == (cardTypes.APPLE_WALLET_CONFIG ) ) {
      arrFields.push ({ id: 25, name: translate('groups_cards.type_25')  });
      arrFields.push ({ id: 26, name: translate('groups_cards.type_26')  });
  }
  
  arrFields.push ({ id: 1, name: translate('groups_cards.type_1')  });
    
  return (arrFields);
  
};


const choicesPosition = (translate, cardType) => { 

    let arrPositions = [];

    arrPositions.push({ id: 0, name: translate('groups_cards.position_0') });

    if (cardType === cardTypes.APPLE_WALLET)
    {
        arrPositions.push({ id: 1, name: translate('groups_cards.position_1') });
    }
    arrPositions.push({ id: 2, name: translate('groups_cards.position_2') });

    if (cardType === cardTypes.APPLE_WALLET)
    {
        arrPositions.push({ id: 3, name: translate('groups_cards.position_3') });
        arrPositions.push({ id: 4, name: translate('groups_cards.position_4') });
        arrPositions.push({ id: 5, name: translate('groups_cards.position_5') });
    }

    arrPositions.push({ id: 6, name: translate('groups_cards.position_6') });

    return (arrPositions);
};


const choicesAlignmentGroupCard = (translate) => { 
  return ([                        
    { id: 0, name: translate('groups_cards.alignment_0')  },
    { id: 1, name: translate('groups_cards.alignment_1')  },
    { id: 2, name: translate('groups_cards.alignment_2')  },
    { id: 3, name: translate('groups_cards.alignment_3')  }
  ]);
};

export const DownloadDemoCard = ({ record, groups_id, ...props}) => {
    
  const translate = useTranslate(); 
  const notify = useNotify();

  const handleAction = async () => {

    let headers = new Headers({ Accept: 'application/json' });
    headers.set('Access-Control-Allow-Origin', apiOrigin );
    headers.set('Authorization', localStorage.getItem('auth'));
    headers.set('Accept-Language', apiDefaultLanguage);
    headers.set('Role', localStorage.getItem('active_permission'));

    const result = await fetch(apiUrl + '/groups_cards/demo/' + groups_id , { 
      headers: headers
    });
    
    const blob = await result.blob();
    const href = window.URL.createObjectURL(blob);
    
    var tempLink = document.createElement('a');
    tempLink.href = href;
    tempLink.setAttribute('download', translate('groups_cards.pdf_card_filename'));
    tempLink.click();
    
    notify(translate('groups_cards.pdf_card_ok'),"success");
    
  };
  
  return (
    <Button label={translate('groups_cards.pdf_card_download')} startIcon={<DownloadIcon />} onClick={handleAction} />
  );

};




export const DownloadCard = ({ record, users_groups_id, ...props}) => {
    
  const translate = useTranslate(); 
  const notify = useNotify();

  const handleAction = async () => {

    let headers = new Headers({ Accept: 'application/json' });
    headers.set('Access-Control-Allow-Origin', apiOrigin );
    headers.set('Authorization', localStorage.getItem('auth'));
    headers.set('Accept-Language', apiDefaultLanguage);
    headers.set('Role', localStorage.getItem('active_permission'));

    const result = await fetch(apiUrl + '/groups_cards/card/' + users_groups_id , { 
      headers: headers
    });
    
    const blob = await result.blob();
    const href = window.URL.createObjectURL(blob);
    
    var tempLink = document.createElement('a');
    tempLink.href = href;
    tempLink.setAttribute('download', translate('groups_cards.pdf_card_filename'));
    tempLink.click();
    
    notify(translate('groups_cards.pdf_card_ok'),"success");
  };
  
  return (
    <Tooltip title={translate('groups_cards.pdf_view')}> 
      <PictureAsPdfIcon onClick={handleAction} />
    </Tooltip>
  );

};

export const RegenerateCard = ({ record, users_groups_id, ...props}) => {
    
  const [open, setOpen] = useState(false);
  const [create, { loading }] = useCreate('bookings');    
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh(); 
  const translate = useTranslate(); 
 
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => { regenerateCard();  };
    
  const regenerateCard = () => {
                
    dataProvider.getOne('groups_cards/regenerate' , { id: users_groups_id })
      .then(( { data } ) => {   
        setOpen(false);            
        notify(translate('groups_cards.regenerated_ok' ), 'success');            
        refresh();
      })
      .catch(error => {                
        notify(error.message, 'error');
        setOpen(false);
      });
        
  };
  
  return (
    <>
      <Tooltip title={translate('groups_cards.regenerate')}> 
        <ReplayCircleFilledIcon onClick={ (e) => { e.stopPropagation(); handleClick(users_groups_id); } }/>
      </Tooltip>
      <Confirm
        isOpen={open}
        loading={loading}
        title={translate('groups_cards.regenerate_title')}
        content={translate('groups_cards.regenerate_text')}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );

};


export const GroupsCardsSendButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('groups_cards/send');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  
  const [info, setInfo] = useState();
  const dataProvider = useDataProvider();
    
  let lastYear = new Date().getFullYear()-1;
    

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };
        

  useEffect(() => {
        
    dataProvider.getOne('groups_cards/prev_info', { id: props.groups_id })
      .then(({ data }) => {     
        setInfo(data);
      })
      .catch(error => {           
      });
       
  }, []);

  let num_pending = '';
  let num_total = '';
  if (info) {
    num_pending = info.num_pending;
    num_total = info.num_total;
  } 
  let option_0=translate('groups_cards.send_dialog_action_0').replaceAll('%num_pending%',num_pending);
  let option_1=translate('groups_cards.send_dialog_action_1').replaceAll('%num_total%',num_total);

  return (
    <>            
      <Button onClick={handleClick} label={translate('groups_cards.send_dialog_button')}>
        <EmailIcon />                
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('groups_cards.send_dialog_button')}
      >
        <DialogTitle>{translate('groups_cards.send_dialog_button')}</DialogTitle>

        <FormWithRedirect
          resource="groups_cards"
          save={handleSubmit}
          initialValues={{ action : 0, groups_id: props.groups_id }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
                                                        
              <DialogContent className="users_sections_form">
                <FormDataConsumer>
                  {({ formData, ...rest }) => <>
                    <Grid container spacing={2} > 
                      <Grid item xs={12}> 
                        <RadioButtonGroupInput source="action" choices={[
                          { id: 0, name: option_0 },
                          { id: 1, name: option_1 }
                        ]} 
                        optionText="name" optionValue="id" label={translate('groups_cards.send_dialog_action')} validate={required()}  />
                      </Grid>
                      {formData.action===1 ? 
                        <Grid item xs={12}> 
                          <BooleanInput label={translate('groups_cards.send_dialog_regenerate')} source="regenerate" />
                        </Grid> : null }
                      { localStorage.getItem('email').includes('@softovi.com') || localStorage.getItem('email').includes('francesctomas@gmail.com') ?
                        <Grid item xs={12}> 
                          <NumberInput label={translate('groups_cards.send_dialog_block_size')} source="number" />
                        </Grid> : null }
                      <Grid item xs={12}> 
                        <span>{translate('groups_cards.send_dialog_text2')}</span>
                      </Grid>
                    </Grid> 
                  </> }
                </FormDataConsumer>
              </DialogContent>
                                                        
              <br />
                            
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                  label={translate('users_annuary.send')}
                  icon={<EmailIcon/>}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );  
    
};