import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Search,
  SearchResultsPanel,
  SearchResultItem
} from '@react-admin/ra-search';



import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';


import { UserAvatarIcon, GroupsAvatarIcon, EventsAvatarIcon,RealStateAvatarIcon,AgreementsAvatarIcon,InsurancesAvatarIcon } from './../components/User';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

export const MySearch = ({targets}) => {

  const classes = useStyles();

  return ( 
    <Search options={{historySize:5, targets: targets }} >
      <SearchResultsPanel >
        <SearchResultItem
          label={(record) => (
            <ListItem>
              <ListItemAvatar>
                { record.type === 'users' ? 
                  <UserAvatarIcon record={record} />
                  : record.type === 'groups' ?
                    <GroupsAvatarIcon />
                    : record.type === 'events' ?
                      <EventsAvatarIcon />
                      : record.type === 'real_state' ?
                        <RealStateAvatarIcon />
                        : record.type === 'agreements' ?
                          <AgreementsAvatarIcon />
                          : record.type === 'insurances' ?
                            <InsurancesAvatarIcon />
                            : null
                }
              </ListItemAvatar>
              <ListItemText
                primary={record.primary_text}
                secondary={record.secondary_text}
              />          
            </ListItem>
          )}
        />
      </SearchResultsPanel>
    </Search>
  );

};

export const MySearchOld = ({targets}) => {

  const classes = useStyles();

  return ( 
    <Search options={{historySize:5, targets: targets }} >
      <SearchResultsPanel >
        <SearchResultItem
          label={(record) => (
            <ListItem>
              <ListItemAvatar>
                <UserAvatarIcon record={record} />
              </ListItemAvatar>
              <ListItemText
                primary={record.content.label}
                secondary={record.email}
              />          
            </ListItem>
          )}
        />
      </SearchResultsPanel>
    </Search>
  );

};


