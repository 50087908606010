import React, { useState } from 'react';

import { SaveButton ,
  List,
  Datagrid,
  TextField,
  DeleteButton,
  SelectInput,
  useTranslate,
  required,    
  useCreate,
  useUpdate,
  useNotify,
  FormWithRedirect,useRefresh,
  Button,
  FunctionField, TopToolbar, NumberInput, ResourceContextProvider,
  ArrayField, ArrayInput,SimpleFormIterator , SelectField
} from 'react-admin';


import EditIcon from '@material-ui/icons/Edit';

import { useStyles  } from '../common/styles';



import { choicesTrainingsType0,choicesTrainingsType1,choicesTrainingsFormal,choicesTrainingsFormalArt  } from './../common/constants';


import Divider from '@mui/material/Divider';

import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';


import { TextFieldShow } from '../components/TextFieldShow';

import Grid from '@mui/material/Grid';


import { validateInteger  } from './../components/Validator';

export const UsersTrainingsList = (props) => {
  const translate = useTranslate(); 
    
  let strRedirect =  `/users/${props.users_id}/more_federation`; 
    
  return (
           
    <ResourceContextProvider value="users_trainings" >
      <List 
        syncWithLocation 
        basePath="/users_trainings" 
        filter={{ users_id : props.users_id, type: props.type }}  
        title=""
        bulkActionButtons={false}                
        actions={<CustomToolbar users_id={props.users_id} type={props.type} /> }  
        perPage={1000}
        sort={{ field: 'year', order: 'ASC' }} 
        empty={<CustomToolbar users_id={props.users_id} type={props.type} /> } 
        pagination={false}
      >

        <Datagrid   
          expand={<UsersTrainingsDetails type={props.type} />} 
          className="header_grey6"
          label=""
          fullWidth
        >
          <TextField source="year" label={translate('common.year')} sortable={false} /> 
          <TextField source="hours" label={translate('users_trainings.total_hours')} sortable={false} />  
          <FunctionField label={translate('users_trainings.total_quantity' + props.type)} render={record => <RenderTotalQuantity record={record} /> } sortable={false} />
          <FunctionField label={translate('users_trainings.total_students')} render={record => <RenderTotalStudents record={record} /> } sortable={false} />
          <FunctionField label={translate('users_trainings.total_teachers_workers')} render={record => <RenderTotalTeachersWorkers record={record} /> } sortable={false} />
          <FunctionField label={translate('users_trainings.total_teachers_volunteers')} render={record => <RenderTotalTeachersVolunteers record={record} /> } sortable={false} />
          <CustomEditButton label="" type={props.type}/>
          <DeleteButton undoable={false} redirect={strRedirect} label=""/>
        </Datagrid>

      </List> 
    </ResourceContextProvider>

  );
};


export const CustomEditButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  

  const [update, { loading, error }] = useUpdate();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };
                        
  const handleSubmit = async values => {
    update(
      { 
        type: 'update', 
        resource: 'users_trainings', 
        payload : { id: values.id, data : 
                {       users_id: values.users_id,
                  year : values.year, 
                  hours : values.hours, 
                  type: values.type,
                  items: values.items
                } }
      },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };    

  return (
    <>
      <Button onClick={handleClick} label="" startIcon={<EditIcon />} />

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
      >
        <DialogTitle>{translate('users_trainings.edit'+props.type)}</DialogTitle>

        <FormWithRedirect
          resource=""
          save={handleSubmit}
          initialValues={{ id : props.record.id, 
            users_id: props.record.users_id,
            year : props.record.year, 
            hours : props.record.hours, 
            type: props.record.type,
            items: props.record.items
          }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <CustomForm edit={true} type={props.type} />
                            
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

const RenderTotalHours = ({ record, ...props }) => {
    
  let total_hours = 0;
  if (record.items)
  {
    record.items.forEach(element => { if (element.hours) total_hours+=element.hours; } );
  }
    
  return ( total_hours );
    
};

const RenderTotalQuantity = ({ record, ...props }) => {
    
  let total_quantity = 0;
  if (record.items)
  {
    record.items.forEach(element => { total_quantity+=1; } );
  }
    
  return ( total_quantity );
    
};

const RenderTotalStudents = ({ record, ...props }) => {
    
  let total_students = 0;
  if (record.items)
  {
    record.items.forEach(element => { if (element.students) total_students+=element.students; } );
  }
    
  return ( total_students );
    
};

const RenderTotalTeachersWorkers = ({ record, ...props }) => {
    
  let total_teachers = 0;
  if (record.items)
  {
    record.items.forEach(element => { if (element.teachers_workers) total_teachers+=element.teachers_workers; } );
  }
    
  return ( total_teachers );
    
};

const RenderTotalTeachersVolunteers = ({ record, ...props }) => {
    
  let total_teachers = 0;
  if (record.items)
  {
    record.items.forEach(element => { if (element.teachers_volunteers) total_teachers+=element.teachers_volunteers; } );
  }
    
  return ( total_teachers );
    
};


export const UsersTrainingsShow = ({ ...props }) => {
  const translate = useTranslate();   

  return (
        
    <ResourceContextProvider value="users_trainings" >
      <List 
        syncWithLocation 
        basePath="/users_trainings" 
        filter={{ users_id : props.users_id, type: props.type }}  
        title=""
        bulkActionButtons={false}                                
        perPage={1000}
        sort={{ field: 'year', order: 'ASC' }} 
        pagination={false}
        actions={false}
        empty={<></>}
        className="users_remittances_list"
      >                            
        <Datagrid
          expand={<UsersTrainingsDetails type={props.type} />} 
          className="header_grey6"
          fullWidth
          props
        >   
          <TextField source="year" label={translate('common.year')} sortable={false}  />                  
          <TextField source="hours" label={translate('users_trainings.total_hours')} sortable={false} /> 
          <FunctionField label={translate('users_trainings.total_quantity' + props.type )} render={record => <RenderTotalQuantity record={record} /> } sortable={false} />
          <FunctionField label={translate('users_trainings.total_students')} render={record => <RenderTotalStudents record={record} /> } sortable={false} /> 
          <FunctionField label={translate('users_trainings.total_teachers_workers')} render={record => <RenderTotalTeachersWorkers record={record} /> } sortable={false} /> 
          <FunctionField label={translate('users_trainings.total_teachers_volunteers')} render={record => <RenderTotalTeachersVolunteers record={record} /> } sortable={false} /> 
        </Datagrid>

      </List> 

    </ResourceContextProvider>            

  );
};

export const CustomToolbar = ({ ...props }) => {

  return (
        
    <TopToolbar>
      <CustomCreateButton users_id={props.users_id} type={props.type} />
    </TopToolbar>
        
  );
};


export const CustomCreateButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('users_trainings');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  
    

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };


  return (
    <>            
      <Button onClick={handleClick} label={translate('users_trainings.create_button')}>
        <IconContentAdd />                
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('users_trainings.create' + props.type)}
      >
        <DialogTitle>{translate('users_trainings.create' + props.type)}</DialogTitle>

        <FormWithRedirect
          resource="users_trainings"
          save={handleSubmit}
          initialValues={{ users_id : props.users_id, type: props.type }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <CustomForm edit={false} type={props.type}/>
                            
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

const CustomForm = ({ edit, ...props }) => {
  const translate = useTranslate(); 
  const classes = useStyles();
    
  return (
    <DialogContent className="users_activities_form">
      <br />
      <Grid container spacing={2} >
        <Grid item xs={3}>  
          { edit ? (
            <NumberInput label={translate('common.year')} fullWidth source="year" step={1} disabled />                                        
          ) : (
            <NumberInput label={translate('common.year')} fullWidth source="year" step={1} validate={[required(),validateInteger(translate('common.validation.number'))]} />                           
          )}
        </Grid> 
        <Grid item xs={3}>  
          <NumberInput label={translate('users_trainings.total_hours')} fullWidth source="hours" step={1} validate={[validateInteger(translate('common.validation.number'))]} />                           
        </Grid> 
      </Grid>
                
      <ArrayInput source="items" label="">
        <SimpleFormIterator 
          disableReordering                     
        >
          {props.type==0 ?
            <SelectInput source="type" label={translate('users_trainings.type')} fullWidth validate={required()} choices={choicesTrainingsType0(translate)} />
            :
            <SelectInput source="type" label={translate('users_trainings.type')} fullWidth validate={required()} choices={choicesTrainingsType1(translate)} />                            
          }
          {props.type==0 ?
            <SelectInput source="formal" label={translate('users_trainings.formal')} fullWidth validate={required()} choices={choicesTrainingsFormalArt(translate)} />
          :
            <SelectInput source="formal" label={translate('users_trainings.formal')} fullWidth validate={required()} choices={choicesTrainingsFormal(translate)} />
          }
          <NumberInput source="students" step={1} validate={[validateInteger(translate('common.validation.number'))]} label={translate('users_trainings.students')} />
          <NumberInput source="teachers_workers" step={1} validate={[validateInteger(translate('common.validation.number'))]} label={translate('users_trainings.teachers_workers')} />
          <NumberInput source="teachers_volunteers" step={1} validate={[validateInteger(translate('common.validation.number'))]} label={translate('users_trainings.teachers_volunteers')} />
                            
        </SimpleFormIterator>
      </ArrayInput>
                        
    </DialogContent>
  );
    
    
};
    

export const UsersTrainingsDetails = ({ id, record, resource, ...props }) => {
  const translate = useTranslate(); 
        
  let total_quantity = 0;
  if (record.items)
  {
    record.items.forEach(element => { total_quantity+=1; } );
  }
        
  let total_students = 0;
  if (record.items)
  {
    record.items.forEach(element => { if (element.students) total_students+=element.students; } );
  }
    
  let total_teachers_workers = 0;
  if (record.items)
  {
    record.items.forEach(element => { if (element.teachers_workers) total_teachers_workers+=element.teachers_workers; } );
  }
    
  let total_teachers_volunteers = 0;
  if (record.items)
  {
    record.items.forEach(element => { if (element.teachers_volunteers) total_teachers_volunteers+=element.teachers_volunteers; } );
  }
        
  return (
    <div className="usersEconomicDataExpand">
      <Grid container spacing={2}>
        <Grid item xs={2} >
          <FunctionField render={record => <TextFieldShow source={record.year} label={translate('common.year')} /> } />
        </Grid>
        <Grid item xs={2} >
          <FunctionField render={record => <TextFieldShow source={record.hours} label={translate('users_trainings.total_hours')} /> } />
        </Grid>
        <Grid item xs={2} >
          <FunctionField render={record => <TextFieldShow source={total_quantity} label={translate('users_trainings.total_quantity'+props.type)} /> } />
        </Grid>
        <Grid item xs={2} >
          <FunctionField render={record => <TextFieldShow source={total_students} label={translate('users_trainings.total_students')} /> } />
        </Grid>
        <Grid item xs={2} >
          <FunctionField render={record => <TextFieldShow source={total_teachers_workers} label={translate('users_trainings.total_teachers_workers')} /> } />
        </Grid>
        <Grid item xs={2} >
          <FunctionField render={record => <TextFieldShow source={total_teachers_volunteers} label={translate('users_trainings.total_teachers_volunteers')} /> } />
        </Grid>
      </Grid>
        
      <br />
      <Divider className="" />
      <br />
      <ArrayField source="items">
        <Datagrid>
          {props.type==0 ?
            <SelectField source="type" choices={choicesTrainingsType0(translate)} label={translate('users_trainings.type')} sortable={false} />
            :
            <SelectField source="type" choices={choicesTrainingsType1(translate)} label={translate('users_trainings.type')} sortable={false} />
          }
          <SelectField source="formal" choices={choicesTrainingsFormal(translate)} label={translate('users_trainings.formal')} sortable={false} />
          <TextField source="students" label={translate('users_trainings.students')} />
          <TextField source="teachers_workers" label={translate('users_trainings.teachers_workers')} />
          <TextField source="teachers_volunteers" label={translate('users_trainings.teachers_volunteers')} />
        </Datagrid>
      </ArrayField>
        
      <br /> 
    </div>
  );
};