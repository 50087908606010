// in src/posts.js
import * as React from 'react';
import { useState, useEffect } from 'react';

import {
  List, 
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  DeleteButton,
  Edit,
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  Button ,
  CreateButton ,
  TopToolbar,
  Toolbar,
  FormTab,
  TabbedForm,
  FormDataConsumer,
  useListContext,
  Filter, required, ChipField,ReferenceArrayField, SingleFieldList, ArrayField,DateField ,DateInput, NumberInput,RadioButtonGroupInput,
  ResourceContextProvider,FunctionField, BooleanInput, Show, SimpleShowLayout, useRefresh, useRedirect,
  NumberField, ShowButton, useNotify, useDataProvider, Loading, Error, SaveButton, FormWithRedirect, useCreate, downloadCSV,
  ReferenceArrayInput,CheckboxGroupInput,  SelectField , useUnselectAll, useRecordSelection, Pagination, DatagridBody 
} from 'react-admin';

import { Delete } from '@mui/icons-material';
import { SelectAll } from '@mui/icons-material';

import jsonExport from 'jsonexport/dist';
import RichTextInput from 'ra-input-rich-text';

import { AccordionSection  } from '@react-admin/ra-form-layout';


import { Link } from 'react-router-dom';

import Grid from '@mui/material/Grid';

//import { Toolbar } from '@material-ui/core';


import Divider from '@mui/material/Divider';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { UsersGroupsQuotesList, UsersGroupsQuotesShowList } from './users_groups_quotes';
import { UsersGroupsShow, UsersGroupsEdit, LastNumMemberInserted } from './users_groups';
import { UsersAnnuaryShow, UsersAnnuaryList, UsersAnnuarySendButton } from './users_annuary';
import { UsersGroupsResponsabilitiesShow, UsersGroupsResponsabilitiesEdit } from './users_groups_responsabilities';
import { UsersEditedBooksShow, UsersEditedBooksEdit } from './users_edited_books';
import { UsersRecognitionsShow, UsersRecognitionsEdit } from './users_recognitions';
import { UsersSchedulesShow, UsersSchedulesEdit } from './users_schedules';
import { UsersAddressesShow, UsersAddressesEdit } from './users_addresses';
import { EmailsHistoryList } from './emails';

import { DocsList, DocsListShow, BoxPhotoUser } from './docs';
import { SocialNetsList , SocialNetsListShow } from './social_nets';
import { UsersFamiliesList, UsersFamiliesShow  } from './users_families';

import { useTranslate  } from '../common/language';
import { useStyles  } from '../common/styles';

import { DownloadXLSButton } from './../components/DownloadXLSButton';
import { UsersPostalLabelsCreateButton } from './../components/UsersPostalLabelsCreateButton';
import { ExportButton } from './../components/ExportButton';
import { MyFilterUsers } from '../components/MyFilterUsers';
import { TextFieldShow } from '../components/TextFieldShow';
import BulkDeleteButton from '../components/BulkDeleteButton';
import BulkGroupsButton from '../components/BulkGroupsButton';
import { ImportButton } from '../components/ImportButton';
import MyLoading from '../components/MyLoading';
import { UserAvatarField , UserFullNameTxt , RenderAge, RenderDate, RenderGender, RenderBoolean, ChipFieldUserName, UserInput } from '../components/User';

import { CustomFieldsShow, CustomFieldsEdit } from '../components/CustomFields';

import { RenderStatus, RemittancesUsersItemsDetails } from '../resources/remittances';
import { UsersContactsList, UsersContactsShow } from '../resources/users_contacts';
import { UsersEmailsList, UsersEmailsShow } from '../resources/users_emails';
import { UsersEconomicDataList, UsersEconomicDataShow } from '../resources/users_economic_data';
import { UsersDemografyList } from '../resources/users_demografy';
import { UsersWorkersList, UsersWorkersShow } from '../resources/users_workers';
import { UsersActivitiesList, UsersActivitiesShow } from '../resources/users_activities';
import { UsersTrainingsList, UsersTrainingsShow } from '../resources/users_trainings';
import { UsersSocialProjectsList, UsersSocialProjectsShow } from '../resources/users_social_projects';
import { UsersShowsList, UsersShowsShow } from '../resources/users_shows';
import { UsersSectionsList, UsersSectionsShow } from '../resources/users_sections';
import { NumMemberInput } from '../resources/users_groups';
import { ClassroomsList, UsersClassroomsShow } from '../resources/classrooms';
import { RealStateActivitiesList } from '../resources/real_state_activities';
import { AgreementsList } from '../resources/agreements';

import DoneIcon from '@mui/icons-material/Done';
import IconCancel from '@material-ui/icons/Cancel';
import SettingsIcon from '@mui/icons-material/Settings';


import { parse } from 'query-string';

import { targets,modules,usersEditedBooksType,choicesDelegations,choicesProperty,choicesCession, recercatActions, choicesTypeUser, choicesGenderUser } from './../common/constants';
import { subscriptionModule } from '../common/utils';


import { validateMaxLength, validateEmail, validatePercent  } from './../components/Validator';

import { useHistory } from 'react-router-dom';


export const UsersList = ({ group, group_public_id, resource_name, group_name, ...props}) => {
        
  const translate = useTranslate(); 
  const history = useHistory();
    
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('users_groups/recercat-actions');
  const notify = useNotify();
  const refresh = useRefresh();    
  const dataProvider = useDataProvider();
    
  const [info, setInfo] = useState();
  const [action, setAction] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName1, setLastName1] = useState();
  const [lastName2, setLastName2] = useState();
  const [usersGroupsId, setUsersGroupsId] = useState();
    
  const [completeName, setCompleteName] = useState();
  const [email, setEmail] = useState();
  const [recercatCentersId, setRecercatCentersId] = useState();
    
    
  const today = new Date().toISOString().slice(0, 10);


  /*const handleClick = () => {
        
        dataProvider.getOne('users/pre-validate', { id: users_id })
        .then(( { data } ) => {   
            setShowDialog(true);
        })
        .catch(error => {   
            notify(error.message, 'error');    
        });
        
    };*/

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify( error.message , 'error');
        }
      }
    );
  };
    
    
  let context_provider = 'users';
  if (resource_name === 'groups-tree-contacts') context_provider = 'users-fac-contacts';
  let base_path = '/' + context_provider;
    
  /*let users_groups_id = 0;
    let action = 0;
    let next_num_member = '';*/
    
  const handleClick = (record,action) => {
        
    if (action === recercatActions.IRMU_VALIDATE)
    {
      // Validar IRMU
      dataProvider.getOne('users_groups/recercat-next-num-member', { id: record.users_groups_id })
        .then(( { data } ) => { 
          setInfo(data);
          setAction(action);
          setFirstName(record.first_name);
          setLastName1(record.last_name_1);
          setLastName2(record.last_name_2);
          setUsersGroupsId(record.users_groups_id);
          setEmail(record.email);
          setRecercatCentersId(record.recercat_centers_id);
          setShowDialog(true);
        })
        .catch(error => {   
          notify(error.message, 'error');    
        });        
    }
    else if (action === recercatActions.IRMU_REVOKE || action === recercatActions.IRMU_DENY || 
                action === recercatActions.CENTER_VALIDATE || action === recercatActions.CENTER_REVOKE || action === recercatActions.CENTER_DENY ||
                action === recercatActions.USER_VALIDATE )
    {
      // La resta d'accions
      setInfo({num_member: record.num_member });
      setAction(action);
      setFirstName(record.first_name);
      setLastName1(record.last_name_1);
      setLastName2(record.last_name_2);
      setUsersGroupsId(record.users_groups_id);
      setEmail(record.email);
      setRecercatCentersId(record.recercat_centers_id);
      setShowDialog(true);
    }
        
  };
             
  return (
    <>
      <ResourceContextProvider value={context_provider} >
        <List                  
          basePath={base_path}
          filter={{ groups_id : group, associations_id: localStorage.getItem('associations_id') }}  
          title={<ElementTitleUsers />}           
          actions={<ListActions group={group} group_public_id={group_public_id} resource_name={resource_name} />}
          empty={<Empty group={group} group_public_id={group_public_id} resource_name={resource_name} />}    
          filters={<UsersFilter />} 
          syncWithLocation
          sort={{ field: 'date_join', order: 'DESC' }}  
          perPage={25}
          bulkActionButtons={!subscriptionModule(modules.RECERCAT) && (window.location.hostname === 'localhost' || window.location.hostname === 'admin.gestioentitats.cat') ? <BulkActionButtonToolbar group_id={group} group_public_id={group_public_id} resource_name={context_provider} group_name={group_name} /> : false}      
          pagination={<Pagination
            rowsPerPageOptions={[10, 25, 50, 100]} 
            {...props}
          />}
        >
          <Datagrid            
            rowClick="show"
          >  
            <UserAvatarField />
            <TextField source="complete_name" label={translate('common.name')} sortable={true} />
                                                
            { /*resource_name=="groups-tree" ? 
                        <FunctionField label={translate('users.age')} render={record => record.birthday ? getAge(record.birthday) + " " + translate('users.age_sufix') : "" } sortable={false}  />
                        : null */ }
                        
            { subscriptionModule(modules.RECERCAT) ?
              <ReferenceField source="recercat_centers_id" reference="recercat_centers"  label={translate('recercat_centers.column_center')} link={false} sortable={false} >
                <TextField source="name" />
              </ReferenceField>
              : null
            }
                        
            {resource_name === 'groups-tree' && group !== 999999999 && group !== 999999998 ? 
              <TextField source="num_member" label={translate('users_groups.num_member')} sortable={true} />
              : null }
                        
            <TextField source="email" label={translate('common.email')} sortable={true} />     
                        
            { subscriptionModule(modules.RECERCAT) ? 
              group_public_id === 'SOCIS' ? 
                <FunctionField label={translate('recercat_centers.actions')} render={record => {
                  return <><Button
                    size="small"
                    color="primary"
                    onClick={(e) => { e.stopPropagation(); handleClick(record,recercatActions.IRMU_REVOKE); }}
                    startIcon={null}
                    label={translate('recercat_centers.actions_revoke')}
                  ></Button>
                  </>;
                }} sortable={false}  /> :
                group_public_id === 'RECERCAT_PENDING_IRMU' ?
                  <FunctionField label={translate('recercat_centers.actions')} render={record => {
                    return <><Button
                      size="small"
                      color="primary"
                      onClick={(e) => { e.stopPropagation(); handleClick(record,recercatActions.IRMU_VALIDATE); }}
                      startIcon={null}
                      label={translate('recercat_centers.actions_validate')}
                    >
                    </Button>
                    <Button
                      size="small"
                      color="primary"
                      onClick={(e) => { e.stopPropagation(); handleClick(record,recercatActions.IRMU_DENY); }}
                      startIcon={null}
                      label={translate('recercat_centers.actions_deny')}
                    >
                    </Button></>;                            
                  }} sortable={false}  /> :
                  group_public_id === 'RECERCAT_PENDING_CENTER' ?  
                    <FunctionField label={translate('recercat_centers.actions')} render={record => {
                      return <><Button
                        size="small"
                        color="primary"
                        onClick={(e) => { e.stopPropagation(); handleClick(record,recercatActions.IRMU_VALIDATE); }}
                        startIcon={null}
                        label={translate('recercat_centers.actions_validate')}
                      ></Button>
                      <Button
                        size="small"
                        color="primary"
                        onClick={(e) => { e.stopPropagation(); handleClick(record,recercatActions.IRMU_DENY); }}
                        startIcon={null}
                        label={translate('recercat_centers.actions_deny')}
                      >
                      </Button></>;
                    }} sortable={false}  /> :
                    group_public_id === 'RECERCAT_PENDING_EMAIL' ?  
                      <FunctionField label={translate('recercat_centers.actions')} render={record => {
                        return <><Button
                          size="small"
                          color="primary"
                          onClick={(e) => { e.stopPropagation(); handleClick(record,recercatActions.IRMU_VALIDATE); }}
                          startIcon={null}
                          label={translate('recercat_centers.actions_validate')}
                        ></Button>
                        <Button
                          size="small"
                          color="primary"
                          onClick={(e) => { e.stopPropagation(); handleClick(record,recercatActions.IRMU_DENY); }}
                          startIcon={null}
                          label={translate('recercat_centers.actions_deny')}
                        >
                        </Button></>;
                      }} sortable={false}  /> 
                      : null 
              : null }
                    
                        
            { !subscriptionModule(modules.RECERCAT) ?
              <FunctionField label={translate('users.phones')} render={record => {
                let str = '';
                if (record.phone) str += record.phone; 
                if (record.mobile) str += str.length > 0 ? ' / ' + record.mobile : record.mobile;                            
                return str;
              }} sortable={false}  />
              : null }
                              
            { !subscriptionModule(modules.RECERCAT) ? 
              resource_name=='groups-tree' ? 
                <ArrayField source="groups" label={translate('users.groups')} sortable={false}>
                  <SingleFieldList linkType={false}>
                    <ChipField source="name" />
                  </SingleFieldList>
                </ArrayField>                   
                : null 
              : null }

          </Datagrid>                             
        </List>

      </ResourceContextProvider>
      {info ? 
        <Dialog
          fullWidth
          open={showDialog}
          onClose={handleCloseClick}
          aria-label={translate('recercat_centers.actions_title' + action)}
        >
          <DialogTitle>{translate('recercat_centers.actions_title' + action )}</DialogTitle>

          <FormWithRedirect
            save={handleSubmit}
            initialValues={{ 
              users_groups_id : usersGroupsId,
              action : action, 
              num_member: info.num_member , 
              send_email: true,                        
              first_name : firstName,
              last_name_1 : lastName1,
              last_name_2 : lastName2,
              email: email,
              recercat_centers_id: recercatCentersId  
            }}
            render={({
              handleSubmitWithRedirect,
              pristine,
              saving
            }) => (
              <>
                <DialogContent>
                  <Grid container spacing={2}>
                    { (action === recercatActions.IRMU_REVOKE || action === recercatActions.CENTER_REVOKE) ?
                      <Grid item xs={12}>
                        {translate('recercat_centers.revoke_ask')}
                      </Grid> : null }
                    { (action === recercatActions.IRMU_DENY || action === recercatActions.CENTER_DENY) ?
                      <Grid item xs={12}>
                        {translate('recercat_centers.deny_ask')}
                      </Grid> : null }
                    <Grid item xs={4}>
                      <TextInput fullWidth source="first_name" label={translate('users.first_name')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} disabled />
                    </Grid>
                    <Grid item xs={4}>
                      <TextInput fullWidth source="last_name_1" label={translate('users.last_name_1')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} disabled />    
                    </Grid>
                    <Grid item xs={4}>
                      <TextInput fullWidth source="last_name_2" label={translate('users.last_name_2')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} disabled />  
                    </Grid>
                  </Grid>  
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextInput fullWidth source="email" label={translate('users.email')} parse={value => value} validate={[validateMaxLength(100,translate('common.validation.maxLength')),validateEmail(),required()]} disabled />
                    </Grid>
                  </Grid>                                    
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <ReferenceInput fullWidth source="recercat_centers_id" reference="recercat_centers"  label={translate('recercat_centers.column_center')} link={false} sortable={false} filter={{ associations_id : localStorage.getItem('associations_id') }} perPage={10000} >
                        <SelectInput optionText="name" optionValue="id" resettable disabled validate={[required()]} />  
                      </ReferenceInput>
                    </Grid>
                  </Grid> 
                  <Grid container spacing={2}>
                    { (action === recercatActions.IRMU_VALIDATE || action === recercatActions.IRMU_REVOKE || action === recercatActions.CENTER_REVOKE) ?
                      <Grid item xs={6}>     
                        <TextInput fullWidth source="num_member" label={translate('recercat_centers.validate_num_member')} disabled={action==1 ? false : true} />
                      </Grid>
                      : null }
                    <Grid item xs={12}> 
                      { (action === recercatActions.IRMU_VALIDATE ) ?
                        <BooleanInput fullWidth source="send_email" label={translate('recercat_centers.validate_send_email')} />
                        : null }
                      { (action === recercatActions.IRMU_REVOKE || action === recercatActions.CENTER_REVOKE ) ?
                        <BooleanInput fullWidth source="send_email" label={translate('recercat_centers.revoke_send_email')} />
                        : null }
                      { (action === recercatActions.IRMU_DENY || action === recercatActions.CENTER_DENY ) ?
                        <BooleanInput fullWidth source="send_email" label={translate('recercat_centers.revoke_send_email')} />
                        : null }
                    </Grid> 
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                    <IconCancel />
                  </Button>
                  <SaveButton
                    handleSubmitWithRedirect={
                      handleSubmitWithRedirect
                    }
                    pristine={pristine}
                    saving={saving}
                    disabled={loading}
                    label={translate('recercat_centers.action_button' + action)}
                  />
                </DialogActions>
              </>
            )}
          />
        </Dialog>
        : null }
                    
    </>
  );
};



const BulkActionButtonToolbar = ({ group_id, group_name, group_public_id, resource_name, ...props }) => {

    const { setSelectedIds } = useListContext();
    
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const unselectAll = useUnselectAll();

    const handleBulkAction = () => {
        //notify(`Has seleccionat ${selectedIds.length} files`);
        // Realitza alguna acció amb les files seleccionades
        unselectAll(resource_name); // Deselecta totes les files després de l’acció
    };
    
    let redirect = '/groups-tree/' + group_id;
    
    return (
        <>
            { group_public_id != 'SOCIS_PENDING' ?
            <BulkGroupsButton 
            {...props} 
            resource_name={resource_name}
            source_group_id={group_id}
            group_name={group_name}
            group_public_id={group_public_id}
            /> : null }
            
            <BulkDeleteButton 
            {...props} 
            resource_name={resource_name}
            />     
            
        </>
    );
};


const Empty = ({group, group_public_id, resource_name, ...props}) => {
  const translate = useTranslate(); 
  const { basePath, resource } = useListContext();
  return (<>
    {group_public_id == 'SOCIS_PENDING' && resource_name === 'groups-tree' ? 
    <Box textAlign="center" m={1}>
        <ShowButton 
            component={Link}
            to={'/public/add-member?associations_id=' + localStorage.getItem('associations_id')}
            label={translate('settings.add_member_view_form')}
            target="_blank"
            align="right"
            className
          /> 
        <Button 
            component={Link}
            to={'/settings-add-member'}
            label={translate('settings.add_member_edit_form')}
            align="right"
            className
          > 
          <SettingsIcon />
        </Button>
    </Box> : null }
          
    {group_public_id !== 'SOCIS_PENDING' ? 
      <Box textAlign="center" m={1}>
        <br />
        <Typography variant="h5" paragraph>
          {translate('users.no_users_title')} 
        </Typography>
        {resource_name === 'groups-tree' ? <>
          <Typography variant="body1">
            {translate('users.no_users_ask')} 
          </Typography>
          <br/> 
          <CreateCustomButton group={group} basePath={basePath} />
          { subscriptionModule(modules.RECERCAT) ? 
            group_public_id === 'SOCIS' || group_public_id === 'RECERCAT_PENDING_IRMU' || group_public_id === 'RECERCAT_PENDING_CENTER' ?
              <ImportButton target={targets.GROUP} items_id={group} group_public_id={group_public_id} />        
              : null 
            :                
            <ImportButton target={targets.GROUP} items_id={group} group_public_id={group_public_id} />
          }
        </> : null } 
      </Box> : null }
  </>
  );
};

const CreateCustomButton = ({group, ...props}) => {

  return (
    <CreateButton
      {...props}
      component={Link}
      to={{
        pathname: '/users/create',
        search: `?groups_id=${group}`, 
      }}
    />
  );
};

const ListActions = ({group, group_public_id, resource_name, ...props}) => {

  const translate = useTranslate(); 
 
  let filterExport = {
    groups_id : group,  
    associations_id: localStorage.getItem('associations_id'),
    filter: props.filterValues && props.filterValues.filter ? props.filterValues.filter : [],
    q: props.filterValues && props.filterValues.q ? props.filterValues.q : ''
  };
    
  const sort=props.currentSort ? props.currentSort : { field: 'id', order: 'DESC' };
  
      
  return (
    
    <>
      <TopToolbar>
        <>
          {group_public_id == 'SOCIS_PENDING' && resource_name === 'groups-tree' ? 
          <ShowButton 
              component={Link}
              to={'/public/add-member?associations_id=' + localStorage.getItem('associations_id')}
              label={translate('settings.add_member_view_form')}
              target="_blank"
              align="right"
              className
            /> 
          : null }
          
          {group_public_id == 'SOCIS_PENDING' && resource_name === 'groups-tree' ? 
          <Button 
              component={Link}
              to={'/settings-add-member'}
              label={translate('settings.add_member_edit_form')}
              align="right"
              className
            > 
            <SettingsIcon />
          </Button>
            
          : null }
          
          {group_public_id !== 'SOCIS_PENDING' && resource_name === 'groups-tree' ? 
            <>        
              <MyFilterUsers group={group} />        
              <CreateCustomButton group={group} />
              { subscriptionModule(modules.FEDERATION) && group_public_id === 'SOCIS' ? 
                <UsersAnnuarySendButton group={group} /> : null
              }
              { subscriptionModule(modules.RECERCAT) ? 
                group_public_id === 'SOCIS' || group_public_id === 'RECERCAT_PENDING_IRMU' || group_public_id === 'RECERCAT_PENDING_CENTER' ?
                  <ImportButton target={targets.GROUP} items_id={group} group_public_id={group_public_id} />        
                  : null 
                :
                <ImportButton target={targets.GROUP} items_id={group} group_public_id={group_public_id} /> }
            </>
            : null }
                      
          <DownloadXLSButton {...props} groups_id={group} />
          
          { group_public_id !== 'SOCIS_PENDING' && resource_name === 'groups-tree' && subscriptionModule(modules.POSTAL_LABELS) ? 
            <UsersPostalLabelsCreateButton {...props} filterValues={filterExport} />
          : null }
          
        </>
      </TopToolbar>
    </>
    
  );
};

/* 
EXPORTACIÓ VELLA
<ExportButton maxResults="100000" filterValues={filterExport} alwaysEnabled={true} exporter={subscriptionModule(modules.RECERCAT) ? exporterRecercat : (resource_name === 'groups-tree') ? exporter : exporterFAC }   sort={sort} /> 
*/

function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}
    
const exporter = (items, translate, notify) => {
      
  const eventsForExport = items.map(user => {
    var eventForExport = {
      id : user.id,
      first_name : user.first_name, 
      last_name_1 : user.last_name_1, 
      last_name_2 : user.last_name_2, 
      email: user.email,
      phone: user.phone,
      mobile: user.mobile,
      group: user.group_name,
      date_join: user.date_join ? user.date_join.substr(0,10) : '' ,
      group_num_member: user.num_member,
      type: translate('users.user_type_' + user.user_type ),
      birthday: user.birthday ,
      age: user.birthday ? getAge(user.birthday): '' ,
      qr_code : user.card_hash
    };
    return eventForExport;
  });
  jsonExport(eventsForExport, {
    headers: ['id', 'type', 'first_name', 'last_name_1', 'last_name_2','email','phone','mobile','birthday', 'age', 'group','group_num_member','date_join','qr_code'],
    rename: [translate('common.id'), translate('users.user_type'), translate('users.first_name'), translate('users.last_name_1'), translate('users.last_name_2'), translate('users.email'),translate('users.phone'),translate('users.mobile'), translate('users.birthday'),translate('users.age'), translate('users.group'), translate('users_groups.num_member'), translate('users_groups.date_join'),translate('users_groups.qr_code')],        
        
  }, (err, csv) => {
    const csv_new = '\uFEFF' + csv;
    downloadCSV(csv_new, translate('users.exporter_file_name') );       
    notify(translate('common.download_ok'),"success");
  });
};

const exporterFAC = (items, translate, notify) => {
    
  const eventsForExport = items.map(user => {
    var eventForExport = {
      id : user.id,
      first_name : user.first_name, 
      last_name_1 : user.last_name_1, 
      last_name_2 : user.last_name_2, 
      email: user.email,
      phone: user.phone,
      mobile: user.mobile,
      group: user.group_name,
      type: translate('users.user_type_' + user.user_type )
    };
    return eventForExport;
  });
  jsonExport(eventsForExport, {
    headers: ['id', 'type', 'first_name', 'last_name_1', 'last_name_2','email', 'phone', 'mobile', 'group'],
    rename: [translate('common.id'), translate('users.user_type'), translate('users.first_name'), translate('users.last_name_1'), translate('users.last_name_2'), translate('users.email'), translate('users.phone'), translate('users.mobile'), translate('users.group')],        
        
  }, (err, csv) => {
    const csv_new = '\uFEFF' + csv;
    downloadCSV(csv_new, translate('users.exporter_file_name')+'FAC' ); 
    notify(translate('common.download_ok'),"success");
  });
};

const exporterRecercat = (items, translate, notify) => {
    
  const eventsForExport = items.map(user => {
    var eventForExport = {
      id : user.id,
      first_name : user.first_name, 
      last_name_1 : user.last_name_1, 
      last_name_2 : user.last_name_2, 
      email: user.email,
      center: user.recercat_centers_public_id,
      group: user.group_name,
      date_join: user.date_join ? user.date_join.substr(0,10) : '' ,
      group_num_member: user.num_member,
      qr_code : user.card_hash
    };
    return eventForExport;
  });
  jsonExport(eventsForExport, {
    headers: ['id', 'first_name', 'last_name_1', 'last_name_2','email','center','group','group_num_member','date_join','qr_code'],
    rename: [translate('common.id'), translate('users.first_name'), translate('users.last_name_1'), translate('users.last_name_2'), translate('users.email'),translate('recercat_centers.column_center'), translate('users.group'), translate('users_groups.num_member'), translate('users_groups.date_join'),translate('users_groups.qr_code')],                
  }, (err, csv) => {
    const csv_new = '\uFEFF' + csv;
    downloadCSV(csv_new, translate('users.exporter_file_name') ); 
    notify(translate('common.download_ok'),"success");
  });
};


const ElementTitleUsers = ({ record }) => {
  const translate = useTranslate(); 
  return '';
};


const handleChangeUserType = event => {
  console.log(event);
};

const UserEditActions = ({ basePath, data, resource }) => {
  let user_complete_name = data ? (<UserFullNameTxt record={data} />) : '';
  return (
    <TopToolbar>
      <Grid container spacing={2}>
        <Grid item xs={8}>                    
          <Typography variant="h5" className="grey8">
            {user_complete_name}                    
          </Typography>
        </Grid>
        <Grid item xs={4} align="right">                    
          <ShowButton basePath={basePath} record={data} />
        </Grid>
      </Grid>            
    </TopToolbar>
  );
};


const FormToolbar = ({ id , ...props }) => {
    
  let redirect = '/groups-tree/' + localStorage.getItem('members_group_id');
    
  return (
    <Toolbar {...props} className="user_form_toolbar" >
      <SaveButton />
      <DeleteButton redirect={redirect} mutationMode="pessimistic" />
    </Toolbar>
  );
};

export const UsersEdit  = ({ id, ...props }) => {

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
    
  const translate = useTranslate();        
  const classes = useStyles();
  const today = new Date().toISOString().slice(0, 10);
        
  const handleFailure = (error) => {        
    notify(error.message, 'error');
  };

  const handleSuccess = ({ data }) => {
    notify(translate('common.notification.updated'));
    redirect('show', props.basePath, data.id);
    refresh();
  };
  
    
  return (
    
    <Edit         
      {...props} 
      title={<ElementTitle />}
      id={id} 
      mutationMode="pessimistic"   
      actions={<UserEditActions />}
      onFailure={handleFailure}
      onSuccess={handleSuccess}        
    >    
    
      { subscriptionModule(modules.RECERCAT) ?
        <SimpleForm
          toolbar={<FormToolbar />}
        >
          <AccordionSection className="accordion" label={translate('users.tab_main_0')} fullWidth defaultExpanded={true}>
            <Grid container spacing={2}>            
              <Grid item xs={4}>
                <TextInput fullWidth source="first_name" label={translate('users.first_name')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]}  />
              </Grid>
              <Grid item xs={4}>
                <TextInput fullWidth source="last_name_1" label={translate('users.last_name_1')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]}  />    
              </Grid>
              <Grid item xs={4}>
                <TextInput fullWidth source="last_name_2" label={translate('users.last_name_2')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />  
              </Grid>
            </Grid>  
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextInput fullWidth source="email" label={translate('users.email')} parse={value => value} validate={[validateMaxLength(100,translate('common.validation.maxLength')),validateEmail()]} />
              </Grid>
            </Grid>  
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ReferenceInput fullWidth source="recercat_centers_id" reference="recercat_centers"  label={translate('recercat_centers.column_center')} link={false} sortable={false} filter={{ associations_id : localStorage.getItem('associations_id') }} perPage={10000} >
                  <SelectInput optionText="name" optionValue="id" resettable validate={[required()]} disabled />  
                </ReferenceInput>
              </Grid>
            </Grid>  
          </AccordionSection>
          <br />        
        </SimpleForm>
        :     
        <TabbedForm        
          toolbar={<FormToolbar />}
        >    
          <FormTab
            label={translate('users.tab_main')}
            contentClassName={classes.tab}
            path="" 
          > 
                  
            <FormDataConsumer>
              {({ formData, ...rest }) => <>
                        
                <Grid container spacing={2}>
                  <Grid item xs={10}>   
                    <Grid container spacing={2}>
                      <Grid item xs={4}>                    
                        <RadioButtonGroupInput source="user_type" choices={choicesTypeUser(translate)} optionText="name" optionValue="id" label={translate('users.user_type')}  validate={required()} disabled />            
                      </Grid>
                    </Grid>
                    
                    { formData.user_type === 0 ? <> 

                      <AccordionSection className="accordion" label={translate('users.tab_main_0')} fullWidth defaultExpanded={true}>
                        <Grid container spacing={2}>                        
                          <Grid item xs={4}>
                            <TextInput fullWidth source="first_name" label={translate('users.first_name')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]}  />
                          </Grid>
                          <Grid item xs={4}>
                            <TextInput fullWidth source="last_name_1" label={translate('users.last_name_1')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]}  />    
                          </Grid>
                          <Grid item xs={4}>
                            <TextInput fullWidth source="last_name_2" label={translate('users.last_name_2')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />  
                          </Grid>
                        </Grid>        

                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <TextInput fullWidth source="email" label={translate('users.email')} parse={value => value} validate={[validateMaxLength(100,translate('common.validation.maxLength')),validateEmail()]} />
                          </Grid>
                          <Grid item xs={4}>
                            <TextInput fullWidth source="email_secondary" label={translate('users.email_secondary')} parse={value => value} validate={[validateMaxLength(100,translate('common.validation.maxLength')),validateEmail()]} />
                          </Grid>
                          <Grid item xs={4}>
                            <TextInput fullWidth source="nif" label={translate('users.nif')} validate={[validateMaxLength(10,translate('common.validation.maxLength'))]} />
                          </Grid>
                        </Grid>           

                        <Grid container spacing={2}>
                          { !(subscriptionModule(modules.FEDERATION) && formData.user_type === 1) ? <>
                            <Grid item xs={4}>
                              <TextInput fullWidth source="address" label={translate('users.address')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />
                            </Grid>
                            <Grid item xs={4}>
                              <TextInput fullWidth source="neighborhood" label={translate('users.neighborhood')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />
                            </Grid>
                            <Grid item xs={4}>
                              <TextInput fullWidth source="zip" label={translate('users.zip')} validate={[validateMaxLength(5,translate('common.validation.maxLength'))]} />
                            </Grid>
                            <Grid item xs={4}>
                              <TextInput fullWidth source="city" label={translate('users.city')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />
                            </Grid>
                            <Grid item xs={4}>
                              <TextInput fullWidth source="region" label={translate('users.region')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />
                            </Grid>
                            <Grid item xs={4}>
                              <TextInput fullWidth source="country" label={translate('users.country')} validate={[validateMaxLength(50,translate('common.validation.maxLength'))]} />
                            </Grid>
                            
                            { formData.user_type === 1 ?
                            <>
                            <Grid item xs={4}>
                              <NumberInput fullWidth source="lat" label={translate('users.lat')}  />
                            </Grid>
                            <Grid item xs={4}>
                              <NumberInput fullWidth source="lng" label={translate('users.lng')}  />
                            </Grid>
                            <Grid item xs={4}>                              
                            </Grid>
                            </> : null }
                            
                          </> : null }
                          <Grid item xs={4}>
                            <TextInput fullWidth source="phone" label={translate('users.phone')} validate={[validateMaxLength(15,translate('common.validation.maxLength'))]} />
                          </Grid>
                          <Grid item xs={4}>
                            <TextInput fullWidth source="mobile" label={translate('users.mobile')} validate={[validateMaxLength(15,translate('common.validation.maxLength'))]} />
                          </Grid>
                          <Grid item xs={4}>
                            <TextInput fullWidth source="phone_emergency" label={translate('users.phone_emergency')} validate={[validateMaxLength(50,translate('common.validation.maxLength'))]} />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>

                          <Grid item xs={4}>
                            <DateInput fullWidth source="birthday" label={translate('users.birthday')} />
                          </Grid>
                          <Grid item xs={4}>
                            <TextInput fullWidth source="job" label={translate('users.job')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />
                          </Grid>
                          <Grid item xs={4}>
                            <SelectInput fullWidth source="gender" choices={choicesGenderUser(translate)} optionText="name" optionValue="id" label={translate('users.gender')} />    
                          </Grid>
                        </Grid>    

                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <TextInput fullWidth source="web" label={translate('users.web')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />
                          </Grid> 
                          <Grid item xs={8}>
                            <BooleanInput fullWidth source="newsletter" label={translate('users.newsletter')} />
                          </Grid> 
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextInput fullWidth source="comment" label={translate('users.comment')} validate={[validateMaxLength(1000,translate('common.validation.maxLength'))]} />
                          </Grid>    
                        </Grid>
                                
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextInput fullWidth source="alerts" label={translate('users.alerts')} validate={[validateMaxLength(1000,translate('common.validation.maxLength'))]} />
                          </Grid>    
                        </Grid>

                      </AccordionSection>

                    </> : null }
                            
                    { formData.user_type === 1 ? <>

                      <AccordionSection className="accordion" label={translate('users.tab_main_1')} fullWidth defaultExpanded={true} >
                    
                        <Grid container spacing={2}>
                          <Grid item xs={9}>
                            <TextInput fullWidth source="first_name" label={translate('users.name_company')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]}  />
                          </Grid>
                          <Grid item xs={3}>
                            <TextInput fullWidth source="nif" label={translate('users.nif_only')} validate={[validateMaxLength(10,translate('common.validation.maxLength'))]} />
                          </Grid>
                        </Grid>        

                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <TextInput fullWidth source="email" label={translate('users.email')} validate={[validateMaxLength(100,translate('common.validation.maxLength')),validateEmail()]} />
                          </Grid>
                          <Grid item xs={4}>
                            <TextInput fullWidth source="email_secondary" label={translate('users.email_secondary')} validate={[validateMaxLength(100,translate('common.validation.maxLength')),validateEmail()]} />
                          </Grid>
                          <Grid item xs={4}>
                            <DateInput fullWidth source="birthday" label={translate('users.foundation_date')} />
                          </Grid>
                        </Grid>           

                        <Grid container spacing={2}>
                          { !(subscriptionModule(modules.FEDERATION) && formData.user_type === 1 ) ? <>
                            <Grid item xs={4}>
                              <TextInput fullWidth source="address" label={translate('users.address')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />
                            </Grid>
                            <Grid item xs={4}>
                              <TextInput fullWidth source="neighborhood" label={translate('users.neighborhood')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />
                            </Grid>
                            <Grid item xs={4}>
                              <TextInput fullWidth source="zip" label={translate('users.zip')} validate={[validateMaxLength(5,translate('common.validation.maxLength'))]} />
                            </Grid>
                            <Grid item xs={4}>
                              <TextInput fullWidth source="city" label={translate('users.city')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />
                            </Grid>
                            <Grid item xs={4}>
                              <TextInput fullWidth source="region" label={translate('users.region')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />
                            </Grid>
                            <Grid item xs={4}>
                              <TextInput fullWidth source="country" label={translate('users.country')} validate={[validateMaxLength(50,translate('common.validation.maxLength'))]} />
                            </Grid>
                            <Grid item xs={4}>
                              <NumberInput fullWidth source="lat" label={translate('users.lat')}  />
                            </Grid>
                            <Grid item xs={4}>
                              <NumberInput fullWidth source="lng" label={translate('users.lng')}  />
                            </Grid>
                            <Grid item xs={4}>                              
                            </Grid>
                          </> : null }
                          <Grid item xs={4}>
                            <TextInput fullWidth source="phone" label={translate('users.phone')} validate={[validateMaxLength(15,translate('common.validation.maxLength'))]} />
                          </Grid>
                          <Grid item xs={4}>
                            <TextInput fullWidth source="mobile" label={translate('users.mobile')} validate={[validateMaxLength(15,translate('common.validation.maxLength'))]} />
                          </Grid>
                          <Grid item xs={4}>
                            <TextInput fullWidth source="web" label={translate('users.web')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />
                          </Grid>
                        </Grid>
                            
                        <Grid container spacing={2}>
                          <Grid item xs={12}>                                    
                            <BooleanInput fullWidth source="newsletter" label={translate('users.newsletter')} />
                          </Grid>
                        </Grid>
                                
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextInput fullWidth source="comment" label={translate('users.comment')} validate={[validateMaxLength(1000,translate('common.validation.maxLength'))]} />
                          </Grid>                                
                        </Grid>
                            
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextInput fullWidth source="alerts" label={translate('users.alerts')} validate={[validateMaxLength(1000,translate('common.validation.maxLength'))]} />
                          </Grid>    
                        </Grid>               

                      </AccordionSection>
                            
                    </> : null }
                    
                    <AccordionSection className="accordion" label={translate('users.bank_data')} fullWidth defaultExpanded={true}>
                      <Grid container spacing={2}>
                        <Grid item xs={8}>       
                          <TextInput fullWidth source="iban" label={translate('users.bank_iban')} validate={[validateMaxLength(32,translate('common.validation.maxLength'))]} />
                        </Grid> 
                        <Grid item xs={4}>       
                          <TextInput fullWidth source="swift" label={translate('users.bank_swift')} validate={[validateMaxLength(11,translate('common.validation.maxLength'))]} />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>       
                          <BooleanInput fullWidth source="payment_cash" label={translate('users.payment_cash')} />
                        </Grid> 
                      </Grid>
                      <Grid container spacing={2}>                        
                        <Grid item xs={6}>   
                          <UserInput source="payer" user_type={-1} disabled={false} label={translate('users.bank_payer')} allowEmpty={true} required={false} />                                
                        </Grid>                             
                      </Grid>
                    </AccordionSection>
                    
                    { subscriptionModule(modules.CUSTOM_FIELDS) ?
                    <CustomFieldsEdit associations_id={localStorage.getItem('associations_id')} target={targets.USER} title={true} />
                    : null }
                    
                  </Grid>
                  <Grid item xs={2}>                   
                    <br />                    
                    <BoxPhotoUser target={targets.USER_PHOTO} items_id={id} edit={true} default_img={formData.user_type} className="docs_photo_user" camera={true} />
                  </Grid>
                </Grid>
            
              </> }
            </FormDataConsumer>

          </FormTab>
            
          <FormTab
            label={translate('users.tab_more')}
            contentClassName={classes.tab}
            path="more" 
          >     
            <FormDataConsumer>
              {({ formData, ...rest }) => <>
                    
                { formData.user_type === 0 ? <>
                  <br />
                  <AccordionSection className="accordion" label={translate('users.tab_family')} fullWidth defaultExpanded={true}>
                    <UsersFamiliesList users_id={id} fullWidth />
                  </AccordionSection>

                  <AccordionSection className="accordion" label={translate('users.tab_contacts_rel')} fullWidth defaultExpanded={true}>
                    <UsersContactsList users_id={formData.id} rel={true} />
                  </AccordionSection> 
                </> : null }
                
                <br />
                <AccordionSection className="accordion" label={translate('users.email_secondary_list')} fullWidth defaultExpanded={true}>
                  <UsersEmailsList users_id={formData.id} />
                </AccordionSection>                  
                  
                { formData.user_type === 1 ? <>

                  { subscriptionModule(modules.FEDERATION) ? 
                    <AccordionSection className="accordion" label={translate('users_federation.tab_managers')} fullWidth defaultExpanded={true}>
                      <UsersContactsList users_id={formData.id} responsabilities={true} />
                    </AccordionSection>
                    : null }

                  <AccordionSection className="accordion" label={translate('users.tab_contacts')} fullWidth defaultExpanded={true}>
                    <UsersContactsList users_id={formData.id} responsabilities={false}/>
                  </AccordionSection>
                        
                  { subscriptionModule(modules.FEDERATION) ? 
                    <AccordionSection className="accordion" label={translate('users_federation.addresses')} fullWidth defaultExpanded={true}>   
                      <UsersAddressesEdit id={id} />
                    </AccordionSection>    
                    : null }
                                            
                </> : null } 
                    
                <AccordionSection className="accordion" label={translate('users.tab_groups')} fullWidth defaultExpanded={true}>
                  <UsersGroupsEdit id={id} today={today} />
                </AccordionSection>    
                
                { subscriptionModule(modules.REMITTANCES) ? 
                <AccordionSection className="accordion" label={translate('users.tab_quotes')} fullWidth defaultExpanded={true}>
                  <UsersGroupsQuotesList users_id={id} />
                </AccordionSection>    
                : null }

                { formData.user_type === 0 ?
                  <AccordionSection className="accordion" label={translate('users.tab_responsabilities')} fullWidth defaultExpanded={true}>                        
                    <UsersGroupsResponsabilitiesEdit id={id} today={today} />
                  </AccordionSection>      
                  : null }

                <AccordionSection className="accordion" label={translate('users.tab_social_nets')} fullWidth defaultExpanded={true}>
                  <SocialNetsList target={targets.USER} items_id={id} />
                  <br />
                </AccordionSection>                    
                            
              </>}
            </FormDataConsumer>
          </FormTab>            
            
          <FormTab
            label={translate('users.tab_docs')}
            contentClassName={classes.tab}
            path="docs" 
          >       
            <DocsList target={targets.USER} items_id={id} />
            <br />
          </FormTab>
            
          { subscriptionModule(modules.FEDERATION) ?  
            <FormTab
              label={translate('users_federation.tab_classrooms')}
              contentClassName={classes.tab}
              path="classrooms"                     
            >           
                
              <FormDataConsumer>
                {({ formData, ...rest }) => formData.user_type === 1 && 
                    <ClassroomsList users_id={id} user_classroom={true} />
                }
              </FormDataConsumer>
                    
            </FormTab>
            : null }
                
            
          { subscriptionModule(modules.FEDERATION) ?  
            <FormTab
              label={translate('users_federation.tab')}
              contentClassName={classes.tab}
              path="federation" 
            >   

              <FormDataConsumer>
                {({ formData, ...rest }) => formData.user_type === 1 && <>
                  <br />   
                    
                  <Grid container spacing={2}>
                    <Grid item xs={3}>  
                      <TextInput fullWidth source="name" label={translate('users_federation.name')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />                    
                    </Grid>
                    <Grid item xs={3}>  
                      <TextInput fullWidth source="acronym" label={translate('users_federation.acronym')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />                    
                    </Grid>
                    <Grid item xs={3}>                    
                      <TextInput fullWidth source="reg_name" label={translate('users_federation.reg_name')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />
                    </Grid>
                    <Grid item xs={3}>                    
                      <TextInput fullWidth source="reg_num" label={translate('users_federation.reg_num')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />
                    </Grid>                        
                  </Grid>
                    
                  <Grid container spacing={2}>
                    <Grid item xs={3}>  
                      <BooleanInput fullWidth source="bcin" label={translate('users_federation.bcin')}  />
                    </Grid>
                    <Grid item xs={3}>                    
                      <BooleanInput fullWidth source="bcil" label={translate('users_federation.bcil')}  />
                    </Grid>
                  </Grid>
                    
                  <Grid container spacing={2}>
                    <Grid item xs={12}>  
                      <TextInput fullWidth source="tickets" label={translate('users_federation.tickets')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />
                    </Grid>
                  </Grid>
                    
                  <Grid container spacing={2}>
                    <Grid item xs={2}>  
                      <BooleanInput fullWidth source="xtac" label={translate('users_federation.xtac')}  />
                    </Grid>
                    <Grid item xs={2}>  
                      <BooleanInput fullWidth source="culture_interest" label={translate('users_federation.culture_interest')}  />
                    </Grid>
                    <Grid item xs={2}>  
                      <BooleanInput fullWidth source="public_utility" label={translate('users_federation.public_utility')}  />
                    </Grid>
                    <Grid item xs={2}>  
                      <BooleanInput fullWidth source="soft_cat" label={translate('users_federation.soft_cat')}  />
                    </Grid>
                    <Grid item xs={2}>  
                      <BooleanInput fullWidth source="rcat" label={translate('users_federation.rcat')}  />
                    </Grid>
                  </Grid>
                    
                  <Grid container spacing={2}>
                    <Grid item xs={3}>  
                      <DateInput fullWidth source="last_managers_change" label={translate('users_federation.last_managers_change')}  />
                    </Grid>
                    <Grid item xs={3}>  
                      <DateInput fullWidth source="next_managers_change" label={translate('users_federation.next_managers_change')}  />
                    </Grid>
                    <Grid item xs={6}>  
                      <SelectInput fullWidth label={translate('users_federation.delegation')} source="delegation" choices={choicesDelegations} resettable /> 
                    </Grid>
                  </Grid>
                    
                  <Grid container spacing={2}>                        
                    <Grid item xs={3}>  
                      <BooleanInput fullWidth source="host" label={translate('users_federation.host')}  />
                    </Grid>   
                    { formData.host ?                       
                      <Grid item xs={9}>  
                        <TextInput fullWidth multiline source="host_entities" label={translate('users_federation.host_entities')} />
                      </Grid>
                    : null }
                  </Grid>
                    
                  <Grid container spacing={2}>                        
                    <Grid item xs={3}>  
                      <BooleanInput fullWidth source="more_federations" label={translate('users_federation.more_federations')}  />
                    </Grid>   
                    { formData.more_federations ?                       
                      <Grid item xs={9}>  
                        <TextInput fullWidth multiline source="federations" label={translate('users_federation.federations')} />
                      </Grid>
                    : null }
                  </Grid>
                    
                  <Grid container spacing={2}>
                    <Grid item xs={4}>                    
                      <BooleanInput fullWidth source="members_cards" label={translate('users_federation.members_cards')}  />
                    </Grid>
                    <Grid item xs={4}>                    
                      <BooleanInput fullWidth source="logo_federation" label={translate('users_federation.logo_federation')}  />
                    </Grid>
                    <Grid item xs={4}>                    
                      <BooleanInput fullWidth source="internal_regulation" label={translate('users_federation.internal_regulation')}  />
                    </Grid>
                  </Grid>
                  
                  { subscriptionModule(modules.CUSTOM_FIELDS) ?
                    <CustomFieldsEdit associations_id={localStorage.getItem('associations_id')} target={targets.USER_FEDERATION} title={false} prefix="federation_"/>
                  : null }
                    
                  <Typography variant="h6" className="grey8 titleUserShow">                        
                    {translate('users_federation.building')}
                  </Typography>
                  <Divider fullWidth className="" />
                  <br />
                    
                  <Grid container spacing={2}>
                    <Grid item xs={3}>                    
                      <BooleanInput fullWidth source="federation_plate" label={translate('users_federation.federation_plate')}  />
                    </Grid>
                    <Grid item xs={3}>                    
                      <BooleanInput fullWidth source="license" label={translate('users_federation.license')}  />
                    </Grid>
                  </Grid>
                    
                  <Grid container spacing={2}>
                    <Grid item xs={3}>                    
                      <BooleanInput fullWidth source="ownership" label={translate('users_federation.ownership')}  />
                    </Grid>
                    <Grid item xs={3}>                    
                      <SelectInput fullWidth label={translate('users_federation.property')} source="property" choices={choicesProperty} resettable /> 
                    </Grid>
                    <Grid item xs={3}>                    
                      <SelectInput fullWidth label={translate('users_federation.rent')} source="rent" choices={choicesCession} resettable /> 
                    </Grid>
                    <Grid item xs={3}>
                      <BooleanInput fullWidth source="expropied" label={translate('users_federation.expropied')}  />
                    </Grid>                    
                  </Grid>
                  
                  <Typography variant="h6" className="grey8 titleUserShow">                        
                    {translate('users_federation.history_title')}
                  </Typography>
                  <Divider fullWidth className="" />
                  <br />
                  
                  <Grid container spacing={2}>
                    <Grid item xs={3}>  
                      <BooleanInput fullWidth source="actived" label={translate('users_federation.actived')}  />
                    </Grid>
                    <Grid item xs={9}>                        
                    </Grid>
                    <Grid item xs={3}>  
                      <BooleanInput fullWidth source="missing" label={translate('users_federation.missing')}  />
                    </Grid>
                    <Grid item xs={3}>  
                      { formData.missing ?   
                        <DateInput fullWidth source="missing_date" label={translate('users_federation.missing_date')}  />
                      : null }
                    </Grid>
                    <Grid item xs={1}>                        
                    </Grid>
                    <Grid item xs={5}>  
                      <BooleanInput fullWidth source="robbed" label={translate('users_federation.robbed')}  />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <RichTextInput fullWidth source="history" label={translate('users_federation.history')} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <RichTextInput fullWidth source="history_equipment" label={translate('users_federation.history_equipment')} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <RichTextInput fullWidth source="history_current" label={translate('users_federation.history_current')} />
                    </Grid>
                  </Grid>
                    
                  <Typography variant="h6" className="grey8 titleUserShow">                        
                    {translate('users_federation.regulations')}
                  </Typography>
                  <Divider fullWidth className="" />
                  <br />
                    
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <BooleanInput fullWidth source="lopd" label={translate('users_federation.lopd')}  />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>                    
                      <TextInput fullWidth multiline source="regulations" label={translate('users_federation.regulations')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />
                    </Grid>
                  </Grid>
                                        
                  <Typography variant="h6" className="grey8 titleUserShow">                        
                    {translate('users_federation.others')}
                  </Typography>
                  <Divider fullWidth className="" />
                  <br />    
                    
                  <ReferenceArrayInput
                    source="census"
                    reference="census"
                    perPage={1000}
                    sort={{ field: 'priority', order: 'ASC' }}
                  >
                    <CheckboxGroupInput 
                      optionText="name" 
                      optionValue="id"
                      label={translate('users_federation.census')}            
                      row={true}
                      className="checkboxGroupInputCompacted"  
                    />
                  </ReferenceArrayInput>    
                    
                  <ReferenceArrayInput
                    source="books"
                    reference="books"
                    perPage={1000}
                    sort={{ field: 'priority', order: 'ASC' }}
                  >
                    <CheckboxGroupInput 
                      optionText="name" 
                      optionValue="id"
                      label={translate('users_federation.books')}            
                      row={true}
                      className="checkboxGroupInputCompacted"  
                    />
                  </ReferenceArrayInput>
                    
                </>
                }
              </FormDataConsumer>
                    
              <ReferenceArrayInput
                source="mailing_lists"
                reference="mailing_lists"
                perPage={1000}
                sort={{ field: 'priority', order: 'ASC' }}
              >
                <CheckboxGroupInput 
                  optionText="name" 
                  optionValue="id"
                  label={translate('users_federation.mailing_lists')}            
                  row={true}
                  className="checkboxGroupInputCompacted"  
                />
              </ReferenceArrayInput> 
            </FormTab>
            : null }
            
          { subscriptionModule(modules.FEDERATION) ?  
            <FormTab
              label={translate('users_federation.tab_more')}
              contentClassName={classes.tab}
              path="more_federation"                     
            >           
                
              <FormDataConsumer>
                {({ formData, ...rest }) => formData.user_type === 1 && <>
                  <br />
                  <AccordionSection className="accordion" label={translate('users_demografy.title')} fullWidth defaultExpanded={true}>                           
                    <UsersDemografyList users_id={id} show={false} />                    
                  </AccordionSection>
                  
                  <AccordionSection className="accordion" label={translate('users.tab_economic_data')} fullWidth defaultExpanded={true}>                           
                    <UsersEconomicDataList users_id={id} />
                  </AccordionSection>

                  <AccordionSection className="accordion" label={translate('users.tab_workers_0')} fullWidth defaultExpanded={true}>                           
                    <UsersWorkersList users_id={id} type={0} />
                  </AccordionSection>

                  <AccordionSection className="accordion" label={translate('users.tab_workers_1')} fullWidth defaultExpanded={true}>                           
                    <UsersWorkersList users_id={id} type={1} />
                  </AccordionSection>

                  <AccordionSection className="accordion" label={translate('users_activities.title')} fullWidth defaultExpanded={true}>                           
                    <UsersActivitiesList users_id={id} />
                  </AccordionSection>

                  <AccordionSection className="accordion" label={translate('users_trainings.title0')} fullWidth defaultExpanded={true}>                           
                    <UsersTrainingsList users_id={id} type={0} />
                  </AccordionSection>

                  <AccordionSection className="accordion" label={translate('users_trainings.title1')} fullWidth defaultExpanded={true}>                           
                    <UsersTrainingsList users_id={id} type={1} />
                  </AccordionSection>

                  <AccordionSection className="accordion" label={translate('users_shows.title')} fullWidth defaultExpanded={true}>                           
                    <UsersShowsList users_id={id} />
                  </AccordionSection>

                  <AccordionSection className="accordion" label={translate('users_sections.title')} fullWidth defaultExpanded={true}>                           
                    <UsersSectionsList users_id={id} />
                  </AccordionSection>
                  
                  <AccordionSection className="accordion" label={translate('users_social_projects.title')} fullWidth defaultExpanded={true}>                           
                    <UsersSocialProjectsList users_id={id} />
                  </AccordionSection>
                        
                  <Typography variant="h6" className="grey8 titleUserShow">                        
                    {translate('users_federation.edited_books_title')}
                  </Typography>
                  <Divider fullWidth className="" />
                  <br />  

                  <AccordionSection className="accordion" label={translate('users_federation.edited_books_books')} fullWidth defaultExpanded={true}>                        
                    <UsersEditedBooksEdit id={id} type={usersEditedBooksType.BOOKS} />
                  </AccordionSection>    

                  <AccordionSection className="accordion" label={translate('users_federation.edited_books_book_entity')} fullWidth defaultExpanded={true}>                        
                    <UsersEditedBooksEdit id={id} type={usersEditedBooksType.BOOK_ENTITY} />
                  </AccordionSection>   

                  <AccordionSection className="accordion" label={translate('users_federation.edited_books_newsletter')} fullWidth defaultExpanded={true}>                        
                    <UsersEditedBooksEdit id={id} type={usersEditedBooksType.NEWSLETTER} />
                  </AccordionSection>   

                  <AccordionSection className="accordion" label={translate('users_federation.edited_books_magazine')} fullWidth defaultExpanded={true}>                        
                    <UsersEditedBooksEdit id={id} type={usersEditedBooksType.MAGAZINE} />
                  </AccordionSection>  
                    
                  <Grid container spacing={2}>
                    <Grid item xs={12}>                    
                      <BooleanInput fullWidth source="memory" label={translate('users_federation.edited_books_memory')}  />
                    </Grid>
                  </Grid>
                        
                  <Typography variant="h6" className="grey8 titleUserShow">                        
                    {translate('users_federation.others')}
                  </Typography>
                  <Divider fullWidth className="" />
                  <br />    

                  <AccordionSection className="accordion" label={translate('users_federation.recognitions')} fullWidth defaultExpanded={true}>                        
                    <UsersRecognitionsEdit id={id} />
                  </AccordionSection>    

                  <AccordionSection className="accordion" label={translate('users_federation.schedules')} fullWidth defaultExpanded={true}>                        
                    <UsersSchedulesEdit id={id} />
                  </AccordionSection> 
                  
                  <AccordionSection className="accordion" label={translate('users_federation.highlighted_dates')} fullWidth defaultExpanded={true}>                        
                    <UsersEditedBooksEdit id={id} type={usersEditedBooksType.HIGHLIGHTED_DATES} />
                  </AccordionSection> 
                  
                  <AccordionSection className="accordion" label={translate('users_federation.notable_individuals')} fullWidth defaultExpanded={true}>                        
                    <UsersEditedBooksEdit id={id} type={usersEditedBooksType.NOTABLE_INDIVIDUALS} />
                  </AccordionSection> 
                                    
                  <AccordionSection className="accordion" label={translate('users_federation.links')} fullWidth defaultExpanded={true}>                        
                    <UsersEditedBooksEdit id={id} type={usersEditedBooksType.LINKS} />
                  </AccordionSection> 
                        
                  <AccordionSection className="accordion" label={translate('users_annuary.title')} fullWidth defaultExpanded={true}>
                    <UsersAnnuaryList users_id={id} />
                  </AccordionSection>  
                        
                </> }
              </FormDataConsumer>
            </FormTab>
            : null }  
            
            <FormTab
            label={translate('users.tab_images')}
            contentClassName={classes.tab}
            path="images" 
          >       
            <DocsList target={targets.USER_MORE_PHOTOS} items_id={id} />
            <br />
          </FormTab>
        
        </TabbedForm>
      }
    </Edit>
    
  );
};

/* ETIQUETES: AL FINAL NO HO POSEM
 * <ReferenceArrayInput
                        source="activities_users"
                        reference="activities_users"
                        perPage={1000}
                        sort={{ field: 'priority', order: 'ASC' }}
                    >
                        <CheckboxGroupInput 
                            optionText="name" 
                            optionValue="id"
                            label={translate('users_federation.activities_users')}            
                            row={true}
                            className="checkboxGroupInputCompacted"  
                        />
                    </ReferenceArrayInput>  
 */




export const UsersCreate = props => {    
  const translate = useTranslate();  
  const dataProvider = useDataProvider();
    
  const [info, setInfo] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
    
  const { groups_id: groups_id_string } = parse(props.location.search);

  const groups_id = groups_id_string ? parseInt(groups_id_string, 10) : '';
        
  useEffect(() => {
    dataProvider.getOne('groups', { id: groups_id })
      .then(( { data } ) => {   
        setInfo(data);
        setLoading(false);
      })
      .catch(error => {                
        setLoading(false);                
      });
            
  }, []);
    
  if (loading) return <MyLoading />;
  if (error) return <Error />;
  if (!info) return null;
    
  let strRedirect =  `/groups-tree/${groups_id}`;
    
  return (
    <>
      <Typography variant="h5" className="groups_title grey8">{translate('users.add_to_group') + ' ' + info.name}</Typography>   
            
      <Create {...props}
      >
        
        <SimpleForm 
          redirect={subscriptionModule(modules.RECERCAT) ? 'show' : 'edit'}
          initialValues={{ associations_id: localStorage.getItem('associations_id') , user_type : 0, groups_id }}>  
         
          {!subscriptionModule(modules.RECERCAT) ?
            <RadioButtonGroupInput source="user_type" choices={choicesTypeUser(translate)} optionText="name" optionValue="id" label={translate('users.user_type')} validate={required()}  />
            : null }

          <FormDataConsumer>
            {({ formData, ...rest }) => formData.user_type === 0 &&
                <>     
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <br/>
                      <TextInput fullWidth source="first_name" label={translate('users.first_name')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />
                    </Grid>
                    <Grid item xs={3}>
                      <br/>
                      <TextInput fullWidth source="last_name_1" label={translate('users.last_name_1')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]}  />            
                    </Grid>
                    <Grid item xs={3}>
                      <br/>
                      <TextInput fullWidth source="last_name_2" label={translate('users.last_name_2')} validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />            
                    </Grid>
                    {(!subscriptionModule(modules.RECERCAT) ? 
                      <Grid item xs={3} className={'last_num_inserted' + groups_id}>
                        <LastNumMemberInserted groups_id={groups_id} />
                        <TextInput fullWidth source="num_member" label={translate('users_groups.num_member')} validate={[validateMaxLength(25,translate('common.validation.maxLength'))]} />            
                      </Grid>
                      : null )}
                  </Grid>    
                </>
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, ...rest }) => formData.user_type === 1 &&
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <span>&nbsp;</span>
                          <TextInput fullWidth source="first_name" label={translate('users.name_company')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]}  />
                        </Grid>
                        <Grid item xs={4} className={'last_num_inserted' + groups_id}>
                          <LastNumMemberInserted groups_id={groups_id}  />
                          <TextInput fullWidth source="num_member" label={translate('users_groups.num_member')} validate={[validateMaxLength(25,translate('common.validation.maxLength'))]} />            
                        </Grid>
                      </Grid> 
                    </>
            }
          </FormDataConsumer>
            
          {subscriptionModule(modules.RECERCAT) ?
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextInput fullWidth source="email" label={translate('users.email')} parse={value => value} validate={[validateMaxLength(100,translate('common.validation.maxLength')),validateEmail(),required()]} />
                </Grid>
              </Grid>  
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ReferenceInput fullWidth source="recercat_centers_id" reference="recercat_centers"  label={translate('recercat_centers.column_center')} link={false} sortable={false} filter={{ associations_id : localStorage.getItem('associations_id') }} perPage={10000} >
                    <SelectInput optionText="name" optionValue="id" resettable validate={[required()]} />  
                  </ReferenceInput>
                </Grid>
              </Grid>  
            </>
            : null }
            
        </SimpleForm>
      </Create></>
  );
};

const UsersFilter = (props) => {
  const translate = useTranslate(); 
  return (
    <>
      <Filter {...props} >
        <TextInput label={translate('common.search')} source="q" alwaysOn validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />  
      </Filter>
    </>
  );
};



const ElementTitle = ({ record }) => {
  const translate = useTranslate(); 
  return (<span>{translate('common.edit')} {record ? `"${record.first_name}"` : ''}</span>);
};



/* *************************************************************************** */
/* SHOW */
export const UsersShow = (props) => {

  //const notify = useNotify();
  const translate = useTranslate(); 
  const refresh = useRefresh();
  const redirect = useRedirect();
    
  const social_nets_reference = props.resource === 'users' ? 'social_nets' : 'social_nets_contacts';
  const users_contacts_reference = props.resource === 'users' ? 'users_contacts' : 'users_contacts_contacts';
  const users_emails_reference = props.resource === 'users' ? 'users_emails' : 'users_emails_contacts';
  const users_contacts_row_click = props.resource === 'users' ? 'users' : 'users-fac-contacts';

  return (
    
    subscriptionModule(modules.RECERCAT) ?
      <Show actions={<UsersShowActions resource_name={props.resource} />} {...props} >
        <SimpleShowLayout>  
            
          <Typography variant="h6" className="grey8 titleUserShow">                        
            {translate('users.tab_main_0')}
          </Typography>
          <Divider className="" />
          <Grid container spacing={2}> 
            <Grid item xs={4} >
              <FunctionField render={record => <TextFieldShow source={record.first_name} label={translate('users.first_name')} /> } /> 
            </Grid>  
            <Grid item xs={4}>
              <FunctionField render={record => <TextFieldShow source={record.last_name_1} label={translate('users.last_name_1')} /> } /> 
            </Grid>
            <Grid item xs={4}>
              <FunctionField render={record => <TextFieldShow source={record.last_name_2} label={translate('users.last_name_2')} /> } /> 
            </Grid>
          </Grid>  
          <Grid container spacing={2}> 
            <Grid item xs={12} >
              <FunctionField render={record => <TextFieldShow source={record.email} label={translate('users.email')} /> } /> 
            </Grid>  
            <Grid item xs={12}>
              <span className="custom-field-title grey6">{translate('recercat_centers.column_center')}</span><br/>
              <ReferenceField source="recercat_centers_id" reference="recercat_centers"  label={translate('recercat_centers.column_center')} link={false} sortable={false} >
                <TextField source="name" />
              </ReferenceField>
            </Grid>
          </Grid>
          <br/>
          <Typography variant="h6" className="grey8 titleUserShow">
            {translate('users.groups')}
          </Typography>
          <Divider className="" />
          <UsersGroupsShow />                        
          <br />
        </SimpleShowLayout> 
      </Show>
      :
      <Show actions={<UsersShowActions resource_name={props.resource} />} {...props} >
        <SimpleShowLayout>            
          <Grid container spacing={2} className="users_show">
            <Grid item xs={2} className="users_show_photo_container">                    
                    
              <br />
              <FunctionField render={record =>                     
                <BoxPhotoUser target={targets.USER_PHOTO} items_id={record.id} edit={false} default_img={record.user_type} resource_name={props.resource} className="docs_photo_user" />                     
              } />
                    
              <Grid container spacing={2}>
                <Grid item xs={12} >                    
                  <SocialNetsListShow target={targets.USER} items_id={props.id} reference={social_nets_reference} />
                </Grid>
              </Grid>

            </Grid>
            <Grid item xs={10} className="users_show_photo_container">       
                
              <FunctionField render={record => record.alerts ? <div className="alert alert-danger">
                {record.alerts}
              </div> : null
              } />
                    
              <Typography variant="h5" className="grey8">
                <FunctionField render={record => <UserFullNameTxt record={record}/> } />
              </Typography>

              <Grid container spacing={5}>
                <Grid item xs={6} className="">     
                    
                  <FunctionField render={record => record.user_type === 0 ? 
                    <Typography variant="h6" className="grey8 titleUserShow">                        
                      {translate('users.tab_main_0')}
                    </Typography>
                    : 
                    <Typography variant="h6" className="grey8 titleUserShow">                        
                      {translate('users.tab_main_1')}
                    </Typography>
                  } />
                  <Divider className="" />
                        
                  <FunctionField render={record => record.user_type === 0 ? (
                    <>
                      <Grid container spacing={2}> 
                        <Grid item xs={4} >
                          <TextFieldShow source={record.first_name} label={translate('users.first_name')} /> 
                        </Grid>  
                        <Grid item xs={4}>
                          <TextFieldShow source={record.last_name_1} label={translate('users.last_name_1')} /> 
                        </Grid>
                        <Grid item xs={4}>
                          <TextFieldShow source={record.last_name_2} label={translate('users.last_name_2')} />
                        </Grid>
                      </Grid>  
                      <Grid container spacing={2}> 
                        <Grid item xs={4} >
                          <TextFieldShow source={record.email} label={translate('users.email')} /> 
                        </Grid> 
                        <Grid item xs={4} >
                          <TextFieldShow source={record.email_secondary} label={translate('users.email_secondary')} /> 
                        </Grid> 
                        <Grid item xs={4}>
                          <TextFieldShow source={record.nif} label={translate('users.nif')} /> 
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                    <Grid container spacing={2}>  
                      <Grid item xs={6} >
                        <TextFieldShow source={record.first_name} label={translate('users.name_company')} />
                      </Grid>  
                      <Grid item xs={6}>
                        <TextFieldShow source={record.nif} label={translate('users.nif')} />
                      </Grid>                      
                    </Grid>  
                    <Grid container spacing={2}> 
                      <Grid item xs={6} >                                    
                        <TextFieldShow source={record.email} label={translate('users.email')} />
                      </Grid>  
                      <Grid item xs={3} >                                    
                        <TextFieldShow source={record.email_secondary} label={translate('users.email_secondary')} />
                      </Grid> 
                    </Grid> 
                    </>
                  ) } />

                  <FunctionField render={record => !(subscriptionModule(modules.FEDERATION) && record.user_type === 1 && props.resource === 'users') ? 
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={4} >
                          <TextFieldShow source={record.address} label={translate('users.address')} />
                        </Grid>
                        <Grid item xs={4} >
                          <TextFieldShow source={record.neighborhood} label={translate('users.neighborhood')} />
                        </Grid>
                        <Grid item xs={4} >
                          <TextFieldShow source={record.zip} label={translate('users.zip')} />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={4} >
                          <TextFieldShow source={record.city} label={translate('users.city')} />
                        </Grid>
                        <Grid item xs={4} >
                          <TextFieldShow source={record.region} label={translate('users.region')} />
                        </Grid>
                        <Grid item xs={4} >
                          <TextFieldShow source={record.country} label={translate('users.country')} />
                        </Grid>
                      </Grid>
                      
                      {record.user_type === 1 ? 
                      <Grid container spacing={2}>
                        <Grid item xs={4} >
                          <TextFieldShow source={record.lat} label={translate('users.lat')} />
                        </Grid>
                        <Grid item xs={4} >
                          <TextFieldShow source={record.lng} label={translate('users.lng')} />
                        </Grid>
                        <Grid item xs={4} >
                        </Grid>
                      </Grid>
                      : null }
                        
                    </> : null } />

                  <FunctionField render={record => record.user_type === 0 ? ( 
                    <Grid container spacing={2}>
                      <Grid item xs={4} >
                        <TextFieldShow source={record.phone} label={translate('users.phone')} />
                      </Grid>
                      <Grid item xs={4} >
                        <TextFieldShow source={record.mobile} label={translate('users.mobile')} />
                      </Grid>                        
                      <Grid item xs={4} >
                        <TextFieldShow source={record.phone_emergency} label={translate('users.phone_emergency')} />
                      </Grid>                        
                    </Grid>
                  ) : 
                    <Grid container spacing={2}>
                      <Grid item xs={4} >
                        <TextFieldShow source={record.phone} label={translate('users.phone')} />
                      </Grid>
                      <Grid item xs={4} >
                        <TextFieldShow source={record.mobile} label={translate('users.mobile')} />
                      </Grid>   
                      <Grid item xs={4} >
                        <TextFieldShow source={<RenderDate source={record.birthday} />} label={translate('users.foundation_date')} />
                      </Grid>
                    </Grid>
                  } />

                  <FunctionField render={record => record.user_type === 0 ? (
                    <Grid container spacing={2}>
                      <Grid item xs={4} >
                        <TextFieldShow source={<RenderDate source={record.birthday} />} label={translate('users.birthday')} />
                      </Grid>
                      <Grid item xs={4} >
                        <TextFieldShow source={<RenderAge source={record.birthday} />} label={translate('users.age')} />
                      </Grid>    
                      <Grid item xs={4} >
                        <TextFieldShow source={<RenderGender source={record.gender} />} label={translate('users.gender')} />
                      </Grid>
                    </Grid> 
                  ) : '' } />
                        
                  <FunctionField render={record => record.user_type === 0 ? ( 
                    <Grid container spacing={2}>                        
                      <Grid item xs={4} >
                        <TextFieldShow source={record.job} label={translate('users.job')} />
                      </Grid>
                      <Grid item xs={4} >
                        <TextFieldShow source={record.web} label={translate('users.web')} link={true} />
                      </Grid>
                      { props.resource=='users' ? 
                        <Grid item xs={4} >
                          <TextFieldShow source={<RenderBoolean source={record.newsletter} />} label={translate('users.newsletter')} />                       
                        </Grid> : null }
                    </Grid>
                  ) : (
                    <Grid container spacing={2}>   
                      <Grid item xs={4} >
                        <TextFieldShow source={record.web} label={translate('users.web')} link={true} />
                      </Grid>
                      { props.resource=='users' ? 
                        <Grid item xs={4} >
                          <TextFieldShow source={<RenderBoolean source={record.newsletter} />} label={translate('users.newsletter')} />                       
                        </Grid> : null }                            
                      <Grid item xs={4} >
                        <TextFieldShow source={<RenderAge source={record.birthday} />} label={translate('users.foundation_age')} />
                      </Grid> 
                    </Grid>
                  ) } />
                
                    <Typography variant="h6" className="grey8 titleUserShow">
                      {translate('users.email_secondary_list')}
                    </Typography>
                    <Divider className="" />
                    <UsersEmailsShow reference={users_emails_reference} />
                    <br />
                        
                </Grid>

                <Grid item xs={6} className="">                                            

                  { props.resource=='users' ? 
                    <FunctionField render={record => record.user_type === 0 ? ( <>
                      <Typography variant="h6" className="grey8 titleUserShow">
                        {translate('users.tab_family')}
                      </Typography>
                      <Divider className="" />
                            
                      <UsersFamiliesShow />                            

                      <br />

                    < /> ) : '' } />
                    : null }
                                                
                  <Typography variant="h6" className="grey8 titleUserShow">
                    {translate('users.comment')}
                  </Typography>
                  <Divider className="" />        
                        
                  <FunctionField render={record => <TextFieldShow source={record.comment} label="" /> } />
                    
                </Grid>
              </Grid>

              <FunctionField render={record => subscriptionModule(modules.FEDERATION) && record.user_type === 1 && props.resource === 'users' ? <>
                <br />
                <Typography variant="h6" className="grey8 titleUserShow">
                  {translate('users_federation.addresses')}
                </Typography>
                <Divider className="" />
                <UsersAddressesShow />  
                <br />
              < />
                : null } />
                    
              { props.resource=='users' ? 
                <>
                  <Typography variant="h6" className="grey8 titleUserShow">
                    {translate('users.groups')}
                  </Typography>
                  <Divider className="" />
                  <UsersGroupsShow />                        
                  <br />
                </> : null }
                
              { subscriptionModule(modules.CUSTOM_FIELDS) ?
              <CustomFieldsShow associations_id={localStorage.getItem('associations_id')} target={targets.USER} title={true} />
              : null }
              
              { props.resource=='users' ? 
                <FunctionField render={record => record.user_type === 0 ? ( <>
                  <Typography variant="h6" className="grey8 titleUserShow">
                    { subscriptionModule(modules.FEDERATION) ? translate('users.tab_responsabilities_fac') : translate('users.tab_responsabilities')}
                  </Typography>
                  <Divider className="" />

                  <UsersGroupsResponsabilitiesShow />
                        
                  <br />
                < /> ) : '' } />
                : null }

              <FunctionField render={record => record.user_type === 1 && subscriptionModule(modules.FEDERATION) && props.resource=='users' ? ( <>
                <Typography variant="h6" className="grey8 titleUserShow">
                  {translate('users_federation.tab_managers_1')}
                </Typography>
                <Divider className="" />
                <UsersContactsShow reference={users_contacts_reference} rowClick={users_contacts_row_click} rel={false} responsabilities={true} only_valid_contacts={1} />
                <br />
              < /> ) : '' } />
                    
              <FunctionField render={record => record.user_type === 1 && subscriptionModule(modules.FEDERATION) && props.resource=='users' ? ( <>
                <Typography variant="h6" className="grey8 titleUserShow">
                  {translate('users_federation.tab_managers_2')}
                </Typography>
                <Divider className="" />
                <UsersContactsShow reference={users_contacts_reference} rowClick={users_contacts_row_click} rel={false} responsabilities={true} only_valid_contacts={2} />
                <br />
              < /> ) : '' } />
                    
              <FunctionField render={record => record.user_type === 1 ? ( <>
                <Typography variant="h6" className="grey8 titleUserShow">
                  {translate('users.tab_contacts')}
                </Typography>
                <Divider className="" />
                <UsersContactsShow reference={users_contacts_reference} rowClick={users_contacts_row_click} rel={false} only_valid_contacts={0} />
                <br />
              < /> ) : '' } />
                    
              <FunctionField render={record => record.user_type === 0 && subscriptionModule(modules.FEDERATION) && props.resource=='users' ? ( <>
                <Typography variant="h6" className="grey8 titleUserShow">
                  {translate('users_federation.tab_managers_other')}
                </Typography>
                <Divider className="" />
                <UsersContactsShow reference={users_contacts_reference} rowClick={users_contacts_row_click} rel={true} responsabilities={true} only_valid_contacts={0} />
                <br />
              < /> ) : '' } />
                    
              <FunctionField render={record => record.user_type === 0 ? ( <>
                <Typography variant="h6" className="grey8 titleUserShow">
                  {translate('users.tab_contacts_rel')}
                </Typography>
                <Divider className="" />
                <UsersContactsShow reference={users_contacts_reference} rowClick={users_contacts_row_click} rel={true} only_valid_contacts={0} />
                <br />
              < /> ) : '' } />

              { props.resource=='users' ? 
                <>
                  <Typography variant="h6" className="grey8 titleUserShow">
                    {translate('users.bank_data')}
                  </Typography>
                  <Divider className="" />
                  <FunctionField render={record => (<RenderBankData source={record} />)} />                     
                  <br />
                </>
                : null }

              { props.resource=='users' && subscriptionModule(modules.REMITTANCES) ? 
                <>
                  <Typography variant="h6" className="grey8 titleUserShow">
                    {translate('users.tab_quotes')}
                  </Typography>
                  <Divider className="" />
                  <UsersGroupsQuotesShowList /> 
                  <br />
                </>
                : null }

              { subscriptionModule(modules.REMITTANCES) && props.resource=='users' ?
                <>
                  <Typography variant="h6" className="grey8 titleUserShow">
                    {translate('remittances.last_receips')}
                  </Typography>
                  <Divider className="" />

                  <ResourceContextProvider value="remittances_users" >
                    <List 
                      syncWithLocation 
                      basePath="/remittances_users" 
                      filter={{ users_id : props.id }}  
                      title=""
                      bulkActionButtons={false}                                
                      perPage={1000}
                      pagination={false}
                      actions={false}
                      empty={<></>}
                      className="users_remittances_list"
                    >                            
                      <Datagrid
                        expand={<RemittancesUsersItemsDetails edit={false} />} 
                        className="header_grey6"
                      >   
                        <DateField source="date_creation" label={translate('remittances.date_creation')} sortable={false} />
                        <TextField source="name" label={translate('remittances.name')} sortable={false}  />
                        <NumberField source="id" label={translate('remittances.ref')} sortable={false}  />
                        <NumberField source="quantity" label={translate('remittances.quantity')} sortable={false} locales="es-ES" options={{ style: 'currency', currency: 'EUR' }} />
                        <FunctionField label={translate('remittances.status')} render={RenderStatus} sortable={false}  />                                
                      </Datagrid>

                    </List> 
                  </ResourceContextProvider>                    
                  <br />
                </>
                : null }

                { subscriptionModule(modules.REAL_STATE) ?      
                <>
                  <Typography variant="h6" className="grey8 titleUserShow">
                    {translate('real_state.activities_in_real_state')}
                  </Typography>
                  <Divider className="" />
                  <RealStateActivitiesList users_id={props.id} show={true} />
                  <br />              
                </>
                : null }
                
                { subscriptionModule(modules.AGREEMENTS) ?   
                <>
                  <Typography variant="h6" className="grey8 titleUserShow">
                    {translate('real_state.agreements')}
                  </Typography>
                  <Divider className="" />
                  <AgreementsList users_id={props.id} show={true} />
                  <br />  
                </>
                : null }
                
              <Typography variant="h6" className="grey8 titleUserShow">
                {translate('users.tab_docs')}
              </Typography>
              <Divider className="" />
              <DocsListShow target={targets.USER} items_id={props.id} resource_name={props.resource} />
              <br />
              
              <Typography variant="h6" className="grey8 titleUserShow">
                {translate('users.tab_images')}
              </Typography>
              <Divider className="" />
              <DocsListShow target={targets.USER_MORE_PHOTOS} items_id={props.id} resource_name={props.resource} />
              <br />
                                        
              <FunctionField render={record => record.user_type === 1 ? (<>
                    
                { subscriptionModule(modules.FEDERATION) && props.resource=='users' ? 
                  <>  
                    <br />
                    <Typography variant="h6" className="grey8 titleUserShow">
                      {translate('users_federation.tab')}
                    </Typography>
                    <Divider className="" />
                            
                    <Grid container spacing={2}>
                      <Grid item xs={3}>  
                        <FunctionField render={record => <TextFieldShow source={record.name} label={translate('users_federation.name')} /> } />
                      </Grid>
                      <Grid item xs={3}>  
                        <FunctionField render={record => <TextFieldShow source={record.acronym} label={translate('users_federation.acronym')} /> } />
                      </Grid>
                      <Grid item xs={3}>                    
                        <FunctionField render={record => <TextFieldShow source={record.reg_name} label={translate('users_federation.reg_name')} /> } />
                      </Grid>
                      <Grid item xs={3}>                    
                        <FunctionField render={record => <TextFieldShow source={record.reg_num} label={translate('users_federation.reg_num')} /> } />
                      </Grid>                        
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={3}>  
                        <FunctionField render={record => <TextFieldShow source={<RenderBoolean source={record.bcin} />} label={translate('users_federation.bcin')} /> } />                        
                      </Grid>
                      <Grid item xs={3}>                    
                        <FunctionField render={record => <TextFieldShow source={<RenderBoolean source={record.bcil} />} label={translate('users_federation.bcil')} /> } />                        
                      </Grid>
                      <Grid item xs={6}>  
                        <FunctionField render={record => <TextFieldShow source={record.tickets} label={translate('users_federation.tickets')} /> } />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={2}>  
                        <FunctionField render={record => <TextFieldShow source={<RenderBoolean source={record.xtac} />} label={translate('users_federation.xtac')} /> } />                        
                      </Grid>
                      <Grid item xs={2}>  
                        <FunctionField render={record => <TextFieldShow source={<RenderBoolean source={record.culture_interest} />} label={translate('users_federation.culture_interest')} /> } />                        
                      </Grid>
                      <Grid item xs={2}>  
                        <FunctionField render={record => <TextFieldShow source={<RenderBoolean source={record.public_utility} />} label={translate('users_federation.public_utility')} /> } />                        
                      </Grid>
                      <Grid item xs={2}>  
                        <FunctionField render={record => <TextFieldShow source={<RenderBoolean source={record.soft_cat} />} label={translate('users_federation.soft_cat')} /> } />                        
                      </Grid>
                      <Grid item xs={2}>  
                        <FunctionField render={record => <TextFieldShow source={<RenderBoolean source={record.rcat} />} label={translate('users_federation.rcat')} /> } />                        
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={3}>  
                        <FunctionField render={record => <TextFieldShow source={<RenderDate source={record.last_managers_change} />} label={translate('users_federation.last_managers_change')} /> } />
                      </Grid>
                      <Grid item xs={3}>  
                        <FunctionField render={record => <TextFieldShow source={<RenderDate source={record.next_managers_change} />} label={translate('users_federation.next_managers_change')} /> } />
                      </Grid>
                      <Grid item xs={6}>  
                        <FunctionField render={record => <TextFieldShow source={<SelectField source="delegation" choices={choicesDelegations} /> } label={translate('users_federation.delegation')} /> } />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>                        
                      <Grid item xs={3}>  
                        <FunctionField render={record => <TextFieldShow source={<RenderBoolean source={record.host} />} label={translate('users_federation.host')} /> } />                        
                      </Grid>                                 
                      <Grid item xs={9}>  
                        <FunctionField render={record => <TextFieldShow source={record.host_entities} label={translate('users_federation.host_entities')} /> } />                      
                      </Grid>
                    </Grid>
                    
                    <Grid container spacing={2}>                        
                      <Grid item xs={3}>  
                        <FunctionField render={record => <TextFieldShow source={<RenderBoolean source={record.more_federations} />} label={translate('users_federation.more_federations')} /> } />                        
                      </Grid>                                 
                      <Grid item xs={9}>  
                        <FunctionField render={record => <TextFieldShow source={record.federations} label={translate('users_federation.federations')} /> } />                      
                      </Grid>
                    </Grid>
        
                    <Grid container spacing={2}>
                      <Grid item xs={3}>                    
                        <FunctionField render={record => <TextFieldShow source={<RenderBoolean source={record.members_cards} />} label={translate('users_federation.members_cards')} /> } />                        
                      </Grid>
                      <Grid item xs={3}>                    
                        <FunctionField render={record => <TextFieldShow source={<RenderBoolean source={record.logo_federation} />} label={translate('users_federation.logo_federation')} /> } />                        
                      </Grid>
                      <Grid item xs={3}>                    
                        <FunctionField render={record => <TextFieldShow source={<RenderBoolean source={record.internal_regulation} />} label={translate('users_federation.internal_regulation')} /> } />                        
                      </Grid>
                    </Grid>
                    
                    { subscriptionModule(modules.CUSTOM_FIELDS) ?
                        <CustomFieldsShow associations_id={localStorage.getItem('associations_id')} target={targets.USER_FEDERATION} title={false} prefix="federation_"/>
                    : null }
                            
                    <Typography variant="h6" className="grey8 titleUserShow">                        
                      {translate('users_federation.building')}
                    </Typography>
                    <Divider fullWidth className="" />

                    <Grid container spacing={2}>
                      <Grid item xs={3}>                    
                        <FunctionField render={record => <TextFieldShow source={<RenderBoolean source={record.federation_plate} />} label={translate('users_federation.federation_plate')} /> } />                        
                      </Grid>
                      <Grid item xs={3}>                    
                        <FunctionField render={record => <TextFieldShow source={<RenderBoolean source={record.license} />} label={translate('users_federation.license')} /> } />                        
                      </Grid>                                
                      <Grid item xs={3}>                    
                        <FunctionField render={record => <TextFieldShow source={record.total_m2} label={translate('users_federation.total_m2')} /> } />   
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={3}>                    
                        <FunctionField render={record => <TextFieldShow source={<RenderBoolean source={record.ownership} />} label={translate('users_federation.ownership')} /> } />                        
                      </Grid>
                      <Grid item xs={3}>   
                        <FunctionField render={record => <TextFieldShow source={<SelectField source="property" choices={choicesProperty} /> } label={translate('users_federation.property')} /> } />
                      </Grid>
                      <Grid item xs={3}>   
                        <FunctionField render={record => <TextFieldShow source={<SelectField source="rent" choices={choicesCession} /> } label={translate('users_federation.rent')} /> } />
                      </Grid>
                      <Grid item xs={3}>
                        <FunctionField render={record => <TextFieldShow source={<RenderBoolean source={record.expropied} />} label={translate('users_federation.expropied')} /> } />                        
                      </Grid>
                    </Grid>
                    
                    <Typography variant="h6" className="grey8 titleUserShow">                        
                      {translate('users_federation.history_title')}
                    </Typography>
                    <Divider fullWidth className="" />
                    
                    <Grid container spacing={2}>
                      <Grid item xs={3}>                    
                        <FunctionField render={record => <TextFieldShow source={<RenderBoolean source={record.actived} />} label={translate('users_federation.actived')} /> } />                        
                      </Grid>
                      <Grid item xs={3}>                    
                        <FunctionField render={record => <TextFieldShow source={<RenderBoolean source={record.missing} />} label={translate('users_federation.missing')} /> } />                        
                      </Grid>
                      <Grid item xs={3}>                    
                        <FunctionField render={record => <TextFieldShow source={<RenderDate source={record.missing_date} />} label={translate('users_federation.missing_date')} /> } />  
                      </Grid>
                      <Grid item xs={3}>                    
                        <FunctionField render={record => <TextFieldShow source={<RenderBoolean source={record.robbed} />} label={translate('users_federation.robbed')} /> } />  
                      </Grid>
                      <Grid item xs={12}>                    
                        <FunctionField render={record => <TextFieldShow source={<div dangerouslySetInnerHTML={{ __html: record.history }} />} label={translate('users_federation.history')} /> } />  
                      </Grid>
                      <Grid item xs={12}>                    
                        <FunctionField render={record => <TextFieldShow source={<div dangerouslySetInnerHTML={{ __html: record.history_equipment }} />} label={translate('users_federation.history_equipment')} /> } />  
                      </Grid>
                      <Grid item xs={12}>                    
                        <FunctionField render={record => <TextFieldShow source={<div dangerouslySetInnerHTML={{ __html: record.history_current }} />} label={translate('users_federation.history_current')} /> } />  
                      </Grid>
                    </Grid>
                         
                    <br />
                    <Typography variant="h6" className="grey8 titleUserShow">
                      {translate('users_demografy.title')}
                    </Typography>
                    <Divider className="" /> 
                    <br />
                    <UsersDemografyList users_id={props.id} show={true} />
                    
                    <br />
                    <Typography variant="h6" className="grey8 titleUserShow">
                      {translate('users.tab_economic_data')}
                    </Typography>
                    <Divider className="" />           
                    <UsersEconomicDataShow users_id={props.id} />
                            
                    <br />
                    <Typography variant="h6" className="grey8 titleUserShow">
                      {translate('users.tab_workers_0')}
                    </Typography>
                    <Divider className="" />           
                    <UsersWorkersShow users_id={props.id} type={0} />
                            
                    <br />
                    <Typography variant="h6" className="grey8 titleUserShow">
                      {translate('users.tab_workers_1')}
                    </Typography>
                    <Divider className="" />           
                    <UsersWorkersShow users_id={props.id} type={1} />
                            
                    <br />
                    <Typography variant="h6" className="grey8 titleUserShow">
                      {translate('users_activities.title')}
                    </Typography>
                    <Divider className="" />           
                    <UsersActivitiesShow users_id={props.id} />
                    <br />
                            
                    <br />
                    <Typography variant="h6" className="grey8 titleUserShow">
                      {translate('users_trainings.title0')}
                    </Typography>
                    <Divider className="" />           
                    <UsersTrainingsShow users_id={props.id} type={0} />
                    <br />
                            
                    <br />
                    <Typography variant="h6" className="grey8 titleUserShow">
                      {translate('users_trainings.title1')}
                    </Typography>
                    <Divider className="" />           
                    <UsersTrainingsShow users_id={props.id} type={1} />
                    <br />
                            
                    <br />
                    <Typography variant="h6" className="grey8 titleUserShow">
                      {translate('users_shows.title')}
                    </Typography>
                    <Divider className="" />           
                    <UsersShowsShow users_id={props.id} />
                    <br />
                            
                    <br />
                    <Typography variant="h6" className="grey8 titleUserShow">
                      {translate('users_sections.title')}
                    </Typography>
                    <Divider className="" />           
                    <UsersSectionsShow users_id={props.id} />
                    <br />
                    
                    <br />
                    <Typography variant="h6" className="grey8 titleUserShow">
                      {translate('users_social_projects.title')}
                    </Typography>
                    <Divider className="" />           
                    <UsersSocialProjectsShow users_id={props.id} />
                    <br />
                    
                    <Typography variant="h6" className="grey8 titleUserShow">                        
                      {translate('users_federation.classrooms')}
                    </Typography>
                    <Divider fullWidth className="" />
                            
                    <UsersClassroomsShow users_id={props.id} /> 
                            
                    <Typography variant="h6" className="grey8 titleUserShow">                        
                      {translate('users_federation.regulations')}
                    </Typography>
                    <Divider fullWidth className="" />

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FunctionField render={record => <TextFieldShow source={<RenderBoolean source={record.lopd} />} label={translate('users_federation.lopd')} /> } />                        
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>                                                        
                        <FunctionField render={record => <TextFieldShow source={record.regulations} label={translate('users_federation.regulations')} /> } />                        
                      </Grid>
                    </Grid>
                            
                    <br />
                    <Typography variant="h6" className="grey8 titleUserShow">
                      {translate('users_federation.edited_books_title')}
                    </Typography>
                    <Divider className="" />
                    <br /><span className="grey6">{translate('users_federation.edited_books_books')}</span>
                    <UsersEditedBooksShow type={usersEditedBooksType.BOOKS} />   
                    <br /><span className="grey6">{translate('users_federation.edited_books_book_entity')}</span>
                    <UsersEditedBooksShow type={usersEditedBooksType.BOOK_ENTITY} />   
                    <br /><span className="grey6">{translate('users_federation.edited_books_newsletter')}</span>
                    <UsersEditedBooksShow type={usersEditedBooksType.NEWSLETTER} />   
                    <br /><span className="grey6">{translate('users_federation.edited_books_magazine')}</span>
                    <UsersEditedBooksShow type={usersEditedBooksType.MAGAZINE} />  
                            
                    <br />
                    <Grid container spacing={2}>
                      <Grid item xs={3}> 
                        <FunctionField render={record => <TextFieldShow source={<RenderBoolean source={record.memory} />} label={translate('users_federation.edited_books_memory')} /> } /> 
                      </Grid>
                    </Grid>
                            
                    <br />
                    <Typography variant="h6" className="grey8 titleUserShow">
                      {translate('users_federation.recognitions')}
                    </Typography>
                    <Divider className="" />
                    <UsersRecognitionsShow />  
                            
                    <br />
                    <Typography variant="h6" className="grey8 titleUserShow">
                      {translate('users_federation.schedules')}
                    </Typography>
                    <Divider className="" />
                    <UsersSchedulesShow />  
                    
                    <br />
                    <Typography variant="h6" className="grey8 titleUserShow">
                      {translate('users_federation.highlighted_dates')}
                    </Typography>
                    <Divider className="" />
                    <UsersEditedBooksShow type={usersEditedBooksType.HIGHLIGHTED_DATES} />   
                    
                    <br />
                    <Typography variant="h6" className="grey8 titleUserShow">
                      {translate('users_federation.notable_individuals')}
                    </Typography>
                    <Divider className="" />
                    <UsersEditedBooksShow type={usersEditedBooksType.NOTABLE_INDIVIDUALS} />   
                    
                    <br />
                    <Typography variant="h6" className="grey8 titleUserShow">
                      {translate('users_federation.links')}
                    </Typography>
                    <Divider className="" />
                    <UsersEditedBooksShow type={usersEditedBooksType.LINKS} /> 
                                                
                    <br />
                    <Typography variant="h6" className="grey8 titleUserShow">
                      {translate('users_federation.census')}
                    </Typography>
                    <Divider className="divider-mb" />
                    <ReferenceArrayField label="" reference="census" source="census" sort={{ field: 'priority', order: 'ASC' }}>
                      <SingleFieldList linkType={false}>
                        <ChipField source="name" />
                      </SingleFieldList>
                    </ReferenceArrayField>  
                                                        
                    <br />
                    <Typography variant="h6" className="grey8 titleUserShow">
                      {translate('users_federation.books')}
                    </Typography>
                    <Divider className="divider-mb" />
                    <ReferenceArrayField label="" reference="books" source="books" sort={{ field: 'priority', order: 'ASC' }}>
                      <SingleFieldList linkType={false}>
                        <ChipField source="name" />
                      </SingleFieldList>
                    </ReferenceArrayField>     
                            
                  < />
                  : null }
              </>) : '' } />
                    
              <FunctionField render={record => true ? (<>
                    
                { subscriptionModule(modules.FEDERATION) && props.resource=='users' ? 
                  <>  
                    <br />
                    <Typography variant="h6" className="grey8 titleUserShow">
                      {translate('users_federation.mailing_lists')}
                    </Typography>
                    <Divider className="divider-mb" />
                    <ReferenceArrayField label="" reference="mailing_lists" source="mailing_lists" sort={{ field: 'priority', order: 'ASC' }} >
                      <SingleFieldList linkType={false}>
                        <ChipField source="name" />
                      </SingleFieldList>
                    </ReferenceArrayField>   
                  </>
                  : null }                    
                    
              </>) : '' } />
                    
              <FunctionField render={record => record.user_type === 1 ? (<>                    
                { subscriptionModule(modules.FEDERATION) && props.resource=='users' ? 
                  <>
                    <br />
                    <Typography variant="h6" className="grey8 titleUserShow">
                      {translate('users_annuary.title')}
                    </Typography>
                    <Divider className="" />
                    <UsersAnnuaryShow />                        
                    <br />
                  </>
                  : null }   
              </>) : '' } />
                                  
                <Typography variant="h6" className="grey8 titleUserShow">
                  {translate('settings.emails_logs')}
                </Typography>
                <Divider className="" />

                <EmailsHistoryList users_id={props.id} show={true}/>
                         
                <br />
                                   
            </Grid>
          </Grid>

            
            

        </SimpleShowLayout>
      </Show>
  );
};

/*
<br />
<Typography variant="h6" className="grey8 titleUserShow">
{translate('users_federation.activities_users')}
</Typography>
<Divider className="" />
<br />
<ReferenceArrayField label="" reference="activities_users" source="activities_users">
    <SingleFieldList linkType={false}>
        <ChipField source="name" />
    </SingleFieldList>
</ReferenceArrayField>   
*/

const RenderBankData = function (props) {
    
  const translate = useTranslate();  

  if (props.source.payer && props.source.payer > 0)
  {
    return (
      <>
        <br />
        <span className="grey6">{translate('users.bank_payer')}&nbsp;&nbsp;&nbsp;</span> 
        <ReferenceField label="" source="payer" reference="users" link="show" > 
          <ChipFieldUserName className="users_show_bank_payer" />                               
        </ReferenceField>
      < />
    );        
  }
  else if(props.source.payment_cash && props.source.payment_cash==true)
  {
    return (<TextFieldShow source={<RenderBoolean source={props.source.payment_cash} />} label={translate('users.payment_cash')} />);          
  }
  else
  {
    return(
      <Grid container spacing={2}>
        <Grid item xs={6} >
          <TextFieldShow source={props.source.iban} label={translate('users.bank_iban')} /> 
        </Grid>
        <Grid item xs={6} >
          <TextFieldShow source={props.source.swift} label={translate('users.bank_swift')} /> 
        </Grid>
      </Grid>
    );
  }

};


const UsersShowActions = ({ basePath, data, resource_name, ...props }) => {
  const translate = useTranslate();  
    
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
    
  const [info, setInfo] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const notify = useNotify();
        
  return (
    <>
      { resource_name === 'users' ? 
        <TopToolbar> 
          { data && data.status && data.status === 1 ? 
            <ValidateUserButton users_id={data.id} /> : null }     
          <EditButton basePath={basePath} record={data} />        
        </TopToolbar>
        : null }
    </>
    
  );
};

const Aside = () => (
  <div style={{ width: 300, margin: '1em' }}>
    <Typography variant="h6">Post details</Typography>
    <Typography variant="body2">
            Posts will only be published once an editor approves them
    </Typography>
  </div>
);


export const ValidateUserButton = ({ users_id, ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('users/validate');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  
  const dataProvider = useDataProvider();
    
    
  const today = new Date().toISOString().slice(0, 10);


  const handleClick = () => {
        
    dataProvider.getOne('users/pre-validate', { id: users_id })
      .then(( { data } ) => {   
        setShowDialog(true);
      })
      .catch(error => {   
        notify(error.message, 'error');    
      });
        
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify( error.message , 'error');
        }
      }
    );
  };
    
  return (
    <>            
      <Button onClick={handleClick} label={translate('users.validate')} className="">
        <DoneIcon />
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('users.validate_title')}
      >
        <DialogTitle>{translate('users.validate_title')}</DialogTitle>

        <FormWithRedirect
          save={handleSubmit}
          initialValues={{ users_id : users_id, groups_id: localStorage.getItem('members_group_id'), date_join: today, validate_send_email: true  }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>     
                    <br />
                    <DateInput fullWidth source="date_join" label={translate('users_groups.date_join')} />
                  </Grid> 
                  <Grid item xs={6}>     
                    <NumMemberInput />
                  </Grid> 
                  <Grid item xs={12}>     
                    <TextInput fullWidth multiline source="comment" label={translate('common.comment')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />   
                  </Grid> 
                  <Grid item xs={12}>     
                    <BooleanInput fullWidth source="validate_send_email" label={translate('users.validate_send_email')} />
                  </Grid> 
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};