
import {
  useTranslate ,
  Button ,
  useNotify
} from 'react-admin';

import SaveIcon from '@material-ui/icons/GetApp';

import { apiUrl, apiOrigin, apiDefaultLanguage,  } from './../common/constants';

export function DownloadXLSButton ({ ...props}) {
    
  const translate = useTranslate(); 
  const notify = useNotify();

  const handleAction = async () => {

    let headers = new Headers({ Accept: 'application/json' });
    headers.set('Access-Control-Allow-Origin', apiOrigin );
    headers.set('Authorization', localStorage.getItem('auth'));
    headers.set('Accept-Language', apiDefaultLanguage);
    headers.set('Role', localStorage.getItem('active_permission'));
    
    const { field, order } = props.currentSort;   
    
    const mainLoading = document.getElementById("mainLoading");
    mainLoading.style.display = "flex";
        
    try {

        var queryStringFilter = '';
        if (props.filterValues && props.filterValues.filter)
        {
            queryStringFilter = '&filter=' + encodeURIComponent(JSON.stringify(props.filterValues.filter));
        }
        var queryStringFilterSearch = '';
        if (props.filterValues && props.filterValues.q)
        {
            queryStringFilterSearch = '&q=' + encodeURIComponent(props.filterValues.q);
        }
        const result = await fetch(apiUrl + '/users/export?associations_id=' + localStorage.getItem('associations_id') + '&groups_id=' + props.groups_id +
                '&page=0&size=1000000&sort=' + field + ',' + order + queryStringFilter + queryStringFilterSearch , { 
          headers: headers
        });

        const blob = await result.blob();
        const href = window.URL.createObjectURL(blob);

        var tempLink = document.createElement('a');
        tempLink.href = href;
        tempLink.setAttribute('download', translate('users.exporter_file_name'));
        tempLink.click();

        notify(translate('remittances.pdf_ok'),"success");
    
    } catch (error) {
    
    } finally {      
        mainLoading.style.display = "none";
    }
    
  };

  return (
    <Button label={translate('users.export')} startIcon={<SaveIcon />} onClick={handleAction} />
  );

}