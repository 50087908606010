// in src/posts.js
import * as React from 'react';

import { 
  Datagrid,
  TextField,
  TextInput,
  ReferenceManyField, required,DateField ,DateInput
} from 'react-admin';



import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';





import { useFormState } from 'react-final-form';


import { useTranslate  } from '../common/language';



import { validateMaxLength  } from './../components/Validator';


/* LLISTAT DES DE PAGINA DE USERS */

export const UsersRecognitionsShow = (props) => {
  const translate = useTranslate();
  return (
    <ReferenceManyField                    
      reference="users_recognitions"
      target="users_id"
      label=""
    >
      <Datagrid     
        className="users_remittances_list header_grey6"
      >
        <TextField source="name" label={translate('common.name')} sortable={false} />
        <DateField source="date_recognition" label={translate('users_federation.recognitions_date')} sortable={false}/>
      </Datagrid>

    </ReferenceManyField>                         
  );
};


export const UsersRecognitionsEdit= (props) => {
  const translate = useTranslate();
  let users_id = props.id;
    
  return (
    <ReferenceManyField                    
      reference="users_recognitions"
      target="users_id"
      label=""
    >
      <EditableDatagrid                       
        createForm={<UsersRecognitionsForm initialValues={{ users_id: users_id }} />}
        editForm={<UsersRecognitionsForm userId={{users_id}} />}
        label=""
        hasCreate
        fullWidth                        
      >
        <TextField source="name" label={translate('common.name')} sortable={false} />
        <DateField source="date_recognition" label={translate('users_federation.recognitions_date')} sortable={false}/>
      </EditableDatagrid>

    </ReferenceManyField> 
  );
};

const UsersRecognitionsForm = ({ ...props}) => {
  const translate = useTranslate();   
  const { values } = useFormState();
  return (
    <RowForm {...props}  >                
      <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />  
      <DateInput source="date_recognition" label={translate('users_federation.recognitions_date')} validate={[required()]} />
    </RowForm>
  );
};