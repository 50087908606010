// in src/posts.js
import React, { useState } from 'react';

import {
  List, 
  Datagrid,
  TextField,
  DeleteButton,
  TextInput,
  Button ,
  TopToolbar, required,NumberInput,  ResourceContextProvider, useCreate, useRefresh, FormWithRedirect, SaveButton,
  useUpdate,
  useNotify,
  FunctionField,
  BooleanInput
    
} from 'react-admin';





import { useTranslate  } from '../common/language';

import Typography from '@mui/material/Typography';



import EditIcon from '@material-ui/icons/Edit';
import Grid from '@mui/material/Grid';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';


import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';




import Box from '@material-ui/core/Box';


import { validateMaxLength, validateInteger  } from './../components/Validator';


export const SettingsPostalLabels = (props) => {
  const translate = useTranslate();    
    
  let strRedirect =  '/settings-postal-labels';  
    
  return (
    <>
      <br/>
      <Typography variant="h5" className="grey8" align="center">
        {translate('settings.postal_labels_desc')}              
      </Typography>
      <Box sx={{ bgcolor: 'background.paper', padding: '20px' }} className="settings_box" >
        <ResourceContextProvider value="postal_labels">
          <List {...props} 
            basePath="/postal_labels" 
            sort={{ field: 'priority', order: 'ASC' }} 
            actions={<ListActions />}    
            filter={{ associations_id : localStorage.getItem('associations_id') }}  
            bulkActionButtons={false}  
            pagination={false}
            empty={<ListActions />} 
            fullWidth
          >        
            <Datagrid fullWidth>
              <TextField source="name" label={translate('postal_labels.name')} sortable={false} />   
              <FunctionField label={translate('postal_labels.colxrows')} render={record => 
                record.num_columns + ' x ' + record.num_rows
              } sortable={false}  />              
              <PostalLabelsEditButton label="" />
              <DeleteButton undoable={false} redirect={strRedirect} label=""/>
            </Datagrid>        
          </List>
        </ResourceContextProvider>
      </Box>
    </>
  );
};

const ListActions = (props) => {
  const translate = useTranslate(); 
  return (
    <TopToolbar>     
      <PostalLabelsCreateButton />      
    </TopToolbar>
  );
};


const PostalLabelsCreateButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
    
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  

  const [create, { loading }] = useCreate('postal_labels');

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify( error.message , 'error');
        }
      }
    );
  };    

  return (
    <>            
      <Button onClick={handleClick} label={translate('postal_labels.create')}>
        <IconContentAdd />                
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('postal_labels.create')}
        
        maxWidth="md"
      >
        <DialogTitle>{translate('postal_labels.create')}</DialogTitle>

        <FormWithRedirect
          resource="postal_labels"
          save={handleSubmit}
          initialValues={ { associations_id : localStorage.getItem('associations_id'), 
              name: 'Etiquetes', 
              num_columns : 3, 
              num_rows: 8, 
              template : '%nom% %cognom1% %cognom2%\n%adreca%\n%cp% %poblacio%\n%pais%',
              font_size: 9,
              line_separator: 3,
              margin_cell_left : 1.0,
              margin_cell_top : 0.5,
              margin_cell_right : 0.3,
              margin_cell_bottom : 0.3,
              margin_page_left : 0.0,
              margin_page_top : 0.0,
              margin_page_right : 0.0,
              margin_page_bottom : 0.0,
              last_row : true,
              priority : 1              
          } }
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <FormContent />
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

const FormContent = (props) => {

    /*
     *  
        <Grid item xs={2}> 
          <NumberInput fullWidth source="priority" label={translate('common.priority')} />   
        </Grid> 
     */
    
  const translate = useTranslate(); 
  return (
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={12}>     
          <TextInput fullWidth source="name" label={translate('postal_labels.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}> 
          <NumberInput fullWidth source="num_columns" label={translate('postal_labels.num_columns')} validate={[required()]} />
        </Grid> 
        <Grid item xs={3}> 
          <NumberInput fullWidth source="num_rows" label={translate('postal_labels.num_rows')} validate={[required()]} />
        </Grid> 
        <Grid item xs={3}> 
          <NumberInput fullWidth source="font_size" label={translate('postal_labels.font_size')} validate={[required()]} />
        </Grid> 
        <Grid item xs={3}> 
          <NumberInput fullWidth source="line_separator" label={translate('postal_labels.line_separator')} validate={[required()]} />
        </Grid> 
      </Grid>
        
      <Grid container spacing={2}>
        <Grid item xs={8}> 
          <TextInput multiline fullWidth source="template" label={translate('postal_labels.template')} />  
        </Grid> 
        <Grid item xs={4}> 
        {translate('postal_labels.available_fields')}<br /><br />{translate('postal_labels.fields')}
        </Grid> 
      </Grid> 
      
      <br/><span>{translate('postal_labels.margins_cell')}</span>  
      <Grid container spacing={2}>
        <Grid item xs={3}>                                         
          <NumberInput fullWidth source="margin_cell_left" label={translate('postal_labels.margin_cell_left')} />
        </Grid> 
        <Grid item xs={3}> 
          <NumberInput fullWidth source="margin_cell_top" label={translate('postal_labels.margin_cell_top')} />
        </Grid> 
        <Grid item xs={3}> 
          <NumberInput fullWidth source="margin_cell_right" label={translate('postal_labels.margin_cell_right')} />
        </Grid> 
        <Grid item xs={3}> 
          <NumberInput fullWidth source="margin_cell_bottom" label={translate('postal_labels.margin_cell_bottom')} />
        </Grid> 
      </Grid>  
      <br/><span>{translate('postal_labels.margins_page')}</span>
      <Grid container spacing={2}>
        <Grid item xs={3}>                                         
          <NumberInput fullWidth source="margin_page_left" label={translate('postal_labels.margin_page_left')} />
        </Grid> 
        <Grid item xs={3}> 
          <NumberInput fullWidth source="margin_page_top" label={translate('postal_labels.margin_page_top')} />
        </Grid> 
        <Grid item xs={3}> 
          <NumberInput fullWidth source="margin_page_right" label={translate('postal_labels.margin_page_right')} />
        </Grid> 
        <Grid item xs={3}> 
          <NumberInput fullWidth source="margin_page_bottom" label={translate('postal_labels.margin_page_bottom')} />
        </Grid> 
      </Grid> 
      <Grid container spacing={2}>
        <Grid item xs={12}> 
          <BooleanInput fullWidth source="last_row" label={translate('postal_labels.last_row')} />        
        </Grid> 
      </Grid> 
    </DialogContent>
  );
};


const PostalLabelsEditButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
    
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  

  const [update, { loading, error }] = useUpdate();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    update(
      { 
        type: 'update', 
        resource: 'postal_labels', 
        payload : { id: values.id, data : values }
      },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };    

  return (
    <>
      <Button onClick={handleClick} label="" startIcon={<EditIcon />} />

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('postal_labels.edit')}
        className="dialog-md"
        maxWidth="lg"
      >
        <DialogTitle>{translate('postal_labels.edit')}</DialogTitle>

        <FormWithRedirect
          resource="postal_labels"
          save={handleSubmit}
          initialValues={ props.record }
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <FormContent />
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

