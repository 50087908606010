import React, { useState } from 'react';
import {
  required,
  Button,
  SaveButton,
  useCreate,
  useNotify,
  FormWithRedirect,
  useRefresh, useTranslate, FileInput, FileField
} from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import PublishIcon from '@mui/icons-material/Publish';
import SaveIcon from '@material-ui/icons/GetApp';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { subscriptionModule  } from '../common/utils';
import {modules } from './../common/constants';

import {maxDocFileSize} from './../common/constants';

import { apiUrl, apiOrigin, apiDefaultLanguage,  } from './../common/constants';

export const ImportButton = ({ target, items_id , label, icon, btnClassname, group_public_id, ...props }) => {
  
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('docs/import');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  

  let allowableAllDocs = false;
  let labelInputFile = '';    
  let maxDocFileSizeMin = maxDocFileSize / 1000000;
  labelInputFile = translate('docs.fieldExcel').replace('%size%',maxDocFileSizeMin);
    
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(
      { payload: { data: values } },
      {
        onSuccess: (data) => {
            
            if (data.data.num > 0)
            {
                setShowDialog(false);
                notify( translate('docs.imported').replaceAll('%num%',data.data.num) , 'success');
                refresh();
            }
            else
            {
                notify( translate('docs.imported_none') , 'error');             
            }
          
        },
        onFailure: ( error ) => {                   
          notify( <div dangerouslySetInnerHTML={{ __html: error.message }} ></div>, 'error');
        }
      }
    );
  };
        
  const handleClickGetTemplate = async () => {
        
    try {
        let headers = new Headers({
            'Authorization': localStorage.getItem('auth'),
            'Accept-Language': apiDefaultLanguage,
            'Role': localStorage.getItem('active_permission')
        });

        const result = await fetch(`${apiUrl}/docs/template_import/${localStorage.getItem('associations_id')}`, {
            headers: headers
        });

        // Comprova si la resposta ha tingut èxit
        if (!result.ok) {
            throw new Error('Error descarregant el fitxer');
        }

        const blob = await result.blob();
        const href = window.URL.createObjectURL(blob);

        // Generar un nom de fitxer dinàmic (opcionalment a partir del `Content-Disposition`)
        const contentDisposition = result.headers.get('Content-Disposition');
        
        const fileName = contentDisposition
            ? contentDisposition.split('filename=')[1].split(';')[0].trim()
            : 'plantilla.xlsx'; // Nom per defecte

        // Crear i clicar l'enllaç temporal per descarregar el fitxer
        const tempLink = document.createElement('a');
        tempLink.href = href;
        tempLink.setAttribute('download', fileName); // Nom dinàmic
        document.body.appendChild(tempLink);
        tempLink.click();

        // Eliminar l'enllaç temporal
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(href); // Alliberar memòria
        
        notify( translate('common.download_ok') , 'success');
        
    } catch (error) {
        
        notify( translate('common.notification.error') , 'error');
    }
    
  };
  
  const handleSubmit2 = async values => {
        
  };

  return (
    <>           
               
      <Button onClick={handleClick} label={translate('users.import')} className={btnClassname} >
        <PublishIcon />
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('users.import')}
      >
        <DialogTitle>{translate('users.import_title')}</DialogTitle>

        <FormWithRedirect
          resource="docs"
          save={handleSubmit}
          onSuccess={handleSubmit2}
          initialValues={{ associations_id : localStorage.getItem('associations_id'), target: target, items_id: items_id }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>                 
                <Button onClick={()=>handleClickGetTemplate()} className="link" label={translate('users.import_template')} icon={<IconCancel />} >
                    <SaveIcon />
                </Button>
                <br /><br />
                <FileInput source="image" label={labelInputFile} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" validate={required()} maxSize={maxDocFileSize} >
                  <FileField source="image" title="title" />
                </FileInput>                                
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
            
    </>
  );
};
