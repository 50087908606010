import React, { useState } from 'react';
import { Button, useListContext, useNotify, useUnselectAll, useDataProvider, useRefresh, RadioButtonGroupInput, 
    FormWithRedirect, SaveButton, DateTimeInput, required, ReferenceInput, SelectInput, FormDataConsumer, 
    BooleanInput, DateInput, useCreate, SaveContextProvider  } from 'react-admin';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Delete } from '@mui/icons-material';
import GroupsIcon from '@mui/icons-material/Groups';
import IconCancel from '@material-ui/icons/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import MyLoading from '../components/MyLoading';
import { useTranslate  } from '../common/language';

import Grid from '@mui/material/Grid';


const BulkGroupsButton = ({ resource_name, group_public_id, source_group_id, group_name, ...props}) => {
    const { selectedIds, resource, setSelectedIds } = useListContext();
    const [open, setOpen] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const refresh = useRefresh();
    const [loading, setLoading] = useState(false);
    const translate = useTranslate(); 
    const [create] = useCreate('users_groups/change');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async (values) => {
        setLoading(true);

        const changePromises = selectedIds.map(id => {
          const newValues = { ...values, users_id: id };

          return new Promise((resolve, reject) => {
            create(
              { payload: { data: newValues } },
              {
                onSuccess: ({ data }) => {
                  resolve(data); // Resol la promesa en cas d'èxit
                },
                onFailure: (error) => {
                  reject(error); // Rebutja la promesa en cas d'error
                }
              }
            );
          });
        });

        const results = await Promise.allSettled(changePromises);

        const errors = results
          .filter(result => result.status === 'rejected')
          .map(result => ({
            id: selectedIds[results.indexOf(result)],
            message: result.reason.message || 'Error desconegut',
          }));

        const successCount = results.filter(result => result.status === 'fulfilled').length;

        let styleNotification='success';
        if ( successCount === 0 ) styleNotification = 'error';
        if ( errors.length > 0 && successCount > 0 ) styleNotification = 'warning';

        let message='';
        if ( successCount > 0 ) 
        {
            message = message + translate('users.group_changes_ok_' + values.action ).replace('%num_registers%',successCount) + '<br/><br/>';
        }

        if ( errors.length > 0 ) 
        {
            message = message + translate('users.group_changes_ko_' + values.action ).replace('%num_registers%',errors.length) + ':<br/>' + ` ${errors.map(e => e.message).join('<br/>')}`
        }

        notify( <div className="" dangerouslySetInnerHTML={{ __html: message }} /> , styleNotification);

        unselectAll(resource_name);
        refresh();  
        handleClose();
        setLoading(false);
    
    };

    const today = new Date();

    if (loading) return <MyLoading />;
    
    return (
        <>
            <Button label={translate('users.group_changes')} onClick={handleClickOpen}>                
               <GroupsIcon />   
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    {translate('users.group_changes_title').replace('%num_registers%',selectedIds.length)}
                </DialogTitle>
                <SaveContextProvider>
                <FormWithRedirect
                save={handleSubmit}
                initialValues={{ source_groups_id: source_group_id, action: 1 , left_send_email: group_public_id == 'SOCIS' ? true : false, date_action : today }}
                render={({
                  handleSubmitWithRedirect,
                  pristine,
                  saving
                }) => (
                  <>
                <DialogContent>                    
                    <Grid container spacing={2}>
                    <Grid item xs={12}> 
                      <RadioButtonGroupInput label={translate('users.group_changes_action')} row={false} validate={[required()]} source="action" choices={[
                        { id: 1, name: translate('users.group_changes_action_1').replace('%group_name%',group_name) },
                        { id: 2, name: translate('users.group_changes_action_2') },
                        { id: 3, name: translate('users.group_changes_action_3') }
                      ]} />
                    </Grid> 
                    </Grid>                    
                    
                    <FormDataConsumer>
                        {({ formData, ...rest }) => 
                        <>
                        { formData.action != 1 ?
                            <Grid container spacing={2}>
                                <Grid item xs={12}>     
                                <ReferenceInput label={translate('users.group_changes_destination_group')} source="destination_groups_id" reference="groups" filter={{ associations_id : localStorage.getItem('associations_id') }} sort={{ field: 'priority', order: 'ASC' }}  >
                                    <SelectInput 
                                    fullWidth 
                                    optionText="path" 
                                    optionValue="id" 
                                    validate={required()}                             
                                    />
                                </ReferenceInput>   
                                </Grid>
                            </Grid>
                        : null }
                        <Grid container spacing={2}>
                            <Grid item xs={6}>     
                                <DateInput fullWidth source="date_action" label={translate('users.group_changes_date_' + formData.action)} validate={[required()]} />   
                            </Grid>
                        </Grid>
                        { group_public_id == 'SOCIS' && formData.action === 1 ?
                        <Grid container spacing={2}>
                            <Grid item xs={12}>     
                              <BooleanInput fullWidth source="left_send_email" label={translate('users_groups.left_send_email')} />
                            </Grid>
                        </Grid>
                        : null }
                        </>
                        }
                    </FormDataConsumer>                       
                    
                </DialogContent>
                
                <DialogActions>
                <Button label="ra.action.cancel" onClick={handleClose} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                  icon={<CheckIcon />}
                  label={translate('users.group_changes_confirm')}
                />
              </DialogActions>
            </>
          )}
        />
        </SaveContextProvider>
        </Dialog>
        </>
    );
};

export default BulkGroupsButton;
