
import { apiUrl, apiDefaultLanguage, apiOrigin } from './../common/constants';
import { setActivePermissionData } from './../common/utils';
import { Login, LoginForm, useTranslate } from 'react-admin';


function removeStorage()  {
    
  localStorage.removeItem('auth');
  localStorage.removeItem('email');
  localStorage.removeItem('users_login_id');
  localStorage.removeItem('permissions');
  localStorage.removeItem('active_permission');  
  
  localStorage.removeItem('associations_id');
  localStorage.removeItem('associations_name');    
  localStorage.removeItem('permissions_groups');
  localStorage.removeItem('members_group_id');
  localStorage.removeItem('members_pending_group_id');
  localStorage.removeItem('associations_logo_doc');
  localStorage.removeItem('subscriptions');
  localStorage.removeItem('language');
  localStorage.removeItem('members_pending_group_id');
  
  
}

// in src/authProvider.js
const authProvider = {
    
  // called when the user attempts to log in
  login: ({ username, password }, translate) =>  {
            
    let headers = new Headers({ Accept: 'application/json' });
    headers.set('Content-Type', 'application/json' );
    headers.set('Access-Control-Allow-Origin', apiOrigin );
    headers.set('Accept-Language', apiDefaultLanguage);

    const request = new Request(apiUrl + '/auth/login', {
      method: 'PUT',
      body: JSON.stringify({ email: username, pswd: password }),
      headers: headers,
    });

    return fetch(request)
      .then(response => {
        if (response.status === 406)
        {
          throw new Error('El compte està bloquejat. Espera uns minuts i torna a provar-ho');     
        }
        else if (response.status === 405)
        {
          throw new Error('S\'han fet massa intents d\'inici de sessió. Per seguretat el compte ha quedat bloquejat durant 5 minuts.'); 
        }
        else if (response.status === 404) {
          throw new Error('Usuari o contrasenya incorrecta');
        }
        else if (response.status < 200 || response.status >= 300) {
          throw new Error('Usuari o contrasenya incorrecta');
        }                
        return response.json();
      })
      .then(auth => {
          
        localStorage.setItem('auth', auth.token );
        localStorage.setItem('email', auth.email );
        localStorage.setItem('users_login_id', auth.users_login_id );                    
        localStorage.setItem('permissions', JSON.stringify(auth.permissions) );        
        localStorage.setItem('language', auth.language);
        
        setActivePermissionData(auth.active_permission);
        
      })
      .catch((e) => {      
        throw new Error(e);               
      });
  },
  // called when the user clicks on the logout button
  logout: () => {
        
    let authHeaders = new Headers({ Accept: 'application/json' });
    authHeaders.set('Access-Control-Allow-Origin', apiOrigin );
    authHeaders.set('Authorization', localStorage.getItem('auth'));
    authHeaders.set('Accept-Language', apiDefaultLanguage);
    authHeaders.set('Role', localStorage.getItem('active_permission'));

    const request = new Request(apiUrl + '/auth/logout', {
      method: 'DELETE',
      headers: authHeaders             
    });

    return fetch(request)
      .then(response => {
        removeStorage();
      })
      .catch(() => {
        removeStorage();
      });

  },
    
  // called when the API returns an error
  checkError: ({ status }) => {        
    if (status === 401 || status === 403) {
      localStorage.removeItem('auth');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem('auth')
      ? Promise.resolve()
      : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
};


export default authProvider;

