// in src/posts.js
import React, { useState } from 'react';

import {
  List, 
  Datagrid,
  TextField,
  DeleteButton,
  Edit,
  SelectInput,
  TextInput,
  Button ,
  CreateButton ,
  TopToolbar,
  FormTab,
  TabbedForm,
  ReferenceManyField,
  FormDataConsumer,
  useListContext, required,NumberInput,BooleanInput,SelectField,  FunctionField, ResourceContextProvider, useCreate, useRefresh, FormWithRedirect, Toolbar, SaveButton,
  useNotify,
  useRedirect,
  ReferenceField    
} from 'react-admin';

import { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Divider from '@mui/material/Divider';


import { useTranslate  } from '../common/language';

import Typography from '@mui/material/Typography';


import RichTextInput from 'ra-input-rich-text';


import { TextFieldShow } from '../components/TextFieldShow';

import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { ColorInput } from 'react-admin-color-input';

import { DocsList, DocsListShow } from './docs';
import { targets, choicesClassroomsType  } from './../common/constants';

import { CityInput } from './../components/Inputs';

import { RenderBoolean } from '../components/User';
import { BackLink } from './../components/BackLink';

import Box from '@material-ui/core/Box';

import { Link } from 'react-router-dom';

import { validateMaxLength, validateInteger  } from './../components/Validator';

//empty={<EmptyClassrooms title={translate('classrooms.no_classrooms_title')} icon={MeetingRoomIcon} />}
/* 
 * Llista editable des de la pestanya general Espais (espais per associació)
 * i Llista des de l'edició de la fitxa d'users (espais d'usuari)
 * El paràmetre que ho distingeix és props.user_classroom
 */
export const ClassroomsList = (props) => {
  const translate = useTranslate();  
    
  let basePath='/classrooms'; 
  let resource = 'classrooms';
  let strRedirect = 'classrooms';
  if (props.user_classroom)
  {
    basePath='/users_classrooms';
    resource = 'users_classrooms';
    strRedirect =  `/users/${props.users_id}/classrooms`;
  }
    
  return (
    <ResourceContextProvider value={resource}>
      <List 
        basePath={basePath}   
        filter={{ associations_id : localStorage.getItem('associations_id'), users_id: props.users_id }}  
        actions={<ListActionsClassrooms users_id={props.users_id}  />}    
        empty={<ListActionsClassrooms users_id={props.users_id} />}        
        bulkActionButtons={false}          
        perPage={1000}   
        pagination={false}
        sort={{ field: 'priority', order: 'ASC' }} 
        label=""  
        title=""  
        resource={resource}
      >        
        <Datagrid 
          rowClick="edit"
          expand={<UsersClassroomsDetails />} >  
          <Avatar><MeetingRoomIcon/></Avatar>                                  
          <TextField source="name" label={translate('common.name')} sortable={false} />    
          <FunctionField render={record => <span>{translate('classrooms.type' + record.type)}</span> } label={translate('common.type')} sortable={false}  />   
          <TextField source="m2" label={translate('classrooms.m2')} sortable={false} />  
          <TextField source="seats" label={translate('classrooms.seats')} sortable={false} />     
          <DeleteButton undoable={false} label="" redirect={strRedirect} />
        </Datagrid>        
      </List>
    </ResourceContextProvider>
  );
};

/* 
 * Llista no editable des de la fitxa d'users (espais d'usuari)
 */
export const UsersClassroomsShow = (props) => {
  const translate = useTranslate();    
  return (
    <ReferenceManyField                    
      reference="users_classrooms"
      target="users_id"
      label=""
      filter={{ associations_id : localStorage.getItem('associations_id') }}  
    >

      <Datagrid          
        hasCreate
        className="table-quotes-periods users_remittances_list header_grey6"
        expand={<UsersClassroomsDetails />} 
      >
        <Avatar><MeetingRoomIcon/></Avatar>                                  
        <TextField source="name" label={translate('common.name')} sortable={false} />    
        <FunctionField render={record => <span>{translate('classrooms.type' + record.type)}</span> } label={translate('common.type')} sortable={false}  />   
        <TextField source="m2" label={translate('classrooms.m2')} sortable={false} />  
        <TextField source="seats" label={translate('classrooms.seats')} sortable={false} />                    
      </Datagrid>

    </ReferenceManyField> 
  );
};


export const UsersClassroomsDetails = ({ id, record, resource, ...props }) => {
  const translate = useTranslate(); 
    
  let target_photo = targets.CLASSROOM_PHOTO;
  let target_doc = targets.CLASSROOM_DOC;
  if (resource === 'users_classrooms')
  {
    target_photo = targets.USER_CLASSROOM_PHOTO;
    target_doc = targets.USER_CLASSROOM_DOC;
  }
    
  return (
    <div className="usersClassroomsExpand">        
      <Grid container spacing={2}>
        <Grid item xs={6}>                    
          <TextFieldShow source={record.name} label={translate('common.name')} />                       
        </Grid>
        <Grid item xs={6}>                    
          <FunctionField render={record => <TextFieldShow source={<SelectField source="type" choices={choicesClassroomsType(translate)} /> } label={translate('classrooms.type')} /> } />                        
        </Grid>
      </Grid>
      
      <Grid container spacing={2}>
        <Grid item xs={6}>                    
          <TextFieldShow source={record.address} label={translate('users.address')} />                       
        </Grid>
        <Grid item xs={6}>        
          <br/><span className="custom-field-title grey6">{translate('events.city')}</span><br/>
          <ReferenceField label={translate('events.city')} source="cities_id" reference="cities" link={false} > 
            <TextField source="name" />                               
          </ReferenceField>                      
        </Grid>
      </Grid>
        
      <Grid container spacing={2}>
        <Grid item xs={2}>                    
          <TextFieldShow source={record.m2} label={translate('classrooms.m2')} />                   
        </Grid>
        <Grid item xs={2}>                    
          <TextFieldShow source={record.seats} label={translate('classrooms.seats')} />                   
        </Grid>            
        <Grid item xs={2}>                    
          <TextFieldShow source={<RenderBoolean source={record.accessible} />} label={translate('classrooms.accessible')} />                   
        </Grid>
        <Grid item xs={2}>  
          <TextFieldShow source={<RenderBoolean source={record.outdoor} />} label={translate('classrooms.outdoor')} />     
        </Grid>
        <Grid item xs={2}>                    
          <TextFieldShow source={<RenderBoolean source={record.lift} />} label={translate('classrooms.lift')} />                   
        </Grid>
      </Grid>
      
      <Grid container spacing={2}>
        <Grid item xs={2}>                    
          <TextFieldShow source={<RenderBoolean source={record.cession} />} label={translate('classrooms.cession')} />                   
        </Grid>
        <Grid item xs={2}>                    
          <TextFieldShow source={<RenderBoolean source={record.rent} />} label={translate('classrooms.rent')} />                   
        </Grid>
        <Grid item xs={2}>                    
          <TextFieldShow source={<RenderBoolean source={record.rentable} />} label={translate('classrooms.rentable')} />                   
        </Grid>        
      </Grid>
        
        
      { resource === 'classrooms' ? <>
      
        <Grid container spacing={2}>
            <Grid item xs={12}>                    
              <TextFieldShow source={<div className="" spacing={2} dangerouslySetInnerHTML={{ __html: record.description }} />} label={translate('common.description')} />                   
            </Grid>
        </Grid>
        <Typography variant="h6" className="grey8 titleUserShow">                        
          {translate('classrooms.visibility')}
        </Typography>
        <Divider fullWidth className="" />
        
        <br/>
        <Grid container spacing={2}>
          <Grid item xs={6}>                    
            <TextFieldShow source={<RenderBoolean source={record.is_public} />} label={translate('classrooms.is_public')} />                   
          </Grid>
          <Grid item xs={6}>                    
            <TextFieldShow source={<RenderBoolean source={record.is_requestable} />} label={translate('classrooms.is_requestable')} />                   
          </Grid>
        </Grid>
        
        <Typography variant="h6" className="grey8 titleUserShow">                        
          {translate('classrooms.events')}
        </Typography>
        <Divider fullWidth className="" />        
        <br/>
        <Grid container spacing={2}>
          <Grid item xs={5}>                    
            <TextFieldShow source={<RenderBoolean source={record.is_for_events} />} label={translate('classrooms.is_for_events')} />                   
          </Grid>
          {record.is_for_events ? <>
            <Grid item xs={5}>                    
              <TextFieldShow source={<RenderBoolean source={record.overlapable} />} label={translate('classrooms.overlapable')} />                   
            </Grid>
            <Grid item xs={2}>                    
              <TextFieldShow source={record.color} label={translate('classrooms.color')} />                   
            </Grid>
          </> : null }
        </Grid>
        
        
      </> : null }
        
      <Typography variant="h6" className="grey8 titleUserShow">                        
        {translate('classrooms.photos')}
      </Typography>
      <Divider fullWidth className="" />
        
      <DocsListShow target={target_photo} items_id={record.id} />
        
      <Typography variant="h6" className="grey8 titleUserShow">                        
        {translate('classrooms.docs')}
      </Typography>
      <Divider fullWidth className="" />
        
      <DocsListShow target={target_doc} items_id={record.id} />
        
    </div>
  );
};


const ListActionsClassrooms = (props) => {
  const translate = useTranslate(); 
  return (
    <TopToolbar>             
      <ClassroomsCreateButton {...props} />
    </TopToolbar>
  );
};


export const ClassroomsCreateButton = ({ users_id , ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('classrooms');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  
  const redirect = useRedirect();
    
  const [automaticName, setAutomaticName] = useState();

    
  useEffect(() => {
    setAutomaticName(translate('classrooms.type0'));            
  }, []);
    
    
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {                    
          if (props.resource === 'users_classrooms')
          {
            redirect('/users_classrooms/' + data.id );                        
          }
          else
            redirect('/classrooms/' + data.id);
        
        },
        onFailure: ( error ) => {
          notify( error.message , 'error');
        }
      }
    );
  };
    
  return (
    <>            
      <Button onClick={handleClick} label={translate('classrooms.create')}>
        <IconContentAdd />                
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('classrooms.create')}
      >
        <DialogTitle>{translate('classrooms.create')}</DialogTitle>

        <FormWithRedirect
          save={handleSubmit}
          initialValues={{ associations_id: localStorage.getItem('associations_id') , type: 0, users_id : users_id, name: automaticName }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>
                <Grid container spacing={1}>                                     
                  <Grid item xs={5}> 
                    <SelectInput
                      onChange={event => { 
                        setAutomaticName(translate('classrooms.type' + event.target.value));  
                      }} 
                      fullWidth label={translate('classrooms.type')} source="type" choices={choicesClassroomsType(translate)} validate={[required()]} />
                  </Grid>  
                                    
                  <Grid item xs={7}>                                               
                    <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
                  </Grid>
                                    
                </Grid> 
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

const ClassroomsActions = ({ basePath, data, resource }) => {

  const translate = useTranslate();   
    
  let backLink = '';
  if (resource === 'users_classrooms' && data && data.users_id )
    backLink = '/users/' + data.users_id + '/classrooms';
  else
    backLink = '/classrooms';
    
  return <BackLink link={backLink} title={ data && data.name ? data.name : null } />;
  
};


export const ClassroomsEdit  = ({ id, ...props }) => {

  const translate = useTranslate();        
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  let strRedirect =  `/classrooms/${id}`;    
    
  const onSuccess = ({ data }) => {
    notify(translate('common.notification.updated'));
        
    if (props.resource === 'users_classrooms')
    {
      redirect('/users/' + data.users_id + '/classrooms');
      refresh();
    }
    else
      redirect('/classrooms');
        
  };
    
  let target_photo = targets.CLASSROOM_PHOTO;
  let target_doc = targets.CLASSROOM_DOC;
  if (props.resource === 'users_classrooms')
  {
    target_photo = targets.USER_CLASSROOM_PHOTO;
    target_doc = targets.USER_CLASSROOM_DOC;
  }
    
  return (

    <Edit 
      title={<ElementTitle {...props}  />} 
      {...props} 
      id={id}
      mutationMode="pessimistic"
      actions={<ClassroomsActions {...props} />}
      onSuccess={onSuccess}   
    >
        
      <TabbedForm        
        toolbar={<FormToolbar />}
      >    
        <FormTab
          label={translate('classrooms.data')}
          contentClassName={classes.tab}
          path="" 
                
        > 
               
          <FormDataConsumer>
            {({ formData, ...rest }) => 
              <>                              
                <br/>
                <Grid container spacing={2}>
                  <Grid item xs={8}>   
                    <TextInput fullWidth autoFocus source="name" label={translate('common.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]}  />
                  </Grid> 
                  <Grid item xs={4}> 
                    <SelectInput fullWidth label={translate('classrooms.type')} source="type" choices={choicesClassroomsType(translate)} validate={[required()]} />
                  </Grid>   
                  <Grid item xs={12} sm={6}>
                    <TextInput fullWidth source="address" label={translate('users.address')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />                             
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CityInput />
                  </Grid>
                </Grid>
                
                
                <Grid container spacing={2}>                   
                  <Grid item xs={3}>                    
                    <NumberInput fullWidth source="m2" label={translate('classrooms.m2')} validate={[validateInteger(translate('common.validation.number'))]}  />
                  </Grid> 
                  <Grid item xs={3}>                    
                    <NumberInput fullWidth source="seats" label={translate('classrooms.seats')} validate={[validateInteger(translate('common.validation.number'))]}  />
                  </Grid>   
                  <Grid item xs={2}>                    
                    <NumberInput fullWidth source="priority" label={translate('common.priority')} validate={[validateInteger(translate('common.validation.number'))]}  />
                  </Grid> 
                </Grid>
                
                <Grid container spacing={2}>                   
                  <Grid item xs={3}>
                    <BooleanInput source="accessible" label={translate('classrooms.accessible')} />         
                  </Grid> 
                  <Grid item xs={3}>
                    <BooleanInput source="outdoor" label={translate('classrooms.outdoor')} />         
                  </Grid>
                  <Grid item xs={3}>
                    <BooleanInput source="lift" label={translate('classrooms.lift')} />         
                  </Grid> 
                </Grid>
                
                <Grid container spacing={2}>       
                  <Grid item xs={3}>
                    <BooleanInput source="cession" label={translate('classrooms.cession')} />         
                  </Grid> 
                  <Grid item xs={3}>
                    <BooleanInput source="rent" label={translate('classrooms.rent')} />         
                  </Grid> 
                  <Grid item xs={3}>
                    <BooleanInput source="rentable" label={translate('classrooms.rentable')} />         
                  </Grid>                   
                </Grid>
                
                <Grid container spacing={2}>
                    
                </Grid>
                
                {!(props.resource === 'users_classrooms') ?
                  <Grid container spacing={2}>
                    <Grid item xs={12}>                     
                      <RichTextInput source="description" label={translate('common.description')} />
                    </Grid>
                  </Grid>
                  : null }
                       
                { props.resource === 'classrooms' ? <>
                  <Typography variant="h6" className="grey8 titleUserShow">                        
                    {translate('classrooms.visibility')}
                  </Typography>
                  <Divider fullWidth className="" />
                  <br />

                  <Grid container spacing={2}>                        
                    <Grid item xs={12}>
                      <BooleanInput source="is_public" label={translate('classrooms.is_public')} />         
                    </Grid> 
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <BooleanInput source="is_requestable" label={translate('classrooms.is_requestable')} />         
                    </Grid> 
                  </Grid>

                  <Typography variant="h6" className="grey8 titleUserShow">                        
                    {translate('classrooms.events')}
                  </Typography>
                  <Divider fullWidth className="" />
                  <br />

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <BooleanInput source="is_for_events" label={translate('classrooms.is_for_events')} />         
                    </Grid>                   
                  </Grid>   
                  { formData.is_for_events ? 
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <BooleanInput source="overlapable" label={translate('classrooms.overlapable')} />         
                        </Grid>                    
                      </Grid>    
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <ColorInput fullWidth source="color" label={translate('classrooms.color')}  validate={[validateMaxLength(7,translate('common.validation.maxLength'))]} />  
                        </Grid>                        
                      </Grid> 
                    </>
                    : null }
                
                </>
                  : null }

              </>
            }
          </FormDataConsumer>

        </FormTab> 
        
        {!(props.resource === 'users_classrooms') ?
          <FormTab
            label={translate('classrooms.photos')}
            contentClassName={classes.tab}
            path="photos" 
                
          > 
            
            <DocsList target={target_photo} items_id={id} />
            
          </FormTab>
          : null }
        
        {!(props.resource === 'users_classrooms') ?
          <FormTab
            label={translate('classrooms.docs')}
            contentClassName={classes.tab}
            path="docs" 
                
          > 
            
            <DocsList target={target_doc} items_id={id} />
            
          </FormTab>
          : null }
    
      </TabbedForm>
    </Edit>
  );
};

const ElementTitle = ({ record, ...props }) => {    
  const translate = useTranslate(); 
  return (<span>{translate('common.edit')} {record ? `"${record.name}"` : ''}</span>);
};

const FormToolbar = ({ id , ...props }) => {
    
  let redirect = '/classrooms';
    
  if (props.resource === 'users_classrooms')
  {
    redirect = '/users/' + props.record.users_id + '/classrooms';
  }
    
  return (
    <Toolbar {...props} className="user_form_toolbar" >
      <SaveButton />
      <DeleteButton redirect={redirect} mutationMode="pessimistic" />
    </Toolbar>
  );
};

const EmptyClassrooms = ({ associations_id , ...props}) => {
  const translate = useTranslate(); 
  const { basePath, resource } = useListContext();
  return (
    <Box textAlign="center" m={1} >
      <br />
      <Typography variant="h5" paragraph className="grey8">
        {translate('classrooms.no_classrooms_title')} 
      </Typography>
      <Typography variant="body1" className="grey8">
        {translate('classrooms.no_classrooms_ask')} 
      </Typography>
      <br />
      <CreateButton />
    </Box>
  );
};


const useStyles = makeStyles({
  comment: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    maxWidth: '100%',
    display: 'block',
  },
});





