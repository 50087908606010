
import {
  useTranslate ,
  Button ,
  useDataProvider  ,
  useNotify
} from 'react-admin';

import SaveIcon from '@material-ui/icons/GetApp';



export function DownloadSEPAButton ({ record, remittances_id, date_creation, ...props}) {
  const translate = useTranslate();
  const dataProvider = useDataProvider();    
  const notify = useNotify();

  if (record)
  {
    remittances_id = record.id;
    date_creation = record.date_creation;
  }

  const handleClick = () => {
    dataProvider.getOne('remittances/sepa',{ id: remittances_id })
      .then(({ data }) => {
        var ms = new Date().getTime();
        var strName = date_creation.substring(0,10) + '_' + remittances_id+ '_' + ms;
        var fileDownload = require('js-file-download');
        fileDownload( data.xmlValue , 'remesa_sepa_' + strName + '.xml');
        notify(translate('remittances.pdf_ok'),"success");
      })
      .catch(error => {
        notify(error.message,'error');
      });
  };
  return <Button {...props} label={translate('remittances.sepa')} onClick={handleClick} startIcon={<SaveIcon />} className="remittancesButtonSEPA" />;
}