import React, { useState } from 'react';

import { SaveButton ,
  List,
  Datagrid,
  TextField,
  DeleteButton,
  required,    
  useCreate,
  useUpdate,
  useNotify,
  FormWithRedirect,useRefresh,
  Button,
  FunctionField, TopToolbar, NumberInput, ResourceContextProvider, TabbedShowLayout, Tab
} from 'react-admin';


import EditIcon from '@material-ui/icons/Edit';

import { useStyles  } from '../common/styles';
import { useTranslate  } from '../common/language';

import Typography from '@material-ui/core/Typography';



import Divider from '@mui/material/Divider';

import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';


import { TextFieldShow } from '../components/TextFieldShow';

import Grid from '@mui/material/Grid';

import { validateInteger, validateDouble, validatePercent  } from './../components/Validator';

export const UsersDemografyList = (props) => {
  const translate = useTranslate(); 
    
  let strRedirect =  `/users/${props.users_id}/more_federation`; 
    
  return (
           
    <ResourceContextProvider value="users_demografy" >
      <List 
        syncWithLocation 
        basePath="/users_demografy" 
        filter={{ users_id : props.users_id }}  
        title=""
        bulkActionButtons={false}                
        actions={!props.show ? <UsersDemografyToolbar users_id={props.users_id} /> : null }  
        perPage={1000}
        sort={{ field: 'year', order: 'ASC' }} 
        empty={!props.show ? <UsersDemografyToolbar users_id={props.users_id} /> : <span /> } 
        pagination={false}
      >

        <Datagrid   
          
          className="header_grey6"
          label=""
          fullWidth
        >
          <TextField source="year" label={translate('users_demografy.year')} sortable={false} />  
          <TextField source="num_members" label={translate('users_demografy.num_members')} sortable={false} />  
          <TextField source="avg_age" label={translate('users_demografy.avg_age')} sortable={false} />  
          <TextField source="avg_age_managers" label={translate('users_demografy.avg_age_managers')} sortable={false} />  
          <TextField source="percent_man" label={translate('users_demografy.percent_man')} sortable={false} />  
          <TextField source="percent_woman" label={translate('users_demografy.percent_woman')} sortable={false} />  
          <TextField source="percent_no_binary" label={translate('users_demografy.percent_no_binary')} sortable={false} />  
          { !props.show ? <UsersDemografyEditButton label=""/> : null }
          { !props.show ? <DeleteButton undoable={false} redirect={strRedirect} label=""/> : null }
        </Datagrid>

      </List> 
    </ResourceContextProvider>

  );
};


export const UsersDemografyToolbar = ({ ...props }) => {

  return (
        
    <TopToolbar>
      <UsersDemografyCreateButton users_id={props.users_id} />
    </TopToolbar>
        
  );
};

export const UsersDemografyCreateButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('users_demografy');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  
    

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };


  return (
    <>            
      <Button onClick={handleClick} label={translate('users_demografy.create')}>
        <IconContentAdd />                
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('users_demografy.create')}
      >
        <DialogTitle>{translate('users_demografy.create')}</DialogTitle>

        <FormWithRedirect
          resource="users_demografy"
          save={handleSubmit}
          initialValues={{ users_id : props.users_id }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <UsersDemografyForm edit={false} />
                            
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};


export const UsersDemografyEditButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  

  const [update, { loading, error }] = useUpdate();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };
                        
  const handleSubmit = async values => {
    update(
      { 
        type: 'update', 
        resource: 'users_demografy', 
        payload : { id: values.id, data : 
                {       users_id: values.users_id,
                  year : values.year, 
                  num_members: values.num_members, 
                  avg_age: values.avg_age, 
                  avg_age_managers: values.avg_age_managers,
                  percent_man : values.percent_man, 
                  percent_woman: values.percent_woman, 
                  percent_no_binary: values.percent_no_binary
                } }
      },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          refresh();
        },
        onFailure: ( error ) => {
          notify(error.message, 'error');
        }
      }
    );
  };    

  return (
    <>
      <Button onClick={handleClick} label="" startIcon={<EditIcon />} />

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
      >
        <DialogTitle>{translate('users_demografy.edit_title')}</DialogTitle>

        <FormWithRedirect
          resource=""
          save={handleSubmit}
          initialValues={{ id : props.record.id, 
            users_id: props.record.users_id,
            year : props.record.year, 
            num_members: props.record.num_members, 
            avg_age: props.record.avg_age, 
            avg_age_managers: props.record.avg_age_managers,
            percent_man : props.record.percent_man, 
            percent_woman: props.record.percent_woman, 
            percent_no_binary: props.record.percent_no_binary
          }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <UsersDemografyForm edit={true} />
                            
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};


const UsersDemografyForm = ({ edit, ...props }) => {
  const translate = useTranslate(); 
    
  return (
    <DialogContent className="">
      <br />
      <Grid container spacing={2} > 
        <Grid item xs={3}>  
          { edit ? (
            <NumberInput label={translate('common.year')} fullWidth source="year" step={1} disabled />                                        
          ) : (
            <NumberInput label={translate('common.year')} fullWidth source="year" step={1} validate={[required(),validateInteger(translate('common.validation.number'))]} />                                        
          )}
        </Grid> 
      </Grid>
      <br/>
        <Grid container spacing={2}>
            <Grid item xs={4}>   
                <NumberInput fullWidth source="num_members" label={translate('users_demografy.num_members')} step={1} validate={[validateInteger(translate('common.validation.number'))]} />
            </Grid> 
            <Grid item xs={4}>   
                <NumberInput fullWidth source="avg_age" label={translate('users_demografy.avg_age')} validate={[validateDouble(translate('common.validation.number'))]}  />
            </Grid> 
            <Grid item xs={4}>   
                <NumberInput fullWidth source="avg_age_managers" label={translate('users_demografy.avg_age_managers')} validate={[validateDouble(translate('common.validation.number'))]}  />
            </Grid> 
            <Grid item xs={4}>   
                <NumberInput fullWidth source="percent_man" label={translate('users_demografy.percent_man')} validate={[validatePercent(translate('common.validation.number'))]}  />
            </Grid> 
            <Grid item xs={4}>   
                <NumberInput fullWidth source="percent_woman" label={translate('users_demografy.percent_woman')} validate={[validatePercent(translate('common.validation.number'))]}  />
            </Grid> 
            <Grid item xs={4}>   
                <NumberInput fullWidth source="percent_no_binary" label={translate('users_demografy.percent_no_binary')} validate={[validatePercent(translate('common.validation.number'))]}  />
            </Grid> 
        </Grid>       
                        
    </DialogContent>
  );
    
    
};


