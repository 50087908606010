import React, { useState } from 'react';

import {
  List, 
  Datagrid,
  TextField,
  DeleteButton,
  Edit,
  SelectInput,
  TextInput,
  Button ,
  Create,
  SimpleForm,
  CreateButton ,
  TopToolbar,
  FormTab,
  TabbedForm,
  ReferenceManyField,
  FormDataConsumer,
  useListContext, required,NumberInput,BooleanInput,SelectField,  FunctionField, ResourceContextProvider, useCreate, useRefresh, FormWithRedirect, Toolbar, SaveButton,
  useNotify,
  useRedirect,
  DateField  , 
  Filter, 
  downloadCSV,
  DateInput,
  SimpleFormIterator,
  ArrayInput,BooleanField, TabbedShowLayout, Tab,
} from 'react-admin';

import { useEffect } from 'react';

import jsonExport from 'jsonexport/dist';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { AccordionSection  } from '@react-admin/ra-form-layout';


import { makeStyles } from '@material-ui/core/styles';

import Divider from '@mui/material/Divider';


import { useTranslate  } from '../common/language';

import Typography from '@mui/material/Typography';


import RichTextInput from 'ra-input-rich-text';


import { TextFieldShow } from '../components/TextFieldShow';

import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import GavelIcon from '@material-ui/icons/Gavel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Chip from '@material-ui/core/Chip';

import { ColorInput } from 'react-admin-color-input';

import { DocsList, DocsListShow } from './docs';
import { targets, modules, choicesClassroomsType, choicesCustomFieldsType  } from './../common/constants';
import { dateOnlyToCatalan, setActivePermissionData, structArrayToIdList, subscriptionModule } from './../common/utils';  
import { CustomFieldsOptionsShow, CustomFieldsOptionsList,CustomFieldsOptionsEdit, CustomFieldsOptionsCreate  } from './../resources/custom_fields_options';

import { CityInput } from './../components/Inputs';
import { ExportButton } from './../components/ExportButton';

import { RenderBoolean } from '../components/User';

import Box from '@material-ui/core/Box';

import { Link } from 'react-router-dom';

import { validateMaxLength, validateInteger, validateIntegerLowerOrEqualThan  } from './../components/Validator';


export const CustomFieldsList = (props) => {
  const translate = useTranslate();  
    
  return (
    
    <>
    <br/>
    <Typography variant="h5" className="grey8" align="center">
      {translate('custom_fields.main_menu_title')}              
    </Typography> 
    <Box sx={{ width: '100%', maxWidth: '90%', bgcolor: 'background.paper', padding: '25px' }} className="settings_box" >
    <TabbedShowLayout
    syncWithLocation={false}
    >
        <Tab
            label={translate('custom_fields.target_' + targets.USER)}
            fullWidth
            path=""
        > 
            <CustomFieldsListTarget target={targets.USER} />    
        </Tab>   
        
        { subscriptionModule(modules.FEDERATION) ?
        <Tab
            label={translate('custom_fields.target_' + targets.USER_FEDERATION)}
            fullWidth
            path=""
        > 
            <CustomFieldsListTarget target={targets.USER_FEDERATION} />    
        </Tab>
        : null }
        
    </TabbedShowLayout>
    </Box>
    </>
  );
};

export const CustomFieldsListTarget = (props) => {
  const translate = useTranslate();  
  
  let basePath='/custom_fields'; 
  let resource = 'custom_fields';
  let strRedirect = 'custom_fields';
  
  return (
  <ResourceContextProvider value={resource}>
      <List 
        basePath={basePath}   
        filter={{ associations_id : localStorage.getItem('associations_id'), target: props.target }}  
        actions={props.show ?  <span/> : <ListActions target={props.target} />}    
        empty={props.show ?  <span/> : <ListActions target={props.target} />}        
        bulkActionButtons={false}          
        perPage={1000}   
        pagination={false}
        sort={{ field: 'priority', order: 'ASC' }} 
        label=""  
        title=""  
        resource={resource}
      >        
        <Datagrid 
          rowClick={props.show ? null : 'edit'}
          expand={<CustomFieldsDetails />} 
          className="expand-without-padding"
          >                                
          <TextField source="name" label={translate('custom_fields.name')} sortable={false} />  
          <FunctionField render={record => <span>{translate('custom_fields.type_' + record.type)}</span> } label={translate('custom_fields.type')} sortable={false}  />
          <BooleanField source="actived" label={translate('custom_fields.actived')} sortable={false} />  
        </Datagrid>        
      </List>
    </ResourceContextProvider>);
}
    

const MyFilter = (props) => {
    const translate = useTranslate(); 
    return (
    <Filter {...props}>         
        <TextInput label={translate('common.search')} source="q" alwaysOn validate={[validateMaxLength(50,translate('common.validation.maxLength'))]} resettable />    
    </Filter>
);
};

export const CustomFieldsDetails = ({ id, record, resource, ...props }) => {
  const translate = useTranslate(); 
        
  return (
    <div className="usersClassroomsExpand">        
      <Grid container spacing={2}>        
        <Grid item xs={4}>   
          <TextFieldShow source={record.name} label={translate('custom_fields.name')} />
        </Grid> 
        <Grid item xs={4}>   
          <TextFieldShow source={translate('custom_fields.type_'+record.type)} label={translate('custom_fields.type')} />
        </Grid>         
        <Grid item xs={4}>   
          { record.type == 1 || record.type == 2 || record.type == 3  ?
          <TextFieldShow source={record.max_length} label={translate('custom_fields.max_length')} />
          : null }
        </Grid> 
        <Grid item xs={4}>   
          <TextFieldShow source={<RenderBoolean source={record.required} />} label={translate('custom_fields.required')} />
        </Grid> 
        <Grid item xs={4}>   
          <TextFieldShow source={<RenderBoolean source={record.actived} />} label={translate('custom_fields.actived')} />
        </Grid> 
        <Grid item xs={4}>   
          <TextFieldShow source={record.priority} label={translate('custom_fields.priority')} />
        </Grid> 
      </Grid>
      
      { record.type == 7 || record.type == 8 ?
      <CustomFieldsOptionsList custom_fields_id={id} show={true} />
      : null }
      
    </div>
  );
};

const ListActions = (props) => {            
    return (
    <TopToolbar>     
        <MyCreateButton {...props} />            
    </TopToolbar>
    );
};

export const MyCreateButton = ({ ...props }) => {

  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('custom_fields');
  const notify = useNotify();
  const refresh = useRefresh();    
  const translate = useTranslate();  
  const redirect = useRedirect();
        
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async values => {
    create(            
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {                    
          redirect('/custom_fields/' + data.id);
        
        },
        onFailure: ( error ) => {
          notify( error.message , 'error');
        }
      }
    );
  };
    
  return (
    <>            
      <Button onClick={handleClick} label={translate('custom_fields.create')}>
        <IconContentAdd />                
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('custom_fields.create')}
      >
        <DialogTitle>{translate('custom_fields.create')}</DialogTitle>

        <FormWithRedirect
          save={handleSubmit}
          initialValues={{ associations_id: localStorage.getItem('associations_id'), target: props.target, type: 1 }}
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }) => (
            <>
              <DialogContent>
                <Grid container spacing={1}>                       
                  <Grid item xs={7}>                                               
                    <TextInput fullWidth source="name" label={translate('custom_fields.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
                  </Grid>
                  <Grid item xs={5}>                                               
                    <SelectInput fullWidth source="type" label={translate('custom_fields.type')} validate={[required()]} choices={choicesCustomFieldsType(translate)} />
                  </Grid>
                </Grid> 
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={loading} >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

const MyActions = ({ basePath, data, resource }) => {

  const translate = useTranslate();   
    
  let linkBack = '/custom_fields';
        
  return (
    <>
      <TopToolbar>
        <Grid container spacing={2}>
          <Grid item xs={12}>   
            <div style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
              <Link
                to={linkBack}
                className=""
              >
                <ArrowBackIosNewIcon className="mr-2" />                        
              </Link>
              <Typography variant="h5" className="grey8">
                {data && data.name ? data.name : null}                    
              </Typography>
            </div>  
                
          </Grid>
        </Grid> 
      </TopToolbar>
    </>
  );
};


export const CustomFieldsEdit  = ({ id, ...props }) => {

  const translate = useTranslate();        
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  
  let strRedirect =  `/custom_fields/${id}`;    
    
  const onSuccess = ({ data }) => {
    notify(translate('common.notification.updated'));        
    redirect('/custom_fields');        
  };
        
  return (

    <Edit 
      title={<ElementTitle {...props}  />} 
      {...props} 
      id={id}
      mutationMode="pessimistic"
      actions={<MyActions {...props} />}
      onSuccess={onSuccess}   
    >
        
      <TabbedForm        
        toolbar={<FormToolbar />}
      >    
        <FormTab
          label={translate('classrooms.data')}
          contentClassName={classes.tab}
          path=""                 
        > 
               
          <FormDataConsumer>
            {({ formData, ...rest }) => 
              <>                              
                <br/>
                <Grid container spacing={2}>
                  <Grid item xs={3}>   
                    <TextInput fullWidth source="name" label={translate('custom_fields.name')} validate={[validateMaxLength(255,translate('common.validation.maxLength'))]} />
                  </Grid> 
                  <Grid item xs={3}>   
                    <SelectInput fullWidth source="type" label={translate('custom_fields.type')} validate={[required()]} choices={choicesCustomFieldsType(translate)} disabled={true} />
                  </Grid> 
                  <Grid item xs={3}>   
                    { formData.type == 1 ? 
                        <NumberInput fullWidth source="max_length" label={translate('custom_fields.max_length')} validate={[validateInteger(),validateIntegerLowerOrEqualThan(100,translate('common.validation.maxValue'))]} />
                    : formData.type == 2 || formData.type == 3 ?
                        <NumberInput fullWidth source="max_length" label={translate('custom_fields.max_length')} validate={[validateInteger(),validateIntegerLowerOrEqualThan(1000,translate('common.validation.maxValue'))]} />
                    : null }
                  </Grid> 
                  <Grid item xs={3}>   
                    <NumberInput fullWidth source="priority" label={translate('custom_fields.priority')} validate={[]} />
                  </Grid> 
                  
                  <Grid item xs={3}>   
                    <BooleanInput fullWidth source="actived" label={translate('custom_fields.actived')}  />
                  </Grid> 
                  <Grid item xs={3}>   
                    <BooleanInput fullWidth source="required" label={translate('custom_fields.required')}  />
                  </Grid> 
                  
                  { formData.target == targets.USER ? 
                  <Grid item xs={6}>   
                    <BooleanInput fullWidth source="form_web" label={translate('custom_fields.form_web')}  />
                  </Grid> 
                  : null }          
                  
                  { formData.target == targets.USER_FEDERATION ? 
                  <Grid item xs={6}>   
                    <BooleanInput fullWidth source="form_annuary" label={translate('custom_fields.form_annuary')}  />
                  </Grid> 
                  : null }  
                  
                </Grid>                                

              </>
            }
          </FormDataConsumer>

        </FormTab> 
        
        <FormTab
          label={translate('custom_fields.options')}
          contentClassName={classes.tab}
          path="options"                 
        > 
        
            <FormDataConsumer>
                {({ formData, ...rest }) => 
                <>     
                    { formData.type == 7 || formData.type == 8 ?
                    <CustomFieldsOptionsList custom_fields_id={id} />
                    : null }
                </>
                }
            </FormDataConsumer>
        
        </FormTab>
        
    
      </TabbedForm>
    </Edit>
  );
};

const ElementTitle = ({ record, ...props }) => {    
  const translate = useTranslate(); 
  return (<span>{translate('common.edit')} {record ? `"${record.name}"` : ''}</span>);
};

const FormToolbar = ({ id , ...props }) => {
    
  let redirect = '/custom_fields';
  const translate = useTranslate(); 
    
    
  return (
    <Toolbar {...props} className="user_form_toolbar" >
      <SaveButton />
      <DeleteButton 
        redirect={redirect} 
        mutationMode="pessimistic"
        confirmTitle={translate('custom_fields.delete_title')}
        confirmContent={translate('custom_fields.delete_ask')} />
    </Toolbar>
  );
};

const useStyles = makeStyles({
  comment: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    maxWidth: '100%',
    display: 'block',
  },
});






export const CustomFieldsCreate  = ({ id, ...props }) => {

  const translate = useTranslate();   
  const [info, setInfo] = useState();
    
  return (

    <Create {...props} >
      <SimpleForm 
        initialValues={{ associations_id: localStorage.getItem('associations_id') }}
        redirect="list"
      >                
        <Grid container spacing={2}>
          <Grid item xs={12}>                    
            <Typography variant="h5" className="grey">{translate('custom_fields.create')}</Typography>
          </Grid>  
          <Grid item xs={11}>                    
            <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
          </Grid>          
        </Grid>   

      </SimpleForm> 
    </Create>
  );
};


