// in src/posts.js
import React, { useState } from 'react';

import {
  List, 
  Datagrid,
  TextField,
  DeleteButton,
  Edit,
  Create,
  SimpleForm,
  TextInput, 
  CreateButton ,
  TopToolbar,
  FormDataConsumer,
  Filter, required,BooleanInput,  FunctionField, useRefresh, Toolbar, SaveButton, useDataProvider,
  BooleanField,
  useRedirect,
  useNotify
    
} from 'react-admin';

import { useEffect } from 'react';


import {useMediaQuery } from '@material-ui/core';


import { useTranslate  } from '../common/language';

import Typography from '@mui/material/Typography';



import Grid from '@mui/material/Grid';


import CorporateFareIcon from '@mui/icons-material/CorporateFare';



import { Empty } from './../components/Empty';
import { SendEmailIconStatus } from './../components/Emails';
import { targetsEmails } from './../common/constants';



import { validateMaxLength, validateEmail  } from './../components/Validator';

        
export const RecercatCentersList = (props) => {
  const translate = useTranslate();    
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  return (
    <><br />
      <List 
        {...props} 
        filter={{ associations_id : localStorage.getItem('associations_id') }} 
        actions={<ListActionsRecercatCentersList />}   
        bulkActionButtons={false}      
        sort={{ field: 'name', order: 'ASC' }} 
        empty={<Empty title={translate('recercat_centers.no_centers_title')} icon={CorporateFareIcon} />} 
        filters={<RecercatCentersFilter />} 
        filterDefaultValues={ { q: '' }}
      >        
        <Datagrid 
          rowClick="edit"
        >  
          <TextField source="public_id" label={translate('recercat_centers.public_id')} sortable={false} />
          <TextField source="name" label={translate('recercat_centers.name')} sortable={false} />
          <TextField source="city" label={translate('recercat_centers.city')} sortable={false} />
          <TextField source="email" label={translate('recercat_centers.email')} sortable={false} />
          <BooleanField source="actived" label={translate('recercat_centers.actived')} sortable={false} />   
          <FunctionField label={translate('recercat_centers.resume_send')} render={record => 
            <SendEmailIconStatus record={record} target={targetsEmails.RECERCAT_CENTER_RESUME}  />
          } sortable={false}  />
        </Datagrid>        
      </List>
    </>
  );
};

const RecercatCentersFilter = (props) => {
  const translate = useTranslate(); 
  return (
    <Filter {...props}>    
      <TextInput label={translate('common.search')} source="q" alwaysOn validate={[validateMaxLength(50,translate('common.validation.maxLength'))]} resettable />    
    </Filter>
  );
};

const ListActionsRecercatCentersList = (props) => {
  const translate = useTranslate(); 
  return (
    <TopToolbar>     
      <CreateButton label={translate('recercat_centers.create')} />      
    </TopToolbar>
  );
};

const FormToolbar = ({ id , ...props }) => {
    
  const translate = useTranslate();   
  let redirect = '/recercat_centers';
    
  return (
    <Toolbar {...props} className="user_form_toolbar" >
      <SaveButton />
      <DeleteButton 
        redirect={redirect} 
        mutationMode="pessimistic" 
        confirmTitle={translate('recercat_centers.delete_center_title')}
        confirmContent={translate('recercat_centers.delete_center_ask')} />
    </Toolbar>
  );
};

export const RecercatCentersEdit  = ({ id, ...props }) => {

  const translate = useTranslate();   
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
    
  const onFailure = (error) => {
    notify(error.message,'error');
  };
    
  return (

    <Edit 
      title={<ElementTitle />} 
      {...props} 
      id={id}
      mutationMode="pessimistic"
      onFailure={onFailure}
    > 
      <SimpleForm redirect="list" toolbar={<FormToolbar />} >
            
        <>  
            
          <FormDataConsumer>
            {({ formData, ...rest }) => 
                
              <>
                
                <Grid container spacing={2}>
                  <Grid item xs={12}>                    
                    <Typography variant="h5" className="grey">{formData.name}</Typography>
                  </Grid>  
                  <Grid item xs={1}>                    
                    <TextInput fullWidth source="public_id" label={translate('recercat_centers.public_id')} validate={[required(),validateMaxLength(3,translate('common.validation.maxLength'))]} disabled />
                  </Grid>
                  <Grid item xs={11}>                    
                    <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
                  </Grid>
                  <Grid item xs={6}>                    
                    <TextInput fullWidth source="city" label={translate('recercat_centers.city')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
                  </Grid>
                  <Grid item xs={6}>                    
                    <TextInput fullWidth source="email" label={translate('recercat_centers.email')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength')),validateEmail()]} />
                  </Grid>
                  <Grid item xs={3}>
                    <BooleanInput fullWidth source="actived" label={translate('recercat_centers.actived')} />
                  </Grid>                
                </Grid>   

              </>

            }
          </FormDataConsumer>

        </>

      </SimpleForm> 
    </Edit>
  );
};


export const RecercatCentersCreate  = ({ id, ...props }) => {

  const translate = useTranslate();   
  const dataProvider = useDataProvider();
  const [info, setInfo] = useState();

  useEffect(() => {
    dataProvider.getOne('recercat_centers/next_public_id', { id: localStorage.getItem('associations_id') })
      .then(( { data } ) => {   
        setInfo(data);
      })
      .catch(error => {         
      });
  }, []);
    
  return (

    <Create {...props} >
      <SimpleForm 
        initialValues={{ associations_id: localStorage.getItem('associations_id'), public_id: info ? info.next_public_id : '' }}
        redirect="list"
      >                
        <Grid container spacing={2}>
          <Grid item xs={12}>                    
            <Typography variant="h5" className="grey">{translate('recercat_centers.create')}</Typography>
          </Grid>  
          <Grid item xs={1}>                    
            <TextInput fullWidth source="public_id" label={translate('recercat_centers.public_id')} validate={[required(),validateMaxLength(3,translate('common.validation.maxLength'))]} />
          </Grid>
          <Grid item xs={11}>                    
            <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
          </Grid>
          <Grid item xs={6}>                    
            <TextInput fullWidth source="city" label={translate('recercat_centers.city')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength'))]} />
          </Grid>
          <Grid item xs={6}>                    
            <TextInput fullWidth source="email" label={translate('recercat_centers.email')} validate={[required(),validateMaxLength(255,translate('common.validation.maxLength')),validateEmail()]} />
          </Grid>
          <Grid item xs={3}>
            <BooleanInput fullWidth source="actived" label={translate('recercat_centers.actived')} />
          </Grid>                
        </Grid>   

      </SimpleForm> 
    </Create>
  );
};


const ElementTitle = ({ record }) => {
  const translate = useTranslate(); 
  return <span>{translate('common.edit')} {record ? `"${record.name}"` : ''}</span>;
};



