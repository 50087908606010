import { createRef } from 'react';

import {
  useTranslate ,
  Button   
} from 'react-admin';

import SaveIcon from '@material-ui/icons/GetApp';

import { apiUrl, apiOrigin, apiDefaultLanguage,  } from './../common/constants';


export function DownloadButtonAuth ({ ...props}) {
  const link = createRef();
  const translate = useTranslate(); 

  const handleAction = async () => {
    if (link.current.href) { return; }  

    let headers = new Headers({ Accept: 'application/json' });
    headers.set('Access-Control-Allow-Origin', apiOrigin );
    headers.set('Authorization', localStorage.getItem('auth'));
    headers.set('Accept-Language', apiDefaultLanguage);
    headers.set('Role', localStorage.getItem('active_permission'));

    const result = await fetch(apiUrl + '/docs/download/' + props.record.id , {	
      headers: headers
    });
    
    const blob = await result.blob();
    const href = window.URL.createObjectURL(blob);
    
    link.current.download = props.record.name;
    link.current.href = href;
      
    link.current.click();
  };

  return (
    <a role='button' ref={link} onClick={handleAction} style={{ textDecoration: 'none' }}>
      <Button label={props.label} startIcon={<SaveIcon />} />
    </a>
  );

}

export function DownloadPublicDoc ({ ...props}) {
  const link = createRef();
  const translate = useTranslate(); 

  const handleAction = async () => {
    if (link.current.href) { return; }  

    let headers = new Headers({ Accept: 'application/json' });
    headers.set('Access-Control-Allow-Origin', apiOrigin );
    headers.set('Accept-Language', apiDefaultLanguage);

    const result = await fetch(apiUrl + '/docs/download/public/' + props.doc.id , {	
      headers: headers
    });
    
    const blob = await result.blob();
    const href = window.URL.createObjectURL(blob);
    
    link.current.download = props.doc.name;
    link.current.href = href;
      
    link.current.click();
  };

  return (<a ref={link} onClick={handleAction}>{props.doc.name}</a>);

}