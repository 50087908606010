import React, { useState } from 'react';
import {
  useCreate,
  useNotify,
  useRefresh, useTranslate,
  useDataProvider, Confirm
} from 'react-admin';

import { targetsEmails, emailsStatus } from './../common/constants';

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

import Tooltip from '@mui/material/Tooltip';

export const SendEmailIconStatus = ({target, ...props}) => {
        
  const translate = useTranslate();  
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh(); 
  const [open, setOpen] = useState(false);
  const [openAgain, setOpenAgain] = useState(false);
  const [create, { loading }] = useCreate('bookings');
   
  // , associations_id: localStorage.getItem('associations_id')
  const sentEmail = (again) => {
                
    dataProvider.getOne(url , { id: props.record.id })
      .then(( { data } ) => {   
        if (again)
          setOpenAgain(false);
        else
          setOpen(false);
            
        notify(translate('bookings.email_sent_ok' ), 'success');            
        refresh();
      })
      .catch(error => {                
        notify(error.message, 'error');
        setOpen(false);
      });
        
  };
    
  const handleClickEmail = () => { setOpen(true);  };    
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => { sentEmail(false);  };
    
  const handleClickEmailAgain = () => { setOpenAgain(true); };
  const handleClickAgain = () => setOpenAgain(true);
  const handleDialogCloseAgain = () => setOpenAgain(false);
  const handleConfirmAgain = () => { sentEmail(true); };
      
  let url = '';
  let status = 0;
  let condition = false;
  let tooltip_button = '';
  let tooltip_ko = '';
  let tooltip_sending = '';
  let tooltip_done = '';
  let popup_title = '';
  let popup_content = '';
  let popup_content_again = '';
        
  if (target==targetsEmails.NEWADMIN)
  {
    // Correu nou administrador
    url='users_permissions/send_email';
    status = props.record.email_status;
    condition = true;
    tooltip_button = translate('users_permissions.email_tooltip_button'); 
    tooltip_sending = translate('users_permissions.email_tooltip_sending'); 
    tooltip_done = translate('users_permissions.email_tooltip_done'); 
    tooltip_ko = translate('users_permissions.email_sent_ko'); 
    popup_title = translate('users_permissions.email_sent_title');
    popup_content = translate('users_permissions.email_sent_ask');
    popup_content_again = translate('users_permissions.email_sent_ask_again');
  }
  else if(target==targetsEmails.WELCOME)
  {
    // Correu benvinguda
    url='users_groups/welcome_email';
    status = props.record.welcome_status;
    condition = (props.record.user.email || props.record.user.email_secondary) && props.record.group.public_id === 'SOCIS' ? true: false;
    tooltip_button = translate('users.welcome_email_tooltip_button'); 
    tooltip_sending = translate('users.welcome_email_tooltip_sending'); 
    tooltip_done = translate('users.welcome_email_tooltip_done'); 
    tooltip_ko = translate('users.welcome_email_sent_ko'); //TODO
    popup_title = translate('users.welcome_email_sent_title');
    popup_content = translate('users.welcome_email_sent_ask');
    popup_content_again = translate('users.welcome_email_sent_ask_again');
  }
  else if(target==targetsEmails.GROUP_LEFT)
  {
    // Correu de baixa
    url='users_groups/left_email';
    status = props.record.left_status;
    condition = (props.record.user.email || props.record.user.email_secondary) && props.record.group.public_id === 'SOCIS' && props.record.date_left != null ? true: false;
    tooltip_button = translate('users.group_left_email_tooltip_button'); 
    tooltip_sending = translate('users.group_left_email_tooltip_sending'); 
    tooltip_done = translate('users.group_left_email_tooltip_done'); 
    tooltip_ko = translate('users.group_left_email_sent_ko'); //TODO
    popup_title = translate('users.group_left_email_sent_title');
    popup_content = translate('users.group_left_email_sent_ask');
    popup_content_again = translate('users.group_left_email_sent_ask_again');
  }
  else if(target==targetsEmails.CLASSROOMREQUEST)
  {
        
  }
  else if(target==targetsEmails.ADDMEMBERREQUEST)
  {
        
  }
  else if(target==targetsEmails.RECEIVERETURNED)
  {
    // Notificació d'impagament
    url='remittances_users/send_email';
    status = props.record.returned_status;
    condition =  props.record.email && props.record.status== 1 ? true: false;
    tooltip_button = translate('remittances.send_returned_tooltip_button'); 
    tooltip_sending = translate('remittances.send_returned_tooltip_sending'); 
    tooltip_done = translate('remittances.send_returned_tooltip_done'); 
    tooltip_ko = translate('remittances.send_returned_ko'); 
    popup_title = translate('remittances.send_returned_notification_title');
    popup_content = translate('remittances.send_returned_notification_content');
    popup_content_again = translate('remittances.send_returned_notification_content_again');
  }
  else if(target==targetsEmails.ALIVE)
  {
        
  }
  else if(target==targetsEmails.EVENT_INVITE)
  {
    // Invitació
    url='bookings/send_email/0';
    status = props.record.invite_status;
    condition = (props.record.email || props.record.email_secondary) ? true: false;
    tooltip_button = translate('bookings.email_sent_tooltip_button_0'); 
    tooltip_sending = translate('bookings.email_sent_tooltip_sending_0'); 
    tooltip_done = translate('bookings.email_sent_tooltip_done_0'); 
    tooltip_ko = translate('bookings.email_sent_ko_0');
    popup_title = translate('bookings.email_sent_0');
    popup_content = translate('bookings.email_sent_ask_0');
    popup_content_again = translate('bookings.email_sent_ask_again_0');
  }
  else if(target==targetsEmails.EVENT_REMINDER)
  {
    // Recordatori
    url='bookings/send_email/1';
    status = props.record.reminder_status;
    condition = (props.record.email || props.record.email_secondary) ? true: false;
    tooltip_button = translate('bookings.email_sent_tooltip_button_1'); 
    tooltip_sending = translate('bookings.email_sent_tooltip_sending_1'); 
    tooltip_done = translate('bookings.email_sent_tooltip_done_1'); 
    tooltip_ko = translate('bookings.email_sent_ko_1');
    popup_title = translate('bookings.email_sent_1');
    popup_content = translate('bookings.email_sent_ask_1');
    popup_content_again = translate('bookings.email_sent_ask_again_1');
  }
  else if(target==targetsEmails.UNDELIVERED_EMAILS)
  {
        
  }
  else if(target==targetsEmails.ANNUARY_SEND)
  {
    // Enviament
    url='users_annuary/send_email/0';
    status = props.record.sent_status;
    condition = true;
    tooltip_button = translate('users_annuary.email_sent_tooltip_button_0'); 
    tooltip_sending = translate('users_annuary.email_sent_tooltip_sending_0'); 
    tooltip_done = translate('users_annuary.email_sent_tooltip_done_0'); 
    tooltip_ko = translate('users_annuary.email_sent_ko_0');
    popup_title = translate('users_annuary.email_sent_0');
    popup_content = translate('users_annuary.email_sent_ask_0');
    popup_content_again = translate('users_annuary.email_sent_ask_again_0');
  }
  else if(target==targetsEmails.ANNUARY_REMINDER)
  {
    // Recordatori
    url='users_annuary/send_email/1';
    status = props.record.reminder_status;
    condition = true;
    tooltip_button = translate('users_annuary.email_sent_tooltip_button_1'); 
    tooltip_sending = translate('users_annuary.email_sent_tooltip_sending_1'); 
    tooltip_done = translate('users_annuary.email_sent_tooltip_done_1'); 
    tooltip_ko = translate('users_annuary.email_sent_ko_1');
    popup_title = translate('users_annuary.email_sent_1');
    popup_content = translate('users_annuary.email_sent_ask_1');
    popup_content_again = translate('users_annuary.email_sent_ask_again_1');
  }
  else if(target==targetsEmails.ANNUARY_CONFIRM)
  {
    // Recordatori
    url='users_annuary/send_email/2';
    status = props.record.confirm_status;
    condition = props.record.answered_status;
    tooltip_button = translate('users_annuary.email_sent_tooltip_button_2'); 
    tooltip_sending = translate('users_annuary.email_sent_tooltip_sending_2'); 
    tooltip_done = translate('users_annuary.email_sent_tooltip_done_2'); 
    tooltip_ko = translate('users_annuary.email_sent_ko_2');
    popup_title = translate('users_annuary.email_sent_2');
    popup_content = translate('users_annuary.email_sent_ask_2');
    popup_content_again = translate('users_annuary.email_sent_ask_again_2');
  }
  else if(target==targetsEmails.DIGITAL_CARD_SEND)
  {
    // Carnet digital
    url='users_groups/digital_card/email';
    status = props.record.card_send_status;
    condition = (props.record.user.email || props.record.user.email_secondary) && (props.record.group.card_digital || props.record.group.card_digital_wallets) ? true: false;
    tooltip_button = translate('users.digital_card_tooltip_button'); 
    tooltip_sending = translate('users.digital_card_tooltip_sending'); 
    tooltip_done = translate('users.digital_card_tooltip_done'); 
    tooltip_ko = translate('users.digital_card_send_ko'); //TODO
    popup_title = translate('users.digital_card_send_title');
    popup_content = translate('users.digital_card_send_ask');
    popup_content_again = translate('users.digital_card_send_ask_again');
  }
  else if(target==targetsEmails.RECERCAT_CENTER_RESUME)
  {
    // Recercat center resume
    url='recercat_centers/resume_email';
    status = props.record.resume_send_status;
    condition = props.record.email && props.record.actived ? true: false;
    tooltip_button = translate('recercat_centers.resume_send_tooltip_button'); 
    tooltip_sending = translate('recercat_centers.resume_send_tooltip_sending'); 
    tooltip_done = translate('recercat_centers.resume_send_tooltip_done'); 
    tooltip_ko = translate('recercat_centers.resume_send_ko'); 
    popup_title = translate('recercat_centers.resume_send_title');
    popup_content = translate('recercat_centers.resume_send_ask');
    popup_content_again = translate('recercat_centers.resume_send_ask_again');
  }
  else if(target==targetsEmails.DONATIONS_USERS_CERTIFICATE)
  {
    // Enviament
    url='donations_users/certificate/send';
    status = props.record.send_status;
    condition = props.record.email && props.record.status == 1 && props.record.want_certificate;
    tooltip_button = translate('donations_users.email_sent_tooltip_button'); 
    tooltip_sending = translate('donations_users.email_sent_tooltip_sending'); 
    tooltip_done = translate('donations_users.email_sent_tooltip_done'); 
    tooltip_ko = translate('donations_users.email_sent_ko');
    popup_title = translate('donations_users.email_sent');
    popup_content = translate('donations_users.email_sent_ask');
    popup_content_again = translate('donations_users.email_sent_ask_again');
  }
  else if(target==targetsEmails.DONATIONS_USERS_CONFIRMATION)
  {
    // Enviament
    url='donations_users/confirmation/send';    
    status = props.record.confirmation_status;
    condition = props.record.email && props.record.status == 1;
    tooltip_button = translate('donations_users.email_sent_confirmation_tooltip_button'); 
    tooltip_sending = translate('donations_users.email_sent_confirmation_tooltip_sending'); 
    tooltip_done = translate('donations_users.email_sent_confirmation_tooltip_done'); 
    tooltip_ko = translate('donations_users.email_sent_confirmation_ko');
    popup_title = translate('donations_users.email_sent_confirmation');
    popup_content = translate('donations_users.email_sent_confirmation_ask');
    popup_content_again = translate('donations_users.email_sent_confirmation_ask_again');
  }
  else if(target==targetsEmails.ASSOCIATIONS_TRIAL || target==targetsEmails.ASSOCIATIONS_BEGIN || target==targetsEmails.ASSOCIATIONS_END)
  {
    // Enviament de notificació a associació
    url='associations/send/' + target;        
    
    status = 0;
    condition = false;
    
    if (target==targetsEmails.ASSOCIATIONS_TRIAL)
    {
        status = props.record.trial_status;  
        let i = 0;
        for(i=0;i<props.record.subscriptions.length ;i++)
        {
            if (props.record.subscriptions[i].modules_options.public_id == 'FREE')
            {
                condition = true;
            }
        }
    }    
    else if (target==targetsEmails.ASSOCIATIONS_BEGIN)
    {
        status = props.record.begin_status;        
    }
    else if (target==targetsEmails.ASSOCIATIONS_END)
    {
        status = props.record.end_status;
        let i = 0;
        for(i=0;i<props.record.subscriptions.length ;i++)
        {
            if (props.record.subscriptions[i].modules_options.public_id == 'FREE')
            {
                condition = true;
            }
        }
    }
        
    tooltip_button = translate('associations.email_sent_tooltip_button'); 
    tooltip_sending = translate('associations.email_sent_tooltip_sending'); 
    tooltip_done = translate('associations.email_sent_tooltip_done'); 
    tooltip_ko = translate('associations.email_sent_confirmation_ko');
    popup_title = translate('associations.email_sent');
    popup_content = translate('associations.email_sent_ask');
    popup_content_again = translate('associations.email_sent_ask_again');
  }
        
  return (
    <>
      {condition ? 
        <>
          { status === emailsStatus.SENDING_TO_SMTP ?
            <>
              <Tooltip title={tooltip_sending}> 
                <ForwardToInboxIcon onClick={ (e) => { e.stopPropagation(); handleClickEmail(props.record.id); } } /> 
              </Tooltip> 
            </>
            :
            <>
              { status === emailsStatus.SENT_TO_SMTP_OK ?
                <Tooltip title={tooltip_done}> 
                  <MarkEmailReadIcon onClick={ (e) => { e.stopPropagation(); handleClickEmailAgain(props.record.id); } } /> 
                </Tooltip> 
                : 
                <>
                  { status === emailsStatus.NOT_SENT || !status ?
                    <Tooltip title={tooltip_button}> 
                      <MailOutlineIcon className="grey2" onClick={ (e) => { e.stopPropagation(); handleClickEmail(props.record.id);}} /> 
                    </Tooltip>    
                    :
                    <>
                      <Tooltip title={tooltip_button}>       
                        <MailOutlineIcon onClick={ (e) => { e.stopPropagation(); handleClickEmail(props.record.id);}} /> 
                      </Tooltip>
                      <Tooltip title={tooltip_ko}>                            
                        <ErrorOutlineIcon className="red" />
                      </Tooltip>

                    </>
                  }
                </>
              }
            </>
          }
                
          <Confirm
            isOpen={open}
            loading={loading}
            title={popup_title}
            content={popup_content}
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
          />
          <Confirm
            isOpen={openAgain}
            loading={loading}
            title={popup_title}
            content={popup_content_again}
            onConfirm={handleConfirmAgain}
            onClose={handleDialogCloseAgain}
          />
        </>
                
        : null }
            
    </> 
            
  );
};
