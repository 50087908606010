// in src/posts.js
import * as React from 'react';
import { useState, useEffect } from 'react';

import {
  List, 
  Datagrid,
  TextField,
  EditButton,
  Edit,
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput, 
  CreateButton ,
  TopToolbar,
  FormTab,
  TabbedForm,
  ReferenceManyField,
  Filter, required,  BooleanInput, useDataProvider, FunctionField
    
} from 'react-admin';

import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';


import Typography from '@mui/material/Typography';

import {useMediaQuery } from '@material-ui/core';
//import Chip from '@material-ui/core/Chip';
import Chip from '@mui/material/Chip';

import { useFormState } from 'react-final-form';


import { PermissionsList  } from './users_permissions';


import { useTranslate  } from '../common/language';

import { targetsEmails,emailsStatus } from '../common/constants';
import { SendEmailIconStatus } from '../components/Emails';



import {
  DateField,
  DateInput
} from 'react-admin';




import { validateMaxLength  } from './../components/Validator';

export const AssociationsList = (props) => {
    
  const translate = useTranslate();      
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  return (
    <List {...props}         
      sort={{ field: 'id', order: 'DESC' }}  
      filters={<AssociationsFilter />}
      bulkActionButtons={false} 
      resource="associations"
      basePath="/associations"
      perPage={50}
      actions={<ListActions/>}
    >
        
      <Datagrid>                    
        <TextField source="name" label={translate('common.name')} sortable={false} />
        <FunctionField render={record => chipsSubscriptions(record.subscriptions) } label={translate('associations.subscriptions')} sortable={false}  /> 
        <FunctionField label={translate('associations.trial')} render={record => 
            <SendEmailIconStatus record={record} target={targetsEmails.ASSOCIATIONS_TRIAL}  />
        } sortable={false}  />
        <FunctionField label={translate('associations.end')} render={record => 
            <SendEmailIconStatus record={record} target={targetsEmails.ASSOCIATIONS_END}  />
        } sortable={false}  />
        <TextField source="num_users" label={translate('associations.report_users')} sortable={false} />
        <TextField source="num_groups" label={translate('associations.report_groups')} sortable={false} />
        <TextField source="num_emails" label={translate('associations.report_emails')} sortable={false} />
        <TextField source="num_emails_failed" label={translate('associations.report_emails_not_sent')} sortable={false} />
        <TextField source="num_docs" label={translate('associations.report_docs')} sortable={false} />
        <TextField source="total_size" label={translate('associations.report_mb')} sortable={false} />
        <TextField source="num_remittances" label={translate('associations.report_remittances')} sortable={false} />
        <TextField source="num_remittances_users" label={translate('associations.report_remittances_users')} sortable={false} />
        <DateField source="last_action" showTime  label={translate('associations.report_last_action')}/>
                    
                    

        <EditButton label="" />
      </Datagrid>
        
    </List>
  );
};

/*
 * <ArrayField source="subscriptions" label={translate('associations.subscriptions')} sortable={false} >
                        <SingleFieldList>
                            <ChipField source="modules.name" />
                        </SingleFieldList>
                    </ArrayField>
 */

const chipsSubscriptions = (subscriptions) => {
  let i = 0;
  let strChips = '';
    
  let arrSubscriptions = [];
  for(i=0;i<subscriptions.length ;i++)
  {
    //strChips+=subscriptions[i].modules.name + " - " + subscriptions[i].modules_options.name;
    let date_expiration = subscriptions[i].date_expiration.substr(0,10);
    let date = new Date(subscriptions[i].date_expiration.substr(0,10));
    let dateNow = new Date();
    var date30d = new Date();
    date30d.setDate(dateNow.getDate()+30);
        
    let color = 'green';
    if (date <= dateNow)
      color = 'red';
    else if (date <= date30d)
      color = 'orange';
        
    let aux_subscription = React.createElement(Chip, {label: subscriptions[i].modules.name , className: 'chip ' + color } );
    arrSubscriptions.push(aux_subscription);
  }
  const list = React.createElement('div', {}, arrSubscriptions);
                        
  return list;
};
                        

const ListActions = (props) => (
  <TopToolbar>        
    <CreateButton {...props} />
  </TopToolbar>
);

export const AssociationsEdit  = ({ id, ...props }) => {

  const translate = useTranslate();   
    
  const dataProvider = useDataProvider();
  const [info, setInfo] = useState();
    
  const today = new Date().toISOString().slice(0, 10);
  const future = new Date('2099-12-31').toISOString();

  useEffect(() => {
        
    dataProvider.getOne('associations', { id: id })
      .then(({ data }) => {     
        setInfo(data);
      })
      .catch(error => {           
      });
       
  }, []);
    
  return (
    
    <>
      <br />
      <Typography variant="h5" className="grey8" >
        {info ? info.name : null }              
      </Typography>
    
      <Edit title={<ElementTitle />} {...props}  id={id}
        mutationMode="pessimistic"> 
    
        <TabbedForm>
          <FormTab
            label={translate('associations.tab_subscriptions')}
          >

            <ReferenceManyField
              fullWidth
              reference="subscriptions"
              target="associations_id"
              label=""
            >

              <EditableDatagrid   
                fullWidth
                createForm={<OptionForm initialValues={{ associations_id: id, date_init: today, date_expiration : future }} edit={false} />}
                editForm={<OptionForm edit={true} />}
                label=""
                hasCreate
              >
                <TextField fullWidth source="modules.name" label={translate('subscriptions.module')} sortable={false} />
                <TextField fullWidth source="modules_options.name" label={translate('subscriptions.option')} sortable={false} />
                <DateField source="date_init" label={translate('subscriptions.date_init')} sortable={false} />
                <DateField source="date_expiration" label={translate('subscriptions.date_expiration')} sortable={false} />
                 
              </EditableDatagrid>

            </ReferenceManyField> 

          </FormTab>

          <FormTab
            label={translate('associations.tab_admin')}             
          >
            <PermissionsList id={id} />

          </FormTab>
            
          <FormTab
            label={translate('associations.tab_data')}
          >              
            <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />
          </FormTab>

        </TabbedForm>
    
      </Edit>
    </>
  );
};

/*
 * <FormTab
                label={translate('associations.tab_statistics')}
            >

            <br />
            <Card fullWidth>
                <CardHeader title="Estadístiques" />
                <CardContent>Aquí apareixeran dades estadístiques de l'associació.</CardContent>
            </Card>

            </FormTab>          
 */

export const AssociationsCreate = props => {
  const translate = useTranslate();   
  return (
    <Create {...props}>
      <SimpleForm redirect="edit">
        <TextInput fullWidth source="name" label={translate('common.name')} validate={[required(),validateMaxLength(100,translate('common.validation.maxLength'))]} />   
        <BooleanInput source="generate_data_demo" label={translate('associations.generate_data_demo')} />         
      </SimpleForm>
    </Create>
  );
};

const OptionsInput = props => {
  const translate = useTranslate();   
  const { values } = useFormState();
  return (
    values.modules_id ? 
      <ReferenceInput sort={{ field: 'priority', order: 'ASC' }} label={translate('subscriptions.option')} source="modules_options_id" reference="modules_options" filter={{ modules_id : values.modules_id }}>
        <SelectInput fullWidth optionText="name" optionValue="id" validate={required()} />
      </ReferenceInput> : ''
  );
};


const OptionForm= ({edit, ...props}) => {
  const translate = useTranslate();   
  const { values } = useFormState();
  return (
    <RowForm {...props}>
      <ReferenceInput label={translate('subscriptions.module')} source="modules_id" reference="modules"  sort={{ field: 'priority', order: 'ASC' }} >
        { edit ? (
          <SelectInput fullWidth optionText="name" optionValue="id" validate={required()} disabled />
        ) : (
          <SelectInput fullWidth optionText="name" optionValue="id" validate={required()} />
        ) }
      </ReferenceInput> 
      <OptionsInput source="modules_options_id" label={translate('subscriptions.option')}  />
      <DateInput source="date_init" validate={required()} label={translate('subscriptions.date_init')} />
      <DateInput source="date_expiration" validate={required()} label={translate('subscriptions.date_expiration')} />
    </RowForm>
  );
};




const AssociationsFilter = (props) => 
{ 
  const translate = useTranslate(); 
  return (
    <Filter {...props}>
      <TextInput label={translate('common.search')} source="q" alwaysOn validate={[validateMaxLength(100,translate('common.validation.maxLength'))]} />        
    </Filter>
  );
};


/*const ListActions = (props) => (
    <TopToolbar>
        {cloneElement(props.filters, { context: 'button' })}
        <CreateButton/>
        <ExportButton />        
    </TopToolbar>
);*/



const AssociationsTitle = ({ record }) => {
  return <span>Editar {record ? `"${record.name}"` : ''}</span>;
};


const ElementTitle = ({ record }) => {
  return <span>Editar {record ? `"${record.name}"` : ''}</span>;
};


