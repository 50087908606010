
import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY
} from 'react-admin';

import { apiUrl, apiDefaultLanguage, apiOrigin } from './../common/constants';

const simpleRestProvider = (apiUrl, httpClient = fetchUtils.fetchJson) => {
  /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = '';
    const options = {};
    var queryString = '';
    switch (type) {
    case 'search': {
      //console.log('search');  
      //console.log(params);  
      const page = 1;
      const perPage = 1000000;
      const field = 'first_name';
      const order = 'ASC';
      let targets_str = params.targets.length > 0 ? params.targets[0] : '';
      queryString = 'associations_id=' + localStorage.getItem('associations_id') + '&q=' + resource + '&targets=' + targets_str;
      url = `${apiUrl}/search?page=${page-1}&size=${perPage}&sort=${field},${order}&${queryString}`;
      break;
    }
    case 'getTree': {
      //console.log('getTree' + resource);  
      const page = 1;
      const perPage = 1000000;
      const field = 'priority';
      const order = 'ASC';        
      queryString = 'associations_id=' + localStorage.getItem('associations_id');
      url = `${apiUrl}/${resource}?page=${page-1}&size=${perPage}&sort=${field},${order}&${queryString}`;
      break;
    }
    case 'moveAsNthChildOf': {
      //console.log('moveAsNthChildOf'); 
      //console.log(params); 
      const source = params.source.id;  
      const destination = params.destination.id;
      const position = params.position;
      url = `${apiUrl}/${resource}/re-parent?source=${source}&destination=${destination}&position=${position}`;
      break;
    }
    case 'moveAsNthSiblingOf': {
      //console.log('moveAsNthSiblingOf'); 
      //console.log(params);   
      const source = params.source.id;  
      const destination = params.destination.id;
      const position = params.position;
      url = `${apiUrl}/${resource}/re-order?source=${source}&destination=${destination}&position=${position}`;
      break;
    }
    case GET_LIST: {
      //console.log('GET_LIST ' + resource);             
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;        
      queryString = Object.keys(params.filter).map(key => key !== 'filter' ? key + '=' + params.filter[key] : '').join('&');
      var queryStringFilter = '';
      if (params.filter && params.filter.filter )
      {
        queryStringFilter = '&filter=' + encodeURIComponent(JSON.stringify(params.filter.filter));
      }
      url = `${apiUrl}/${resource}?page=${page-1}&size=${perPage}&sort=${field},${order}&${queryString}${queryStringFilter}`;
      break;
    }
    case GET_ONE:
      //console.log('GET_ONE ' + resource);
      queryString = Object.keys(params).map(key => key !== 'id' ? key + '=' + params[key] : '').join('&');
      if(queryString.length > 0) queryString='?'+queryString.substring(1);
      url = `${apiUrl}/${resource}/${params.id}${queryString}`;
      break;
    case GET_MANY: {
      //console.log('GET_MANY ' + resource);
      if (params.ids.length === 1)
        url = `${apiUrl}/${resource}/${params.ids[0]}`;
      else
        url = `${apiUrl}/${resource}/many/${params.ids}`;
      break;
    }
    case GET_MANY_REFERENCE: {
      //console.log('GET_MANY_REFERENCE ' + resource);   
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      queryString = Object.keys(params.filter).map(key => key + '=' + params.filter[key]).join('&');

      url = `${apiUrl}/${resource}?page=${page-1}&size=${perPage}&sort=${field},${order}&${params.target}=${params.id}&${queryString}`;
      break;
    }
    case UPDATE:
      //console.log('UPDATE ' + resource);   
      url = `${apiUrl}/${resource}/${params.id}`;
      options.method = 'PUT';
      options.body = JSON.stringify(params.data);
      break;
    case CREATE:
      //console.log('CREATE');   
        
      options.method = 'POST';
      //console.log('CREATE ' + resource);

      if (params.data.image && params.data.image.rawFile)
      {
        // MULTIPART FORM (FITXER + JSON DADES)
        url = `${apiUrl}/${resource}`;
        let formData = new FormData();        
        //formData.append('postDto', JSON.stringify(params.data) );
        formData.append('associations_id', params.data.associations_id);
        formData.append('target', params.data.target);
        formData.append('items_id', params.data.items_id);
        formData.append('file', params.data.image.rawFile);
        options.body = formData;
      }
      else
      {
        // SENSE MULTIPART FORM
        url = `${apiUrl}/${resource}`;
        options.body = JSON.stringify(params.data);
      }
        
      break;
    case 'deleteBranch':
    {
      //console.log('deleteBranch ' + resource);   
      url = `${apiUrl}/${resource}/${params.id}`;
      options.method = 'DELETE';
      break;
    }
    case DELETE:
      //console.log('DELETE ' + resource);   
      url = `${apiUrl}/${resource}/${params.id}`;
      options.method = 'DELETE';
      break;
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} Data response
     */
  const convertHTTPResponse = (response, type, resource, params) => {
    const { json } = response;
    
    //alert("Passa per aqui?");
    if(response.status === 200 || response.status === 201 || response.status === 204 ){
        
      switch (type) {
      case GET_LIST:      
      case GET_MANY_REFERENCE:
        if (!json.hasOwnProperty('totalElements')) {
          throw new Error(
            'The numberOfElements property must be must be present in the Json response'
          );
        }         
        return {
          data: json.content ,
          total: parseInt(json.totalElements, 10)
        };
      case CREATE:
        //return { data: { ...params.data, id: json.id } };
        return { data: json };
      case GET_MANY:
        if (Array.isArray(json))
          return {
            data: json ,
            total: parseInt(json.totalElements, 10)
          };
        else
          return {
            data: [json] ,
            total: 1
          };
      case 'search':
        return {
          data: json.content ,
          total: parseInt(json.totalElements, 10)
        };

      case DELETE:
        //TODO: Revisar aquest retorn...
        return { data: { ...params.data, id: 0 } };

      default:            
        // GetTree, GET_ONE
        //alert("Default dataProvider" + type);
        return { data: json };
      }
    
    }else{
      //alert("Error dataProvider");
      if (json.message)
        throw new Error(json.message);
      else
        throw new Error('Error connectant amb la API...');
        //throw new Error('Errooooooor');
    }
    
  };

  /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
  return (type, resource, params) => {
    // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead

    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data)
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json)
      }));
    }
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'DELETE'
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json)
      }));
    }

    const { url, options } = convertDataRequestToHTTP(type, resource, params);
    return httpClient(url, options).then(response => { 
      return (        
      convertHTTPResponse(response, type, resource, params)); }
    );
  };
};





const fetchJson = (url, options = {}) => {

  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  options.headers.set('Access-Control-Allow-Origin', apiOrigin );
  options.headers.set('Authorization', localStorage.getItem('auth'));
  options.headers.set('Accept-Language', apiDefaultLanguage);
  options.headers.set('Role', localStorage.getItem('active_permission'));

  return fetchUtils.fetchJson(url, options);
};


const dataProvider = simpleRestProvider(apiUrl, fetchJson);

/*
const dataProviderWithTree = addTreeMethodsBasedOnParentAndPosition(
    dataProvider,
    'parent_id',
    'position',
    false
);




var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
*/

//console.log(dataProvider);
//const aaa = dataProvider.getList('groups-tree');
/*
var treeDataProvider = addTreeMethodsBasedOnParentAndPosition(dataProvider);
// We have to overwrite the getRootNodes because FakeRest does not support
// filtering by null values and returns all records
treeDataProvider.getRootNodes = function (resource) { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 , dataProvider.getList(resource, {
                    filter: { parent_id: null },
                    sort: { field: 'position', order: 'ASC' },
                    pagination: { page: 1, perPage: 1000 },
                })];
            case 1:
                data = (_a.sent()).data;
                return [2 , {
                        data: data
                            // We filter ourselves by excluding nodes which have a parent
                            .filter(function (record) { return record.parent_id === null; })
                            .map(function (record) { return (__assign(__assign({}, record), { children: [] })); }),
                    }];
        }
    });
}); };*/


export { dataProvider };
